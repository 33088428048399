/**
 * Use this file to share common functions across codebase
 */
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    public trimFormFieldValues(form: FormGroup): FormGroup {

        Object.entries(form.controls)
            .filter(([key, { value }]) => key && typeof value === 'string')
            .forEach(([key, control]) => control.setValue(control.value.trim()));

        return form;
    }
}
