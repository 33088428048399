<div class="model-search-section">
    <ngx-spinner class="spinner-attributes" bdOpacity=3 bdColor="rgba(0, 0, 0, 0.3)" size="medium" color="#4054B2"
        type="line-scale-party" [fullScreen]="true" [name]="SEARCH_MODEL_SPINNER">
        <p class="login-spinner spinner mt-3">Please wait...</p>
    </ngx-spinner>
    <div class="side-gutter">
        <div class="search-box  mt-5 mt-5">
            <div class="model-search-text">Search for a product</div>
            <div class="d-flex justify-content-between">
                <div class="mt-3"><img class="fa fa-search" (click)="modelsearch()"
                        src="./assets/images/dfr/search-icon.svg">
                    <input type="text" (keyup)="applyFilters()" [pattern]="'^[a-zA-Z \-\']$'" [(ngModel)]="searchString"
                        placeholder="Search by Model Number">
                </div>
                <div class="ml-auto"><button class="dfr-btn-light back" (click)="goBack()">Back</button></div>
            </div>
        </div>
    </div>
    <div class="container-ms">
        <div class="layout-ms mt-4">
            <div [ngClass]="(availableRacks?.length > 0)?'col-ms col-product-select':'selected-product-hide'" >
                <div class="model-selected-text">Selected Products</div>
                <form [formGroup]="selectedRackForm" *ngIf="availableRacks?.length > 0">
                    <div class="selected-models" *ngFor="let rack of availableRacks;index as i">
                        <div class="card1 card align-items-center" *ngIf="rack?.products[0]">                           
                            <div class="card-body">
                                <div class="d-flex justify-content-between">
                                    <h5 class="card-label ">{{rack?.productName}}
                                    </h5>
                                    <div class="align-items-center">
                                        <i class="view mr-3 mt-1" (click)="productDetails(rack)"
                                        title="view product details"></i>
                                        <i class="copy mr-3 mt-1" (click)="copyRack(rack?.nodeId)"
                                            title="copy rack"></i>
                                        <i class="delete mt-1" (click)="deleteRack(rack?.nodeId)"
                                            title="delete rack"></i>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-start">
                                    <span *ngIf="!rack?.displayEditRack" class="card-data">{{rack?.label}}</span>
                                    <div class="form-group required mt-2">
                                        <input validateOnBlur type="text" class="form-control"
                                            *ngIf="rack?.displayEditRack" class="revision-input" type="text"
                                            [(ngModel)]="rack.label" formControlName="rackName"
                                            [ngClass]="{ 'is-invalid':  (selectedRackForm.controls['rackName'].dirty || selectedRackForm.controls['rackName'].touched || submitted) && selectedRackForm.controls['rackName'].errors }">
                                        <div *ngIf="selectedRackForm.controls['rackName'].invalid && (selectedRackForm.controls['rackName'].dirty || selectedRackForm.controls['rackName'].touched || submitted)"
                                            class="invalid-feedback">
                                            <div *ngIf="selectedRackForm.controls['rackName'].errors?.required">
                                                Rack Name is required</div>
                                            <div *ngIf="selectedRackForm.controls['rackName'].errors?.pattern">
                                                Rack Name must be a character</div>
                                            <div *ngIf="selectedRackForm.controls['rackName'].errors?.maxlength">Rack
                                                Name
                                                must
                                                contain max of
                                                120
                                                characters</div>
                                        </div>
                                    </div>
                                    <span class="ml-1"><i *ngIf="!rack?.displayEditRack" class="fa fa-pencil cursor"
                                            aria-hidden="true" (click)="editRackName(rack)"
                                            title="Edit rack name"></i></span>

                                    <span class="mt-2 ml-2" *ngIf="rack?.displayEditRack">
                                        <i *ngIf="selectedRackForm.controls['rackName'].errors===null && selectedRackForm.controls['rackName'].dirty"
                                            (click)="onRackNameUpdate(rack?.label, rack)"
                                            class="fa fa-check cursor mr-1" aria-hidden="true"
                                            title="Update rack name"></i>
                                        <i (click)="cancelEditRackName(rack)" class="fa fa-times cursor"
                                            aria-hidden="true" title="Close"></i>
                                    </span>
                                </div>                               
                            </div>
                        </div>
                    </div>
                </form>

            </div>
            <div class="col-ms col-list-product">
                <div fxLayout="row wrap" fxLayoutWrap class="side-gutter product-wrapper mb-5">
                    <app-product-card *ngFor="let product of filteredProducts;index as i" [id]="'product'+i"
                        [product]="product" (productSelectEvent)="onProductSelect($event)"
                        (productDetailEvent)="productDetail($event)">
                    </app-product-card>
                </div>
                <div *ngIf="this.filteredProducts?.length < 1 && enableNoMatchFound" class="side-gutter no-match-min-h">
                    <span class="sorry-no-matches">Sorry, no matches found for <b>{{searchString}}</b>. Please try
                        again.</span>
                </div>
                <div *ngIf="this.filteredProducts?.length > 1 && enableNoMatchFound" class="save-version-section">
                    <div class="d-flex flex-row justify-content-center action-section">
                        <!-- <div class="d-flex flex-column mb-1 child-tpa">
                            <div class="p-2 bd-highlight"><span class="action-text">Edit Project Details</span>
                            </div>
                            <div class="p-2 bd-highlight"><button (click)="saveVersion()"
                                    class="save-button action-items" title="Save Project">
                                    Update Project</button></div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>