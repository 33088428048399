<div class="project-details mt-2">
    <div [ngClass]="{'wrapper_readOnly': project?.Status__c !== projectStatus.Active }">
        <form class="p-3" [formGroup]="createProjectForm" (ngSubmit)="onUpdateProject()">
            <div [ngClass]="{'disablePointerEvents': project?.Status__c !== projectStatus.Active}">
                <ngx-spinner class="spinner-attributes" bdOpacity=3 bdColor="rgba(0, 0, 0, 0.3)" size="medium"
                    color="#4054B2" type="line-scale-party" [fullScreen]="false" [name]="PROJECT_SPINNER">
                    <p class="login-spinner spinner mt-3">Please wait...</p>
                </ngx-spinner>
                <div class="d-flex align-items-center flex-nowrap">
                    <label *ngIf="!displayEditProject" class="sub-header m-2">{{getProjectName()}}
                        {{isPrimaryChanged}}</label>
                    <div class="form-group required ml-auto">
                        <input validateOnBlur type="text" class="form-control" *ngIf="displayEditProject"
                            class="project-input" type="text" formControlName="Name"
                            [ngClass]="{ 'is-invalid':  (createProjectForm.controls['Name'].dirty || createProjectForm.controls['Name'].touched || submitted) && createProjectForm.controls['Name'].errors }">
                        <div *ngIf="createProjectForm.controls['Name'].invalid && (createProjectForm.controls['Name'].dirty || createProjectForm.controls['Name'].touched || submitted)"
                            class="invalid-feedback">
                            <div *ngIf="createProjectForm.controls['Name'].errors?.required">
                                Project Name is required</div>
                            <div *ngIf="createProjectForm.controls['Name'].errors?.pattern">
                                Project Name must be a character</div>
                            <div *ngIf="createProjectForm.controls['Name'].errors?.maxlength">Project Name
                                must
                                contain max of
                                120
                                characters</div>
                        </div>
                    </div>
                    <div *ngIf="productsInRevisions.length>0" class="wrapper_expiration">
                        <span [ngClass]="expirationClass"></span>
                        <span class="expiration_Text">{{expirationText}}</span>
                    </div>
                    <span>
                        <span *ngIf="!displayEditProject">
                            <i (click)="openProject()" class="fa fa-pencil cursor mr-2" aria-hidden="true"
                                title="edit project details"></i>
                        </span>
                        <span *ngIf="displayEditProject">
                            <i *ngIf="createProjectForm.controls['Name'].errors===null && createProjectForm.controls['Name'].dirty"
                                (click)="onUpdateProject()" class="fa fa-check cursor" aria-hidden="true"
                                title="update"></i>
                            <i (click)="cancelEditProject()" class="fa fa-times cursor" aria-hidden="true"
                                title="Close"></i>
                        </span>
                    </span>
                </div>
                <div class="parent d-flex flex-wrap">
                    <div class="child">
                        <div for="CreatedDate" class="form-labels">Creation Date</div>
                        <div class="label-value">{{getControlValue('CreatedDate')}}</div>
                    </div>
                    <div class="child">
                        <div for="ModifiedTime" class="form-labels">Last Updated</div>
                        <div class="label-value">{{getControlValue('ModifiedTime')}}</div>
                    </div>
                    <div class="child">
                        <div for="revisionCount" class="form-labels">Revision Count</div>
                        <div class="label-value">{{revisionCount}}</div>
                    </div>
                    <div class="child">
                        <div for="revisionCount" class="form-labels">ASHRAE Ambient Temperature</div>
                        <div class="label-value">{{getControlValue('Ambient_Temp__c') !== null
                            ?getControlValue('Ambient_Temp__c')+' '+ getUnitSymbol() : 'NA'}}</div>
                    </div>

                    <div class="child">
                        <div class="wrap-status">
                            <div for="drop_ship_address_line_1__c" class="form-labels">Site Address</div>
                            <div class="label-value">{{getControlValue('drop_ship_address_line_1__c') !== null
                                ?getControlValue('drop_ship_address_line_1__c') : 'NA'}}</div>
                        </div>
                    </div>

                    <div class="child">
                        <div class="wrap-status">
                            <div for="Application Type" class="form-labels">Application Type</div>
                            <div class="label-value">{{getControlValue('application__c') !== null
                                ?getControlValue('application__c') : 'NA'}}</div>
                        </div>
                    </div>
                    <div class="child">
                        <div class="wrap-status">
                            <div for="Store Type" class="form-labels">Store Type</div>
                            <div class="label-value">{{getControlValue('storeType__c') !== null
                                ?getControlValue('storeType__c') : 'NA'}}</div>
                        </div>
                    </div>
                    <div class="child">
                        <div class="wrap-status">
                            <div class="control-labels wrap-label mt-3" for="Status__c">Project Status</div>
                            <select class="form-control status label-value" (change)="onUpdateProject()" id="Status__c"
                                formControlName="Status__c">
                                <option value="Active">Active</option>
                                <option value="Abandoned">Abandoned</option>
                                <option value="Completed">Completed</option>
                                <option value="Closed">Closed</option>
                            </select>
                        </div>
                    </div>
                    <div class="child">
                        <div for="Owner" class="form-labels">Project Owner</div>
                        <div class="label-value">{{getControlValue('Owner')}}</div>
                    </div>
                    <div class="child">
                        <div for="" class="form-labels-value-org">Sold to Customer</div>
                        <div class="label-value-org-value">{{accountName?accountName:'NA'}}</div>
                    </div>
                    <!-- new child added -->
                    <div class="child">
                        <div for="" class="form-labels-value-org">User Organization</div>
                        <div *ngIf="!isInternalUser" class="label-value-org-value">{{accountName?accountName:'NA'}}
                        </div>
                        <div *ngIf="isInternalUser" class="label-value-org-value">DFR</div>
                    </div>
                    <!-- end -->
                    <div class="child">
                        <div for="" class="form-labels-value-org">Station Name</div>
                        <div class="label-value-org-value">{{getControlValue('stationName__c')}}</div>
                    </div>
                    <!-- new added for unit -->
                    <div class="child">
                        <div class="wrap-status">
                            <div class="control-labels wrap-label mt-3" for="UnitType">Units of Measurement</div>
                            <select class="form-control status label-value" (change)="onUpdateUnit()" id="UnitType"
                                formControlName="UnitType">
                                <option value="metric">Metric</option>
                                <option value="imperial">Imperial</option>
                            </select>
                        </div>
                    </div>
                    <!-- end -->
                    <div class="child">
                        <div class="wrap-status" style="display:none">
                            <div class="control-labels wrap-label mt-3" for="storeType__c">Store Type</div>
                            <select class="form-control status" (change)="onUpdateProject()" id="storeType__c"
                                formControlName="storeType__c">
                                <option value="null" disabled selected>Store Type</option>
                                <option value="C-store">C-store</option>
                                <option value="Small Format">Small Format</option>
                                <option value="Supermarket">Supermarket</option>
                                <option value="Club Store">Club Store</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>