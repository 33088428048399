<!-- <app-banner></app-banner> -->
<div class="dashboard">
  <div class="actions">
    <div fxLayout="row" fxLayout.xs="row wrap">
      <div fxLayout="column" class="landing-rectangle-large start-project" fxFlex="100" fxLayoutAlign="start start">
        <!-- ball-scale-multiple line-scale-party square-loader-->
        <ngx-spinner class="spinner-attributes" bdOpacity=3 bdColor="rgba(0, 0, 0, 0.3)" size="medium" color="#4054B2"
          type="line-scale-party" [fullScreen]="true" [name]="ADD_PRODUCT_SPINNER">
          <p class="login-spinner mt-3">Configuration in progress. Please wait...</p>
        </ngx-spinner>
        <div class="row side-gutter">
          <!-- <label for="button" class="sub-header">Start a project</label> -->
          <img class="sub-header" src="assets/images/dfr/smart-select.png">
          <button (click)="openProject()" class="start-project-button dfr-btn-dark">START A NEW
            PROJECT</button>
        </div>
      </div>
    </div>
  </div>
  <div class="project-archive mt-5">
    <div>
      <div class="sub-header side-gutter mb-4">Active Projects</div>
      <div class="side-gutter">
        <app-project></app-project>
      </div>
    </div>
  </div>
  <hr class="dfr-hr">
  <div class="project-archive mt-5 mb-5">
    <div class="sub-header side-gutter mb-4">Project Archive</div>
    <div class="side-gutter">
      <app-archived-projects></app-archived-projects>
    </div>
  </div>
</div>