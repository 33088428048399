import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { bufferIf } from '@cpq-app/shared/libraries/rxjs-custom';
import { ProposalPreview } from '@cpq-app/tenants/Cpq.interfaces.service';
import { environment } from '@cpq-environments/environment';
import {
  BehaviorSubject,
  from,
  Observable,
  Observer,
  of,
  ReplaySubject,
  Subject,
  Subscription,
  throwError
} from 'rxjs';
import { bufferWhen, debounceTime, distinctUntilChanged, finalize, map, mergeMap, scan, switchMap, tap } from 'rxjs/operators';
import { v4 } from 'uuid';
import { CartService } from './cart.service';
import {
  CadData, ConfigData, ConfigNodeData, CopyNodeConfig, CopyNodeData,
  ExecuteResponse, NodeInfo, OptionType, OptionUpdatePayload, ServerFunctionReply
} from './product.interface';
export { CadData, ConfigData, CopyNodeData, ExecuteResponse, Grouping, Node, Option, SaveDataRCresponse } from './product.interface';


export enum FpxCpaasFunction {
  CreateNode = 'fpCreateNode',
  DeleteNode = 'fpDeleteNode',
  SetQuantity = 'fpSetQuantity',
  SetValue = 'fpSetValue',
  AlterAttribute = 'fpAlterAttribute'
}

enum FpxServerFunction {
  GetCadData = 'getCadData',
  SetCadData = 'setCadData',
  GetGroup = 'getGroup',
  MoveNode = 'moveNode',
  CopyNode = 'copyNode',
  CopyInstance = 'copyInstance',
  GetProposalData = 'getProposalData',
  GetProposalDataRacks = 'getProposalDataRacks'
}


const EXPERT_MODE_OPTION_ID = 'PSO_ExpertMode';
const ROOT_NODE_ID = '1';
const ROOT_PRODUCT_ID = '1';
const ProductServiceVariables = {
  cpq: 'cpq',
  SERVER_FUNCTION: 'function',
  cpaas: 'cpaas',
  configs: 'configs',
  rfst: 'rfst',
  execute: 'execute'
};

const NO_IMAGE_PLACEHOLDER = 'No_image_available.png';
//const PATH_TO_IMAGES = '/assets/images/vws/products/';
const HTML_IMG_TAG = '<img';
const HOSTED_IMAGE_URL = 'http';
const HTML_IMG_PATTERN = /cpq\/images\/([\w\d-._]+)\"/i;

interface WatcherCommand {
  add?: string,
  del?: string,
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  toasterProperty: any = {
    positionClass: 'toast-bottom-center',
    progressBar: true,
    progressAnimation: 'increasing'
  };
  private backendUrl = environment.B2CConfigs.BackendURL;
  private updatesInFlight = new Set<string>();
  private cadUpdatesInFlight = new Set<string>();

  private configSubjects = new Map<string, ReplaySubject<any>>();


  private cadSubjects = new Map<string, ReplaySubject<CadData>>();

  private dirtyConfigs = new Set<string>();
  private configChangeSubscriptions = new Map<string, Subscription>();
  private performOptionUpdate = new Subject<OptionUpdatePayload>();
  private watcher = new Subject<WatcherCommand>();

  enableBuffer: Observable<boolean> = this.watcher.pipe(
    scan((set: Set<string>, cmd: WatcherCommand) => {
      if (cmd.add) {
        set.add(cmd.add);
      }
      if (cmd.del) {
        set.delete(cmd.del)
      }
      return set;
    }, new Set<string>()),
    map(set => set.size > 0),
    tap(x => console.log(`Enable Buffer? ${x}`)),
  );

  public optionValueOrQuantityUpdated = new Subject<void>();

  public expand3dViewSubject = new BehaviorSubject<boolean>(false);
  /**
   * This Subject is used to trigger root level config when child objects are modified
   */
  public configDirty$ = new Subject<NodeInfo>();


  constructor(
    private http: HttpClient,
    private cartService: CartService,
  ) {
    this.configDirty$.subscribe({
      next: (configData: NodeInfo) => {
        const dirty = this.configDirty(configData.configID, configData.nodeID);
        dirty.complete();
      }
    });

    this.initOptionValueUpdateSubscriptions();
  }

  public configDirty(configId: string, nodeId?: string): Observer<void> {
    const configIdHash = this.calcConfigIdHash(configId, nodeId);

    this.dirtyConfigs.add(configIdHash);
    return {
      next: () => { },
      error: () => { },
      complete: () => {
        this.dirtyConfigs.delete(configIdHash);
        this.updateConfig(configId, nodeId);
      },
    };
  }

  /**
   * Returns an Observable (Subject) for the config data
   * @param configId the configuration session id as a `string`
   * @returns a `Subject`
   */
  public publicationForConfig(configId: string, nodeId?: string): Observable<ConfigData> {
    // If we don't already have a subject, set one up
    const configIdHash = this.calcConfigIdHash(configId, nodeId);

    if (!this.configSubjects.has(configIdHash)) {
      this.configSubjects.set(configIdHash, new ReplaySubject<ConfigData>(1));
      this.updateConfig(configId, nodeId);
    }

    return this.configSubjects.get(configIdHash);
  }

  private calcConfigIdHash(configId: string, nodeId = ROOT_NODE_ID): string {
    return `${configId}-${nodeId}`;
  }

  private calcCadConfigIdHash(configId: string, nodeId = ROOT_NODE_ID): string {
    return `${configId}-${nodeId}`;
  }

  public updateConfig(configId: string, nodeId = ROOT_NODE_ID) {
    const configIdHash = this.calcConfigIdHash(configId, nodeId);
    if (!this.dirtyConfigs.has(configIdHash) && !this.updatesInFlight.has(configIdHash)) {
      console.log(`Updating config ${configIdHash}`);
      this.updatesInFlight.add(configIdHash);

      const sub = this.getGroup(configId, nodeId)
        .pipe(
          finalize(() => this.updatesInFlight.delete(configIdHash)),
        )
        .subscribe({
          next: data => {
            const configSubject = this.configSubjects.get(configIdHash);
            if (configSubject) {
              configSubject.next(data);
            }

            if (this.cadSubjects.has(configId)) {
              this.updateCadData(configId); // TODO we should integrate the CAD data so that a second call isn't required
            }
          },
          error: error => {
            // retry??
            console.warn(`Updating config ${configIdHash} failed`, error);
          },
        });

      this.configChangeSubscriptions.set(configId, sub);

    } else {
      console.log(`Update in flight for ${configIdHash}`);
    }
  }

  public getGroup(configId: string, nodeId = ROOT_NODE_ID): Observable<ConfigData> {
    const url = this.cpqServerFunctionUrl(FpxServerFunction.GetGroup);
    const options = {
      params: {
        configId,
        nodeId
      },
      withCredentials: true
    };

    return new Observable<ConfigData>(observer => {
      const sub = this.http.post<ServerFunctionReply>(url, null, options).subscribe({
        next: reply => {
          try {
            const configData = JSON.parse(reply.result);
            observer.next(configData);
          } catch (err) {
            console.error(err); // FIXME: log / error handling
            observer.error(err);
          } finally {
            observer.complete();
          }
        },
        error: err => {
          console.error(err); // FIXME: log / error handling
          observer.error(err);
        },
      });

      return {
        unsubscribe: () => {
          sub.unsubscribe();
          observer.complete();
        }
      };
    });
  }

  flushCadSubjectBuffer(configId: string): void {
    if (this.cadSubjects.has(configId)) {
      this.cadSubjects.get(configId).complete();
      this.cadSubjects.delete(configId);
    }
  }
  /**
   * Returns an Observable (Subject) for the CAD data
   * @param configId the configuration session id as a `string`
   * @returns a `Subject`
   */
  public publicationForCad(configId: string, productId?: string, nodeId?: string): Subject<CadData> {

    // If we don't already have a subject, set one up
    const configIdHash = this.calcCadConfigIdHash(configId, nodeId);
    // if (!this.configSubjects.has(configIdHash)) {
    this.cadSubjects.set(configIdHash, new ReplaySubject<any>(1));
    this.updateCadData(configId, productId, nodeId);
    // }

    return this.cadSubjects.get(configIdHash);
  }

  /**
   * Triggers the CAD data to be refreshed.  Data can be obtained from the CAD data subscription.
   * @param configId the configuration session id as a `string`
   */
  public updateCadData(configId: string, productId?: string, nodeId?: string): void {
    const configIdHash = this.calcCadConfigIdHash(configId, nodeId);
    if (!this.dirtyConfigs.has(configIdHash) && !this.cadUpdatesInFlight.has(configIdHash)) {
      this.cadUpdatesInFlight.add(configIdHash);

      this.getCad(configId, productId, nodeId)?.subscribe((data: CadData) => {
        try {
          this.cadUpdatesInFlight.delete(configIdHash);
          this.cadSubjects.get(configId)?.next(data);
          const configSubject = this.cadSubjects.get(configIdHash);
          if (configSubject) {
            configSubject.next(data);
          }
        } catch (err) {
          console.error(err);
          // this.toast.error(err, this.toasterProperty);
        }
      }, err => {
        this.cadUpdatesInFlight.delete(configIdHash);
        // this.toast.error(err, this.toasterProperty);
      });


    } else {
      console.log(`CAD update in flight for ${configIdHash}`);
    }
  }
  /**
   * Process the Cad data and provides the latest cad data
   * @param configId - Id of the configuration; Required
   * @returns cad data to draw 3D
   */
  private getCad(configId: string, productBaseId?: string, nodeId?: string): Observable<CadData> {
    const url = this.cpqServerFunctionUrl(FpxServerFunction.GetCadData);
    let productId = Number(productBaseId) || null;
    const options = {
      params: {
        configId,
        nodeId,
        productId
      },
      withCredentials: true
    };
    return this.cadObservable(url, options);
  }

  getProposalData(quoteId: string): Observable<ProposalPreview> {
    const url = this.cpqServerFunctionUrl(FpxServerFunction.GetProposalData);
    const options = {
      params: {
        id: quoteId,
      },
      withCredentials: true
    };
    return new Observable<ProposalPreview>(observer => {
      const subscription = this.http.get<any>(url, options).subscribe(
        results => {
          const proposalData = JSON.parse(results?.result);
          observer.next(proposalData);
          observer.complete();
        },
        err => {
          // FIXME add error handling or remove this and allow it to bubble up
          console.log('Failed to fetch the quote with products', err);
          observer.error('Failed to fetch the quote with products');
        }
      );
      return {
        unsubscribe: () => {
          subscription.unsubscribe();
          observer.complete();
        }
      };
    });
  }

  getProposalDataRacks(quoteId: string): Observable<ProposalPreview> {
    const url = this.cpqServerFunctionUrl(FpxServerFunction.GetProposalDataRacks);
    const options = {
      params: {
        id: quoteId,
      },
      withCredentials: true
    };
    return new Observable<ProposalPreview>(observer => {
      const subscription = this.http.get<any>(url, options).subscribe(
        results => {
          const proposalData = JSON.parse(results?.result);
          observer.next(proposalData);
          observer.complete();
        },
        err => {
          // FIXME add error handling or remove this and allow it to bubble up
          console.log('Failed to fetch the quote with products', err);
          observer.error('Failed to fetch the quote with products');
        }
      );
      return {
        unsubscribe: () => {
          subscription.unsubscribe();
          observer.complete();
        }
      };
    });
  }


  cadObservable(url, options): Observable<CadData> {
    return new Observable<CadData>(observer => {
      const subscription = this.http.post<any>(url, null, options).subscribe({
        next: (reply: any) => {
          const cadData = JSON.parse(reply?.result);
          observer.next(cadData);
          observer.complete();
        },
        error: (err) => {
          console.error('There was an issue fetch new cad data', err);
        },
        complete: () => {
          observer.complete();
        }
      });

      return {
        unsubscribe: () => {
          subscription.unsubscribe();
          observer.complete();
        }
      };
    });
  }

  /**
   * Process the Cad data and provides the latest cad data
   * @param configId - Id of the configuration; Required
   * @param nodeID - Id of the node on which create/copy/move operation is performed; optional
   * @param targetXPosition - drop position(xAxis) for nodeID; optional
   * @returns cad data to draw 3D
   */
  private setCad(configId: string, nodeID: string, targetPositionX?: string): Observable<CadData> {
    const url = this.cpqServerFunctionUrl(FpxServerFunction.SetCadData);
    const options = {
      params: {
        configId,
        nodeId: nodeID,
        targetXPosition: targetPositionX,
      },
      withCredentials: true
    };

    return this.cadObservable(url, options);
  }

  selectProduct(configId: string, productId: string): Observable<ExecuteResponse> {
    this.flushCadSubjectBuffer(configId);
    return this.updateOptionQuantity(configId, productId, 1);
  }

  saveSelectionsToCart(configId: string, quoteId: string): Observable<any> {
    const url = this.cpqUrl(ProductServiceVariables.cpaas, ProductServiceVariables.configs, configId);
    return this.http.post(url, { quoteId }, { withCredentials: true }).pipe(
      switchMap(x => this.cartService.updateQuoteData(quoteId))
    );
  }

  updateProductToCart(
    configId: string,
    quoteId: string,
    productId: string,
    rfst: string
  ): Observable<any> {
    const url = this.cpqUrl(ProductServiceVariables.cpaas, ProductServiceVariables.configs, configId, productId);
    return this.http.put(url + `?${ProductServiceVariables.rfst}=` + rfst, null, { withCredentials: true }).pipe(
      switchMap(x => this.cartService.updateQuoteData(quoteId))
    );
  }

  /**
   * Updates an option quantity in FPX.
   *
   * @param configId a `string` of the configuration id
   * @param optionId a `string` of the option id
   * @param quantity a `number` for the new quantity
   */
  public updateOptionQuantity(configId: string, optionId: string, quantity: number, nodeId?: string): Observable<ExecuteResponse> {
    const success = new Subject<ExecuteResponse>();
    this.performOptionUpdate.next({ optionType: OptionType.Quantity, configId, optionId, quantity, nodeId, success });
    return success;
  }

  private setOptionQuantity(
    configId: string,
    optionId: string,
    quantity: number,
    nodeId?: string
  ): Observable<any> {
    console.log(`Sending update: ${optionId} to ${quantity}`);
    if (!configId) {
      return;
    }

    if (!optionId) {
      return;
    }

    const url = this.cpqUrl(ProductServiceVariables.cpaas, ProductServiceVariables.configs,
      configId, ProductServiceVariables.execute);
    const options = {
      withCredentials: true
    };
    const params = {
      key: optionId,
      quantity,
      nodeIndex: nodeId,
    };

    const body = [
      {
        name: FpxCpaasFunction.SetQuantity,
        args: params
      }
    ];

    return this.http.post<any>(url, body, options);
  }

  convertBufferToStream(buffer: OptionUpdatePayload[]): Observable<OptionUpdatePayload> {
    const reducedUpdates = new Map<string, any>();
    buffer.forEach(update => {
      if (reducedUpdates.has(update.optionId)) {
        // If we are replacing a value, close out it's observer
        reducedUpdates.get(update.optionId).success?.complete();
      }
      reducedUpdates.set(update.optionId, update);
    });
    return from(reducedUpdates.values());
  }

  initOptionValueUpdateSubscriptions() {
    const POST_DEBOUNCE_TIME = 750; // mS

    /*
    * Multiple unique effects are activated when an option update request is received. 
    */

    // Whenever an option needs to be updated, immediately cancel any pending or inflight GETs to prevent the local state
    // from being overwritten.
    this.performOptionUpdate.subscribe(({ configId }) => {
      if (this.configChangeSubscriptions.has(configId)) {
        this.configChangeSubscriptions.get(configId).unsubscribe();
        this.configChangeSubscriptions.delete(configId);
      }
    });

    // Whenever an option needs to be updated, debounce and accumulate updates, then send the results
    // to the backend.
    this.performOptionUpdate.pipe(
      distinctUntilChanged(),
      bufferWhen(() => this.performOptionUpdate.pipe(
        debounceTime(POST_DEBOUNCE_TIME),
      )),
      // The buffer returns an array of results, which may contain redundant values
      mergeMap(this.convertBufferToStream),
    ).subscribe(({ optionType, configId, optionId, value, quantity, nodeId, success }) => {
      console.log(`POSTing ${optionId} with ${value ? value : quantity}`);
      let setOption: Observable<any>;
      // POST
      switch (optionType) {
        case OptionType.Value:
          setOption = this.setOptionValue(configId, optionId, value, nodeId);
          break;

        case OptionType.Quantity:
        default:
          setOption = this.setOptionQuantity(configId, optionId, quantity, nodeId);
          break;
      }

      const postSub = setOption   // How should the POST subscription be handled? What code needs to be able to cancel it?
        .pipe(
          finalize(() => success.complete())
        )
        .subscribe({
          next: response => {
            const [result] = response?.results;
            if (!result?.success) { // Call Subscribe.error when results.success is false
              const [messageObj] = result?.errors || [];
              success.error(messageObj);
            } else {
              success.next(response);
              this.optionValueOrQuantityUpdated.next();
            }
          },
          error: reason => {
            success.error(reason);
            console.error('failed to post ' + reason);
          }
        });
    });


    // Whenever an option needs to be updated, ensure all updates complete before
    // requesting a state update. This is accomplished with two pieces of logic.
    // The first creates a unique tag-out for each option, the second waits for
    // all tag-outs to resolve.
    this.performOptionUpdate.subscribe(({ success }) => {
      let uuid = v4();

      this.watcher.next({ add: uuid });
      success.subscribe().add(() => this.watcher.next({ del: uuid }));
    });

    this.performOptionUpdate.pipe(
      // Strip out the update details leaving just the configId and nodeId
      map(({ configId, nodeId }) => ({ configId, nodeId })),
      // Accumulate all the impacted configs until debounce completes
      bufferWhen(() => this.performOptionUpdate.pipe(
        debounceTime(POST_DEBOUNCE_TIME),
      )),
      // Reduce redundant impacted configs
      mergeMap(updates => {
        const reducedUpdates = new Map<string, any>();
        updates.forEach(update => {
          const configIdHash = this.calcConfigIdHash(update.configId, update.nodeId);
          reducedUpdates.set(configIdHash, update);
        });
        return from(reducedUpdates.values());
      }),
      // Wait for POSTs to complete
      bufferIf(this.enableBuffer),
    ).subscribe(({ configId, nodeId }) => {
      this.updateConfig(configId, nodeId);
    });

  }


  public updateOptionValue(configId: string, optionId: string, value: any, nodeId?: string): Observable<ExecuteResponse> {
    const success = new Subject<ExecuteResponse>();
    this.performOptionUpdate.next({ optionType: OptionType.Value, configId, optionId, value, nodeId, success });
    return success;
  }

  private setOptionValue(
    configId: string,
    optionId: string,
    value: any,
    nodeId?: string
  ): Observable<any> {
    console.log(`Sending update: ${optionId} to ${value}`);
    if (!configId) {
      return;
    }

    if (!optionId) {
      return;
    }

    if (value == null) {
      return new Observable(obs => obs.error('A value is required'));
    }

    const url = this.cpqUrl(ProductServiceVariables.cpaas,
      ProductServiceVariables.configs,
      configId,
      ProductServiceVariables.execute);
    const options = {
      withCredentials: true
    };

    const params = {
      key: optionId,
      value,
      nodeIndex: nodeId,
    };

    const body = [
      {
        name: FpxCpaasFunction.SetValue,
        args: params
      }
    ];

    return this.http.post<any>(url, body, options);
  }

  public alterAttributeValue(
    configId: string,
    payload
  ): Observable<any> {
    if (!configId) {
      return;
    }

    if (!payload) {
      return;
    }

    if (payload == null) {
      return new Observable(obs => obs.error('A value is required'));
    }

    const url = this.cpqUrl(ProductServiceVariables.cpaas,
      ProductServiceVariables.configs,
      configId,
      ProductServiceVariables.execute);
    const options = {
      withCredentials: true
    };



    return this.http.post<any>(url, payload, options);
  }


  discardProduct(): boolean {
    return true;
  }

  closeProduct(): boolean {
    return true;
  }

  setExpertMode(configId: string, nodeIndex: string, state = true): Observable<ExecuteResponse> {
    return this.updateOptionQuantity(
      configId,
      EXPERT_MODE_OPTION_ID,
      state ? 1 : 0,
      nodeIndex
    );
  }

  // FIXME: We shouldn't have to defnie this twice; where does this belong?
  public cpqUrl(...args: string[]): string {
    let url = `${this.backendUrl}/${ProductServiceVariables.cpq}`;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < args.length; i++) {
      if (args[i] != null) {
        // Do not append null or undefined; doesn't stop empty strings
        url += '/' + args[i];
      }
    }

    return url;
  }

  private cpqServerFunctionUrl(funcName: FpxServerFunction): string {
    return this.cpqUrl(ProductServiceVariables.SERVER_FUNCTION, funcName);
  }

  public configCreateNodeAndUpdatePosition(
    configId: string,
    optionId: string, nodeId?: string): Observable<string> {
    const newNodeId = new Subject<string>();
    const dirty = this.configDirty(configId);

    this.createNode(configId, optionId, nodeId).pipe(
      switchMap(nodeId =>
        this.setCad(configId, nodeId).pipe(
          switchMap(x => of(nodeId))
        )
      )
    ).subscribe(
      nodeId => {
        newNodeId.next(nodeId);
        dirty.complete();
      },
      error => {
        newNodeId.next(error);
        dirty.complete();
      },
      () => {
        newNodeId.complete();
      }
    );
    return newNodeId;
  }

  public configCreateNode(
    configId: string,
    optionId: string,
    nodeId?: string
  ): Observable<string> {
    const newNodeId = new Subject<string>();
    const dirty = this.configDirty(configId);

    this.createNode(configId, optionId, nodeId).subscribe(
      resultNode => {
        newNodeId.next(resultNode);
        this.flushCadSubjectBuffer(configId);
        dirty.complete();
      },
      error => {
        newNodeId.next(error);
        dirty.complete();
      },
      () => {
        newNodeId.complete();
      }
    );
    return newNodeId;
  }

  private createNode(
    configId: string,
    optionId: string,
    nodeId?: string
  ): Observable<string> {
    if (!configId) {
      return;
    }
    if (!optionId) {
      return;
    }

    const url = this.cpqUrl(ProductServiceVariables.cpaas,
      ProductServiceVariables.configs,
      configId,
      ProductServiceVariables.execute);
    const options = {
      withCredentials: true
    };

    let nodePayLoad = {
      name: FpxCpaasFunction.CreateNode,
      args: {
        key: optionId,
        quantity: 1
      }
    };

    if (nodeId) {
      nodePayLoad.args['nodeIndex'] = nodeId;
    }

    const body = [nodePayLoad];

    return new Observable<string>(observer => {
      const subscription = this.http.post<ConfigNodeData>(url, body, options).subscribe(
        resp => {
          const res = resp.results[0];
          if (res.success) {
            observer.next(res.newNodeIndex);
          } else {
            observer.error(res.errors[0]?.message);
          }
          observer.complete();
        },
        err => {
          console.log('Failed to create node', err);
          observer.error(err);
        }
      );

      return {
        unsubscribe: () => {
          subscription.unsubscribe();
          observer.complete();
        }
      };
    });
  }

  /**
   * Removes a node from the configuration then refreshes the configuration state.
   * @param configId of the target config session
   * @param nodeIndex of the node to be removed
   * @returns `Observable` indicating success
   */
  public configDeleteNode(
    configId: string,
    nodeIndex: string
  ): Observable<boolean> {
    const isNodeDeleted = new Subject<boolean>();
    const dirty = this.configDirty(configId);

    this.deleteNode(configId, nodeIndex).subscribe(
      resultNode => {
        isNodeDeleted.next(resultNode);
        dirty.complete();
      },
      error => {
        isNodeDeleted.next(error);
        dirty.complete();
      },
      () => {
        isNodeDeleted.complete();
      }
    );
    return isNodeDeleted;
  }

  private deleteNode(
    configId: string,
    nodeIndex: string
  ): Observable<boolean> {
    if (!configId) {
      return;
    }
    if (!nodeIndex) {
      return;
    }

    const url = this.cpqUrl(ProductServiceVariables.cpaas,
      ProductServiceVariables.configs, configId,
      ProductServiceVariables.execute);
    const options = {
      withCredentials: true
    };
    const body = [
      {
        name: FpxCpaasFunction.DeleteNode,
        args: {
          nodeIndex
        }
      }
    ];
    return new Observable<boolean>(observer => {
      const subscription = this.http.post<ConfigNodeData>(url, body, options).subscribe(
        resp => {
          const res = resp.results[0];
          if (res.success) {
            observer.next(true);
          } else {
            observer.error(false);
          }
          observer.complete();
        },
        err => {
          console.log('Failed to delete Node', err);
          observer.error(false);
        }
      );

      return {
        unsubscribe: () => {
          subscription.unsubscribe();
          observer.complete();
        }
      };
    });
  }

  public configMoveNodeAndUpdatePosition(
    cfgId: string,
    nodeId: string,
    targetPosition: number,
  ): Observable<string> {
    if (!cfgId) {
      return throwError('Valid Config ID required');
    }
    if (!nodeId) {
      return throwError('valid Node ID required');
    }
    const isNodeMoved = new Subject<string>();
    const dirty = this.configDirty(cfgId);

    this.setCad(cfgId, nodeId, targetPosition.toString())
      .subscribe({
        next: data => {
          isNodeMoved.next(null);
          dirty.complete();
        },
        error: error => {
          isNodeMoved.next(error);
          dirty.complete();
        },
        complete: () => {
          isNodeMoved.complete();
        }
      });
    return isNodeMoved;
  }

  public configMoveNode(
    cfgId: string,
    currentPosition: number,
    targetPosition: number,
  ): Observable<boolean> {
    const isNodeMoved = new Subject<boolean>();
    const dirty = this.configDirty(cfgId);

    this.moveNode(cfgId, currentPosition.toString(), targetPosition.toString()).subscribe({
      next: isSuccess => {
        isNodeMoved.next(isSuccess);
        dirty.complete();
      },
      error: error => {
        isNodeMoved.next(error);
        dirty.complete();
      },
      complete: () => {
        isNodeMoved.complete();
      }
    });
    return isNodeMoved;
  }


  moveNode(cfgId: string, currentPosition: string, targetPosition: string): Observable<boolean> {
    const serFurl = this.cpqServerFunctionUrl(FpxServerFunction.MoveNode);

    const params = {
      configId: cfgId,
      currentPosition,
      targetPosition,
    };

    const options = {
      params,
      withCredentials: true
    };
    return new Observable<boolean>(observer => {
      const subscription = this.http.post<ConfigNodeData>(serFurl, null, options).subscribe(
        resp => {
          if (resp.success) {
            observer.next(true);
          } else {
            observer.error(resp.results);
          }
          observer.complete();
        },
        err => {
          console.log('Failed to move Node', err);
          observer.error(false);
        }
      );

      return {
        unsubscribe: () => {
          subscription.unsubscribe();
          observer.complete();
        }
      };
    });
  }

  public configCopyNodeAndUpdatePosition(
    cfgId: string,
    nodeId: string
  ): Observable<CopyNodeData> {
    const isNodeCopied = new Subject<CopyNodeData>();
    const dirty = this.configDirty(cfgId);

    this.copyNode(cfgId, nodeId)
      .pipe(
        switchMap(data =>
          this.setCad(cfgId, data.newNode).pipe(
            switchMap(x => of(data))
          )
        )
      )
      .subscribe({
        next: data => {
          isNodeCopied.next(data);
          dirty.complete();
        },
        error: error => {
          isNodeCopied.next(error);
          dirty.complete();
        },
        complete: () => {
          isNodeCopied.complete();
        }
      });
    return isNodeCopied;
  }

  public configCopyNode(
    cfgId: string,
    nodeId: string
  ): Observable<CopyNodeData> {
    const isNodeCopied = new Subject<CopyNodeData>();
    const dirty = this.configDirty(cfgId);

    this.copyNode(cfgId, nodeId).subscribe({
      next: data => {
        isNodeCopied.next(data);
        dirty.complete();
      },
      error: error => {
        isNodeCopied.next(error);
        dirty.complete();
      },
      complete: () => {
        isNodeCopied.complete();
      }
    });
    return isNodeCopied;
  }


  copyNode(cfgId: string, nodeId: string): Observable<CopyNodeData> {
    const serFurl = this.cpqServerFunctionUrl(FpxServerFunction.CopyNode);

    const params = {
      configId: cfgId,
      nodeId
    };

    const options = {
      params,
      withCredentials: true
    };
    return new Observable<CopyNodeData>(observer => {
      const subscription = this.http.post<CopyNodeConfig>(serFurl, null, options).subscribe(
        resp => {
          observer.next(JSON.parse(resp.result));
          observer.complete();
        },
        err => {
          console.log('Failed to copy Node', err);
          observer.error(err);
        }
      );

      return {
        unsubscribe: () => {
          subscription.unsubscribe();
          observer.complete();
        }
      };
    });
  }

  public configCopyInstance(
    cfgId: string,
    nodeId: string
  ): Observable<CopyNodeData> {
    const isNodeCopied = new Subject<CopyNodeData>();
    const dirty = this.configDirty(cfgId);

    this.copyInstance(cfgId, nodeId).subscribe({
      next: data => {
        isNodeCopied.next(data);
        dirty.complete();
      },
      error: error => {
        isNodeCopied.next(error);
        dirty.complete();
      },
      complete: () => {
        isNodeCopied.complete();
      }
    });
    return isNodeCopied;
  }

  copyInstance(cfgId: string, nodeId: string): Observable<CopyNodeData> {
    const serFurl = this.cpqServerFunctionUrl(FpxServerFunction.CopyInstance);

    const params = {
      configId: cfgId,
      nodeId
    };
    const options = {
      params,
      withCredentials: true
    };
    return new Observable<CopyNodeData>(observer => {
      const subscription = this.http.post<CopyNodeConfig>(serFurl, null, options).subscribe(
        resp => {
          observer.next(JSON.parse(resp.result));
          observer.complete();
        },
        err => {
          console.log('Failed to copy Node', err);
          observer.error(err);
        }
      );
      return {
        unsubscribe: () => {
          subscription.unsubscribe();
          observer.complete();
        }
      };
    });
  }

}
