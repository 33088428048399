import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CartService, CpqUser, CpqQueryObjects } from '@cpq-app/services/cart.service';
import { SalesforceProxyService, SfdcContact, SfdcTask, sfdcObjectType } from '@cpq-app/services/salesforce.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { DfrCpqOpportunity } from '../DFR.interfaces.service';
import { environment } from '@cpq-environments/environment';

const enum ModalContext {
  PRICE = 'price',
  TPS = 'tps'
}

const enum ASSISTANCE {
  REQUEST = 'Request for price',
  TPS_CONTACT = 'TPS Contact Request',
  PROPOSAL = 'Request for Complete Proposal'
}

@Component({
  selector: 'app-create-lead-sfdc',
  templateUrl: './create-task-sfdc.component.html',
  styleUrls: ['./create-task-sfdc.component.scss']
})
export class CreateLeadSfdcComponent implements OnInit {
  leadRequestForm: FormGroup;
  project: DfrCpqOpportunity;
  createTaskSubscription: Subscription;
  LEAD_SPINNER = "lead spinner";
  @Input() title: string;
  @Input() projectId: string;
  @Input() context: string;
  @Output() completeProposal = new EventEmitter<boolean>();

  constructor(public modal: NgbActiveModal,
    private formbuilder: FormBuilder,
    private salesforce: SalesforceProxyService,
    private toastr: ToastrService,
    private cartService: CartService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.createRequestPriceForm();
  }

  createRequestPriceForm() {
    let defaultComment;
    if (this.context === ModalContext.PRICE) {
      defaultComment = 'Please share budgetary estimate with price';
    } else {
      defaultComment = '';
    }
    const nonWhiteSpaceRegExp: RegExp = new RegExp('\\S');
    this.leadRequestForm = this.formbuilder.group({
      Comments__c: [defaultComment, [Validators.required, Validators.pattern(nonWhiteSpaceRegExp), Validators.maxLength(120)]],
    });
  }

  submitRequestForm() {
    this.createTask();
  }

  createTask() {
    const requestForm = this.leadRequestForm.value;
    const userType = sessionStorage.getItem('userType').toLowerCase();
    this.spinner.show(this.LEAD_SPINNER);
    let activityDate = new Date();
    const DAYS_UNTIL_CLOSE = 1;
    activityDate.setDate(activityDate.getDate() + DAYS_UNTIL_CLOSE);

    this.createTaskSubscription = this.getOpportunityDetails()
      .subscribe({
        next: (oppurtunity: any) => {
          this.project = oppurtunity;
          let accountId = userType === 'internal' ? environment.salesForce.dfrAccount : this.project['Account'].ExternalId;
          if (!accountId) {
            this.spinner.hide(this.LEAD_SPINNER);
            this.toastr.error('Please contact system administrator. Account is not available in salesforce.');
            return;
          }
          // create contact payload
          const sfdcContactPayload: SfdcContact = {
            FirstName: sessionStorage.getItem('UserFirstName'),
            LastName: sessionStorage.getItem('UserLastName'),
            Email: sessionStorage.getItem('Email'),
            AccountId: accountId,
            OwnerId: ''
          };
          // create task payload
          const sfdcTaskPayload: SfdcTask = {
            Subject: this.title === ASSISTANCE.REQUEST
              ? `${ASSISTANCE.REQUEST}: [${sessionStorage.getItem('UserFirstName')} ${sessionStorage.getItem('UserLastName')}]`
              : this.title === ASSISTANCE.TPS_CONTACT
                ? `${ASSISTANCE.TPS_CONTACT}: [${sessionStorage.getItem('UserFirstName')} ${sessionStorage.getItem('UserLastName')}]`
                : `${ASSISTANCE.PROPOSAL}: [${sessionStorage.getItem('UserFirstName')} ${sessionStorage.getItem('UserLastName')}]`,
            Priority: "Normal",
            Status: "Open",
            WhoId: "",
            Description: requestForm['Comments__c'] || '',
            CPQ_Project_Link__c: `${environment.B2CConfigs.redirectUrl}/productSelector/${this.projectId}`,
            ActivityDate: activityDate
          };

          this.salesforce.getSfdcAccountById(accountId).subscribe({
            next: (ownerID) => {
              if (ownerID.length) {
                let ownerId = ownerID[0].OwnerId;
                sfdcContactPayload.OwnerId = ownerId;
              } else {
                this.spinner.hide(this.LEAD_SPINNER);
                this.toastr.error('Error in fetching account details.');
                return;
              }
              this.salesforce.getSfdcCurrentContactByEmailId(sfdcContactPayload.Email).subscribe({
                next: (result) => {
                  if (result.length) {
                    sfdcTaskPayload.WhoId = result[0].Id;
                    this.createTaskInSFDC(sfdcTaskPayload);
                  } else {
                    this.salesforce.createSfdcObject(sfdcObjectType.CONTACT, sfdcContactPayload).subscribe({
                      next: (contact) => {
                        if (contact) {
                          sfdcTaskPayload.WhoId = contact.id;
                          this.createTaskInSFDC(sfdcTaskPayload);
                        }
                      },
                      error: err => {
                        this.spinner.hide(this.LEAD_SPINNER);
                        this.toastr.error('Error to create contact in Salesforce.');
                      }
                    })
                  }
                },
                error: err => {
                  this.spinner.hide(this.LEAD_SPINNER);
                  console.warn(`There was an issue while saving the config`, err)
                }
              });
            },
            error: err => {
              this.spinner.hide(this.LEAD_SPINNER);
              this.toastr.error('Error in fetching Sales force owner Id');
            }
          });
        }
      });
  }

  createTaskInSFDC(sfdcTaskPayload) {
    this.salesforce.createSfdcObject(sfdcObjectType.TASK, sfdcTaskPayload).subscribe({
      next: (task) => {
        this.toastr.success('Request submitted successfully');
        this.spinner.hide(this.LEAD_SPINNER);
        this.modal.dismiss()
        if (this.title === ASSISTANCE.PROPOSAL) {
          this.completeProposal.emit(true);
        }
      },
      error: err => {
        this.spinner.hide(this.LEAD_SPINNER);
      }
    })
  }

  getOpportunityDetails() {
    return this.cartService.getCpqObjects<DfrCpqOpportunity>(CpqQueryObjects.Opportunities, {
      Id: this.projectId, addonFields: DfrCpqOpportunity.addOnFields.join(', '), resolveNames: 'true'
    });
  }
}
