import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, retry } from 'rxjs/operators';
import { environment } from '@cpq-environments/environment';


@Injectable({
  providedIn: 'root'
})

export class EnergyCalculation {
  private _energybackendUrl = environment.fpx.energyAPI;

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------


  getAmbientTemperature(zipcode, latitude, longitude) {
    const url = `${environment.B2CConfigs.BackendURL}/energyConversion/temperature`;
    const params = new HttpParams()
      .set('zipCode', zipcode)
      .set('latitude', latitude)
      .set('longitude', longitude);
    return this._httpClient.get(url, { params }).pipe(
      catchError(this.handleError)
    );
  };


  getFlexModels(lowSaturationSuctionTemp, medSaturationSuctionTemp, lowTempRequiredLoad, medTempRequiredLoad, offsetAmbientTemp, percentCapacityGap, adiabaticOverride, zipcode, lat, log) {
    if (lat !== 0 && log !== 0) {
      zipcode = '0';
    }
    const url = `${environment.B2CConfigs.BackendURL}/energyConversion/flex/${lowSaturationSuctionTemp}/${medSaturationSuctionTemp}/${lowTempRequiredLoad}/${medTempRequiredLoad}/${offsetAmbientTemp}/${percentCapacityGap}/${adiabaticOverride}/${zipcode}/${lat}/${log}`;
    return this._httpClient.get(url).pipe(
      catchError(this.handleError)
    );
    // return this._httpClient.get(url).pipe(
    //   catchError(this.handleError)
    // );
  };

  getPakModels(refrigerationType, ambientTemperature, LtTemp, MtTemp, LtCapacity, MtCapacity, percentCapacityGap, offSetAmbientTemp) {
    const url = `${environment.B2CConfigs.BackendURL}/energyConversion/compressorcapacity/${refrigerationType}/${ambientTemperature}/${LtTemp}/${MtTemp}/${LtCapacity}/${MtCapacity}/${percentCapacityGap}/${offSetAmbientTemp}`;
    return this._httpClient.get(url).pipe(
      catchError(this.handleError)
    );
  };

  getModelCompressorCapacity(productId, refrigerationType, ambientTemperature, LtTemp, MtTemp,
    LtCapacity, MtCapacity, percentCapacityGap, offSetAmbientTemp) {

    const url = `${environment.B2CConfigs.BackendURL}/energyConversion/pakModelCompressorData`;
    const params = new HttpParams()
      .set('productId', productId)
      .set('refrigerationType', refrigerationType)
      .set('ambientTemperature', ambientTemperature)
      .set('ltTemp', LtTemp)
      .set('mtTemp', MtTemp)
      .set('ltCapacity', LtCapacity)
      .set('mtCapacity', MtCapacity)
      .set('percentCapacityGap', percentCapacityGap)
      .set('offSetAmbientTemp', offSetAmbientTemp);

    return this._httpClient.get(url, { params }).pipe(
      catchError(this.handleError)
    );
  };

  getModelDetails(data) {
    const url = `${environment.B2CConfigs.BackendURL}/energyConversion/modelDetails`;
    return this._httpClient.post(url, data).pipe(
      catchError(this.handleError)
    );
  };

  // get station data
  getStationResult(lat, log, unit) {
    // const url = `${environment.B2CConfigs.BackendURL}/energyConversion/stations/${lat}/${log}`;
    const url = `${environment.B2CConfigs.BackendURL}/cpq/stations/${lat}/${log}/${unit}`;
    return this._httpClient.get(url).pipe(
      catchError(this.handleError)
    );
  };

  handleError(error) {
    console.log(error);
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error.message;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
