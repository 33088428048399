<div class="product-selector mt-3">
    <div class="Project-details side-gutter">
        <div class="d-flex flex-row flex-wrap justify-content-between ml-4">
            <nav class="custom-breadcrumb" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><mat-icon class="breadcrumb-icons">home</mat-icon>
                        <a [routerLink]="['/']">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page"> <mat-icon
                            class="breadcrumb-icons">inventory</mat-icon>Project Details</li>
                </ol>
            </nav>
        </div>
        <app-project-details-component [zipCodeRequired]="isZipCodeEmpty" (onZipCode)="checkZipCode($event)"
            (onProjectStatusChange)="onProjectStatusChange($event)" [projectId]="projectID"
            [revisionCount]="revisions?.length" [isPrimaryChanged]="isPrimaryChanged"
            (onUnitChange)="onUnitChange($event)">
        </app-project-details-component>
    </div>
    <div class="side-gutter mb-5 mt-4">
        <ngx-spinner class="spinner-attributes" bdOpacity=3 bdColor="rgba(0, 0, 0, 0.3)" size="medium" color="#4054B2"
            type="line-scale-party" [fullScreen]="true" [name]="REVISION_SPINNER">
            <p class="login-spinner spinner mt-3">Please wait...</p>
        </ngx-spinner>
        <div class="d-flex flex-row justify-content-between ml-4">
            <div
                [ngClass]="{'wrapper_readOnly': project?.Status__c !== projectStatus.Active || isSubmittedToCRM}">
                <span
                    [ngClass]="{'disablePointerEvents': project?.Status__c !== projectStatus.Active || isSubmittedToCRM}">
                    <div><button (click)="newRevision()" class="dfr-btn-dark">
                        Add a New Version
                    </button></div>
                </span>
            </div>


            <div class="search-box mb-2" *ngIf="showSearchProduct">
                <img class="fa fa-search" (click)="startNewModelSearch()" src="./assets/images/dfr/search-icon.svg">
                <input type="text" (keydown.enter)="startNewModelSearch();" [(ngModel)]="searchString"
                    placeholder="Search by Model Number">
            </div>
            <div><button (click)="goToDashboard()" class="dfr-btn-light">Back To Dashboard</button></div>
        </div>
        <form class="p-3" [formGroup]="revisionForm">
            <div *ngFor="let revision of revisions; index as i;">
                <ngb-accordion class="accordion" #acc="ngbAccordion" [closeOthers]="true" [activeIds]="activeId"
                    (panelChange)="toggleAccordion($event, revision)">
                    <ngb-panel id="ngb-panel-{{i}}">
                        <ng-template ngbPanelHeader let-opened="opened">
                            <div class="d-flex align-items-center flex-wrap">
                                <button ngbPanelToggle class="chevron d-flex justify-content-between">
                                    <i class="fa"
                                        [ngClass]="{'fa-chevron-down': opened, 'fa-chevron-right': !opened}"></i>
                                </button>
                                <span *ngIf="!revisions[i].displayEditRevision"
                                    class="revision-text">{{revisions[i].Name}}</span>
                                <div class="form-group required mt-2">
                                    <input validateOnBlur type="text" class="form-control"
                                        *ngIf="revisions[i].displayEditRevision" class="revision-input" type="text"
                                        [(ngModel)]="revisions[i].Name" formControlName="quoteName"
                                        [ngClass]="{ 'is-invalid':  (revisionForm.controls['quoteName'].dirty || revisionForm.controls['quoteName'].touched || submitted) && revisionForm.controls['quoteName'].errors }">
                                    <div *ngIf="revisionForm.controls['quoteName'].invalid && (revisionForm.controls['quoteName'].dirty || revisionForm.controls['quoteName'].touched || submitted)"
                                        class="invalid-feedback">
                                        <div *ngIf="revisionForm.controls['quoteName'].errors?.required">
                                            Revision Name is required</div>
                                        <div *ngIf="revisionForm.controls['quoteName'].errors?.pattern">
                                            Revision Name must be a character</div>
                                        <div *ngIf="revisionForm.controls['quoteName'].errors?.maxlength">Revision Name
                                            must
                                            contain max of
                                            225
                                            characters</div>
                                    </div>
                                </div>
                                <span
                                    [ngClass]="{'wrapper_readOnly': project?.Status__c !== projectStatus.Active || isSubmittedToCRM}">
                                    <span
                                        [ngClass]="{'disablePointerEvents': project?.Status__c !== projectStatus.Active || isSubmittedToCRM }">
                                        <i *ngIf="!revisions[i].displayEditRevision" class="fa fa-pencil cursor"
                                            aria-hidden="true" (click)="editRevisionName(revisions[i])"
                                            title="Edit revision name"></i>
                                    </span>
                                </span>
                                <span *ngIf="revisions[i].displayEditRevision">
                                    <i *ngIf="revisionForm.controls['quoteName'].errors===null && revisionForm.controls['quoteName'].dirty"
                                        (click)="onRevisionNameUpdate(revisions[i].Name,revision)"
                                        class="fa fa-check cursor" aria-hidden="true" title="Update resvision name"></i>
                                    <i (click)="cancelEditRevisionName(revisions[i])" class="fa fa-times cursor"
                                        aria-hidden="true" title="Close"></i>
                                </span>
                                <div><span class="last-modified">Last
                                        Updated:<span
                                            class="pl-1">{{revision?.ModifiedTime|date:'yyyy-MM-dd'}}</span></span>
                                </div>


                                <div class="rev-btn-wrap-inner ml-auto mt-2 dispay_Expiration">
                                    <!-- TODO Check this condition *ngIf="revisions[i]?.revisionProductCount!==0 && revisions[i]?.revisionProductCount!==undefined" -->
                                    <div *ngIf="revisions[i]?.revisionProductCount!==0 && productsInRevisions?.length > 0"
                                        class="wrapper_expiration">
                                        <span [ngClass]="getExpirationClass(revision)"> </span>
                                        <span class="expiration_Text">{{getExpirationCText(revision)}}</span>
                                    </div>
                                    <div class="rev-btn-wrap-inner preferred">
                                        <label class="container primary-revision"><input
                                                [disabled]="project?.Status__c !== projectStatus.Active || isSubmittedToCRM"
                                                type="checkbox" [checked]="revision?.IsPreferred__c" [id]="i"
                                                [value]="i" (change)="onSettingPreferredRevisions(revision,$event)">
                                            <label [for]="i" title="Preferred"></label>
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="d-flex flex-wrap">
                                <div class="rev-btn-wrap-inner"><button type="button" class="dfr-btn-light small-btn"
                                        (click)="copyRevision(revision)"
                                        [disabled]="project?.Status__c !== projectStatus.Active || isSubmittedToCRM">Copy</button>
                                </div>

                                <div class="rev-btn-wrap-inner"><button type="button" class="dfr-btn-light small-btn"
                                        (click)="editRevision(revision)"
                                        [disabled]="isZipCodeEmpty ||  project?.Status__c !== projectStatus.Active || isSubmittedToCRM">{{
                                        project?.Status__c !== projectStatus.Active ?
                                        'View' : 'Edit'}}</button>
                                </div>
                                <!-- <div class="rev-btn-wrap-inner"><button type="button" class="dfr-btn-light small-btn"
                                        (click)="editRevision(revision)">Edit</button>
                                </div> -->
                                <div class="rev-btn-wrap-inner"><button type="button" [disabled]="revision?.IsPrimary"
                                        class="dfr-btn-light small-btn"
                                        (click)="deleteRevision(revision)">Delete</button>
                                </div>
                                <div class="rev-btn-wrap-inner"><button type="button" class="dfr-btn-light small-btn"
                                        (click)="onClickPreview(revision)" [disabled]="productsInRevisions?.length===0"
                                        *ngIf="(revision?.isExpiredProposal && isInternalUser)">Preview</button>
                                </div>
                                <ng-template #ToolTipTemplate>
                                    <small>Revision is Expired.</small>
                                </ng-template>
                                <!-- <div class="rev-btn-wrap-inner" *ngIf="revision?.Proposals.length > 0">
                                    <span [ngbTooltip]="(revision?.isExpiredProposal) ? ToolTipTemplate : ''">
                                        <select required class="form-control selected_report" id="proposalReport"
                                            (change)="downloadProposal()"
                                            [disabled]="(revision?.isExpiredProposal||productsInRevisions?.length===0 ||  project?.Status__c !== projectStatus.Active )">
                                            <option value="" disabled selected hidden>Download Proposal</option>
                                            <option *ngFor="let report of proposalReports">
                                                <span>{{report.name}}</span>
                                            </option>
                                        </select>
                                    </span>
                                </div> -->
                                <!-- new code for download -->
                                <div class="rev-btn-wrap-inner">
                                    <span [ngbTooltip]="(revision?.isExpiredProposal) ? ToolTipTemplate : ''">
                                        <app-proposal-report [reportData]="proposalResult" [QuoteId]="quoteId"
                                            [isDisable]="(revision?.isExpiredProposal||productsInRevisions?.length===0 ||  project?.Status__c !== projectStatus.Active )"></app-proposal-report>
                                    </span>
                                </div>

                                <!-- end -->
                                <div class="rev-btn-wrap-inner">
                                    <div class="rev-btn-wrap-inner">
                                        <label class="container-Primary"><input
                                                [disabled]="project?.Status__c !== projectStatus.Active || isSubmittedToCRM"
                                                type="checkbox" [checked]="revision?.IsPrimary" id="primaryRevision"
                                                (change)="onPrimaryQuote(revision,$event.target.checked)" class="pv_Cb">
                                            <span class="primary-revision">Primary Version</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="rev-btn-wrap-inner"><button type="button" class="dfr-btn-light small-btn"
                                        [disabled]="project?.Status__c !== projectStatus.Active || isSubmittedToCRM"
                                        (click)="requestPrice()" *ngIf="isEorUser">Request
                                        Price</button>
                                </div>
                                <!-- new SFDC button -->
                                <!-- this.completeProposalBtn TODO -->
                                <div *ngIf="!isEorUser"
                                    [ngClass]="{'wrapper_readOnly': project?.Status__c !== projectStatus.Active || isSubmittedToCRM}">
                                    <span
                                        [ngClass]="{'disablePointerEvents': project?.Status__c !== projectStatus.Active || isSubmittedToCRM}">
                                        <div><button (click)="completeSfdcProposal()" class="dfr-btn-dark">Request for a
                                                complete proposal</button></div>
                                    </span>
                                </div>
                                <!-- end code-->
                            </div>
                            <div class="d-flex flex-wrap w-100">
                                <div class="revision-product-info d-flex flex-wrap"
                                    *ngIf="productsInRevisions?.length > 0">
                                    <div class="capacity">
                                        <div class="header">Total Capacity</div>
                                        <table mat-table class="table-responsive" [dataSource]="productsInRevisions">
                                            <ng-container matColumnDef="ProductId">
                                                <th mat-header-cell *matHeaderCellDef></th>
                                                <td mat-cell *matCellDef="let element"> {{element.ProductId}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="MedTempCompressorCapacity">
                                                <th mat-header-cell *matHeaderCellDef> MT </th>
                                                <td mat-cell *matCellDef="let element">
                                                    <span *ngIf="element?.type==='Co2Model'">
                                                        {{element.MedTempExcessPercent === 0
                                                        ?0:element.MedTempCompressorCapacity}}
                                                    </span>
                                                    <span
                                                        *ngIf="element?.type!=='Co2Model'">{{element.MedTempCompressorCapacity}}
                                                    </span>
                                                </td>
                                            </ng-container>


                                            <ng-container matColumnDef="LowTempCompressorCapacity">
                                                <th mat-header-cell *matHeaderCellDef> LT </th>
                                                <td mat-cell *matCellDef="let element">
                                                    <span *ngIf="element?.type==='Co2Model'">
                                                        {{element.LowTempExcessPercent === 0
                                                        ?0:element.MedTempCompressorCapacity}}</span>
                                                    <span *ngIf="element?.type!=='Co2Model'">
                                                        {{element.LowTempCompressorCapacity}} </span>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Total_Capacity__c">
                                                <th mat-header-cell *matHeaderCellDef></th>
                                                <td mat-cell *matCellDef="let element">
                                                    <span *ngIf="element?.type==='Co2Model'">
                                                        <span
                                                            *ngIf="element.LowTempExcessPercent === 0">{{element.MedTempCompressorCapacity}}</span>
                                                        <span
                                                            *ngIf="element.MedTempExcessPercent === 0">{{element.MedTempCompressorCapacity}}</span>
                                                    </span>
                                                    <span *ngIf="element?.type!=='Co2Model'">
                                                        {{ ((parseCapacity(element.MedTempCompressorCapacity) || 0) +
                                                        (parseCapacity(element.LowTempCompressorCapacity) || 0)).toFixed(2)
                                                        }} {{totalUnit}}

                                                    </span>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="header-row-base-group">
                                                <th mat-header-cell *matHeaderCellDef [attr.colspan]="1">
                                                    Model Name
                                                </th>
                                            </ng-container>


                                            <ng-container matColumnDef="header-row-first-group">
                                                <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                                                    Compressor Capacity
                                                </th>
                                            </ng-container>


                                            <ng-container matColumnDef="header-row-capacity-group">
                                                <th mat-header-cell *matHeaderCellDef [attr.colspan]="0">
                                                    Total Capacity
                                                </th>
                                            </ng-container>

                                            <tr mat-header-row
                                                *matHeaderRowDef="['header-row-base-group','header-row-first-group','header-row-capacity-group']">
                                            </tr>
                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                        </table>
                                        <div
                                            [ngClass]="{'wrapper_readOnly': project?.Status__c !== projectStatus.Active || isSubmittedToCRM}">
                                            <div
                                                [ngClass]="{'disablePointerEvents': project?.Status__c !== projectStatus.Active || isSubmittedToCRM }">
                                                <div class="note-title">
                                                    Comments or Special Instructions</div>
                                                <textarea *ngIf="revisions[i].displayEditNote" formControlName="note"
                                                    type="text" class="note-content"
                                                    [(ngModel)]="revisions[i].Note"></textarea>
                                                <div class="note-content" *ngIf="!revisions[i].displayEditNote">
                                                    {{revisions[i].Note}}
                                                </div>
                                                <div class="justify-content-start"> <button type="button"
                                                        class="dfr-btn-light small-btn"
                                                        *ngIf="!revisions[i].displayEditNote"
                                                        (click)="editRevisionNote(revisions[i])">
                                                        Edit Comment
                                                    </button>
                                                    <button type="button" class="dfr-btn-light small-btn"
                                                        *ngIf="revisions[i].displayEditNote"
                                                        (click)="onRevisionNoteUpdate(revisions[i].Note, revision)">
                                                        Save Comment
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </form>
    </div>
</div>