import { Injectable } from '@angular/core';
import { UserAgentApplication } from 'msal';
import { environment } from '../../../environments/environment';

@Injectable()
export class MSALService {
  B2C_RESET_PASSWORD_ERROR = 'AADB2C90118';
  private app: any;
  private accessToken = null;
  public isAuthenticated = false;
  private timeLeft = 3598;
  private applicationConfig = {
    clientID: environment.B2CConfigs.clientId,
    authority: `${environment.B2CConfigs.authURL}/${environment.B2CConfigs.tenant}/${this.getPolicyName('signinPolicy')}`,
    b2cScopes: [environment.B2CConfigs.b2cScopes],
    redirectUrl: environment.B2CConfigs.redirectUrl,
    state: environment.B2CConfigs.redirectUrl,
    tenant: environment.B2CConfigs.tenant,
    b2cLoginURL: environment.B2CConfigs.b2cLoginURL,
    passwordChangeSuccessURL: environment.B2CConfigs.passwordChangeSuccessUrl,
    resetPasswordPolicy: `${this.getPolicyName('resetPasswordPolicy')}`,
    changePasswordPolicy: `${this.getPolicyName('changePasswordPolicy')}`,
    postLogoutRedirectUri: environment.B2CConfigs.redirectUrl,
  };
  isForgotPassword = false;
  changePasswordValue = false;

  opcoName: string;

  constructor() {
    this.createUserAgent('/');
  }

  createUserAgent(beforeAuthUrl) {
    this.app = new UserAgentApplication(
      this.applicationConfig.clientID,
      this.applicationConfig.authority,
      this.authCallBack.bind(this),
      {
        storeAuthStateInCookie: true,
        redirectUri: this.applicationConfig.redirectUrl,
        navigateToLoginRequestUrl: false,
        postLogoutRedirectUri: this.applicationConfig.redirectUrl,
        cacheLocation: 'sessionStorage',
        validateAuthority: false,
        state: beforeAuthUrl
      }
    );
  }


  authCallBack(errorDesc, token, error, tokenType) {
    if (token) {
      this.isAuthenticated = true;
      this.accessToken = token;
      this.isForgotPassword = false;
    } else if (errorDesc) {
      this.isAuthenticated = false;
      if (errorDesc.indexOf(this.B2C_RESET_PASSWORD_ERROR) > -1) {
        this.callResetPasswordPolicy();
        return;
      }
    } else if (error) {
      this.isAuthenticated = false;
      if (error.errorMessage.indexOf(this.B2C_RESET_PASSWORD_ERROR) > -1) {
        this.callResetPasswordPolicy();
        return;
      }
    }
  }

  getPolicyName(key) {
    let domainURL = window.location.href;
    let signinPolicy;
    let redirectURL;
    let resetPasswordPolicy;
    let changePasswordPolicy;
    if (domainURL.toLowerCase().includes('vws')) {
      signinPolicy = environment.B2CConfigs.signinPolicy.VWS;
      redirectURL = environment.B2CConfigs.redirectUrl;
      resetPasswordPolicy = environment.B2CConfigs.resetPasswordPolicy.VWS;
      changePasswordPolicy = environment.B2CConfigs.changePasswordPolicy.VWS;
      this.opcoName = 'VWS';
    } else if (domainURL.toLowerCase().includes('twg')) {
      signinPolicy = environment.B2CConfigs.signinPolicy.TWG;
      redirectURL = environment.B2CConfigs.redirectUrl;
      resetPasswordPolicy = environment.B2CConfigs.resetPasswordPolicy.TWG;
      changePasswordPolicy = environment.B2CConfigs.changePasswordPolicy.TWG;
      this.opcoName = 'TWG';
    }
    else if (domainURL.toLowerCase().includes('dfr-co2-dev')) {
      signinPolicy = environment.B2CConfigs.signinPolicy.DFR;
      redirectURL = environment.B2CConfigs.redirectUrl;
      resetPasswordPolicy = environment.B2CConfigs.resetPasswordPolicy.DFR;
      changePasswordPolicy = environment.B2CConfigs.changePasswordPolicy.DFR;
      this.opcoName = 'DFR';
    } else if (domainURL.toLowerCase().includes('cpqportaluat-dfr')) {
      signinPolicy = environment.B2CConfigs.signinPolicy.DFR;
      redirectURL = environment.B2CConfigs.redirectUrl;
      resetPasswordPolicy = environment.B2CConfigs.resetPasswordPolicy.DFR;
      changePasswordPolicy = environment.B2CConfigs.changePasswordPolicy.DFR;
      this.opcoName = 'DFRUAT';
    } else {
      signinPolicy = environment.B2CConfigs.signinPolicy.DFR;
      redirectURL = environment.B2CConfigs.redirectUrl;
      resetPasswordPolicy = environment.B2CConfigs.resetPasswordPolicy.DFR;
      changePasswordPolicy = environment.B2CConfigs.changePasswordPolicy.DFR;
      this.opcoName = 'DFR';
    }
    sessionStorage.setItem('opcoName', this.opcoName);
    if (key === 'signinPolicy') return signinPolicy;
    else if (key === 'redirectURL') return redirectURL;
    else if (key === 'resetPasswordPolicy') return resetPasswordPolicy;
    else if (key === 'changePasswordPolicy') return changePasswordPolicy;
  }


  callResetPasswordPolicy(): void {
    this.isForgotPassword = true;
    window.location.href = `${this.applicationConfig.b2cLoginURL}/${this.applicationConfig.tenant}/oauth2/v2.0/authorize?p=${this.applicationConfig.resetPasswordPolicy}&client_id=${this.applicationConfig.clientID}&nonce=defaultNonce&redirect_uri=${this.applicationConfig.redirectUrl}&scope=openid&response_type=id_token`;
  }

  callChangePasswordPolicy(): void {
    // this.changePassword = true;
    // sessionStorage.setItem('changePassword', this.changePassword.toString());
    let locationURL = window.location.href;
    this.changePasswordValue = true;
    sessionStorage.setItem('changePasswordStore', this.changePasswordValue.toString());
    locationURL = `${this.applicationConfig.b2cLoginURL}/${this.applicationConfig.tenant}/oauth2/v2.0/authorize?p=${this.applicationConfig.changePasswordPolicy}&client_id=${this.applicationConfig.clientID}&nonce=defaultNonce&redirect_uri=${this.applicationConfig.redirectUrl}
    &scope=openid&response_type=id_token&username=${sessionStorage.getItem('username')}&opcoName=${sessionStorage.getItem('opcoName')}`;
  }

  login(beforeAuthUrl) {
    this.createUserAgent(beforeAuthUrl);
    this.app.loginRedirect(this.applicationConfig.b2cScopes);
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.app.logout();
  }

  isOnline(): boolean {
    return this.app.getUser();
  }

  getUser() {
    const user = this.app.getUser();
    return user;
  }

  getTokenId() {
    if (this.accessToken) {
      return this.accessToken;
    } else {
      return sessionStorage.getItem('msal.idtoken');
    }
  }

  getAudience() {
    return this.app.getUser().idToken.aud;
  }

  getTenantName() {
    let tenantName: string = this.app.getUser().idToken.OpcoName;
    return sessionStorage.getItem('opcoName').toUpperCase();
  }

  clearCache(): any {
    this.app.clearCache();
  }

  setTimer() {
    setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.getToken();
      }
    }, 1000);
  }

  getToken() {
    return this.app.acquireTokenSilent(this.applicationConfig.b2cScopes).then(
      (accessToken) => {
        return accessToken;
      },
      (error) => {
        return this.app
          .acquireTokenRedirect(this.applicationConfig.b2cScopes)
          .then(
            (accessToken) => {
              return accessToken;
            },
            (err) => {
              console.error(error);
            }
          );
      }
    );
  }

  getUserOid() {
    return this.getUser().idToken.oid;
  }
}


