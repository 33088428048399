import { Injectable } from '@angular/core';
import { MSALService } from '../shared/services/msal.service';
import { DFRRoutes } from './DFR/dfr-routing.module';

@Injectable()
export class TenantRoutingService {
    static getTenantRoutes() {
        let TenantRoutes = [];
        let msalService = new MSALService();
        let user = msalService.getUser();
        //In future, we can access required token to get the opco related information
        if (localStorage.getItem('logged_in') != 'true') {
            TenantRoutes = DFRRoutes;
        } else {
            TenantRoutes = DFRRoutes
        }
        return TenantRoutes;
    }
}