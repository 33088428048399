import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CartService, CpqQueryObjects, CpqUser, CpqObjectType } from '@cpq-app/services/cart.service';
import { ConfigData, ExecuteResponse, FpxCpaasFunction, ProductService } from '@cpq-app/services/product.service';
import { SalesforceProxyService } from '@cpq-app/services/salesforce.service';
import { EnergyCalculation } from '@cpq-app/shared/services/energy-calculation.service';
import { MSALService } from '@cpq-app/shared/services/msal.service';
import { environment } from '@cpq-environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CpqOperations } from '../cpqOperations.service';
import { DfrCpqOpportunity, DfrCpqQuote, DFRProduct, DfrRevisions, DfrSfdcOpportunity, FlexModelResult, QueryParams, RackData, SfdcLeadSourceCO2 } from '../DFR.interfaces.service';
import { DfrSfdcOpportunityStage } from '../guided-selling/guided-selling.component';
import { SaveProjectComponent } from '../save-project/save-project.component';
const ROOT_NODE_INDEX = '1';
const DELAY_BEFORE_SAVE = 2000;
const NEWRACK = 'NewRack';
const MAXRACK_COUNT = 9;

const SFDC_DEFAULT_OPP_NAME = 'Cpq Opportunity';
@Component({
  selector: 'app-model-search',
  templateUrl: './model-search.component.html',
  styleUrls: ['./model-search.component.scss']
})
export class ModelSearchComponent implements OnInit, OnDestroy {

  filteredProducts: any[] = [];
  configId: string;
  generalSubscription: Subscription;
  searchString: string;
  availableProducts: any[] = [];
  opportunityId: string;
  quoteId: string;
  loadingSearchResults = false;
  enableNoMatchFound = false;
  SEARCH_MODEL_SPINNER = "search model spinner";
  SEARCH_MODEL_SPINNER1 = "search model spinner1";
  // ----------------------------------------------------------------
  subscription$: Subscription[] = [];
  projectId: string;
  revisionId: string;
  hasRevisionRecords: boolean;
  revisions: DfrCpqQuote[] = [];
  revisionName: string;
  displayEditRevision = false;
  productId: string;

  currentRevisionEditId: string;
  opcoName: string;
  updateTarget: string;
  navigateToProductDetailsPage: boolean = false;
  canEdit = true;
  productBaseId: number;
  isInternalUser: boolean;

  newRackKey: string;
  rootDesignCriteria;
  availableRacks: any[];
  rackDesignCriteria;
  recommendedProducts: any[];
  selectedProducts: any[];
  selectedProduct: DFRProduct;
  currentNodeIndex = ROOT_NODE_INDEX;
  rackNode: string;
  submitted = false;
  DESIGN_CRITERIA_SPINNER = "Design criteria";
  SELECTED_MODEL_SPINNER = "Selected Model";
  rackData: RackData[];
  rackInfo: RackData[] = [];

  init$: Subscription[] = [];
  configSubscription: Subscription;
  saveSubscription: Subscription;
  updateSubscription: Subscription;
  deleteNodeSubscription: Subscription;
  moveNodeSubscription: Subscription;
  copyNodeSubscription: Subscription;
  customerViewSubscription: Subscription;
  createNodeSubscription: Subscription;
  nodeGroupSubscription: Subscription;
  classApplied = false;
  selectedRackForm: FormGroup;
  saveProjectForm: FormGroup;
  ActivateNode: number;
  productCadId: string;

  project: DfrCpqOpportunity;
  zipCode: string;
  latitude: number;
  longitude: number;


  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private cartService: CartService,
    private productService: ProductService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private dfrCpqOperations: CpqOperations,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private activatedRoute: ActivatedRoute,
    private salesforce: SalesforceProxyService,
    private energyService: EnergyCalculation
  ) { }


  ngOnInit(): void {
    this.generalSubscription = this.activatedRoute.paramMap.subscribe(
      paramMap => {
        this.configId = paramMap.get('configId');
        this.opportunityId = paramMap.get('projectId');
        this.projectId = paramMap.get('projectId');
        this.revisionId = paramMap.get('revisionId');
        this.quoteId = paramMap.get('revisionId');
      },
      err => {
        this.toastr.error(
          'There was an error while fetching Model Information', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      }
    );

    const querySubscription = this.activatedRoute.queryParamMap.subscribe(
      queryParamMap => {
        this.searchString = queryParamMap.get('search')?.trim();
        this.zipCode = queryParamMap.get('zipCode')?.trim();
        this.loadingSearchResults = true;
      },
      err => {
        this.toastr.error(
          'There was an error while fetching Model Information', 'Error', {
          disableTimeOut: true,
          closeButton: true
        });
      }
    );
    this.generalSubscription.add(querySubscription);
    this.init$.push(this.route.params.subscribe({
      next: params => {
        this.initialFormData();
        this.getRevisionInfo();
        this.setupConfigSubscription(this.configId);
      },
    }));
    this.submitConfiguration();
  }

  getRevisionInfo() {
    try {
      this.subscription$.push(this.dfrCpqOperations.getQuoteInfo(this.revisionId).subscribe({
        next: (revision: DfrRevisions) => {
          this.revisionName = revision?.Name;
        },
        error: err => {
          this.spinner.hide(this.SEARCH_MODEL_SPINNER);
        }
      }));
    } catch (err) {
      this.spinner.hide(this.SEARCH_MODEL_SPINNER);
    }
  }

  setExternalParams(rack) {
    const attributesToBeUpdated = ['LT_EvapCapacity', 'MT_EvapCapacity', 'GasCoolerOutletTemp', 'GasCoolerOutletPressure', 'LowTempExcessCapacity', 'MedTempExcessCapacity'];

    this.subscription$.push(this.cartService.getCpqObjects<DfrCpqOpportunity>(CpqQueryObjects.Opportunities, {
      Id: this.projectId, addonFields: DfrCpqOpportunity.addOnFields.join(', '), resolveNames: 'true'
    })
      .subscribe({
        next: (opportunity: any) => {
          this.project = opportunity;
          console.log('this.project model search', this.project);
          // if(!opportunity?.Zip_Code__c) {
          //   this.zipCode = '30013';
          // } 
          this.zipCode = opportunity?.Zip_Code__c ? opportunity?.Zip_Code__c : '0';
          this.latitude = opportunity?.latitude__c ? opportunity?.Zip_Code__c : 0;
          this.longitude = opportunity?.longitude__c ? opportunity?.Zip_Code__c : 0;

          this.energyService.getFlexModels(0, 0, 0, 0, 0, 0, false, this.zipCode, this.latitude, this.longitude).subscribe(
            (res: FlexModelResult) => {
              if (res.flexModels.length > 0) {
                let payload = [];
                res.flexModels.forEach((model) => {
                  let params, payloadObject = {};
                  attributesToBeUpdated.forEach((attribute) => {

                    let attributeValue;
                    if (attribute === 'LT_EvapCapacity') {
                      attributeValue = model.lowTempCompressorCapacity;
                    } else if (attribute === 'MT_EvapCapacity') {
                      attributeValue = model.medTempCompressorCapacity;
                    } else if (attribute === 'GasCoolerOutletTemp') {
                      attributeValue = model.gasCoolerOutletTemp;
                    } else if (attribute === 'GasCoolerOutletPressure') {
                      attributeValue = model.gasCoolerOutletPressure;
                    } else if (attribute === 'LowTempExcessCapacity') {
                      attributeValue = model.lowTempExcessCapacity;
                    } else if (attribute === 'MedTempExcessCapacity') {
                      attributeValue = model.medTempExcessCapacity;
                    }

                    params = {
                      key: model.modelname,
                      attribute: attribute,
                      value: attributeValue,
                      nodeIndex: rack.nodeId,
                    };

                    payloadObject = {
                      name: FpxCpaasFunction.AlterAttribute,
                      args: params
                    }
                    payload.push(payloadObject);
                  });
                })
                this.productService.alterAttributeValue(this.configId, payload).subscribe((res) => {
                  console.log('upated flex models');
                  this.updateProductCollections();
                  this.submitConfiguration();
                });
              }
            },
            err => {
              this.spinner.hide(this.SEARCH_MODEL_SPINNER);
              console.log('Error on flex models load', err);
            }
          );
        },
        error: err => {
          this.toastr.error(
            'There is fatal error while fetching Project details', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      }));
  }

  updateProductCollections() {
    this.spinner.show(this.SEARCH_MODEL_SPINNER);
    const subscription$ = this.productService
      .publicationForConfig(this.configId)
      .subscribe(
        data => {
          if (data.products?.related?.length > 3) {
            this.availableProducts = data.products?.related || [];
            this.newRackKey = NEWRACK;
            this.availableProducts.forEach((availableProduct: DFRProduct) => {
              availableProduct.imageName = availableProduct.imageName.replace(/["']/g, '');
              availableProduct['imageUrl'] = `${environment.B2CConfigs.BackendURL}/cpq/images/${availableProduct.imageName}`;
            });
            if (this.availableProducts.length > 0) { this.applyFilters(); }
          }
        },
        err => {
          this.availableProducts = [];
          this.filteredProducts = [];
          this.spinner.hide(this.SEARCH_MODEL_SPINNER);
        }
      );
    this.generalSubscription.add(subscription$);
  }

  applyFilters() {
    try {
      const searchStr = this.searchString?.trim().toLowerCase() || '';
      this.filteredProducts = this.availableProducts.filter(item =>
        item.name.toLowerCase().includes(searchStr) || item.tags.toLowerCase().includes(searchStr)
      );
      this.filteredProducts.sort((a, b) => (a.sequence || 0) - (b.sequence || 0));
    } finally {
      if (this.filteredProducts.length > 0) {
        this.enableNoMatchFound = true;
        setTimeout(() => this.spinner.hide(this.SEARCH_MODEL_SPINNER), DELAY_BEFORE_SAVE);
      }
    }

  }

  setQuantityAndNavigate(product): void {
    this.spinner.show();
    this.productService.selectProduct(this.configId, product.name).subscribe({
      next: () => {
        this.router.navigate([
          '/products/configuration',
          this.opportunityId,
          this.quoteId,
          this.configId
        ]);
      },
      error: () => {
        this.spinner.hide();
        // Message?
      },
    });
  }
  modelsearch() {
    if (this.searchString) {
      this.applyFilters();
    }
  }
  // -----------------------------------------------------
  addRack() {
    this.spinner.show(this.SEARCH_MODEL_SPINNER);
    const rackKey = this.newRackKey;
    let newRack = { name: rackKey };
    this.createNodeSubscription = this.productService.configCreateNode(this.configId, newRack.name, this.currentNodeIndex)
      .subscribe((nodeId: string) => {
        if (nodeId) {
          console.log('new node id', nodeId);
          newRack['nodeId'] = nodeId;
          this.rackNode = nodeId;
          this.addProduct(newRack);
        } else {
          console.log('No data');
          this.spinner.hide(this.SEARCH_MODEL_SPINNER);
        }
      }, err => {
        this.spinner.hide(this.SEARCH_MODEL_SPINNER);
        this.toastr.error(
          'There is error while createNode', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      });
  }

  editRackName(rack) {
    rack.displayEditRack = !rack.displayEditRack;
  }

  onRackNameUpdate(rackName, rack) {

    if (rackName) {

      const params = {
        key: rack?.name,
        attribute: "DescriptionList",
        value: rackName,
        nodeIndex: rack.nodeId,
      };

      const body = [
        {
          name: FpxCpaasFunction.AlterAttribute,
          args: params
        }
      ];
      this.subscription$.push(this.productService.alterAttributeValue(this.configId, body).subscribe({
        next: () => {
          rack.displayEditRack = !rack.displayEditRack;
          this.productService.configDirty$.next({ configID: this.configId, nodeID: rack.nodeId });
          setTimeout(() => this.submitConfiguration(), DELAY_BEFORE_SAVE);

        },
        complete: () => {
          this.displayEditRevision = false;
        },
        error: (err) => {
          this.toastr.error(
            'There was an error while updating revision name', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      }));
    }
    else {
    }
  }

  cancelEditRackName(rack) {
    if (this.selectedRackForm.controls['rackName'].dirty) {
      this.selectedRackForm.controls['rackName'].setValue('Add Rack');
    } else {
      this.selectedRackForm.controls['rackName'].setValue(rack?.label);
    }
    rack.displayEditRack = !rack.displayEditRack;
  }

  onRootNodeChange() {
    this.currentNodeIndex = ROOT_NODE_INDEX;
  }

  onRackChange(rack) {
    this.spinner.show(this.SEARCH_MODEL_SPINNER);
    this.currentNodeIndex = rack?.nodeId;
    if (this.nodeGroupSubscription && !this.nodeGroupSubscription.closed) {
      this.nodeGroupSubscription.unsubscribe();
    }
    this.nodeGroupSubscription = this.productService
      .publicationForConfig(this.configId, rack?.rackNode)
      .subscribe((data: ConfigData) => {
        if (data) {

        } else {
          console.log('No data');
        }
      }, err => {
        this.spinner.hide(this.SEARCH_MODEL_SPINNER);
        this.toastr.error(
          'There is fatal error while fetching GetGroup', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      });
  }

  markSelectedProducts() {
    this.selectedProducts.forEach((selectedProduct) => {
      this.recommendedProducts.forEach((availableProduct: DFRProduct) => {
        if (selectedProduct.name === availableProduct.name) {
          availableProduct.selected = selectedProduct.selected;
        }
      })
    })
  }

  submitConfiguration() {
    if (this.canEdit) {
      if (this.updateTarget !== 'quoteproduct') {
        Boolean(this.productId) ? this.updateConfig() : this.saveConfig();
      } else {
        this.updateConfig();
      }
    }
  }

  saveConfig() {
    this.dfrCpqOperations.getQuoteLineProducts(this.revisionId).subscribe({
      next: (quoteWithProducts: any) => {
        if (quoteWithProducts.QuoteLines.length > 0) {
          const [quoteLine] = quoteWithProducts.QuoteLines;
          this.productId = (quoteLine.ProductId as any).Id;
          this.updateQueryParam(QueryParams.ProductId, this.productId);
          this.updateConfig();
        } else {
          this.saveNewConfiguration();
        }
      },
      error: err => {
        console.log(err);
      }
    });
  }

  saveNewConfiguration() {
    // If there is a save in progress, abort it and start a new one
    if (this.saveSubscription) {
      this.saveSubscription.unsubscribe();
    }
    if (this.canEdit) {
      this.saveSubscription = this.cartService.saveConfiguration(this.configId, this.revisionId)
        .pipe(
          tap(saveResult => {
            this.productId = saveResult.productId;
            this.updateQueryParam(QueryParams.ProductId, this.productId);
          }),
          switchMap(x => this.cartService.updateProducts([this.revisionId])),
          switchMap(x => this.getOpportunityDetails().pipe(
            tap(opportunity => this.project = opportunity)
          )),
          switchMap(x => this.conditionallyCreateSfdcOpp(this.project))
        ).subscribe({
          next: () => {
            if (this.navigateToProductDetailsPage) {
              this.router.navigate([
                'product/details',
                this.revisionName,
                this.projectId,
                this.revisionId,
                this.configId,
                this.selectedProduct.id,
                this.rackNode
              ], { queryParams: { quickSearchFlow: true } });
            }
          },
          error: err => {
            this.spinner.hide(this.SEARCH_MODEL_SPINNER);
            console.warn(`There was an issue while saving the config`, err)
          }
        });
    }
  }

  getOpportunityDetails(): Observable<DfrCpqOpportunity> {
    return this.cartService.getCPQObjectById(CpqObjectType.Opportunity, this.projectId);
  }

  conditionallyCreateSfdcOpp(opportunity: DfrCpqOpportunity) {
    return this.cartService.getCPQObjectById<CpqUser>(CpqObjectType.User,opportunity.OwnerId).pipe(
      switchMap((oppOwner) => {
        const isDistributor = (oppOwner?.PartnerId) ? true : false;
        if (!opportunity?.ExternalId) {
          let closeDate = new Date();
          const DAYS_UNTIL_CLOSE = 30;
          closeDate.setDate(closeDate.getDate() + DAYS_UNTIL_CLOSE);
          const sfdcOppPayload: DfrSfdcOpportunity = {
            Name: opportunity?.Name || SFDC_DEFAULT_OPP_NAME,
            StageName: DfrSfdcOpportunityStage.DEFAULT,
            Leadsource: SfdcLeadSourceCO2.CO2,
            AccountId: "",
            CloseDate: closeDate,
          };

          return this.salesforce.createOpportunity(sfdcOppPayload).pipe(
            switchMap((result) => {
              this.toastr.success('Oppurtunity added to salesforce successfully');
              return this.cartService.updateObjectById(CpqObjectType.Opportunity, this.projectId, {
                ExternalId: result['id']
              });
            })
          );
        } else {
          return of(opportunity.ExternalId);
        }
      })
    );
  }


  updateQueryParam(param: string, value: string) {
    const queryParams: Params = {};
    queryParams[param] = value;

    this.router.navigate([],
      {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge', // Magic string from the library itself
        replaceUrl: true, // Causes the history to be updated instead of a new entry
      });
  }

  updateConfig() {
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
    this.updateSubscription = this.cartService
      .editProductConfiguration(this.configId, this.productId).pipe(
        switchMap(x => this.cartService.updateProducts([this.revisionId])),
        switchMap(x => this.productService.getGroup(this.configId).pipe(
          tap(x => this.processConfigData(x))
        )),
      ).subscribe({
        next: () => {
          if (this.navigateToProductDetailsPage) {
            this.router.navigate([
              'product/details',
              this.revisionName,
              this.projectId,
              this.revisionId,
              this.configId,
              this.selectedProduct.id,
              this.rackNode
            ], { queryParams: { quickSearchFlow: true } });
          }
        },
        error: err => {
          this.spinner.hide(this.SEARCH_MODEL_SPINNER);
          console.warn(`There was an issue while saving the config`, err)
        }
      });
  }

  postOptionChange(optionData: any) {
    if (!optionData) {
      console.error('No optiondata');
      return;
    }
    console.log('post change option data', optionData);
    if (this.canEdit) {
      const classname = optionData.classname;
      if (classname === 'Options' || classname === 'PreselectOptions') {
        this.productService.updateOptionQuantity(
          this.configId,
          optionData.name,
          optionData.value,
          this.currentNodeIndex
        );
      } else {
        this.productService.updateOptionValue(
          this.configId,
          optionData.name,
          optionData.value,
          this.currentNodeIndex
        );
      }
    }
  }
  private setupConfigSubscription(configId: string): void {
    if (this.configSubscription && !this.configSubscription.closed) {
      this.configSubscription.unsubscribe();
    }
    this.spinner.show(this.SEARCH_MODEL_SPINNER);
    this.configSubscription = this.productService
      .publicationForConfig(configId)
      .subscribe({
        next: data => {
          this.processConfigData(data);
        },
        error: err => {
          this.spinner.hide(this.SEARCH_MODEL_SPINNER);
          this.toastr.error('There was an error while fetching data set', 'Error', {
            disableTimeOut: true,
            closeButton: true
          });
        },
      });
  }
  private processConfigData(data: ConfigData) {
    if (data?.parts?.selected?.length > 0) {
      this.availableRacks = data?.parts?.selected;
      this.availableRacks.forEach((rack) => {
        rack['displayEditRack'] = false;
        rack['productName'] = rack?.products[0]?.label || '';
      });

    }
    else {
      this.spinner.hide(this.SEARCH_MODEL_SPINNER);
    }
  }

  productDetails(rack) {
    if (rack) {
      this.productBaseId = rack?.products[0]?.id;
      this.productCadId = rack?.products[0]?.cadId;
      this.router.navigate([
        'product/details',
        this.revisionName,
        this.projectId,
        this.revisionId,
        this.configId,
        this.productBaseId,
        rack.nodeId,
        this.productCadId
      ],
        { queryParams: { ActiveNode: this.ActivateNode = 1, quickSearchFlow: true } });
    }
  }

  productDetail(product) {
    if (this.canEdit) {
      this.productBaseId = product.id;
      this.productCadId = product.cadId;
      this.router.navigate([
        'product/details',
        this.revisionName,
        this.projectId,
        this.revisionId,
        this.configId,
        product?.id,
        this.currentNodeIndex,
        this.productCadId
      ],
        { queryParams: { ActiveNode: this.ActivateNode = 0, quickSearchFlow: true } });
    }
  }

  onProductSelect(selectedProduct) {
    if (this.createNodeSubscription && !this.createNodeSubscription.closed) {
      return;
    }
    if (this.availableRacks === undefined || this.availableRacks.length < MAXRACK_COUNT) {
      this.spinner.show(this.SEARCH_MODEL_SPINNER);
      const rackKey = this.newRackKey;
      let newRack = { name: rackKey };
      try {
        this.subscription$.push(this.createNodeSubscription = this.productService.configCreateNode(this.configId, newRack.name, this.currentNodeIndex).pipe(
          tap((nodeId: string) => { this.rackNode = nodeId, this.addProduct(newRack) }),
          switchMap(nodeId => this.productService.configCreateNode(this.configId, selectedProduct.name, this.rackNode))
        ).subscribe({
          next: (nodeId: string) => {
            if (nodeId) {
              console.log('new node id', nodeId);
              selectedProduct.nodeId = nodeId;
              this.selectedProduct = selectedProduct;
              this.addProduct(selectedProduct);
              this.setExternalParams({ nodeId: selectedProduct.nodeId });
            } else {
              console.log('No data');
              this.spinner.hide(this.SEARCH_MODEL_SPINNER);
            }
          },
          error: err => {
            this.toastr.error(
              'There is error while createRackNode', 'Error', {
              disableTimeOut: true,
              closeButton: true
            }
            );
            this.spinner.hide(this.SEARCH_MODEL_SPINNER);
          }
        }));
      } catch (err) {
        this.spinner.hide(this.SEARCH_MODEL_SPINNER);
      }
    }
    else {
      alert('Max 9 racks can be added for a project.');
    }
  }



  addProduct(part) {
    const nodeId = this.rackNode;
    this.productService.configDirty$.next({ configID: this.configId, nodeID: nodeId });
    setTimeout(() => this.submitConfiguration(), DELAY_BEFORE_SAVE);
  }

  saveVersion() {
    const instance = this.modalService.open(SaveProjectComponent, {
      size: 'lg',
      windowClass: 'previewModal'
    }).componentInstance;
    instance.projectId = this.projectId;
  }
  saveProject() {
  }

  goBack() {
    try {
      this.router.navigate([
        'productSelector',
        this.projectId
      ]);

    } catch (error) {
      console.log(error);
    }
  }
  copyRack(rackNodeId) {
    if (this.availableRacks.length < MAXRACK_COUNT) {
      this.spinner.show(this.SEARCH_MODEL_SPINNER);
      if (this.copyNodeSubscription && !this.copyNodeSubscription.closed) {
        this.copyNodeSubscription.unsubscribe();
      }
      this.copyNodeSubscription = this.productService
        .configCopyInstance(this.configId, rackNodeId)
        .subscribe((data) => {
          setTimeout(() => this.submitConfiguration(), DELAY_BEFORE_SAVE);
        }, err => {
          this.toastr.error(
            'There is fatal error while Node Copy', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
          this.spinner.hide(this.SEARCH_MODEL_SPINNER);
        });
    }
    else {
      alert('Max 9 racks can be added for a project.');
    }
  }
  deleteRack(rackNodeId) {
    this.spinner.show(this.SEARCH_MODEL_SPINNER);
    if (this.deleteNodeSubscription && !this.deleteNodeSubscription.closed) {
      this.deleteNodeSubscription.unsubscribe();
    }
    this.copyNodeSubscription = this.productService
      .configDeleteNode(this.configId, rackNodeId)
      .subscribe((data) => {
        this.availableRacks = this.availableRacks?.filter(item => item.nodeId != rackNodeId);
        this.spinner.hide(this.SEARCH_MODEL_SPINNER);
        setTimeout(() => this.submitConfiguration(), DELAY_BEFORE_SAVE);

      }, err => {
        this.toastr.error(
          'There is fatal error while Node Delete', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
        this.spinner.hide(this.SEARCH_MODEL_SPINNER);
      });
  }
  onSelectedProductShow() {
    this.classApplied = !this.classApplied;
  }
  initialFormData() {
    const nonWhiteSpaceRegExp: RegExp = new RegExp('\\S');
    this.selectedRackForm = this.formBuilder.group({
      rackName: [null, [Validators.required, Validators.pattern(nonWhiteSpaceRegExp), Validators.maxLength(120)]]
    });
  }

  ngOnDestroy() {
    this.generalSubscription.unsubscribe();
    this.init$.forEach(sub => sub.unsubscribe());
    this.subscription$.forEach(sub => sub.unsubscribe());
    this.configSubscription?.unsubscribe();
    this.saveSubscription?.unsubscribe();
    this.updateSubscription?.unsubscribe();
    this.deleteNodeSubscription?.unsubscribe();
    this.moveNodeSubscription?.unsubscribe();
    this.createNodeSubscription?.unsubscribe();
    this.nodeGroupSubscription?.unsubscribe();
    this.copyNodeSubscription?.unsubscribe();
  }

}
