import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '@cpq-app/services/cart.service';
import { LoginService } from '@cpq-app/services/login.service';
import { ProductService } from '@cpq-app/services/product.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CpqOperations } from '../cpqOperations.service';
import { OpenProjectComponent } from '../open-project/open-project.component';
import { SaveProjectComponent } from '../save-project/save-project.component';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  subscription$: Subscription[] = [];
  projectId: string;
  revisionId: string;
  configId: string;
  productsData: any;
  searchString = '';
  startNewProjectFlow: boolean;
  ADD_PRODUCT_SPINNER = 'addProduct';

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService,
    private dfrCpqOperations: CpqOperations,
    private cartService: CartService,
    private productService: ProductService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.spinner.hide(this.ADD_PRODUCT_SPINNER);
  }

  startNewModelSearch() {
    const rex = /^[a-zA-Z0-9_ ]*$ |([\w ]+)/;
    if (!rex.test(this.searchString.trim())) {
      this.toastr.error('Please enter valid search text');
      return;
    }
    this.createProjectAndRevisionWithDefaultValues();
  }

  startNewProject() {
    this.startNewProjectFlow = true;
    this.createProjectAndRevisionWithDefaultValues();
  }

  createProjectAndRevisionWithDefaultValues() {
    this.spinner.show(this.ADD_PRODUCT_SPINNER);
    try {
      this.subscription$.push(this.dfrCpqOperations.createDFRProject().pipe(
        tap((opportunityID: string) => this.projectId = opportunityID),
        switchMap(opportunityID => this.dfrCpqOperations.createDFRQuote(opportunityID, !this.startNewProjectFlow))
      ).subscribe({
        next: (revisionId: string) => {
          this.revisionId = revisionId;
          this.startNewConfiguration();
        },
        error: err => {
          this.spinner.hide(this.ADD_PRODUCT_SPINNER);
          this.startNewProjectFlow = false;
        }
      }));
    } catch (err) {
      this.spinner.hide(this.ADD_PRODUCT_SPINNER);
    }
  }

  startNewConfiguration() {
    this.spinner.show(this.ADD_PRODUCT_SPINNER);
    const openSubscription = this.cartService
      .openConfigurationDefaultDataset(this.projectId, this.revisionId)
      .pipe(
        tap((configData) => this.configId = configData.configId),
        switchMap(configData => this.productService.publicationForConfig(configData.configId)),
        tap((data) => {
          if (data) {
            this.productsData = data.products?.available || [];
          }
        }),
        switchMap(data => this.cartService.deleteExistingConfiguredProductsIfAny(this.revisionId)),
        switchMap(x => this.productService.selectProduct(this.configId, this.productsData[2].name)),
      )
      .subscribe(
        data => {
          if (this.startNewProjectFlow) {
            this.startNewProjectFlow = false;
            this.navigateToConfigPage();
          } else {
            this.navigateToModelSearchPage();
          }
        },
        err => {
          this.spinner.hide();
          this.toastr.error(
            'There was an error while starting a configuration'
          );
        }
      );
    this.subscription$.push(openSubscription);
  }

  navigateToConfigPage() {
    // this.router.navigate([
    //   'product/selection',
    //   'New Revision',
    //   this.projectId,
    //   this.revisionId,
    //   this.configId
    // ]);
    this.router.navigate([
      'productSelector',
      this.projectId
    ]);
  }

  navigateToModelSearchPage() {
    this.router.navigate(
      ['model/results',
        this.projectId,
        this.revisionId,
        this.configId
      ],
      { queryParams: { search: this.searchString } }
    );
  }
  openProject() {
    const instance = this.modalService.open(OpenProjectComponent, {
      size: 'xl',
    }).componentInstance;
    instance.projectId = this.projectId;
  }

  ngOnDestroy() {
    this.subscription$.forEach(sub => sub.unsubscribe());
  }
}
