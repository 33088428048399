<div class="modal-header d-flex justify-content-between share-header">
    <div>Share job</div>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>

</div>
<div class="modal-body">
    <form class="p-3" [formGroup]="shareForm">
        <div class="website-view">
            <div class="form-group row pb-1">
                <div class="col-md-12 form-group">
                    <label for="UserId" class="label-select">Select User</label>
                    <ng-multiselect-dropdown class="form-control" [placeholder]="'Select'" [data]="userNames"
                        formControlName="UserId" id="UsreId" [settings]="dropdownSettings" [(ngModel)]="selectedUsers"
                        [disabled]="!canShare">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="modal-footer d-flex">
            <button type="button" class="cancel-button" (click)="activeModal.close()">
                <i class="fa fa-times" aria-hidden="true"></i> Cancel</button>
            <button type="button" class="share-button" [disabled]="shareForm.invalid" (click)="onShareClick()">
                <i class="fa fa-check mr-1" aria-hidden="true"></i>Finish</button>
        </div>
    </form>
</div>