import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MSALService } from '@cpq-app/shared/services/msal.service';
import { AlertService } from '@cpq-app/shared/services/alert.service';
import { AppConfig } from '@cpq-app/config/app.config';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { CartService } from '@cpq-app/services/cart.service';
import { forkJoin, of } from 'rxjs';
import { HeaderService } from './header.service';
import { GuestConfigService } from '@cpq-app/adminstration/guest-config/guest-config.service';
import { LogoutService } from '@cpq-app/shared/services/logoutService';
import { environment } from '@cpq-environments/environment';
import { StatusCodes } from 'http-status-codes';

export enum SessionVariables {
  PERSONA = 'persona',
  USER_TYPE = 'userType'
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  headerData = {};
  userName;
  constructor(
    private router: Router,
    private appConfig: AppConfig,
    private msalService: MSALService,
    private userService: UsersService,
    private headerService: HeaderService,
    private alertService: AlertService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private cartService: CartService,
    private guestService: GuestConfigService,
    private logoutService: LogoutService
  ) { }

  ngOnInit() {
    this.spinner.show();
    const loggedInUserAdInfo = this.msalService.getUser().idToken;
    this.getUserDetails(loggedInUserAdInfo.oid).subscribe(res => {
      if (res['statusCode'] !== StatusCodes.OK) {
        this.alertService.error(`${res['message']}`);
      } else {
        let userData = res['data'];
        let headerData;
        if (userData === null) {
          if (environment.B2CConfigs.superAdminOid !== loggedInUserAdInfo.oid) {
            this.toaster.error('User is not registered with application.');
            this.logoutService.logout();
          }
        }
        this.getHeaderDetails(loggedInUserAdInfo.oid).subscribe(res1 => {
          if ( res1['statusCode'] !== StatusCodes.OK) {
            this.alertService.error(`${res1['message']}`);
          } else {
            headerData = res1['data'];
            this.guestService.setGuestUserDetails(userData);
            let userRole = '';
            headerData['user'] = {};
            if (userData === null) {
              userRole = 'Super Admin';
              let userName = loggedInUserAdInfo.given_name + ' ' + loggedInUserAdInfo.family_name;
              this.headerService.setUsername(userName);
              headerData['user']['userType'] = 'superadmin';
            } else {
              userRole = userData['personaName'];
              headerData['user']['userType'] = userData['userType'];
              let userName = userData['firstName'] + ' ' + userData['lastName'];
              this.headerService.setUsername(userName);
              if (userData['privacyTerm'] === null || userData['privacyTerm']?.isAccepted === false) {
                this.router.navigate(['/privacy-terms']);
              }
              if (userData.isActive !== true) {
                this.toaster.warning('User is inactive. Please contact admin in order to login.')
                this.logoutService.logout();
              }

            }

            this.headerService.getUserName().subscribe((name) => {
              this.userName = name;
            })
            const userType = headerData['user']['userType'];
            this.setSessionStorageData(userRole, userType);
            headerData['user']['role'] = userRole;
            headerData['user']['pic'] =
              userRole === 'Super Admin'
                ? './assets/images/administrator-profile-pic.png'
                : userData?.profilePic?.blobUri;
            this.headerData = headerData;
          }
        });

      }
      this.spinner.hide();
    });
  }

  setSessionStorageData(userRole: string, userType: string) {
    sessionStorage.setItem(SessionVariables.PERSONA, userRole);
    sessionStorage.setItem(SessionVariables.USER_TYPE, userType);
  }

  getUserDetails(loggedInUserOid) {
    return this.userService.getUser(loggedInUserOid);
    ;
  }

  getHeaderDetails(loggedInUserOid) {
    return this.headerService.getHeader(loggedInUserOid);
  }
}
