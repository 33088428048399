import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, retry } from 'rxjs/operators';
import { environment } from '@cpq-environments/environment';


@Injectable({
  providedIn: 'root'
})

export class PAK {
  private _energybackendUrl = environment.fpx.energyAPI;

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  
  getPakModels(refrigerationType, ambientTemperature, LtTemp, MtTemp, LtCapacity, MtCapacity,percentCapacityGap,offSetAmbientTemp) {
    const url = `${environment.B2CConfigs.BackendURL}/energyConversion/compressorcapacity/${refrigerationType}/${ambientTemperature}/${LtTemp}/${MtTemp}/${LtCapacity}/${MtCapacity}/${percentCapacityGap}/${offSetAmbientTemp}`;
    return this._httpClient.get(url).pipe(
      catchError(this.handleError)
    );
  };


  handleError(error) {
    console.log(error);
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error.message;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
