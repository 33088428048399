import { Component, Input, OnDestroy, OnInit, ElementRef, EventEmitter, NgZone, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { CartService, CpqObjectType, CpqQueryObjects, CpqUser } from '@cpq-app/services/cart.service';
import { ProductService } from '@cpq-app/services/product.service';
import { SalesforceProxyService, sfdcObjectType } from '@cpq-app/services/salesforce.service';
import { AlertService } from '@cpq-app/shared/services/alert.service';
import { EnergyCalculation } from '@cpq-app/shared/services/energy-calculation.service';
import { CpqAccounts } from '@cpq-app/tenants/Cpq.interfaces.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { CpqOperations, DfrCpqNewOpportunity } from '../cpqOperations.service';
import { AmbientTempResult, DfrAccounts, DfrCpqOpportunity, DfrJobs, DfrSfdcOpportunity, SfdcLeadSourceCO2 } from '../DFR.interfaces.service';
import { GeoLocationService } from '@cpq-app/shared/services/geo-location.service';
const DELAY_BEFORE_ACOOUNTS_LOAD = 5000;
const DATE_FMT = 'yyyy-MM-dd';

export interface PlaceSearchResult {
  address: string;
  Latitude__c?: any;
  Longitude__c?: any;
  zipCode?: any;
}

const countryCodes = {
  United_States: 'us',
  Canada: 'ca',
  Mexico: 'mx',
  Saint_Pierre_And_Miquelon: 'pm',
  Bermuda: 'bm',
  Bahamas: 'bs',
  Cuba: 'cu',
  Jamaica: 'jm',
  Dominican_Republic: 'do',
  Puerto_Rico: 'pr',
  Virgin_Islands: 'vi',
  Belize: 'bz',
  Guatemala: 'gt',
  El_Salvador: 'sv',
  Honduras: 'hn',
  Nicaragua: 'cr',
  Costa_Rica: 'pa',
  Antigua_and_Barbuda: 'ag',
  Sint_Maarten: 'sx',
  Guadeloupe: 'gp',
  Martinique: 'mq',
  Saint_Lucia: 'lc',
  Barbados: 'bb',
  Grenada: 'gd',
  Trinidad_and_Tobago: 'tt',
  Aruba: 'aw',
  Curaçao: 'cw',
  Bonaire: 'bq',
  Colombia: 'co',
  Panama: 'pa'
} as const;

type CountryCode = keyof typeof countryCodes;

function autocompleteObjectValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === 'string') {
      return { 'invalidAutocompleteObject': { value: control.value } }
    }
    return null  /* valid option selected */
  }
}

@Component({
  selector: 'app-open-project',
  templateUrl: './open-project.component.html',
  styleUrls: ['./open-project.component.scss']
})
export class OpenProjectComponent implements OnInit, OnDestroy {
  saveProjectForm: FormGroup;
  subscription$: Subscription[] = [];
  projectInfo: DfrJobs;
  availableAccounts = [];
  submitted = false;
  PROJECT_SPINNER = "project spinner";
  @Input() projectId: string;
  revisionId: string;
  configId: string;
  productsData: any;
  searchString = ' ';
  startNewProjectFlow: boolean;
  projectPayload: DfrCpqNewOpportunity[] = [];
  projects: DfrCpqOpportunity[] = [];
  project: DfrCpqOpportunity;
  isUpdateProject: boolean;
  selectStationChange: google.maps.Marker[] = [];
  markers: google.maps.Marker[] = [];

  filteredAccounts: Observable<DfrAccounts[]>;
  selectedAccount: string;
  isAmbientTemperatureAvailable: boolean = false;
  openInfoWindowsArray = [];
  childAccounts = [];
  selectedChildAccount;
  filteredChildAccounts: Observable<DfrAccounts[]>;
  countryName: string;

  @ViewChild('Zip_Code__d')
  Zip_Code__d!: ElementRef;
  autocomplete: google.maps.places.Autocomplete | undefined;
  // private stations: StationData[] = [];
  stationData: any;
  // private stationsTwo: StationData[] = [];
  private stationsTwo = [];
  private map: google.maps.Map;
  mapLatitude;
  mapLongitude;
  @ViewChild('mapContainer', { static: true }) mapContainer: ElementRef;


  constructor(private formBuilder: FormBuilder,
    public modal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService,
    private dfrCpqOperations: CpqOperations,
    private cartService: CartService,
    private productService: ProductService,
    private energyService: EnergyCalculation,
    private salesforce: SalesforceProxyService,
    private alertService: AlertService,
    private googleMapsService: GeoLocationService,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {
    this.initialFormData();
    this.getAccountNames();
    if (this.projectId) {
      this.isUpdateProject = true;
      this.editFormInitialData();
    }
    else {
      this.isUpdateProject = false;
    }
    this.loadMap();
  }

  addMarkersToMap(stations, lat, log) {
    if (this.map) {
      this.selectStationChange = [];
      this.clearMarkers();
      const targetLatitude = lat;
      const targetLongitude = log;
      if (this.countryName) {
        const targetMarker = new google.maps.Marker({
          position: { lat: targetLatitude, lng: targetLongitude },
          map: this.map,
          title: this.countryName,
          icon: {
            url: 'http://maps.gstatic.com/mapfiles/ms2/micons/grn-pushpin.png',
            scaledSize: new google.maps.Size(25, 25)
          },
        });
        this.markers.push(targetMarker);
      }


      stations.forEach((location) => {
        const markerPosition = { lat: location.latitude, lng: location.longitude };
        let markerIconUrl = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'; // Default red marker
        const marker = new google.maps.Marker({
          position: markerPosition,
          map: this.map,
          title: location.station_Name,
          icon: {
            url: markerIconUrl // Change marker color here
          }
        });
        this.markers.push(marker);
        const contentDiv = document.createElement('div');
        contentDiv.classList.add('station-info');
        const strongElement = document.createElement('strong');
        strongElement.innerText = location.station_Name;

        const brElement = document.createElement('br');
        const br2 = document.createElement('br');
        const br3 = document.createElement('br');
        const br4 = document.createElement('br');
        const br5 = document.createElement('br');
        const br6 = document.createElement('br');

        const amptemText = document.createTextNode(`Annual Extreme Dry Bulb Temperature : ${location.extreme_annual_db_Max}`);
        const coolingDryText = document.createTextNode(`Cooling Dry Bulb Temperature(DB_Cooling) : ${location.cooling_DB_04}`);
        const McwbText = document.createTextNode(`Mean Coincident Wet Bulb Temperature(MCWB) : ${location.cooling_MCWB_04}`);
        const stnCodeText = document.createTextNode(`Station Code : ${location.station_Code}`);
        const elevationText = document.createTextNode(`Elevation : ${location.elevation}`);
        const selectButton = document.createElement('button');
        selectButton.innerText = `Select Station`;
        selectButton.classList.add('map-button');
        selectButton.style.backgroundColor = '#75bf36';
        selectButton.addEventListener('click', () => {
          this.selectStation(location);
        });
        contentDiv.appendChild(strongElement);
        contentDiv.appendChild(brElement);
        contentDiv.appendChild(amptemText);
        contentDiv.appendChild(br2);
        contentDiv.appendChild(coolingDryText);
        contentDiv.appendChild(br3);
        contentDiv.appendChild(McwbText);
        contentDiv.appendChild(br4);
        contentDiv.appendChild(stnCodeText);
        contentDiv.appendChild(br5);
        contentDiv.appendChild(elevationText);
        if (!this.isUpdateProject) {
          contentDiv.appendChild(br6);
          contentDiv.appendChild(selectButton);
        }
        const infoWindow = new google.maps.InfoWindow({
          content: contentDiv
        });

        marker.addListener('click', () => {
          this.closeInfoWindows();
          infoWindow.open(this.map, marker);
          this.openInfoWindowsArray.push(infoWindow);
        });
        this.selectStationChange.push(marker);

      });
    }

  }

  closeInfoWindows() {
    if (this.openInfoWindowsArray && this.openInfoWindowsArray.length > 0) {
      this.openInfoWindowsArray.forEach(infoWindow => infoWindow.close());
      this.openInfoWindowsArray.length = 0;
    }
  }

  clearMarkers() {
    this.markers.forEach((marker) => {
      marker.setMap(null);
    });
    this.markers = [];
  }

  selectStation(stationName: any) {
    this.selectStationChange.forEach(marker => {
      let markerIconUrl = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'; // Default red marker icon URL
      marker.setIcon(markerIconUrl);
    });
    const selectedMarker = this.selectStationChange.find(marker => marker.getPosition().lat() === stationName.latitude && marker.getPosition().lng() === stationName.longitude);
    if (selectedMarker) {
      let markerIconUrl = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'; // Default green marker
      selectedMarker.setIcon(markerIconUrl);
    }
    this.saveProjectForm.controls.latitude__c.setValue(stationName?.latitude);
    this.saveProjectForm.controls.longitude__c.setValue(stationName?.longitude);
    this.saveProjectForm.controls.stationName__c.setValue(stationName?.station_Name);
    this.saveProjectForm.patchValue({ 'Ambient_Temp__Station': stationName?.extreme_annual_db_Max });
    this.getAmbientTemperature();
    this.closeInfoWindows();
  }

  displayFn(account?: DfrAccounts): string | undefined {
    return account ? account.Name : undefined;
  }
  returnFn(account?: DfrAccounts): string | undefined {
    return account ? account.Id : undefined;
  }

  private _filter(Name: string): DfrAccounts[] {
    const filterValue = Name.toLowerCase();

    return this.availableAccounts.filter(
      option => option.Name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private _filterChildAccount(Name: string): DfrAccounts[] {
    const filterValue = Name.toLowerCase();

    return this.childAccounts.filter(
      option => option.Name.toLowerCase().indexOf(filterValue) === 0
    );
  }


  initialFormData() {
    const nonWhiteSpaceRegExp: RegExp = new RegExp('\\S');
    const zipcodePattern = '^[0-9]{5}(?:-[0-9]{4})?$';
    this.saveProjectForm = this.formBuilder.group({
      accountSelection: new FormControl(null, { validators: [Validators.required, autocompleteObjectValidator()] }),
      Name: [null, [Validators.required, Validators.pattern(nonWhiteSpaceRegExp), Validators.maxLength(120)]],
      CreatedDate: [null],
      LastModifiedDate: [null],
      Ambient_Temp__c: [null],
      Zip_Code__c: [null, [Validators.pattern(nonWhiteSpaceRegExp)]],
      Zip_Code__d: [null, [Validators.required]],
      application__c: [null],
      storeType__c: [null],
      Status__c: [null],
      Project_Type: [null],
      latitude__c: [null],
      longitude__c: [null],
      AmbientTempJSON__c: [null],
      stationName__c: [null],
      UnitType: [null],
      Ambient_Temp__Station: [null],
      childAccountId: new FormControl(null, { validators: [Validators.required, autocompleteObjectValidator()] })
    });
  }

  editFormInitialData() {
    this.subscription$.push(this.cartService.getCpqObjects<any>(CpqQueryObjects.Opportunities, {
      Id: this.projectId, addonFields: DfrCpqOpportunity.addOnFields.join(', '), resolveNames: 'true'
    })
      .subscribe({
        next: (opportunity: any) => {
          this.project = opportunity;
          this.selectedAccount = opportunity?.Account?.Name;
          this.selectedChildAccount = opportunity?.ChildAccount?.Name;
          this.saveProjectForm.controls.Name.setValue(opportunity?.Name);
          this.saveProjectForm.controls.Status__c.setValue(opportunity?.Status__c);
          this.saveProjectForm.controls.Zip_Code__c.setValue(opportunity?.Zip_Code__c ? opportunity?.Zip_Code__c : '0');
          this.saveProjectForm.controls.application__c.setValue(opportunity?.application__c);
          this.saveProjectForm.controls.storeType__c.setValue(opportunity?.storeType__c);
          this.saveProjectForm.controls.Ambient_Temp__c.setValue(opportunity?.Ambient_Temp__c);
          this.saveProjectForm.controls.accountSelection.setValue(opportunity?.Account);
          this.saveProjectForm.controls.Zip_Code__d?.setValue(opportunity?.drop_ship_address_line_1__c ? opportunity?.drop_ship_address_line_1__c : 'NA');
          this.saveProjectForm.controls.latitude__c?.setValue(opportunity?.latitude__c ?? 0);
          this.saveProjectForm.controls.longitude__c?.setValue(opportunity?.Longitude__c ?? 0);
          this.saveProjectForm.controls.stationName__c?.setValue(opportunity?.stationName__c ? opportunity?.stationName__c : 'NA');
          this.saveProjectForm.controls.UnitType?.setValue(opportunity?.UnitType ?? 'undefined');
          this.saveProjectForm.controls.childAccountId.setValue(opportunity?.ChildAccount);
          // for get station name
          this.getStationResult({ latitude: opportunity.latitude__c, longitude: opportunity.longitude__c, unitType: this.saveProjectForm.controls.UnitType?.value });
        },
        error: err => {
          this.toastr.error(
            'There is fatal error while fetching Project details', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      }));
  }

  openGuidedProject() {
    let accountID = this.saveProjectForm.value.accountSelection ? this.saveProjectForm.value.accountSelection : '';
    if (accountID) {
      this.startNewProjectFlow = true;
      this.createProjectAndRevisionWithDefaultValues();
    } else {
      this.toastr.error(
        'Please select valid sold to customer.'
      );
    }
    //this.refreshCpqObjectFromCrm(this.saveProjectForm.value.accountSelection);
  }

  openModelSearch() {
    this.startNewProjectFlow = false;
    this.createProjectAndRevisionWithDefaultValues();
    //this.refreshCpqObjectFromCrm(this.saveProjectForm.value.accountSelection);
  }

  getAccountNames() {
    this.availableAccounts = [];
    this.subscription$.push(this.cartService.getCpqObjects<CpqAccounts>(CpqQueryObjects.Accounts, { resolveNames: 'true' })
      .subscribe(
        (results) => {
          const accountsWithExternalId = results.filter(account => account.ExternalId);
          this.availableAccounts = [...this.availableAccounts, ...accountsWithExternalId];
          this.filteredAccounts = this.saveProjectForm.controls['accountSelection'].valueChanges.pipe(
            startWith(""),
            map(value => (typeof value === "string" ? value : value.Name)),
            map(name => (name ? this._filter(name) : this.availableAccounts.slice()))
          );
          const selectedAcc = this.availableAccounts.find(acc => acc.Id === this.saveProjectForm.controls.accountSelection.value);
          if (selectedAcc) {
            this.childAccounts = selectedAcc?.ChildAccounts;
            if (selectedAcc?.hasOwnProperty('ChildAccounts')) {
              this.childAccounts = selectedAcc.ChildAccounts;
            } else {
              this.childAccounts = [{
                Id: selectedAcc?.Id,
                Name: selectedAcc?.Name
              }];
              this.selectedChildAccount = this.childAccounts[0]?.Name;
              this.saveProjectForm.controls.childAccountId.setValue(this.childAccounts[0]);
            }
            this.filteredChildAccounts = this.saveProjectForm.controls['childAccountId'].valueChanges.pipe(
              startWith(""),
              map(value => (typeof value === "string" ? value : value?.Name)),
              map(name => (name ? this._filterChildAccount(name) : this.childAccounts.slice()))
            );
          }
        },
        err => {
          this.toastr.error(
            'There is fatal error while fetching Account Names', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      ));
  }

  onProjectSave() {
    let account = this.saveProjectForm.value.accountSelection ? this.saveProjectForm.value.accountSelection : '';
    if (account) {
      let opportunityParams = {
        AccountId: account?.Id,
        Name: this.saveProjectForm.value.Name,
        Zip_Code__c: this.saveProjectForm.value.Zip_Code__c,
        application__c: this.saveProjectForm.value.application__c,
        storeType__c: this.saveProjectForm.value.storeType__c,
        Ambient_Temp__c: this.saveProjectForm.value.Ambient_Temp__c,
        ChildAccountId: this.saveProjectForm.value.childAccountId.Id ? this.saveProjectForm.value.childAccountId.Id : ''
      };
      this.cartService.updateObjectById(CpqObjectType.Opportunity, this.projectId, opportunityParams).pipe().subscribe(data => {
        //FIX-ME : Enable this below line to refresh account from Salesfoce once CDS CPQ Refresh API is ready
        //this.refreshCpqObjectFromCrm(account?.Id);
        this.modal.dismiss();
        //this.updateOppInSFDC(this.saveProjectForm.value.Name, account?.Id);
      });
    } else {
      this.toastr.error(
        'Please select valid sold to customer.'
      );
    }

  }

  updateOppInSFDC(projectName, accountId) {
    const filteredAccounts = this.availableAccounts.filter((account) => account.Id === accountId);
    if (filteredAccounts.length > 0) {
      const sfdcLeadPayload = { Name: projectName, AccountId: filteredAccounts[0].ExternalId };
      if (this.project?.ExternalId) {
        this.salesforce.updateSfdcObjectById(this.project?.ExternalId, sfdcObjectType.OPPORTUNITY, sfdcLeadPayload).subscribe({
          next: (lead) => { },
          error: err => {
            console.log('Issue in Salesforce opportunity', err);
          }
        })
      }
    }
  }

  refreshCpqObjectFromCrm(account) {
    let accountId = account?.Id ? account?.Id : account;
    if (accountId) {
      this.cartService.refreshCpqObjectFromCrm(CpqObjectType.Account, accountId).subscribe({
        next: (result) => {
          console.log('result', result);
        },
        error: err => {
          console.log('Issue in updating crm object', err);
          this.alertService.warn('There was an issue while syncing sold to customer data. Please contact system administrator.');
        }
      })
    }
  }

  goBacktoRevision() {
    try {
      this.router.navigate([
        'productSelector',
        this.projectId
      ]);

    } catch (error) {
      console.log(error);
    }
  }

  getAmbientTemperature() {
    if (!this.saveProjectForm.controls['latitude__c'].invalid && !this.saveProjectForm.controls['longitude__c'].invalid) {
      const zipCode = '0';
      const lat = this.saveProjectForm.controls['latitude__c'].value;
      const log = this.saveProjectForm.controls['longitude__c'].value;
      this.energyService.getAmbientTemperature(zipCode, lat, log).subscribe(
        (res: AmbientTempResult) => {
          if (res) {
            this.saveProjectForm.patchValue({ 'Ambient_Temp__c': res.ptChart.ambientTemp });
            this.saveProjectForm.patchValue({ 'AmbientTempJSON__c': res });
            this.isAmbientTemperatureAvailable = true;
          }
          else {
            confirm('Please enter valid address.');
            this.saveProjectForm.controls.Zip_Code__c.setValue(null);
            this.saveProjectForm.controls.Zip_Code__d.setValue(null);
            this.saveProjectForm.controls.latitude__c.setValue(null);
            this.saveProjectForm.controls.longitude__c.setValue(null);
            this.saveProjectForm.controls.Ambient_Temp__c.setValue(0);
            this.isAmbientTemperatureAvailable = false;
            this.saveProjectForm.controls.stationName__c.setValue(null);
          }
        },
        err => {
          this.spinner.hide();
          this.toastr.error(
            'Please enter valid address.'
          );
          this.saveProjectForm.controls.Zip_Code__c.setValue(null);
          this.saveProjectForm.controls.Zip_Code__d.setValue(null);
          this.saveProjectForm.controls.latitude__c.setValue(null);
          this.saveProjectForm.controls.longitude__c.setValue(null);
          this.saveProjectForm.controls.Ambient_Temp__c.setValue(0);
          this.isAmbientTemperatureAvailable = false;
          this.saveProjectForm.controls.stationName__c.setValue(null);
        }
      );
    } else {
      this.toastr.error(
        'Invalid Latitude and Longitude.'
      );
    }
  }

  // get station
  getStationResult(placeCoordinates: { latitude: any, longitude: any, unitType: any }) {
    this.closeInfoWindows();
    const { latitude, longitude, unitType } = placeCoordinates;
    if (latitude && longitude) {
      const lat = latitude;
      const log = longitude;
      const unit = unitType;
      // this.cartService.getStations(lat, log).subscribe(
      this.energyService.getStationResult(lat, log, unit).subscribe(
        (res: any) => {
          if (res) {
            if (this.isUpdateProject) {
              this.stationData = res.filter(item => item.latitude == lat && item.longitude == log
              );
            } else {
              this.stationData = res;
            }
            if (this.stationData && this.stationData.length > 0) {
              this.updateMap({ latitude: latitude, longitude: longitude });
            } else {
              if (!this.isUpdateProject) {
                this.toastr.error(
                  'No station found.Please select another location.'
                );
              }
            }

          }
          else {
            confirm('No station found.Please select another location.');
          }
        },
        err => {
          this.spinner.hide();
          this.toastr.error(
            'No station found.Please select another location.'
          );
        }
      );
    } else {
      this.toastr.error(
        'Invalid Latitude and Longitude.'
      );
    }
  }

  createProjectAndRevisionWithDefaultValues() {
    this.spinner.show(this.PROJECT_SPINNER);
    let payload: any = this.prepareProjectPayload();
    try {
      sessionStorage.setItem('unitType', payload?.UnitType);
      this.subscription$.push(this.dfrCpqOperations.createDFRProject(payload).pipe(
        tap((opportunityID: string) => this.projectId = opportunityID),
        switchMap(opportunityID => this.dfrCpqOperations.createDFRQuote(opportunityID, !this.startNewProjectFlow))
      ).subscribe({
        next: (revisionId: string) => {
          this.revisionId = revisionId;
          //this.startNewConfiguration();
          this.configId = "0";
          this.navigateToConfigPage();
        },
        error: err => {
          this.spinner.hide(this.PROJECT_SPINNER);
          this.startNewProjectFlow = false;
        }
      }));
    } catch (err) {
      this.spinner.hide(this.PROJECT_SPINNER);
    }
  }

  startNewConfiguration() {
    this.spinner.show(this.PROJECT_SPINNER);
    const openSubscription = this.cartService
      .openConfigurationDefaultDataset(this.projectId, this.revisionId)
      .pipe(
        tap((configData) => this.configId = configData.configId),
        switchMap(configData => this.productService.publicationForConfig(configData.configId)),
        tap((data) => {
          if (data) {
            this.productsData = data.products?.available || [];
          }
        }),
        switchMap(data => this.cartService.deleteExistingConfiguredProductsIfAny(this.revisionId)),
        switchMap(x => this.productService.selectProduct(this.configId, this.productsData[2].name)),
      )
      .subscribe(
        data => {
          if (this.startNewProjectFlow) {
            this.startNewProjectFlow = false;
            this.navigateToConfigPage();
          } else {
            this.navigateToModelSearchPage();
          }
        },
        err => {
          this.spinner.hide();
          this.toastr.error(
            'There was an error while starting a configuration'
          );
        }
      );
    this.subscription$.push(openSubscription);
  }

  navigateToConfigPage() {
    this.modal.dismiss();
    this.router.navigate([
      'product/selection',
      'New Revision',
      this.projectId,
      this.revisionId,
      this.configId,
      this.saveProjectForm.controls.Ambient_Temp__Station.value.split(' ')[0]
    ]);
  }

  navigateToModelSearchPage() {
    this.modal.dismiss();
    this.router.navigate(
      ['model/results',
        this.projectId,
        this.revisionId,
        this.configId
      ],
      { queryParams: { search: this.searchString, zipCode: this.saveProjectForm.value.Zip_Code__c } }
    );
  }
  prepareProjectPayload() {
    if (!this.saveProjectForm.invalid) {
      let payload = {};
      return payload = {
        'Name': this.saveProjectForm.controls.Name.value || 'DFRCO2 Project',
        'Ambient_Temp__c': this.saveProjectForm.controls.Ambient_Temp__c.value,
        'Zip_Code__c': this.saveProjectForm.controls.Zip_Code__c.value,
        'application__c': this.saveProjectForm.controls.application__c.value,
        'storeType__c': this.saveProjectForm.controls.storeType__c.value,
        'accountSelection': this.saveProjectForm.value.accountSelection?.Id,
        'drop_ship_address_line_1__c': this.saveProjectForm.controls.Zip_Code__d?.value,
        'latitude__c': this.saveProjectForm.controls.latitude__c?.value,
        'longitude__c': this.saveProjectForm.controls.longitude__c?.value,
        'AmbientTempJSON__c': JSON.stringify(this.saveProjectForm.controls.AmbientTempJSON__c.value),
        'stationName__c': this.saveProjectForm.controls.stationName__c?.value,
        'UnitType': this.saveProjectForm.controls.UnitType.value,
        'ChildAccountId': this.saveProjectForm.value.childAccountId?.Id
      };
    }
  }

  getControlValue(type: string) {
    return this.saveProjectForm.controls[type].value;
  }

  // Google api code
  ngAfterViewInit() {
    if (!this.isUpdateProject) {
      this.googleMapsService.loadGoogleMapsApi(() => {
        const allowedCountries: string[] = Object.values(countryCodes);
        this.autocomplete = new google.maps.places.Autocomplete(this.Zip_Code__d.nativeElement);
        this.autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            const place = this.autocomplete?.getPlace();
            if (place?.address_components) {
              const countryComponent = place.address_components.find(component => component.types.includes('country'));
              if (countryComponent && allowedCountries.includes(countryComponent.short_name.toLowerCase())) {
                const result: PlaceSearchResult = {
                  address: this.Zip_Code__d.nativeElement.value,
                  Latitude__c: place?.geometry?.location?.lat() || 0,
                  Longitude__c: place?.geometry?.location?.lng() || 0,
                  zipCode: '0',
                };
                const countryName = countryComponent?.short_name.toLowerCase() || '';
                if (countryName === countryCodes.Canada) {
                  this.saveProjectForm.controls.UnitType.setValue('metric');
                } else {
                  this.saveProjectForm.controls.UnitType.setValue('imperial');
                }
                this.saveProjectForm.controls.Zip_Code__d.setValue(result.address);
                this.saveProjectForm.controls.Zip_Code__c.setValue('0');
                this.saveProjectForm.controls.Ambient_Temp__Station.setValue(0);
                this.saveProjectForm.controls.stationName__c.setValue(null);
                this.mapLatitude = result?.Latitude__c;
                this.mapLongitude = result?.Longitude__c;
                this.countryName = place.name ? place.name : countryComponent.long_name;
                this.getStationResult({ latitude: this.mapLatitude, longitude: this.mapLongitude, unitType: this.saveProjectForm.controls.UnitType?.value });
              } else {
                this.Zip_Code__d.nativeElement.value = '';
                alert('No station found for this location.');
              }
            } else {
              alert('No address found.');
            }
          });
        });
      });
    }
  }

  loadMap() {
    const mapOptions: google.maps.MapOptions = {
      center: { lat: 37.09024, lng: -95.712891 }, // Center of the restricted area
      // center: { lat: 40.7128, lng: -74.0060 }, // Example: New York City coordinates
      zoom: 4,
      scrollwheel: false,
      // mapTypeControl: false,
      // fullscreenControl: false,
      // streetViewControl: false,
      // minZoom: this.deskTopView.matches ? 1 : !this.mobileview.matches ? 2 : 0,
    };

    this.googleMapsService.initializeMap(this.mapContainer.nativeElement, mapOptions)
      .then((map: google.maps.Map) => {
        this.map = map;
      })
      .catch((error) => {
        console.error('Error initializing map:', error);
      });
  }

  updateMap(placeCoordinates: { latitude: any, longitude: any }) {
    if (this.map) {
      const { latitude, longitude } = placeCoordinates;
      const newCenter = new google.maps.LatLng(latitude, longitude);
      this.map.setZoom(4);
      this.map.panTo(newCenter);
      setTimeout(() => {
        this.map.setZoom(8);
      }, 1000);
      this.addMarkersToMap(this.stationData, latitude, longitude);

    }
  }

  onChangeUnit(unit: any) {
    if (this.mapLatitude && this.mapLongitude) {
      this.saveProjectForm.controls.Ambient_Temp__Station.setValue(0);
      this.saveProjectForm.controls.stationName__c.setValue(null);
      this.saveProjectForm.controls.UnitType.setValue(unit);
      this.getStationResult({ latitude: this.mapLatitude, longitude: this.mapLongitude, unitType: unit });
    }
  }

  onAccountSelect(event) {
    this.childAccounts = [];
    this.selectedChildAccount = null;
    this.saveProjectForm.controls.childAccountId.setValue(null);
    if (event.option.value.hasOwnProperty('ChildAccounts')) {
      this.childAccounts = event.option.value.ChildAccounts;
    } else {
      this.childAccounts = [{
        Id: event.option.value.Id,
        Name: event.option.value.Name
      }];
      this.selectedChildAccount = this.childAccounts[0]?.Name;
      this.saveProjectForm.controls.childAccountId.setValue(this.childAccounts[0]);
    }
    this.filteredChildAccounts = this.saveProjectForm.controls['childAccountId'].valueChanges.pipe(
      startWith(""),
      map(value => (typeof value === "string" ? value : value?.Name)),
      map(name => (name ? this._filterChildAccount(name) : this.childAccounts.slice()))
    );
  }

  onInputChange(event) {
    if (event.target.value === '') {
      this.childAccounts = [];
      this.selectedChildAccount = null;
      this.saveProjectForm.controls.childAccountId.setValue(null);
    }
  }

  ngOnDestroy() {
    this.subscription$.forEach(sub => sub.unsubscribe());
    if (this.autocomplete) {
      google.maps.event.clearInstanceListeners(this.autocomplete);
    }
  }
}
