import { Directive, Input, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';

export type SortColumn = '';
export enum SortDirection {
    Ascending = 'asc',
    Descending = 'desc',
    Natural = '',
}

// const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface SortEvent {
    column: SortColumn;
    direction: SortDirection;
}

// tslint:disable: directive-selector
@Directive({
    selector: 'th[sortable], div[sortable], span[sortable]',
})
export class SortableHeaderDirective {

    @Input() sortable: SortColumn = '';
    @Input() direction = SortDirection.Natural;
    @Output() sort = new EventEmitter<SortEvent>();

    @HostBinding('class.asc')
    get isAscending() { return this.direction === SortDirection.Ascending; }

    @HostBinding('class.desc')
    get isDescending() { return this.direction === SortDirection.Descending; }

    @HostListener('click')
    rotate(): void {
        switch (this.direction) {
            case SortDirection.Ascending:
                this.direction = SortDirection.Descending;
                break;
            case SortDirection.Descending:
                this.direction = SortDirection.Natural;
                break;
            case SortDirection.Natural:
                this.direction = SortDirection.Ascending;
                break;
            default:
                return;
        }
        this.sort.emit({ column: this.sortable, direction: this.direction });
    }
}
