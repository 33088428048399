import { LabelType, Options } from '@angular-slider/ngx-slider';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { CartService, CpqObjectType, CpqProposalReport, CpqQueryObjects, CpqReport, CpqUser } from '@cpq-app/services/cart.service';
import { ConfigData, CopyNodeData, ExecuteResponse, FpxCpaasFunction, Grouping, ProductService } from '@cpq-app/services/product.service';
import { SalesforceProxyService } from '@cpq-app/services/salesforce.service';
import { EnergyCalculation } from '@cpq-app/shared/services/energy-calculation.service';
import { PAK } from '@cpq-app/shared/services/PAK.service';
import { MSALService } from '@cpq-app/shared/services/msal.service';
import { SubGroup } from '@cpq-app/tenants/VWS/VWS.interfaces.service';
import { environment } from '@cpq-environments/environment';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { debounceTime, finalize, first, switchMap, tap } from 'rxjs/operators';
import { CpqOperations } from '../cpqOperations.service';
import { CreateLeadSfdcComponent } from '../create-task-sfdc/create-task-sfdc.component';
import { DFRAclAccess, DfrCpqOpportunity, DfrCpqQuote, DFRProduct, DfrSfdcOpportunity, FlexModelResult, ModelCompData, PakModel, PakModelResult, QueryParams, SfdcLeadSourceCO2 } from '../DFR.interfaces.service';
import { ProductDetailComponent } from '../product-detail/product-detail.component';
import { ProductSelectorComponent } from '../product-selector/product-selector.component';
import { SaveProjectComponent } from '../save-project/save-project.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ProposalPreview } from '@cpq-app/tenants/Cpq.interfaces.service';
import { PreviewComponent } from '@cpq-app/tenants/preview/preview.component';
import { EditDialogComponent } from '../edit-dialog/edit-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { forEach, StringChain } from 'lodash';
import { PageEvent } from '@angular/material/paginator';
import { RackOptionComponent } from '@cpq-app/sharedDirectives/rack-option/rack-option.component';
import { DialogBoxComponent } from '@cpq-app/shared/dialog-box/dialog-box.component';
import { MatTabGroup } from '@angular/material/tabs';
import { CompareProductsComponent } from '../compare-products/compare-products.component';

const FIRST_PANEL_PARTS_CONFIG = 'ngb-panel-rack-0';
const ROOT_NODE_INDEX = '1';
const DELAY_BEFORE_SAVE = 2000;
const NEWRACK = 'New Rack';
const SNACK_BAR_DURATION = 2000;
const MIN_CHUNK_SIZE = 500;
const MIN_CHUNK_SIZE_G = 1;
const LOAD_TEMP_MULTIPLIER = 1000;
declare const cds: any;
const NO_OF_RACKS = 9;
const ADIABATIC_TEMP = 101;



enum SelectionTypes {
  NumericInput = 'NumericInput',
  StringInput = 'StringInput',
  Radio = 'Radio',
  CheckBox = 'CheckBox',
  Quantity = 'Quantity'
}

enum SubGroup_Types {
  ExpertMode = 'G_ExpertMode',
  Nodes = 'G_Models'
}

enum RackNotification {
  AddRack = 'NewRack',
  RackExceeded = 'RackExceeded',
  SuccessAction = 'Success',
  RackExceededAction = 'Warning',
  SuccessMsg = 'New rack is added.',
  RackExceededMsg = 'Maximum number of racks per project is 9.'
}

enum EnergyCalculations {
  RefrigerantType = 'PS_RefrigTp',
  AmbientGroup = 'PS_AmbientTemp',
  CondenderTempGroup = 'PS_CondenserTp',
  LTLowTempGroup = 'PS_LTTotLoad',
  MTLowTempGroup = 'PS_MTTotLoad',
  RequiredLoadingRatio = 'PS_ReqLoadingRatio',
  LTTemp = 'PS_LTEvapTemp',
  MtTemp = 'PS_MTEvapTemp',
  AmbientOffSet = 'PS_OffsetAmbTemp',
  THR = 'THR'
}
enum RefrigerantType {
  R744 = 'R-744',
  R449A = 'R-449A',
  R448A = 'R-448A'
}
enum OptionType {
  Root = 'Root',
  Rack = 'Rack'
}
export const SFDC_DEFAULT_OPP_NAME = 'Cpq Opportunity';

export enum DfrSfdcOpportunityStage {
  DEFAULT = 'Needs Analysis'
}

const optionData = {
  "classname": "PreselectOptions",
  "id": 914,
  "name": "PSO_ReqLoading115",
  "value": 1,
  "textValue": null,
  "selected": 0,
  "quantity": 0,
  "label": "15",
  "unitPrice": 0,
  "imageUrl": "",
  "selectionType": "Radio",
  "disabled": 0,
  "shortDescription": "15"
}
const attributesToBeUpdated_Flex = ['LT_EvapCapacity', 'MT_EvapCapacity', 'MTExcessCapcPercent', 'LTExcessCapcPercent',
  'GasCoolerOutletTemp', 'GasCoolerOutletPressure', 'LowTempExcessCapacity', 'MedTempExcessCapacity'];

const attributesToBeUpdated_Pak = ['LT_EvapCapacity', 'MT_EvapCapacity', 'MTExcessCapcPercent', 'LTExcessCapcPercent'];

enum rackAttribute {
  LT_EvapCapacity = 'LT_EvapCapacity',
  MT_EvapCapacity = 'MT_EvapCapacity',
  MTExcessCapcPercent = 'MTExcessCapcPercent',
  LTExcessCapcPercent = 'LTExcessCapcPercent',
  GasCoolerOutletTemp = 'GasCoolerOutletTemp',
  LowTempExcessCapacity = 'LowTempExcessCapacity',
  MedTempExcessCapacity = 'MedTempExcessCapacity',
  GasCoolerOutletPressure = 'GasCoolerOutletPressure'
}
class SelectedOptions {
  optionId: string;
  attributeID: string;
  name: string;
  value: string;
}

class RacksOptions {
  name: string;
  value: string;
  defaultMax?: string;
  defaultMin?: string;
  optionId: string;
  label: string;
  suppressSelect?: boolean;
  subGroup: RacksOptions[];
}

class DesignCriteriaSelectedOptions {
  OptionGroupId: string;
  ValueString: string;
}
enum cdsConfigurator {
  RangeAttribute = "cds.configurator.RangeAttribute",
  Range = "range",
  String = "string"
}

@Component({
  selector: 'app-guided-selling',
  templateUrl: './guided-selling.component.html',
  styleUrls: ['./guided-selling.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GuidedSellingComponent implements OnInit, OnDestroy {
  subscription$: Subscription[] = [];
  projectId: string;
  revisionId: string;
  configId: string;
  hasRevisionRecords: boolean;
  revisions: DfrCpqQuote[] = [];
  revisionName: string;
  displayEditRevision = false;
  project: DfrCpqOpportunity;
  currentRevisionEditId: string;
  opcoName: string;
  updateTarget: string;
  saveProjectForm: FormGroup;
  navigateToProductDetailsPage: boolean = false;
  productBaseId: number;
  productCadId: string;

  activeId = 'static-1';
  newRackKey: string;
  rootDesignCriteria;
  availableRacks: any[];
  rackDesignCriteria;
  recommendedProducts: any[];
  flexRecommendedProducts: any[];
  filteredProducts: any[];
  availableProducts: any[];
  selectedProducts: any[];
  selectedProduct: DFRProduct;
  currentNodeIndex = ROOT_NODE_INDEX;
  rackForm: FormGroup;
  submitted = false;
  DESIGN_CRITERIA_SPINNER = "Design criteria";
  selectedRootProduct;
  refrigerationArchitecture;
  designCriteriaData;
  centralizedArchitecture: boolean;
  ActivateNode: number;
  isGuidedSelling = true;
  inputRackName: string;
  showProductbutton = false;
  showfetchProductsbutton: boolean;
  controlSpinner = false;
  availableSummary: any;
  downloadReport: boolean;
  airOverCoilTemperature: string;
  isDesignChangePopUpOpend = false;

  icons = {
    price: "monetization_on",
    energy: "energy_savings_leaf",
    redundancy: "sync",
    environment: "nest_eco_leaf",
    installation: "tools_wrench",
    exposure: "water_damage"
  }

  productList: DFRProduct[];
  productId: string;

  mtLoadValueForRack: number = 0;
  ltLoadValueForRack: number = 0;
  optionsForRackLT: Options = {
    floor: 0,
    ceil: 200,
    disabled: true
  };
  optionsForRackMT: Options = {
    floor: 0,
    ceil: 200,
    disabled: true
  };

  mtLoadValueForRoot: number = 0;
  ltLoadValueForRoot: number = 0;
  optionsForRootLT: Options;
  optionsForRootMT: Options;

  externalParams = [
    {
      key: 'RP_GCOutH',
      value: 130
    },
    {
      key: 'RP_FTSatLiqH',
      value: 87.7
    },
    {
      key: 'RP_FTSatVapH',
      value: 185.11
    },
    {
      key: 'LT_CoilIntetEntalphy',
      value: 102
    },
    {
      key: 'MT_CoilIntetEntalphy',
      value: 102
    }
  ];


  init$: Subscription[] = [];
  configSubscription: Subscription;
  saveSubscription: Subscription;
  updateSubscription: Subscription;
  deleteNodeSubscription: Subscription;
  moveNodeSubscription: Subscription;
  copyNodeSubscription: Subscription;
  customerViewSubscription: Subscription;
  createNodeSubscription: Subscription;
  nodeGroupSubscription: Subscription;
  mainFlexSubscription: Subscription;
  mainPakSubscription: Subscription;
  flexSubscriptionGuided: Subscription;
  flexSubscriptionQuickSearch: Subscription;
  proposalSubscription: Subscription;
  setPramsSubscription: Subscription;
  nodeGroupSubscription1: Subscription;
  updateMassFlowSubscription: Subscription;
  getModelDetailSubscription: Subscription;
  setMassFlowAndCapacitySubscription: Subscription;
  setCompressorCapacitySubscription: Subscription;

  modalReference: NgbModalRef;

  zipCode: string;
  latitude: any;
  longitude: any;
  offSetAmbientTemp: number;
  currentRevision: DfrCpqQuote;
  productInRevision: any[];

  proposalReports: CpqReport[] = [];
  proposalUrl: string;
  guidedSellingOn = true;
  obs: Observable<any>;
  dataSource: MatTableDataSource<any> = new MatTableDataSource;
  searchString: string;
  enableNoMatchFound = false;
  isExpertMode = false;
  @ViewChild('changeGuidedFlow', { static: false }) changeGuidedFlow: MatSlideToggle;

  proposalPreview: ProposalPreview;
  btnSpinner = false;
  subscriptions$: Subscription[] = [];
  EditRack: any;
  length = 0;
  pageSize = 10;
  pageIndex: number;
  // MatPaginator Output
  pageEvent: PageEvent;

  isInternalUser: boolean;
  currentRack;
  productsInRevisions = [];
  isShowDesignCriteria = true;
  isShowRackCriteria = true;
  rackTabsData = [];
  currentSelectedTab = 0;
  uniqueIdCounter = 1;
  rackId: string;
  ambientTemp: string;
  isConfigurationChanged = false;
  isDesignConfigurationChanged = false;
  productSelected: boolean = false;
  update = new BehaviorSubject<boolean>(false);
  currentSelectedRackId: string;
  newcreatedRackId: string;
  proposalResult: any;
  proposalQuoteId: string;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  primaryRack: string;
  primaryDesignCriteria = [];
  primaryRackCriteria = [];
  designCriteriaChanged = false;
  dataUnitValue = 'imperial';
  configOptions;
  unitType;
  uom;
  imperialEntries = ['aAmbientTemperature', 'aOffsetTemperature', 'aDesignTemperature', 'aAirOverCoilTemperature', 'aMtTotalLoad', 'aMtTemperature', 'aLtTotalLoad', 'aLtTemperature'];
  metricEntries = ['aAmbientTemperatureMetric', 'aOffsetTemperatureMetric', 'aDesignTemperatureMetric', 'aAirOverCoilTemperatureMetric', 'aMtTotalLoadMetric', 'aMtTemperatureMetric', 'aLtTotalLoadMetric', 'aLtTemperatureMetric']
  loaderCount = 0;
  compareProducts = [];
  getcompareProductsData = [];
  compareProductsLables = [];
  selectedCompareProducts = [];
  userClicked = false;
  loading=false;
  byPassTabClick = true;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private cartService: CartService,
    private productService: ProductService,
    private toastr: ToastrService,
    private msalService: MSALService,
    private dfrCpqOperations: CpqOperations,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private userService: UsersService,
    private salesforce: SalesforceProxyService,
    private energyService: EnergyCalculation,
    private pakService: PAK,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.opcoName = this.msalService.getTenantName();
    this.init$.push(this.route.params.subscribe({
      next: params => {
        this.initialFormData();
        this.processParams(params);
        this.isInternalUser = this.userService.checkLoggedInUserIsInternalUser();
      },
    }));

    if (!this.cartService.getConfigData()) {
      this.getConfigOptions();
    } else {
      this.configOptions = this.cartService.getConfigData();
      this.initData();
    }
    this.init$.push(this.route.queryParamMap.subscribe({
      next: queryParamMap => {
        this.updateTarget = queryParamMap.get(QueryParams.Update)?.trim();
        this.productId = queryParamMap.get(QueryParams.ProductId)?.trim();
      },
      error: err => {
        console.error('Failed to obtain query params', err);
      }
    }));
    this.dfrCpqOperations.productsInCurrentRevisions.subscribe((rev) => {
      this.productInRevision = rev;
    });
    this.isInternalUser = this.userService.checkLoggedInUserIsInternalUser();
  }

  getConfigOptions() {
    this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
    this.loaderCount++;
    this.cartService.getConfigOptions().subscribe({
      next: res => {
        this.configOptions = res;
        this.cartService.setConfigData(res);
        this.loaderCount--;
        if (this.loaderCount === 0) {
          this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
        }
      },
      error: err => {
        this.loaderCount--;
        if (this.loaderCount === 0) {
          this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
        }
      },
      complete: () => {
        this.initData();
      }

    });
  }

  initData() {
    // this.CdsInitalization();
    this.uom = sessionStorage.getItem('unitType');
    this.unitType = this.uom === 'metric' ? "avUomMetric" : "avUomImperial";
    this.checkIfDefaltQuotelineExist(true);
    this.update.subscribe(update => update === true ? this.checkIfDefaltQuotelineExist() : '');
  }

  private CdsInitalization() {
    let offSetAmbientTemp = this.getOffsetTemp();
    let AOCT = this.getAOCT();
    let initialOptions = {
      'aUom': this.unitType,
      "aAmbientTemperature": this.ambientTemp,
      'aAmbientTemperatureMetric': this.ambientTemp,      
      "aAirOverCoilTemperature": AOCT,
      'aAirOverCoilTemperatureMetric': AOCT, 
      'aRefrigerationType':'avRefrigerationTypeR744'  
    };
    let scopeElements = {
      "design": "cds-config-options-design",
      "rack": "cds-config-options-rack-1"
    };
    cds.configurator.runtime.init(this, this.configOptions.main, initialOptions, scopeElements);
    cds.configurator.runtime.manager.onChangeHandler = function (object) {
      let self = cds.configurator.runtime;
      let scope = null;
      for (let a in self.attributeOrder) {
        for (let i = 0; i < self.attributeOrder[a].length; i++) {
          let id = object.classId === "cds.configurator.SetAttributeValue" ?
            object.attribute.id : object.id;
          if (id === self.attributeOrder[a][i]) {
            scope = a;
          }
        }
      }
      if (cds.configurator.runtime.callingObject) {
        cds.configurator.runtime.callingObject.onCdsConfiguratorOptionChange(scope);
      }
    };
  }

  private getOffsetTemp() {
    let offSetAmbientTemp = 0;
    if (this.primaryDesignCriteria && this.primaryDesignCriteria?.length > 0) {
      offSetAmbientTemp = this.primaryDesignCriteria?.find(x => ['aOffsetTemperature', 'aOffsetTemperatureMetric'].includes(x[0].OptionGroupId))[0]?.ValueDouble || 0;
    }
    return offSetAmbientTemp;
  }
  getAOCT() {
    let airOverCoilTemperature = 0;
    if (this.primaryDesignCriteria && this.primaryDesignCriteria?.length > 0) {
      if (this.primaryDesignCriteria.find(x => ['aAirOverCoilTemperature', 'aAirOverCoilTemperatureMetric'].includes(x[0]?.OptionGroupId)) != undefined) {
        airOverCoilTemperature = this.primaryDesignCriteria?.find(x => ['aAirOverCoilTemperature', 'aAirOverCoilTemperatureMetric'].includes(x[0]?.OptionGroupId))[0]?.ValueDouble || 0;
      }
    }
    return airOverCoilTemperature;
  }

  ifDesignCriteriaChanged(): boolean {
    if (this.primaryDesignCriteria?.length > 0) {
      this.primaryDesignCriteria.forEach(primaryDesign => {
        if (primaryDesign?.length > 0) {
          if (primaryDesign[0].OptionType === cdsConfigurator.Range || primaryDesign[0].OptionType === cdsConfigurator.String) {
            if (cds.configurator.runtime.attributes[primaryDesign[0].OptionGroupId] != undefined) {
              let optionValue = cds.configurator.runtime.attributes[primaryDesign[0].OptionGroupId].getValue();
              if (optionValue && Math.round(primaryDesign[0].ValueDouble) !== Math.round(optionValue)) {
                return this.designCriteriaChanged = true;
              }
            }
          }
          else {
            if (cds.configurator.runtime.attributes[primaryDesign[0].OptionGroupId] != undefined) {
              let optionValue = cds.configurator.runtime.attributes[primaryDesign[0].OptionGroupId].getValue() ? cds.configurator.runtime.attributes[primaryDesign[0].OptionGroupId].getValue()[0].id : null;
              if (optionValue && primaryDesign[0].ValueString !== optionValue) {
                return this.designCriteriaChanged = true;
              }
            }
          }
        }
      });
    }
    return this.designCriteriaChanged;
  }
  ifRackCriteriaChanged(): boolean {
    if (this.primaryRackCriteria?.length > 0) {
      this.primaryRackCriteria.forEach(primaryRack => {
        if (primaryRack?.length > 0) {
          if (primaryRack[0].OptionType === cdsConfigurator.Range || primaryRack[0].OptionType === cdsConfigurator.String) {
            if (cds.configurator.runtime.attributes[primaryRack[0].OptionGroupId] != undefined && cds.configurator.runtime.attributes[primaryRack[0].OptionGroupId].getValue()!=null)
              if (Math.round(primaryRack[0].ValueDouble) !== Math.round(cds.configurator.runtime.attributes[primaryRack[0].OptionGroupId].getValue())) {
                return this.designCriteriaChanged = true;
              }
          }
          else {
            if (cds.configurator.runtime.attributes[primaryRack[0].OptionGroupId] != undefined) {
              let optionValue = cds.configurator.runtime.attributes[primaryRack[0].OptionGroupId].getValue() ? cds.configurator.runtime.attributes[primaryRack[0].OptionGroupId].getValue()[0].id : null;
              if (primaryRack[0].ValueString !== optionValue) {
                return this.designCriteriaChanged = true;
              }
            }
          }
        }
      });
    }
    return this.designCriteriaChanged;
  }

  getUOMSpecificEntries(QuoteEntries) {
    let latestQuotelineEntries;
    if (this.uom === 'metric') {
      latestQuotelineEntries = QuoteEntries.filter(x => !this.imperialEntries.includes(x.OptionGroupId));
    } else {
      latestQuotelineEntries = QuoteEntries.filter(x => !this.metricEntries.includes(x.OptionGroupId));
    }
    // latestQuotelineEntries = latestQuotelineEntries.filter(x => x.OptionGroupId !== 'aUom');
    return latestQuotelineEntries;
  }

  getPrimaryRacksData(primarayRackQuoteLineData) {
    if (primarayRackQuoteLineData?.length > 0) {
      let designGroup;
      let rackGroup;
      if (this.uom === 'metric') {
        designGroup = cds.configurator.runtime.attributeOrder?.design.filter(x => !this.imperialEntries.includes(x));
        rackGroup = cds.configurator.runtime.attributeOrder?.rack.filter(x => !this.imperialEntries.includes(x))
      } else {
        designGroup = cds.configurator.runtime.attributeOrder?.design.filter(x => !this.metricEntries.includes(x));
        rackGroup = cds.configurator.runtime.attributeOrder?.rack.filter(x => !this.metricEntries.includes(x))
      }
      this.primaryDesignCriteria = [];
      this.primaryRackCriteria = [];
      designGroup?.forEach(designDefault => {
        if (!["aAirOverCoilTemperature", 'aAirOverCoilTemperatureMetric'].includes(designDefault))
          this.primaryDesignCriteria.push(primarayRackQuoteLineData.filter(x => x.OptionGroupId === designDefault));
      });
      rackGroup?.forEach(rackDefault => {
        this.primaryRackCriteria.push(primarayRackQuoteLineData.filter(x => x.OptionGroupId === rackDefault));
      });
    }
  }

  private CdsInitalizationDefalts() {
    let offSetAmbientTemp = this.getOffsetTemp();
    let AOCT = this.getAOCT();
    let initialOptions = {
      'aUom': this.unitType,
      "aAmbientTemperature": this.ambientTemp,
      'aAmbientTemperatureMetric': this.ambientTemp,      
      "aAirOverCoilTemperature": AOCT,
      'aAirOverCoilTemperatureMetric': AOCT,
      'aOffsetTemperature': offSetAmbientTemp,
      'aOffsetTemperatureMetric': offSetAmbientTemp,
      "aRefrigerationType": this.getValueAttributes(cds.configurator.runtime.attributes["aRefrigerationType"]),
      "aElectricalType": this.getValueAttributes(cds.configurator.runtime.attributes["aElectricalType"]),
      "aCondenserGasCoolerType": this.getValueAttributes(cds.configurator.runtime.attributes["aCondenserGasCoolerType"]),
      "aRackLocation": this.getValueAttributes(cds.configurator.runtime.attributes["aRackLocation"]),
      "aRemoteOrIntegratedCondenser": this.getValueAttributes(cds.configurator.runtime.attributes["aRemoteOrIntegratedCondenser"]),
      "aCondenserGasCoolerBrand": this.getValueAttributes(cds.configurator.runtime.attributes["aCondenserGasCoolerBrand"]),
      "aMtTotalLoad": cds.configurator.runtime.attributes['aMtTotalLoad'].defaultValue,
      "aMtTemperature": cds.configurator.runtime.attributes['aMtTemperature'].defaultValue,
      "aLtTotalLoad": cds.configurator.runtime.attributes['aLtTotalLoad'].defaultValue,
      "aLtTemperature": cds.configurator.runtime.attributes['aLtTemperature'].defaultValue,
      "aMinRequiredSpareCapacity": cds.configurator.runtime.attributes['aMinRequiredSpareCapacity'].defaultValue.id,
      "aHeatRecovery": cds.configurator.runtime.attributes['aHeatRecovery']?.defaultValue?.id || 'avHeatRecoveryNo',
      "aHeatReclaim": cds.configurator.runtime.attributes['aHeatReclaim']?.defaultValue?.id || 'avHeatReclaimNo'
    };
    let scopeElements = {
    };
    cds.configurator.runtime.init(this, this.configOptions.main, initialOptions, scopeElements);

    cds.configurator.runtime.manager.onChangeHandler = function (object) {
      let self = cds.configurator.runtime;
      let scope = null;
      for (let a in self.attributeOrder) {
        for (let i = 0; i < self.attributeOrder[a].length; i++) {
          let id = object.classId === "cds.configurator.SetAttributeValue" ?
            object.attribute.id : object.id;
          if (id === self.attributeOrder[a][i]) {
            scope = a;
          }
        }
      }
      if (cds.configurator.runtime.callingObject) {
        cds.configurator.runtime.callingObject.onCdsConfiguratorOptionChange(scope);
      }
    };
  }
  onCdsConfiguratorOptionChange(ConfiguratorType) {
    console.log("ConfiguratorType");
    console.log(ConfiguratorType);
    this.designCriteriaChanged = false;
    if (ConfiguratorType == 'design') {
      setTimeout(() => {
        if (this.ifDesignCriteriaChanged()) {
          if (this.currentSelectedRackId === this.primaryRack) {
            if (this.availableRacks.length > 0) {
              if (this.availableRacks.length > 1 && !this.isDesignChangePopUpOpend) {
                this.isDesignChangePopUpOpend = true;
                const dialogRef = this.dialog.open(DialogBoxComponent, {
                  data: { message: 'Are you sure you would like continue with design criteria change as there are other multiple racks.?' },
                });
                dialogRef.afterClosed().subscribe((result) => {
                  if (result) {
                    // if clicked Yes
                    this.deleteRacksforDesignCriteria(this.availableRacks);
                    this.isConfigurationChanged = true;
                    this.compareProducts = [];
                    this.selectedCompareProducts = [];
                    this.AoctMasking();
                  } else {
                    this.resetDesignCriteria();
                    this.isConfigurationChanged = false;
                  }
                  this.isDesignChangePopUpOpend = false;
                });
              }
              else {
                if (this.availableProducts.length > 0) {
                  const selectedProducts = this.availableProducts.filter(product => product.Selected === true);
                  if (selectedProducts?.length > 0 && !this.isDesignChangePopUpOpend) {
                    this.isDesignChangePopUpOpend = true;
                    const dialogRef = this.dialog.open(DialogBoxComponent, {
                      data: { message: 'Are you sure you would like continue with design criteria change as product is selected.?' },
                    });
                    dialogRef.afterClosed().subscribe((result) => {
                      if (result) {
                        // if clicked Yes
                        this.resetSelectedProduct(this.availableRacks);
                        this.isConfigurationChanged = true;
                        this.compareProducts = [];
                        this.selectedCompareProducts = [];
                        this.AoctMasking();
                      } else {
                        this.resetDesignCriteria();
                        this.isConfigurationChanged = false;
                      }
                      this.isDesignChangePopUpOpend = false;
                    });

                  } else {
                    this.isConfigurationChanged = true;
                    this.compareProducts = [];
                    this.selectedCompareProducts = [];
                    this.AoctMasking();
                  }
                } else {
                  this.isConfigurationChanged = true;
                  this.compareProducts = [];
                  this.selectedCompareProducts = [];
                  this.AoctMasking();
                }
              }
            }
          } else {
            if (this.availableRacks?.length > 0 && !this.isDesignChangePopUpOpend) {
              this.isDesignChangePopUpOpend = true;
              const dialogRef = this.dialog.open(DialogBoxComponent, {
                data: { message: 'Are you sure you would like continue with design criteria change as this will delete all racks other than first rack.?' },
              });
              dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                  // if clicked Yes
                  this.deleteRacksforDesignCriteria(this.availableRacks);
                  this.compareProducts = [];
                  this.selectedCompareProducts = [];
                } else {
                  this.resetDesignCriteria();
                  this.isConfigurationChanged = false;
                }
                this.isDesignChangePopUpOpend = false;
              });
            }
          }
          this.designCriteriaChanged = false;
        }
      }, 200);
    } else {
      if (ConfiguratorType == 'rack') {
        setTimeout(() => {
          if (this.ifRackCriteriaChanged()) {
            this.isConfigurationChanged = true;
            this.compareProducts = [];
            this.selectedCompareProducts = [];
            this.AoctMasking();
          }
        }, 200);
      }
    }
  }

  resetDesignCriteria() {
    if (this.primaryDesignCriteria?.length > 0) {
      this.primaryDesignCriteria.forEach(primaryDesign => {
        if (primaryDesign?.length > 0) {
          if (primaryDesign[0].OptionType === cdsConfigurator.Range || primaryDesign[0].OptionType === cdsConfigurator.String) {
            if (cds.configurator.runtime.attributes[primaryDesign[0].OptionGroupId] != undefined)
              if (primaryDesign[0].ValueDouble !== cds.configurator.runtime.attributes[primaryDesign[0].OptionGroupId].getValue()) {
                cds.configurator.runtime.attributes[primaryDesign[0].OptionGroupId].setValue(primaryDesign[0].ValueDouble);
              }
          }
          else {
            if (cds.configurator.runtime.attributes[primaryDesign[0].OptionGroupId] != undefined) {
              let optionValue = cds.configurator.runtime.attributes[primaryDesign[0].OptionGroupId].getValue() ? cds.configurator.runtime.attributes[primaryDesign[0].OptionGroupId].getValue()[0].id : null;
              if (primaryDesign[0].ValueString !== optionValue) {
                cds.configurator.runtime.attributes[primaryDesign[0].OptionGroupId].setValue(primaryDesign[0].ValueString);
              }
            }
          }
        }
      });
    }
  }

  deleteRacksforDesignCriteria(racks) {
    if (racks?.length > 0) {
      racks.forEach(rack => {
        if (rack?.Id !== this.primaryRack) {
          this.deleteMultipleRack(rack?.Id)
        }
      });
    }
  }
  check() {
    let data = cds.configurator.runtime.manager.getValue();
    for (const [key, value] of Object.entries(data)) {
      if (Array.isArray(value) && value.length > 0) {
        const firstItem = value[0];
        if (firstItem && typeof firstItem === 'object' && 'id' in firstItem) {
          console.log(`Key: ${key}, Value: ${firstItem.id}`);
        } else {
          console.warn(`Key: ${key}, does not contain a valid object with an 'id' field`);
        }
      } else if (typeof value === 'string' || typeof value === 'number') {
        console.log(`Key: ${key}, Value: ${value}`);
      } else {
        console.warn(`Key: ${key}, Value is not of expected type`);
      }
    }
  }

  resetSelectedProduct(Racks) {
    if (Racks?.length > 0) {
      this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
      this.loaderCount++;
      Racks?.forEach(rack => {
        let paramOptions = {
          "ProductId": null
        }
        this.subscription$.push(this.cartService.updateObjectById(CpqObjectType.QuoteLine, rack?.Id, paramOptions)
          .subscribe({
            next: (quoteLine: any) => {
              this.availableProducts = [];
              this.availableProducts = quoteLine?.AvailableProducts;
              this.isConfigurationChanged = true;
              this.rackId = rack.Id;
              this.AoctMasking();
              this.downloadReport = true;
              this.loaderCount--;
              if (this.loaderCount === 0) {
                this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
              }
            },
            error: err => {
              this.loaderCount--;
              if (this.loaderCount === 0) {
                this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
              }
              this.toastr.error(
                'There is fatal error while updated product card', 'Error', {
                disableTimeOut: true,
                closeButton: true
              }
              );
            }
          }));
      });
    }
  }

  resetSingleSelectedProduct(rack) {
    if (rack) {
      this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
      this.loaderCount++;
      let paramOptions = {
        "ProductId": null
      }
      this.subscription$.push(this.cartService.updateObjectById(CpqObjectType.QuoteLine, rack, paramOptions)
        .subscribe({
          next: (quoteLine: any) => {
            this.availableProducts = [];
            this.availableProducts = quoteLine?.AvailableProducts;
            this.loaderCount--;
            if (this.loaderCount === 0) {
              this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
            }
          },
          error: err => {
            this.loaderCount--;
            if (this.loaderCount === 0) {
              this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
            }
            this.toastr.error(
              'There is fatal error while updated product card', 'Error', {
              disableTimeOut: true,
              closeButton: true
            }
            );
          }
        }));
    }
  }


  generateUniqueId(): string {
    return `cds-config-options-rack-${this.uniqueIdCounter++}`;
  }

  // new code
  addTabRack() {
    if (!this.isConfigurationChanged) {
      this.compareProducts = [];
      this.selectedCompareProducts = [];
      if (this.availableRacks?.length < NO_OF_RACKS) {
        this.byPassTabClick = true;
        this.CreateQuoteLine(true);
      }
      else {
        this.rackNotification(RackNotification.RackExceededMsg, RackNotification.RackExceeded)
      }
    }
    else {
      alert('Please Retrieve Products.');
    }
  }

  paramForQuoteLineEntries() {
    let designRackGroup = cds.configurator.runtime.manager.getValue();
    const attributeGroup = [...this.configOptions.main.attributeGroups['design'], ...this.configOptions.main.attributeGroups['rack']];
    attributeGroup.push('aUom');
    const filteredAttributes = attributeGroup.reduce((orderedObj, key) => {
      if (designRackGroup.hasOwnProperty(key)) {
        orderedObj[key] = designRackGroup[key];
      }
      return orderedObj;
    }, {})
    if (filteredAttributes.length > 0) {
      var index = filteredAttributes.indexOf('aAirOverCoilTemperature');
      if (index == -1) {
        filteredAttributes.unshift("aAirOverCoilTemperature");
      }
    }
    let Entries = {};
    for (let item in filteredAttributes) {
      if (item === 'refrigerant_used_type') {
        console.log("Error");
      }
      let inputEntries = this.getValueAttributes(cds.configurator.runtime.attributes[item]);
      if (typeof inputEntries !== 'undefined' && inputEntries !== null) {
        Entries[item] = this.getValueAttributes(cds.configurator.runtime.attributes[item]);
      }
    }
    return Entries;
  }

  getValueAttributes(attribute) {
    if (typeof attribute !== 'undefined' && attribute !== null) {
      const value = attribute.getValue();
      if (Array.isArray(value) && value.length > 0) {
        return value[0].id;
      } else {
        return value;
      }
    }
  }

  CreateQuoteLine(isNewRack?) {
    if (isNewRack) {
      this.CdsInitalizationDefalts();
    }
    if (true) {
      this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
      this.loaderCount++;
      let quoteLinePayload = {
        Name: NEWRACK,
        Description: `Desc for new Rack.`,
        ParentQuoteId: this.revisionId,
        RootQuoteId: this.revisionId,
        Quantity: 1
      }
      this.subscription$.push(this.cartService.createObject(CpqQueryObjects.QuoteLine, quoteLinePayload)
        .subscribe({
          next: (quoteLine: any) => {
            this.rackId = quoteLine;
            this.currentSelectedRackId = quoteLine;
            this.newcreatedRackId = quoteLine;
            this.updateRack(quoteLine, true, this.dataUnitValue);
            this.loaderCount--;
            if (this.loaderCount === 0) {
              this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
            }
          },
          error: err => {
            this.loaderCount--;
            if (this.loaderCount === 0) {
              this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
            }
            this.toastr.error(
              'There is fatal error while fetching revision', 'Error', {
              disableTimeOut: true,
              closeButton: true
            }
            );
          },
          complete: () => {
            this.update.next(true);
            this.proposalQuoteId = this.revisionId;
            // this.resetSingleSelectedProduct(this.rackId);
          }
        }));
    }
    else {
      this.rackNotification(RackNotification.RackExceededMsg, RackNotification.RackExceeded)
    }
  }
  checkIfDefaltQuotelineExist(isOnLoad?) {
    this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
    this.loaderCount++;
    this.subscription$.push(this.cartService.getCPQObjectById(CpqObjectType.Quote, this.revisionId)
      .subscribe({
        next: (quoteLine: any) => {
          this.loading = false;
          if (quoteLine?.Lines.length === 0) {
            this.CreateQuoteLine();
            this.CdsInitalization();
          }
          else {
            this.getQuoteAvailableRacks(quoteLine, isOnLoad);
          }
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
          }
        },
        error: err => {
          this.loading = false;
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
          }
          this.toastr.error(
            'There is fatal error while fetching revision', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      }));
  }
  //loadRackData
  getQuoteAvailableRacks(quoteLines, onFirstLoad?) {
    this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
    this.loaderCount++;
    if (quoteLines?.Lines?.length >= 0) {
      this.availableRacks = quoteLines?.Lines;
      this.proposalQuoteId = this.revisionId;
      this.availableRacks.sort((a, b) => a.Sequence - b.Sequence);
      if (this.newcreatedRackId) {
        let newRackIndex = this.availableRacks.findIndex(x => x.Id === this.newcreatedRackId);
        if (newRackIndex > 0) {
          this.tabGroup.selectedIndex = newRackIndex;
        }
        this.newcreatedRackId = "";
      }
      this.rackId = this.currentSelectedRackId = this.currentSelectedRackId ? this.currentSelectedRackId : this.availableRacks[0]?.Id;
      if (this.availableRacks?.length > 0) {
        this.primaryRack = this.availableRacks[0]?.Id;
      }
    }
    else { this.rackId = quoteLines?.Id; }
    this.subscription$.push(this.cartService.getCPQObjectById(CpqObjectType.QuoteLine, this.rackId)
      .subscribe({
        next: (QuoteLineData: any) => {
          let QuoteEntries = QuoteLineData?.Entries;
          this.availableProducts = QuoteLineData?.AvailableProducts;
          if (this.availableProducts) {
            this.availableProducts.forEach((product) => {
              product['imageUrl'] = `${environment.cds.ImageBaseUrl}/domains/dfr/images/${product['Image']}`;
            });
            const selectedProducts = this.availableProducts.filter(product => product.Selected === true);
            this.downloadReport = selectedProducts.length === 1 ? false : true;
          }
          this.primaryDesignCriteria = [];
          let latestQuotelineEntries = this.getUOMSpecificEntries(QuoteEntries);
          if (onFirstLoad) {
            let initialOptions = this.prepareOptions(latestQuotelineEntries);
            initialOptions['aUom'] = this.unitType;
            let scopeElements = {
              "design": "cds-config-options-design",
              "rack": "cds-config-options-rack-1"
            };
            cds.configurator.runtime.init(this, this.configOptions.main, initialOptions, scopeElements);

            cds.configurator.runtime.manager.onChangeHandler = function (object) {
              let self = cds.configurator.runtime;
              let scope = null;
              for (let a in self.attributeOrder) {
                for (let i = 0; i < self.attributeOrder[a].length; i++) {
                  let id = object.classId === "cds.configurator.SetAttributeValue" ?
                    object.attribute.id : object.id;
                  if (id === self.attributeOrder[a][i]) {
                    scope = a;
                  }
                }
              }
              if (cds.configurator.runtime.callingObject) {
                cds.configurator.runtime.callingObject.onCdsConfiguratorOptionChange(scope);
              }
            };
          } else {
            latestQuotelineEntries.forEach(option => {
              if (option.OptionType === cdsConfigurator.Range || option.OptionType === cdsConfigurator.String) {
                if (cds.configurator.runtime.attributes?.[option.OptionGroupId] != undefined)
                  cds.configurator.runtime.attributes[option.OptionGroupId].setValue(option.ValueDouble);
              }
              else {
                if (cds.configurator.runtime.attributes?.[option.OptionGroupId] != undefined) {
                  let optionValue = cds.configurator.runtime.attributes[option.OptionGroupId].getValue() ? cds.configurator.runtime.attributes[option.OptionGroupId].getValue()[0].id : null;
                  if (option.OptionGroupId !== 'aUom' && optionValue !== option.ValueString) {
                    cds.configurator.runtime.attributes[option.OptionGroupId].setValue(option.ValueString);
                  } else if (option.OptionGroupId === 'aUom' && optionValue !== this.unitType) {
                    cds.configurator.runtime.attributes[option.OptionGroupId].setValue(this.unitType);
                  }
                }
              }
            });
          }
          this.getPrimaryRacksData(latestQuotelineEntries);
          this.isConfigurationChanged = false;
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
          }
        },
        error: err => {
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
          }
          this.toastr.error(
            'There is fatal error while fetching QuoteEntries', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        },
        complete: () => {
          this.ifDesignCriteriaChanged();
          if (this.availableProducts && this.availableProducts.length < 1) {
            this.enableNoMatchFound = true;
          } else {
            this.enableNoMatchFound = false;
          }
        }
      }));
  }

  prepareOptions(quoteLineEntries) {
    const attributeGroup = [...this.configOptions.main.attributeGroups['design'], ...this.configOptions.main.attributeGroups['rack']];
    attributeGroup.push('aUom');
    const filteredAttributes = quoteLineEntries.reduce((orderedObj, key) => {
      if (attributeGroup.includes(key.OptionGroupId)) {
        orderedObj.push(key);
      }
      return orderedObj;
    }, [])
    return filteredAttributes.reduce((acc, x) => {
      acc[x.OptionGroupId] = x.ValueString || x.ValueDouble;
      return acc;
    }, {});
  }

  //update Rack
  updateRack(quotelineId, refreshProduct: boolean = false, unit?: string) {
    this.loading= true;
    this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
    this.loaderCount++;
    this.availableProducts = [];
    let paramOptions = {
      "Entries": this.paramForQuoteLineEntries(),
    }
    this.subscription$.push(this.cartService.updateObjectById(CpqObjectType.QuoteLine, quotelineId, paramOptions, { refreshAvailableProducts: refreshProduct, dataUnitSystem: unit })
      .subscribe({
        next: (quoteLine: any) => {
          this.loading= false;
          this.availableProducts = [];
          this.availableProducts = quoteLine?.AvailableProducts;
          this.primaryDesignCriteria = [];
          let latestQuotelineEntries = this.getUOMSpecificEntries(quoteLine?.Entries);
          this.getPrimaryRacksData(latestQuotelineEntries);
          if (this.availableProducts.length > 0) {
            this.availableProducts.forEach((product) => {
              product['imageUrl'] = `${environment.cds.ImageBaseUrl}/domains/dfr/images/${product['Image']}`;
              this.airOverCoilTemperature = product?.AirOverCoilTemp || 0;
            });
            const aoctVal = this.uom === 'metric' ? "aAirOverCoilTemperatureMetric" : "aAirOverCoilTemperature";
            if (cds.configurator.runtime.attributes[aoctVal] != undefined) {
              cds.configurator.runtime.attributes[aoctVal].setValue(this.airOverCoilTemperature);
            }
            const selectedProducts = this.availableProducts.filter(product => product.Selected === true);
            this.downloadReport = selectedProducts.length === 1 ? false : true;
          }
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
          }
        },
        error: err => {
          this.loading= false;
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
          }
          this.toastr.error(
            'There is fatal error while fetching revision', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        },
        complete: () => {
          this.loading= false;
          this.updateEntries(quotelineId, false);
          if (this.availableProducts && this.availableProducts.length < 1) {
            this.enableNoMatchFound = true;
          } else {
            this.enableNoMatchFound = false;
          }
        }
      }));
  }

  updateEntries(quotelineId, refreshProduct: boolean = false) {
    this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
    this.loaderCount++;
    let paramOptions = {
      "Entries": this.paramForQuoteLineEntries(),
    }
    this.subscription$.push(this.cartService.updateObjectById(CpqObjectType.QuoteLine, quotelineId, paramOptions, { refreshAvailableProducts: refreshProduct, dataUnitSystem: this.dataUnitValue })
      .subscribe({
        next: (quoteLine: any) => {
          this.availableProducts = [];
          this.availableProducts = quoteLine?.AvailableProducts;
          this.primaryDesignCriteria = [];
          let latestQuotelineEntries = this.getUOMSpecificEntries(quoteLine?.Entries);
          this.getPrimaryRacksData(latestQuotelineEntries);
          if (this.availableProducts.length > 0) {
            this.availableProducts.forEach((product) => {
              product['imageUrl'] = `${environment.cds.ImageBaseUrl}/domains/dfr/images/${product['Image']}`;
              this.airOverCoilTemperature = product?.AirOverCoilTemp || 0;
            });
            const aoctVal = this.uom === 'metric' ? "aAirOverCoilTemperatureMetric" : "aAirOverCoilTemperature";
            if (cds.configurator.runtime.attributes[aoctVal] != undefined) {
              cds.configurator.runtime.attributes[aoctVal].setValue(this.airOverCoilTemperature);
            }
            const selectedProducts = this.availableProducts.filter(product => product.Selected === true);
            this.downloadReport = selectedProducts.length === 1 ? false : true;
          }
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
          }
        },
        error: err => {
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
          }
          this.toastr.error(
            'There is fatal error while fetching revision', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        },
        complete: () => {

        }
      }));
  }





  // end code
  private processParams(params: Params) {
    if (params?.projectId) {
      this.projectId = params.projectId;
    }
    if (params?.revisionName) {
      this.revisionName = params.revisionName;
    }
    if (params?.revisionId) {
      this.revisionId = params.revisionId;
    }
    if (params?.configId) {
      this.configId = params?.configId;
    }
    if (params?.ambientTemp) {
      this.ambientTemp = params?.ambientTemp;
    }
  }

  private setupConfigSubscription(configId: string): void {
    this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
    this.loaderCount++;
    if (this.configSubscription && !this.configSubscription.closed) {
      this.configSubscription.unsubscribe();
    }

    this.configSubscription = this.productService
      .getGroup(configId)
      .subscribe({
        next: data => {
          this.processConfigData(data);
        },
        error: err => {
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
          }
          this.controlSpinner = false;
          this.toastr.error('There was an error while fetching data set', 'Error', {
            disableTimeOut: true,
            closeButton: true
          });
        }, complete: () => {
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
          }
        }
      });
  }

  private processConfigData(data: ConfigData) {
    if (data) {
      try {
        this.designCriteriaData = data.groups?.preSelect?.subGroups[0];
        this.rootDesignCriteria = data.groups?.preSelect;
        this.availableRacks = data?.parts?.selected;
        this.availableSummary = data?.parts?.summary;
        this.newRackKey = NEWRACK;
        this.selectedRootProduct = data.products?.available?.filter((product) => product['selected'] === 1);
        this.availableRacks.forEach((rack) => {
          rack['displayEditRack'] = false;
          rack['rackNameCopy'] = rack?.label;
        });
        const getSubgroups = this.rootDesignCriteria['subGroups'][0]['subGroups'];
        var filterSubGroups = getSubgroups.filter(group => (group.name === 'PS_SiteLocation'));

        this.zipCode = this.availableSummary.geographyParams.ss_ZipCode ? this.availableSummary.geographyParams.ss_ZipCode : '0';
        this.latitude = this.availableSummary.geographyParams.ss_Latitude ? this.availableSummary.geographyParams.ss_Latitude : 0;
        this.longitude = this.availableSummary.geographyParams.ss_Longitude ? this.availableSummary.geographyParams.ss_Longitude : 0;


        filterSubGroups = getSubgroups.filter(group => (group.name === EnergyCalculations.AmbientOffSet));
        let ambientoffSet = filterSubGroups[0].options?.filter(option => option.selected === 1);
        this.offSetAmbientTemp = ambientoffSet[0]?.result;
        if (this.availableRacks.length > 0) {
          this.onRackChange(this.availableRacks[0], null);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log('No data');
    }
  }

  displayTotalLoadCapacities() {
    const getSubgroups = this.rootDesignCriteria['subGroups'][0]['subGroups'];
    const filterSubGroups = getSubgroups.filter(group => (group.name === 'PS_PrdMTTotLoad' || group.name === 'PS_PrdLTotLoad'));
    if (filterSubGroups.length) {
      filterSubGroups.forEach((group) => {
        if (group.name === 'PS_PrdMTTotLoad') {
          this.mtLoadValueForRoot = group.options[0].value;
        } else {
          this.ltLoadValueForRoot = group.options[0].value;
        }
      })
    }
    const userDefinedLTLoad = this.ltLoadValueForRoot;
    const userDefinedMTLoad = this.mtLoadValueForRoot;

    if (this.mtLoadValueForRoot !== 0) {
      this.optionsForRootMT = {
        floor: 0,
        ceil: userDefinedMTLoad * 1.3,
        disabled: true
      };
    }
    if (this.ltLoadValueForRoot !== 0) {
      this.optionsForRootLT = {
        floor: 0,
        ceil: userDefinedLTLoad * 1.3,
        disabled: true
      };
    }
  }

  initialFormData() {
    const nonWhiteSpaceRegExp: RegExp = new RegExp('\\S');
    this.rackForm = this.formBuilder.group({
      quoteName: [null, [Validators.required, Validators.pattern(nonWhiteSpaceRegExp), Validators.maxLength(120)]]
    });
  }

  addRack() {
    if (this.availableRacks.length < NO_OF_RACKS) {
      this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
      this.loaderCount++;
      const rackKey = this.newRackKey;
      let newRack = { name: rackKey };
      this.createNodeSubscription = this.productService.configCreateNode(this.configId, newRack.name).subscribe({
        next: (nodeId: string) => {
          if (nodeId) {
            newRack['nodeId'] = nodeId;
            this.addProduct(newRack);
          } else {
            console.log('No data');
          }
        },
        error: err => {
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
          }
          this.toastr.error(
            'There is error while createNode', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        },
        complete: () => {
          this.rackNotification(RackNotification.SuccessMsg, RackNotification.AddRack);
          this.onRackChange(this.availableRacks[this.availableRacks.length - 1], null);
          this.setupConfigSubscription(this.configId);
          this.getProposalData();
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
          }
        }
      });
    }
    else {
      this.rackNotification(RackNotification.RackExceededMsg, RackNotification.RackExceeded)
    }
  }

  editRackName(rack) {
    rack.displayEditRack = !rack.displayEditRack;
  }

  downloadProposal() {
    if (this.currentRevision?.Proposals.length > 0) {
      const reportIndex = document.getElementById("proposalReport") as HTMLSelectElement | null;;
      if (this.proposalReports.length > 0) {
        let selectedReport: CpqReport = this.proposalReports[reportIndex.selectedIndex - 1];
        if (selectedReport) {
          const [proposal] = this.currentRevision?.Proposals;
          this.proposalUrl = this.cartService.getProposalUrl(proposal?.Id, selectedReport?.id);
          window.open(this.proposalUrl, '_blank');
        }
      }
    }
  }

  getProposalData() {
    this.dfrCpqOperations.getQuoteLineProducts(this.revisionId).subscribe({
      next: (quoteWithProducts: any) => {
        this.currentRevision = quoteWithProducts;
        if (quoteWithProducts) {
          this.getRevisionProposals();
        }
      },
      error: err => {
        console.log(err);
      }
    });
  }

  getRevisionProposals() {
    const [proposal] = this.currentRevision?.Proposals;
    if (proposal) {
      this.subscription$.push(this.cartService.addProposalReports(proposal?.Id).subscribe({
        next: (value: CpqProposalReport) => {
          this.proposalReports = value?.selectedList;
          this.proposalReports = this.proposalReports.filter(report => report?.available === true);
          this.getProductsAssociatedWithRevision();
        },
        error: (err) => {
          this.toastr.error(
            'There was an error while updating proposals name', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      }));
    }
  }

  getProductsAssociatedWithRevision() {
    this.productsInRevisions = [];
    if (this.revisionId) {
      this.dfrCpqOperations.getQuoteLineProducts(this.revisionId).subscribe({
        next: (quoteWithProducts: any) => {
          if (quoteWithProducts.QuoteLines.length > 0) {
            const productId = quoteWithProducts.QuoteLines[0]?.ProductId.Id;
            this.getQuoteProducts(productId, this.revisionId);
          } else {
            this.productsInRevisions = [];
            this.revisions.forEach((resQuote) => {
              if (this.revisionId === resQuote?.Id) {
                resQuote.revisionProductCount = 0;
              }
            });
          }
        },
        error: err => {
          this.toastr.error('Failed to fetch data. Please contact your CPQ administrator', 'Error!', {
            disableTimeOut: true,
            closeButton: true
          });
        }
      });
      return this.productsInRevisions;
    }
  }

  getQuoteProducts(productId, quote) {
    this.dfrCpqOperations.getProductsAssociatedWithQuotes(productId).subscribe({
      next: (res) => {
        this.productsInRevisions = [];
        this.productsInRevisions = res;
      },
      error: err => {
        this.toastr.error(
          'There was an error while getting product info', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      }
    });
  }

  onRackNameUpdate(rackName: string, rack) {
    if (rackName) {
      let paramOptions = {
        "Name": rackName
      }
      this.subscription$.push(this.cartService.updateObjectById(CpqObjectType.QuoteLine, rack?.Id, paramOptions).subscribe({
        next: () => {
          rack.displayEditRack = !rack.displayEditRack;
        },
        complete: () => {
          this.displayEditRevision = false;
          this.update.next(true);
        },
        error: (err) => {
          this.toastr.error(
            'There was an error while updating revision name', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      }));
    }
    else {
    }
  }

  cancelEditRackName(rack) {
    rack.label = rack['rackNameCopy']
    rack.displayEditRack = !rack.displayEditRack;
  }

  onRootNodeChange() {
    this.currentNodeIndex = ROOT_NODE_INDEX;
  }

  onRackChange(rack, accordian?) {
    // this.currentRack = rack;
    // this.dataSource.data = [];
    // this.obs = this.dataSource.connect();
    // this.recommendedProducts = [];
    // this.filteredProducts = [];
    // this.currentNodeIndex = rack?.nodeId;
    // this.ltLoadValueForRack = 0;
    // this.mtLoadValueForRack = 0;
    // this.checkRackSubscriptions();
    // this.nodeGroupSubscription = this.productService
    //   .getGroup(this.configId, rack?.nodeId)
    //   .subscribe({
    //     next: data => {
    //       if (data) {
    //         const indexSubGroup = data?.groups?.mainGroup?.subGroups.findIndex(value => value.name === SubGroup_Types.ExpertMode) || 0;
    //         this.isExpertMode = false;
    //         // if (indexSubGroup >= 0) {
    //         //   if (data?.groups?.mainGroup?.subGroups[indexSubGroup].options.length > 0) {
    //         //     this.isExpertMode = (data?.groups?.mainGroup?.subGroups[indexSubGroup].options[0].selected === 1);
    //         //   }
    //         // }
    //         this.isExpertMode ? this.quickSearchFlow(data, rack) : this.guidedSellingFlow(data, rack);
    //       } else {
    //         console.log('No data');
    //         this.controlSpinner = false;
    //       }
    //     },
    //     error: () => {
    //       this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
    //       this.controlSpinner = false;
    //       this.recommendedProducts = [];
    //       this.filteredProducts = [];
    //       this.toastr.error(
    //         'There is fatal error while fetching GetGroup', 'Error', {
    //         disableTimeOut: true,
    //         closeButton: true
    //       }
    //       );
    //     },
    //     complete: () => { }
    //   });
  }


  private checkRackSubscriptions() {
    if ((this.nodeGroupSubscription && !this.nodeGroupSubscription.closed)
      || (this.mainFlexSubscription && !this.mainFlexSubscription.closed)
      || (this.flexSubscriptionGuided && !this.flexSubscriptionGuided.closed)
      || (this.flexSubscriptionQuickSearch && !this.flexSubscriptionQuickSearch.closed)) {
      this.mainFlexSubscription?.unsubscribe();
      this.setPramsSubscription?.unsubscribe();
      this.nodeGroupSubscription?.unsubscribe();
      this.flexSubscriptionGuided?.unsubscribe();
      this.flexSubscriptionQuickSearch?.unsubscribe();
    }
  }

  private guidedSellingFlow(data: ConfigData, rack: any) {
    if (!this.isExpertMode) {
      this.recommendedProducts = [];
      this.filteredProducts = [];
      this.selectedProducts = [];
      this.rackDesignCriteria = data?.groups?.mainGroup;
      this.selectedProduct = data?.parts?.selected[0];
      const indexSubGroup = data?.groups?.mainGroup?.subGroups.findIndex(value => value.name === SubGroup_Types.Nodes) || 0;
      if (indexSubGroup > 0) {
        this.recommendedProducts = data?.groups?.mainGroup?.subGroups[indexSubGroup]['nodes'];
        this.recommendedProducts = this.recommendedProducts.slice(0, 5);
        this.recommendedProducts.forEach((availableProduct: DFRProduct) => {
          availableProduct.imageName = availableProduct.imageName.replace(/["']/g, '');
          availableProduct['imageUrl'] = `${environment.B2CConfigs.BackendURL}/cpq/images/${availableProduct.imageName}`;
        });
        this.filteredProducts = this.recommendedProducts;
        this.selectedProducts = data.parts?.selected;
        if (this.selectedProducts?.length > 0) {
          this.filteredProducts = this.filteredProducts.sort((a, b) => Number(b?.id === this.selectedProducts[0].id) - Number(a?.id === this.selectedProducts[0].id));
        }
        if (this.recommendedProducts.length > 0 && this.searchString != undefined) { this.applyFilters(); }
        if (this.dataSource) {
          this.dataSource.disconnect();
        }
        this.dataSource.data = this.filteredProducts;
        this.length = this.filteredProducts.length;
        this.obs = this.dataSource.connect();

      }
      this.markSelectedProducts();
      this.setExternalParams(rack);
    }
  }

  applyFilters() {
    try {
      const searchStr = this.searchString?.trim().toLowerCase() || '';
      this.filteredProducts = this.recommendedProducts.filter(item =>
        item.name.toLowerCase().includes(searchStr) || item.tags.toLowerCase().includes(searchStr)
      );
      this.filteredProducts.sort((a, b) => (a.sequence || 0) - (b.sequence || 0));
      if (this.dataSource) {
        this.dataSource.disconnect();
      }
      this.dataSource.data = this.filteredProducts;
      this.length = this.filteredProducts.length;
      this.pageIndex = 1;
      this.obs = this.dataSource.connect();
    } finally {
      if (this.filteredProducts.length > 0) {
        this.enableNoMatchFound = true;
      } else {
        this.enableNoMatchFound = false;
      }
    }

  }


  private quickSearchFlow(data: ConfigData, rack: any) {
    if (this.isExpertMode) {
      this.recommendedProducts = [];
      this.selectedProducts = [];
      this.filteredProducts = [];
      this.rackDesignCriteria = data?.groups?.mainGroup;
      this.selectedProduct = data?.parts?.selected[0];
      const indexSubGroup = data?.groups?.mainGroup?.subGroups.findIndex(value => value.name === SubGroup_Types.Nodes) || 0;
      if (indexSubGroup >= 0) {
        this.recommendedProducts = data?.groups?.mainGroup?.subGroups[indexSubGroup]['nodes'];
        this.recommendedProducts.forEach((availableProduct: DFRProduct) => {
          availableProduct.imageName = availableProduct.imageName.replace(/["']/g, '');
          availableProduct['imageUrl'] = `${environment.B2CConfigs.BackendURL}/cpq/images/${availableProduct.imageName}`;
        });
        this.selectedProducts = data.parts?.selected;
        this.filteredProducts = this.recommendedProducts;
        if (this.selectedProducts?.length > 0) {
          this.filteredProducts = this.filteredProducts.sort((a, b) => Number(b?.id === this.selectedProducts[0].id) - Number(a?.id === this.selectedProducts[0].id));
        }
        if (this.recommendedProducts.length > 0 && this.searchString !== undefined) { this.applyFilters(); }
        if (this.dataSource) {
          this.dataSource.disconnect();
        }

        this.dataSource.data = this.filteredProducts;
        this.length = this.filteredProducts.length;
        this.obs = this.dataSource.connect();
      }

      this.markSelectedProducts();
      this.setExternalParams(rack);
    }
  }

  setExternalParams(rack) {
    if (rack) {
      this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
      this.loaderCount++;
      this.setPramsSubscription = this.productService.getGroup(this.configId, rack.nodeId).subscribe((data) => {
        this.rackDesignCriteria = data?.groups?.mainGroup;
        let lowTempRequiredLoad, medTempRequiredLoad, percentCapacityGap, lowSaturationSuctionTemp, medSaturationSuctionTemp, adiabaticOverride;
        const rackPreselect = data?.groups?.preSelect['subGroups'][0]['subGroups'];

        let getRefrigerantGroup = rackPreselect.filter(group => (group.name === EnergyCalculations.RefrigerantType));
        let getAmbientGroup = rackPreselect.filter(group => (group.name === EnergyCalculations.AmbientGroup));
        let getAmbientoffsetGroup = rackPreselect.filter(group => (group.name === EnergyCalculations.AmbientOffSet));

        let refrigerantType = getRefrigerantGroup[0].options?.filter(option => option.selected === 1);
        let ambientTemperature = getAmbientGroup[0].options?.filter(option => option.selected === 1);
        let ambientoffSet = getAmbientoffsetGroup[0].options?.filter(option => option.selected === 1);

        if (!this.isExpertMode) {
          var filterSubGroupsRackPre = rackPreselect.filter(group => (group.name === EnergyCalculations.CondenderTempGroup));
          const optionsCondensor = filterSubGroupsRackPre[0].options?.filter(option => option.selected === 1);
          adiabaticOverride = optionsCondensor[0].textResult;

          const getSubgroupsRack = this.rackDesignCriteria['subGroups'][0]['subGroups'];
          if (getSubgroupsRack?.length > 0) {
            var filterSubGroupsRack = getSubgroupsRack.filter(group => (group.name === EnergyCalculations.LTLowTempGroup));
            lowTempRequiredLoad = filterSubGroupsRack[0].options[0].value * LOAD_TEMP_MULTIPLIER;

            filterSubGroupsRack = getSubgroupsRack.filter(group => (group.name === EnergyCalculations.MTLowTempGroup));
            medTempRequiredLoad = filterSubGroupsRack[0].options[0].value * LOAD_TEMP_MULTIPLIER;

            filterSubGroupsRack = getSubgroupsRack.filter(group => (group.name === EnergyCalculations.RequiredLoadingRatio));
            const options = filterSubGroupsRack[0].options?.filter(option => option.selected === 1);
            percentCapacityGap = options[0].label;

            filterSubGroupsRack = getSubgroupsRack.filter(group => (group.name === EnergyCalculations.LTTemp));
            lowSaturationSuctionTemp = filterSubGroupsRack[0].options[0].value;

            filterSubGroupsRack = getSubgroupsRack.filter(group => (group.name === EnergyCalculations.MtTemp));
            medSaturationSuctionTemp = filterSubGroupsRack[0].options[0].value;
          }
          this.offSetAmbientTemp = ambientoffSet[0]?.result;
        } else {
          lowTempRequiredLoad = 0;
          medTempRequiredLoad = 0;
          percentCapacityGap = 0;
          lowSaturationSuctionTemp = 0;
          medSaturationSuctionTemp = 0;
          adiabaticOverride = false;
        }

        if (refrigerantType[0]?.label === RefrigerantType.R744) {
          if (lowTempRequiredLoad == undefined || medTempRequiredLoad == undefined || percentCapacityGap == undefined || lowSaturationSuctionTemp == undefined || medSaturationSuctionTemp == undefined) {
            return false;
          }
          this.mainFlexSubscription = this.energyService.getFlexModels(lowSaturationSuctionTemp, medSaturationSuctionTemp, lowTempRequiredLoad,
            medTempRequiredLoad, this.offSetAmbientTemp, percentCapacityGap, adiabaticOverride, this.zipCode, this.latitude, this.longitude).subscribe({
              next: (res: any) => {
                if (res.flexModels.length > 0) {
                  let flexModels = res.flexModels;
                  let payload = [];
                  res.flexModels.forEach((model) => {
                    let params, payloadObject = {};
                    attributesToBeUpdated_Flex.forEach((attribute) => {
                      let attributeValue;
                      if (attribute === rackAttribute.LT_EvapCapacity) {
                        attributeValue = model.lowTempCompressorCapacity;
                      } else if (attribute === rackAttribute.MT_EvapCapacity) {
                        attributeValue = model.medTempCompressorCapacity;
                      } else if (attribute === rackAttribute.MTExcessCapcPercent) {
                        attributeValue = model.medTempExcessPercent;
                      } else if (attribute === rackAttribute.LTExcessCapcPercent) {
                        attributeValue = model.lowTempExcessPercent;
                      } else if (attribute === rackAttribute.GasCoolerOutletTemp) {
                        attributeValue = model.gasCoolerOutletTemp;
                      } else if (attribute === rackAttribute.GasCoolerOutletPressure) {
                        attributeValue = model.gasCoolerOutletPressure;
                      } else if (attribute === rackAttribute.LowTempExcessCapacity) {
                        attributeValue = model.lowTempExcessCapacity;
                      } else if (attribute === rackAttribute.MedTempExcessCapacity) {
                        attributeValue = model.medTempExcessCapacity;
                      }
                      params = {
                        key: model.modelname,
                        attribute: attribute,
                        value: attributeValue,
                        nodeIndex: rack.nodeId,
                      };
                      payloadObject = {
                        name: FpxCpaasFunction.AlterAttribute,
                        args: params
                      }
                      payload.push(payloadObject);
                    });
                  })
                  this.isExpertMode ? this.updateFlexDataIntoCpqQuickSearch(payload, rack) : this.updateFlexDataIntoCpqGuidedSelling(payload, rack, flexModels, refrigerantType);
                }
                else {
                  this.recommendedProducts = [];
                  alert('No models found for provided inputs. Please contact TPS.');
                  this.controlSpinner = false;
                  this.showProductbutton = false;
                }
                this.loaderCount--;
                if (this.loaderCount === 0) {
                  this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
                }
              },
              error: err => {
                console.log('Error on flex models load', err);
                let payload = [];
                this.loaderCount--;
                if (this.loaderCount === 0) {
                  this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
                }
                this.controlSpinner = false;
                this.showProductbutton = false;
              },
              complete: () => {
                console.log('set parameters is completed.');
                let payload = [];
                this.showProductbutton = false;
              }
            });
        }
        else if (refrigerantType[0]?.label === RefrigerantType.R449A || refrigerantType[0]?.label === RefrigerantType.R448A) {

          if (this.isExpertMode) {
            lowTempRequiredLoad = 0;
            medTempRequiredLoad = 0;
            percentCapacityGap = 0;
            lowSaturationSuctionTemp = -20;
            medSaturationSuctionTemp = 20;
          }
          if (lowTempRequiredLoad == undefined || medTempRequiredLoad == undefined || percentCapacityGap == undefined || lowSaturationSuctionTemp == undefined || medSaturationSuctionTemp == undefined) {
            return false;
          }
          this.mainPakSubscription = this.energyService.getPakModels(refrigerantType[0]?.label, ambientTemperature[0]?.result, lowSaturationSuctionTemp, medSaturationSuctionTemp, lowTempRequiredLoad, medTempRequiredLoad, percentCapacityGap, this.offSetAmbientTemp).subscribe({
            next: (resPakModels: any) => {
              if (resPakModels.length > 0) {
                let PakModels = resPakModels;
                let payload = [];
                resPakModels.forEach((model) => {
                  let params, payloadObject = {};

                  attributesToBeUpdated_Pak.forEach((attribute) => {
                    let attributeValue;
                    if (attribute === rackAttribute.LT_EvapCapacity) {
                      attributeValue = model.ltCapacity;
                    } else if (attribute === rackAttribute.MT_EvapCapacity) {
                      attributeValue = model.mtCapacity;
                    } else if (attribute === rackAttribute.MTExcessCapcPercent) {
                      attributeValue = (model.excessPercentMT) ? model.excessPercentMT.toFixed(2) : 0;
                    } else if (attribute === rackAttribute.LTExcessCapcPercent) {
                      attributeValue = (model.excessPercentLT) ? model.excessPercentLT.toFixed(2) : 0;
                    }

                    params = {
                      key: model.model,
                      attribute: attribute,
                      value: attributeValue,
                      nodeIndex: rack.nodeId,
                    };
                    payloadObject = {
                      name: FpxCpaasFunction.AlterAttribute,
                      args: params
                    }
                    payload.push(payloadObject);
                  });
                })
                this.isExpertMode ? this.updateFlexDataIntoCpqQuickSearch(payload, rack) : this.updateFlexDataIntoCpqGuidedSelling(payload, rack, PakModels, refrigerantType);
              }
              else {
                this.recommendedProducts = [];
                alert('No models found for provided inputs. Please contact TPS.');
                this.controlSpinner = false;
              }
              this.loaderCount--;
              if (this.loaderCount === 0) {
                this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
              }
            },
            error: err => {
              console.log('Error on flex models load', err);
              let payload = [];
              this.loaderCount--;
              if (this.loaderCount === 0) {
                this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
              }
              this.controlSpinner = false;
            },
            complete: () => {
              this.controlSpinner = false;
              let payload = [];
              this.showProductbutton = false;
            }
          });
        }

      });

    } else {
      this.controlSpinner = false;
    }
  }

  private updateFlexDataIntoCpqGuidedSelling(payload: any[], rack: any, models: any, refrigerantType: any) {
    this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
    this.loaderCount++;
    if (payload && payload.length > 0) {
      let payloadDivider = Math.ceil(payload.length / MIN_CHUNK_SIZE);
      const chunkSize = Math.ceil(payload.length / payloadDivider);
      const chunks = [];
      for (let i = 0; i < payload.length; i += chunkSize) {
        chunks.push(payload.slice(i, i + chunkSize));
      }
      chunks.forEach((chunk, index) => {
        this.flexSubscriptionGuided = this.productService.alterAttributeValue(this.configId, chunk).subscribe(
          {
            next: (res) => {
              if (index === chunks.length - 1) {
                this.cartService.updateProducts([this.revisionId]).subscribe((res3) => {
                });
                this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
                this.loaderCount++;
                this.productService.getGroup(this.configId, rack.nodeId).subscribe({
                  next: (data) => {
                    if (data) {
                      this.recommendedProducts = [];
                      this.selectedProducts = [];
                      this.flexRecommendedProducts = [];
                      this.rackDesignCriteria = data?.groups?.mainGroup;
                      this.selectedProduct = data?.parts?.selected[0];
                      const indexSubGroup = data?.groups?.mainGroup?.subGroups.findIndex(value => value.name === SubGroup_Types.Nodes) || 0;
                      if (indexSubGroup >= 0) {
                        this.recommendedProducts = data?.groups?.mainGroup?.subGroups[indexSubGroup]['nodes'];
                        if (this.recommendedProducts && models) {
                          for (let i = 0; i < this.recommendedProducts.length; i++) {
                            for (let j = 0; j < models.length; j++) {
                              if (refrigerantType[0]?.label === RefrigerantType.R744) {
                                if (this.recommendedProducts[i]?.name === models[j]?.modelname) {
                                  this.flexRecommendedProducts.push(this.recommendedProducts[i])
                                }
                              } else {
                                if (this.recommendedProducts[i]?.label === models[j]?.model) {
                                  this.flexRecommendedProducts.push(this.recommendedProducts[i])
                                }
                              }
                            }
                          };
                          this.recommendedProducts = this.flexRecommendedProducts;
                        }

                        this.recommendedProducts = this.recommendedProducts.slice(0, 5);
                        this.recommendedProducts.forEach((availableProduct: DFRProduct) => {
                          availableProduct.imageName = availableProduct.imageName.replace(/["']/g, '');
                          availableProduct['imageUrl'] = `${environment.B2CConfigs.BackendURL}/cpq/images/${availableProduct.imageName}`;
                        });

                        this.filteredProducts = this.recommendedProducts;
                        if (this.selectedProducts?.length > 0) {
                          this.filteredProducts = this.filteredProducts.sort((a, b) => Number(b?.id === this.selectedProducts[0].id) - Number(a?.id === this.selectedProducts[0].id));
                        }
                        this.dataSource.data = this.filteredProducts;
                        this.length = this.filteredProducts.length;
                        this.obs = this.dataSource.connect();
                      }
                      this.selectedProducts = data.parts?.selected;
                      this.getRevisionProposals();
                      this.markSelectedProducts();

                    } else {
                      console.log('No data');
                      this.showProductbutton = false;
                      this.controlSpinner = false;
                    }
                    this.loaderCount--;
                    if (this.loaderCount === 0) {
                      this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
                    }
                  },
                  error: err => {
                    this.loaderCount--;
                    if (this.loaderCount === 0) {
                      this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
                    }
                    this.controlSpinner = false;
                    this.showProductbutton = false;
                  },
                  complete: () => {
                    this.controlSpinner = false;
                    this.showProductbutton = false;
                  }
                });
              }
            },
            error: err => {
              console.log('Error while fetching the flex data');
              this.loaderCount--;
              if (this.loaderCount === 0) {
                this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
              }
            },
            complete: () => {
              setTimeout(() => {
                this.loaderCount--;
                if (this.loaderCount === 0) {
                  this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
                }
              }, DELAY_BEFORE_SAVE);
            }

          });

      });

    }
  }

  private updateFlexDataIntoCpqQuickSearch(payload: any[], rack: any) {
    if (payload && payload.length > 0) {
      let payloadDivider = Math.ceil(payload.length / MIN_CHUNK_SIZE);
      const chunkSize = Math.ceil(payload.length / payloadDivider);
      const chunks = [];
      for (let i = 0; i < payload.length; i += chunkSize) {
        chunks.push(payload.slice(i, i + chunkSize));
      }
      this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
      this.loaderCount++;
      chunks.forEach((chunk, index) => {
        this.flexSubscriptionQuickSearch = this.productService.alterAttributeValue(this.configId, chunk).subscribe({
          next: (res) => {
            if (index === chunks.length - 1) {
              this.cartService.updateProducts([this.revisionId]).subscribe((res3) => {
              });
              this.productService.getGroup(this.configId, rack.nodeId).subscribe({
                next: (data) => {
                  if (data) {
                    this.recommendedProducts = [];
                    this.selectedProducts = [];
                    this.rackDesignCriteria = data?.groups?.mainGroup;
                    this.selectedProduct = data?.parts?.selected[0];
                    const indexSubGroup = data?.groups?.mainGroup?.subGroups.findIndex(value => value.name === SubGroup_Types.Nodes) || 0;
                    if (indexSubGroup >= 0) {
                      this.recommendedProducts = data?.groups?.mainGroup?.subGroups[indexSubGroup]['nodes'];
                      this.recommendedProducts.forEach((availableProduct: DFRProduct) => {
                        availableProduct.imageName = availableProduct.imageName.replace(/["']/g, '');
                        availableProduct['imageUrl'] = `${environment.B2CConfigs.BackendURL}/cpq/images/${availableProduct.imageName}`;
                      });
                      this.filteredProducts = this.recommendedProducts;
                      if (this.selectedProducts?.length > 0) {
                        this.filteredProducts = this.filteredProducts.sort((a, b) => Number(b?.id === this.selectedProducts[0].id) - Number(a?.id === this.selectedProducts[0].id));
                      }
                      this.dataSource.data = this.filteredProducts;
                      this.length = this.filteredProducts.length;
                      this.obs = this.dataSource.connect();
                    }
                    this.selectedProducts = data.parts?.selected;
                    this.getRevisionProposals();
                    this.markSelectedProducts();

                  } else {
                    console.log('No data');
                    this.controlSpinner = false;
                  }
                  this.loaderCount--;
                  if (this.loaderCount === 0) {
                    this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
                  }
                },
                error: err => {
                  this.loaderCount--;
                  if (this.loaderCount === 0) {
                    this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
                  }
                  this.controlSpinner = false;
                },
                complete: () => {
                  this.controlSpinner = false;
                }
              });
            }
          },
          error: err => {
            console.log('Error while fetching flex records' + err);
            this.loaderCount--;
            if (this.loaderCount === 0) {
              this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
            }
          },
          complete: () => {
            if (!this.controlSpinner) {
              // this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
            }
            this.loaderCount--;
            if (this.loaderCount === 0) {
              this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
            }
          }
        });

      });
    }
  }

  callGetGroup() {
    this.postOptionChange(optionData);
  }

  tabChange(e) {
    if (!this.isConfigurationChanged) {
      if (this.availableRacks.length > 0 && !this.byPassTabClick) {
        this.availableProducts = [];
        this.compareProducts = [];
        this.selectedCompareProducts = [];
        this.controlSpinner = true;
        this.recommendedProducts = [];
        this.filteredProducts = [];
        this.isShowRackCriteria = true;
        let currentSelectedRack = this.availableRacks[e.index];
        this.currentSelectedRackId = currentSelectedRack?.Id;
        this.getQuoteAvailableRacks(currentSelectedRack);
      }
    }
    else {
      alert('Please Retrieve Products.');
    }
    this.byPassTabClick = false;
  }

  onClickPreview() {
    this.btnSpinner = true;
    this.subscriptions$.push(this.productService.getProposalData(this.revisionId)
      .subscribe({
        next: (record: any) => {
          if (record) {
            this.proposalPreview = record;
            this.openPreviewDialog(this.proposalPreview);
            this.btnSpinner = false;
          }
          else {
            this.toastr.info('No Preview available', 'Information', {
              disableTimeOut: true,
              closeButton: true
            });
            this.btnSpinner = false;
          }
        },
        error: (err) => {
          console.log(err);
          this.btnSpinner = false;
        }
      }));
  }

  openPreviewDialog(record: ProposalPreview) {
    const instance = this.modalService.open(PreviewComponent, {
      size: 'xl',
      windowClass: 'previewModal'
    }).componentInstance;
    instance.jobPreviewData = record;
  }



  markSelectedProducts() {
    this.selectedProducts.forEach((selectedProduct) => {
      this.recommendedProducts.forEach((availableProduct: DFRProduct) => {
        if (selectedProduct.name === availableProduct.name) {
          availableProduct.selected = selectedProduct.selected;
          this.mtLoadValueForRack = selectedProduct.mtLoadPct;
          this.ltLoadValueForRack = selectedProduct.ltLoadPct;
        }
      })
    })

    this.optionsForRackLT = {
      floor: 0,
      ceil: 200,
      disabled: true
    };
    this.optionsForRackMT = {
      floor: 0,
      ceil: 200,
      disabled: true
    };

    if (!this.controlSpinner) {
      // this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
    }
  }

  submitConfiguration() {
    if (this.updateTarget !== 'quoteproduct') {
      Boolean(this.productId) ? this.updateConfig() : this.saveConfig();
    } else {
      this.updateConfig();
    }

  }

  saveConfig() {
    this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
    this.loaderCount++;
    this.dfrCpqOperations.getQuoteLineProducts(this.revisionId).subscribe({
      next: (quoteWithProducts: any) => {
        if (quoteWithProducts.QuoteLines.length > 0) {
          const [quoteLine] = quoteWithProducts.QuoteLines;
          this.productId = (quoteLine.ProductId as any).Id;
          this.updateQueryParam(QueryParams.ProductId, this.productId);
          this.updateConfig();
        } else {
          this.saveNewConfiguration();
        }
        this.loaderCount--;
        if (this.loaderCount === 0) {
          this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
        }
      },
      error: err => {
        console.log(err);
        this.loaderCount--;
        if (this.loaderCount === 0) {
          this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
        }
      },
      complete: () => {
      }
    });
  }


  getOpportunityDetails(): Observable<DfrCpqOpportunity> {
    return this.cartService.getCPQObjectById(CpqObjectType.Opportunity, this.projectId);
  }

  saveNewConfiguration() {
    // If there is a save in progress, abort it and start a new one
    if (this.saveSubscription) {
      this.saveSubscription.unsubscribe();
    }
    this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
    this.loaderCount++;
    this.saveSubscription = this.cartService.saveConfiguration(this.configId, this.revisionId)
      .pipe(
        tap(saveResult => {
          this.productId = saveResult.productId;
          this.updateQueryParam(QueryParams.ProductId, this.productId);
        }),
        switchMap(x => this.cartService.updateProducts([this.revisionId])),
        switchMap(x => this.getOpportunityDetails().pipe(
          tap(opportunity => this.project = opportunity)
        )),
        // switchMap(x => this.conditionallyCreateSfdcOpp(this.project))
      ).subscribe({
        next: () => {
          if (this.navigateToProductDetailsPage) {
            this.router.navigate([
              'product/details',
              this.revisionName,
              this.projectId,
              this.revisionId,
              this.configId,
              this.productBaseId,
              this.selectedProducts[0]?.nodeId
            ]);
          }
          this.getProductsAssociatedWithRevision();
        },
        error: err => {
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
          }
          console.warn(`There was an issue while saving the config`, err)
        }, complete: () => {
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
          }
        }
      });
  }

  updateQueryParam(param: string, value: string) {
    const queryParams: Params = {};
    queryParams[param] = value;

    this.router.navigate([],
      {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge', // Magic string from the library itself
        replaceUrl: true, // Causes the history to be updated instead of a new entry
      });
  }

  updateConfig() {
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
    this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
    this.loaderCount++;
    this.updateSubscription = this.cartService
      .editProductConfiguration(this.configId, this.productId).pipe(
        switchMap(x => this.cartService.updateProducts([this.revisionId]))
      ).subscribe({
        next: () => {
          if (this.navigateToProductDetailsPage) {
            this.router.navigate([
              'product/details',
              this.revisionName,
              this.projectId,
              this.revisionId,
              this.configId,
              this.productBaseId,
              this.selectedProducts[0]?.nodeId
            ]);
          }
          this.getProductsAssociatedWithRevision();
        },
        error: err => {
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
          }
          console.warn(`There was an issue while saving the config`, err)
        }, complete: () => {
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
          }
        }
      });
  }

  getValueBasedOnSelectionType(optionData) {
    let value = optionData.value;
    const selectionType = optionData.selectionType;
    if (selectionType === SelectionTypes.Quantity) {
      value = optionData.quantity;
    } else if (selectionType === SelectionTypes.StringInput) {
      value = optionData.textValue;
    }
    return value;
  }

  postOptionChange(optionData: any) {
    if (!optionData) {
      console.error('No optiondata');
      return;
    }
    const value = this.getValueBasedOnSelectionType(optionData);
    let nodeId = this.currentNodeIndex;
    if (optionData?.level === OptionType.Root) {
      nodeId = ROOT_NODE_INDEX;
    }
    const selectionType = optionData.selectionType;
    if (selectionType === SelectionTypes.CheckBox
      || selectionType === SelectionTypes.Radio
      || selectionType === SelectionTypes.Quantity) {
      this.productService.updateOptionQuantity(
        this.configId,
        optionData.name,
        value,
        nodeId
      ).subscribe({
        error: err => {
          this.toastr.warning(err?.message);
          this.refreshGetGroup(this.currentRack?.nodeId);
        }
      });
    } else {
      this.productService.updateOptionValue(
        this.configId,
        optionData.name,
        value,
        nodeId
      ).subscribe({
        error: err => {
          this.toastr.warning(err?.message);
          this.refreshGetGroup(this.currentRack?.nodeId);
        }
      });
    }
  }

  onProductSelect(selectedProduct) {
    this.modalService.dismissAll();
    this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
    this.loaderCount++;
    let paramOptions = {
      "ProductId": selectedProduct.ProductId
    }
    this.subscription$.push(this.cartService.updateObjectById(CpqObjectType.QuoteLine, this.rackId, paramOptions)
      .subscribe({
        next: (quoteLine: any) => {
          this.availableProducts = [];
          this.availableProducts = quoteLine?.AvailableProducts;
          if (this.availableProducts) {
            this.availableProducts.forEach((product) => {
              product['imageUrl'] = `${environment.cds.ImageBaseUrl}/domains/dfr/images/${product['Image']}`;
            });
            const selectedProducts = this.availableProducts.filter(product => product.Selected === true);
            this.downloadReport = selectedProducts.length === 1 ? false : true;
          }
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
          }
          this.productBaseId = selectedProduct?.ProductId;
          this.productCadId = selectedProduct?.ProductId;
          this.router.navigate([
            'product/details',
            this.revisionName,
            this.projectId,
            this.revisionId,
            this.configId,
            this.productBaseId,
            this.currentNodeIndex,
            this.productCadId,
            this.ambientTemp
          ],
            { queryParams: { quoteLineId: this.rackId } });
        },
        error: err => {
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
          }
          this.toastr.error(
            'There is fatal error while updated product card', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      }));
  }

  productDetail(product) {
    this.productBaseId = product?.ProductId;
    this.productCadId = product?.ProductId;
    this.router.navigate([
      'product/details',
      this.revisionName,
      this.projectId,
      this.revisionId,
      this.configId,
      this.productBaseId,
      this.currentNodeIndex,
      this.productCadId,
      this.ambientTemp
    ],
      { queryParams: { quoteLineId: this.rackId } });
  }

  addProduct(part) {
    const nodeId = this.currentNodeIndex;
    this.productService.configDirty$.next({ configID: this.configId, nodeID: nodeId });
    setTimeout(() => this.submitConfiguration(), DELAY_BEFORE_SAVE);
  }

  contactTps() {
    const instance = this.modalService.open(CreateLeadSfdcComponent, {
      size: 'lg',
      windowClass: 'previewModal'
    }).componentInstance;
    instance.projectId = this.projectId;
    instance.title = 'TPS Contact Request';
    instance.context = 'tps';
  }

  saveVersion() {
    const instance = this.modalService.open(SaveProjectComponent, {
      size: 'lg',
      windowClass: 'previewModal'
    }).componentInstance;
    instance.projectId = this.projectId;
  }

  saveProject() {
  }

  toggleAccordion(accordian) {
    this.activeId = accordian?.panelId;
  }

  goBack() {
    try {
      this.router.navigate([
        'productSelector',
        this.projectId
      ]);

    } catch (error) {
      console.log(error);
    }
  }

  copyRack(rack) {
    if (!this.isConfigurationChanged) {
      if (this.availableRacks.length < NO_OF_RACKS) {
        this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
        this.loaderCount++;
        if (this.copyNodeSubscription && !this.copyNodeSubscription.closed) {
          this.copyNodeSubscription.unsubscribe();
        }
        const newName = NEWRACK;
        this.copyNodeSubscription = this.cartService.copyObjectById(CpqObjectType.QuoteLine, this.rackId, newName)
          .subscribe({
            next: (data) => {
              this.update.next(true);
            },
            error: err => {
              this.loaderCount--;
              if (this.loaderCount === 0) {
                this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
              }
              this.toastr.error(
                'There is fatal error while Rack Copy', 'Error', {
                disableTimeOut: true,
                closeButton: true
              });
            },
            complete: () => {
              this.loaderCount--;
              if (this.loaderCount === 0) {
                this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
              }
            }
          });
      }
      else {
        this.rackNotification(RackNotification.RackExceededMsg, RackNotification.RackExceeded);
      }

    } else {
      alert('Please Retrieve Products.');
    }
  }

  deleteRack(rackId: string) {
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      data: { message: 'Are you sure you would like to delete this rack ?' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loading = true;
        this.byPassTabClick = true;
        this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
        this.loaderCount++;
        if (this.deleteNodeSubscription && !this.deleteNodeSubscription.closed) {
          this.deleteNodeSubscription.unsubscribe();
        }
        this.deleteNodeSubscription = this.cartService.deleteObjectByObjectId(CpqObjectType.QuoteLine, rackId)
          .subscribe({
            next: (data: any) => {
              this.availableRacks = this.availableRacks?.filter(item => item.Id != rackId);
              let currentSelectedRack = this.availableRacks[this.availableRacks.length-1];
              this.currentSelectedRackId = currentSelectedRack?.Id;
              this.availableProducts = [];
            },
            error: err => {
              this.toastr.error(
                'There is fatal error while Node Delete', 'Error', {
                disableTimeOut: true,
                closeButton: true
              })
              this.loaderCount--;
              if (this.loaderCount === 0) {
                this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
              }
            },
            complete: () => {
              this.update.next(true);
              this.loaderCount--;
              if (this.loaderCount === 0) {
                this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
              }
            }
          });
      } else {
        console.log('You clicked No!');
      }
    });
  }

  deleteMultipleRack(rackId: string) {
    this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
    this.loaderCount++;
    this.subscription$.push(this.cartService.getCPQObjectById(CpqObjectType.Quote, this.revisionId)
      .subscribe({
        next: (quoteLines: any) => {
          if (quoteLines?.Lines?.length > 0) {
            let isRackAvailable = quoteLines?.Lines?.filter(quoteLine => quoteLine.Id === rackId);
            if (isRackAvailable?.length > 0) {
              this.subscription$.push(this.cartService.deleteObjectByObjectId(CpqObjectType.QuoteLine, rackId).subscribe({
                next: (data: any) => {
                  this.tabGroup.selectedIndex = 0;
                  this.availableRacks = this.availableRacks?.filter(item => item.Id != rackId);
                },
                error: err => {
                  this.loaderCount--;
                  if (this.loaderCount === 0) {
                    this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
                  }
                  this.toastr.error(
                    'There is fatal error while Node Delete', 'Error', {
                    disableTimeOut: true,
                    closeButton: true
                  })
                },
                complete: () => {
                  if (this.availableRacks?.length === 1) {
                    console.log("Complete call");
                    this.resetSelectedProduct(this.availableRacks);
                  }
                  this.loaderCount--;
                  if (this.loaderCount === 0) {
                    this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
                  }
                }
              }));
            }
          }
        }
      }));



    // this.deleteNodeSubscription = this.cartService.deleteObjectByObjectId(CpqObjectType.QuoteLine, rackId)
    //   .subscribe({
    //     next: (data: any) => {
    //       this.availableRacks = this.availableRacks?.filter(item => item.Id != rackId);
    //     },
    //     error: err => {
    //       this.toastr.error(
    //         'There is fatal error while Node Delete', 'Error', {
    //         disableTimeOut: true,
    //         closeButton: true
    //       })
    //     },
    //     complete: () => {   
    //       this.update.next(true);         
    //       this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
    //     }
    //   });    
  }

  rackNotification(message: string, Type: string) {
    const config = new MatSnackBarConfig();
    config.panelClass = Type === RackNotification.AddRack ? ['rack_notification_Success'] : ['rack_notification_RackExceeded'];
    config.duration = SNACK_BAR_DURATION;
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'end';
    Type === RackNotification.AddRack ? this._snackBar.open(message, RackNotification.SuccessAction, config) : this._snackBar.open(message, null, config);
  }

  toggleGuidedSelling(event: MatSlideToggleChange, rack) {
    if (event.checked) {
      this.enableExpertMode(false, rack?.nodeId).subscribe({
        next: () => {
          if (this.availableRacks.length > 0) {
            this.onRackChange(rack);
            this.guidedSellingOn = false;
          }
        },
        error: () => this.guidedSellingOn = false
      });
    }
    else {
      // this.controlSpinner = true;
      this.enableExpertMode(true, rack?.nodeId).subscribe({
        next: () => {
          if (this.availableRacks.length > 0) {
            this.onRackChange(rack);
            this.guidedSellingOn = true;
          }
        },
        error: () => this.isExpertMode = true
      });
    }
  }

  enableExpertMode(isEnabled: boolean, nodeId: string): Observable<ExecuteResponse> {
    return this.productService.setExpertMode(this.configId, nodeId, isEnabled);
  }

  openRackNameDialog(rack) {
    this.EditRack = rack;
    this.inputRackName = rack?.Name;
    rack.displayEditRack = !rack.displayEditRack;
    this.dialog.open(EditDialogComponent, {
      width: '300px',
      data: { callback: this.callBack.bind(this), defaultValue: this.inputRackName }
    });
  }

  callBack(rackName: string) {
    if (this.EditRack) {
      if (this.EditRack.label !== rackName) {
        this.onRackNameUpdate(rackName, this.EditRack);
        this.dialog.closeAll();
        this.EditRack = [];
      }
      else {
        alert('You have not edited rack name.');
      }
    }

  }
  AoctMasking() {
    const element = document.getElementById('aAirOverCoilTemperature_value') as HTMLInputElement;
    if (element) {
      element.value = "##";
      element.readOnly = true;
      element.style.cursor = "none";
      element.style.pointerEvents = "none";
    }
  }
  setAOCT() {
    const aoctVal = this.uom === 'metric' ? "aAirOverCoilTemperatureMetric" : "aAirOverCoilTemperature";
    cds.configurator.runtime.attributes[aoctVal].value = this.getAOCT();
  }
  fetchProducts() {
    this.isConfigurationChanged = false;
    this.setAOCT();
    this.dataUnitValue = '';
    this.updateRack(this.rackId, true);
  }

  onDesignCriteriaClick() {
    if (this.isShowDesignCriteria) {
      this.isShowDesignCriteria = false;
    } else {

      this.isShowDesignCriteria = true;
    }
  }

  onRackCriteriaClick() {
    if (this.isShowRackCriteria) {
      this.isShowRackCriteria = false;
    } else {

      this.isShowRackCriteria = true;
    }
  }

  setMassFlowAndCapacity(selectedProduct) {
    let refrigerantType; let ambientTemperature; let ambientoffSet
    this.setMassFlowAndCapacitySubscription = this.productService.getGroup(this.configId, this.currentNodeIndex).subscribe((data) => {
      this.rackDesignCriteria = data?.groups?.mainGroup;
      let lowTempRequiredLoad, medTempRequiredLoad, percentCapacityGap, lowSaturationSuctionTemp, medSaturationSuctionTemp, adiabaticOverride;
      if (!this.isExpertMode) {
        const rackPreselect = data?.groups?.preSelect['subGroups'][0]['subGroups'];
        var filterSubGroupsRackPre = rackPreselect.filter(group => (group.name === 'PS_CondenserTp'));
        const optionsCondensor = filterSubGroupsRackPre[0].options?.filter(option => option.selected === 1);
        adiabaticOverride = optionsCondensor[0].textResult;


        let getRefrigerantGroup = rackPreselect.filter(group => (group.name === EnergyCalculations.RefrigerantType));
        let getAmbientGroup = rackPreselect.filter(group => (group.name === EnergyCalculations.AmbientGroup));
        let getAmbientoffsetGroup = rackPreselect.filter(group => (group.name === EnergyCalculations.AmbientOffSet));

        refrigerantType = getRefrigerantGroup[0].options?.filter(option => option.selected === 1);
        ambientTemperature = getAmbientGroup[0].options?.filter(option => option.selected === 1);
        ambientoffSet = getAmbientoffsetGroup[0].options?.filter(option => option.selected === 1);

        const getSubgroupsRack = this.rackDesignCriteria['subGroups'][0]['subGroups'];
        if (getSubgroupsRack.length > 0) {
          var filterSubGroupsRack = getSubgroupsRack.filter(group => (group.name === 'PS_LTTotLoad'));
          lowTempRequiredLoad = filterSubGroupsRack[0].options[0].value * 1000;

          filterSubGroupsRack = getSubgroupsRack.filter(group => (group.name === 'PS_MTTotLoad'));
          medTempRequiredLoad = filterSubGroupsRack[0].options[0].value * 1000;

          filterSubGroupsRack = getSubgroupsRack.filter(group => (group.name === 'PS_ReqLoadingRatio'));
          const options = filterSubGroupsRack[0].options?.filter(option => option.selected === 1);
          percentCapacityGap = options[0].label;

          filterSubGroupsRack = getSubgroupsRack.filter(group => (group.name === 'PS_LTEvapTemp'));
          lowSaturationSuctionTemp = filterSubGroupsRack[0].options[0].value;

          filterSubGroupsRack = getSubgroupsRack.filter(group => (group.name === 'PS_MTEvapTemp'));
          medSaturationSuctionTemp = filterSubGroupsRack[0].options[0].value;
        }
      } else {
        lowTempRequiredLoad = 0;
        medTempRequiredLoad = 0;
        percentCapacityGap = 0;
        lowSaturationSuctionTemp = 0;
        medSaturationSuctionTemp = 0;
        adiabaticOverride = false;
      }
      const attributesToBeUpdatedLT = ['LTMassflow', 'LTCapacity', 'DescriptionList'];
      const attributesToBeUpdatedMT = ['MTMassflow', 'MTCapacity', 'DescriptionList'];
      if (refrigerantType[0]?.label === RefrigerantType.R744) {
        let payload = {
          "productId": selectedProduct?.name,
          "zipCode": this.zipCode ? this.zipCode.toString() : '0',
          "latitude": this.latitude ? this.latitude : 0,
          "longitude": this.longitude ? this.longitude : 0,
          "lowSaturationSuctionTemp": lowSaturationSuctionTemp,
          "medSaturationSuctionTemp": medSaturationSuctionTemp,
          "lowTempRequiredLoad": lowTempRequiredLoad,
          "medTempRequiredLoad": medTempRequiredLoad,
          "offsetAmbientTemp": this.offSetAmbientTemp,
          "percentCapacityGap": Number(percentCapacityGap),
          "adiabaticOverride": adiabaticOverride === 'true' ? true : false
        }
        this.getModelDetailSubscription = this.energyService.getModelDetails(payload).subscribe({
          next: (res: ModelCompData) => {
            if (res.mtComp.length > 0 || res.ltComp.length > 0) {
              let payload = [];
              res.mtComp.forEach((model, index) => {
                let params, payloadObject = {};
                attributesToBeUpdatedMT.forEach((attribute) => {
                  let attributeValue;
                  if (attribute === 'MTMassflow') {
                    attributeValue = model.massFlow.toFixed(3);
                  } else if (attribute === 'MTCapacity') {
                    attributeValue = model.capacity.toFixed(3);
                  } else if (attribute === 'DescriptionList') {
                    attributeValue = model.compressorName;
                  }
                  params = {
                    key: `CompressorMT${index + 1}`,
                    attribute: attribute,
                    value: attributeValue,
                    nodeIndex: selectedProduct.nodeId
                  };
                  payloadObject = {
                    name: FpxCpaasFunction.AlterAttribute,
                    args: params
                  }
                  payload.push(payloadObject);
                });
              })

              res.ltComp.forEach((model, index) => {
                let params, payloadObject = {};

                attributesToBeUpdatedLT.forEach((attribute) => {
                  let attributeValue;
                  if (attribute === 'LTMassflow') {
                    attributeValue = model.massFlow.toFixed(3);
                  } else if (attribute === 'LTCapacity') {
                    attributeValue = model.capacity.toFixed(3);
                  } else if (attribute === 'DescriptionList') {
                    attributeValue = model.compressorName;
                  }
                  params = {
                    key: `CompressorLT${index + 1}`,
                    attribute: attribute,
                    value: attributeValue,
                    nodeIndex: selectedProduct.nodeId
                  };
                  payloadObject = {
                    name: FpxCpaasFunction.AlterAttribute,
                    args: params
                  }
                  payload.push(payloadObject);
                });
              })
              this.updateFlexDataIntoCpq(payload);
            }
            else {
              console.log('No compressor data found');
            }
          },
          error: err => {
            console.log('Error on flex models load', err);
            let payload = [];
          },
          complete: () => {
            console.log('set parameters for selected model is completed.');
            let payload = [];
          }
        });
      }
      else if (refrigerantType[0]?.label === RefrigerantType.R449A || refrigerantType[0]?.label === RefrigerantType.R448A) {
        const attributesToBeUpdatedLT_PAK = ['LTCapacity', 'DescriptionList'];
        const attributesToBeUpdatedMT_PAK = ['MTCapacity', 'DescriptionList'];
        this.setCompressorCapacitySubscription = this.energyService.getModelCompressorCapacity(selectedProduct?.name, refrigerantType[0]?.label, ambientTemperature[0]?.result, lowSaturationSuctionTemp, medSaturationSuctionTemp, lowTempRequiredLoad, medTempRequiredLoad, percentCapacityGap, this.offSetAmbientTemp)
          .subscribe({
            next: (res: ModelCompData) => {
              if (res.mtComp.length > 0 || res.ltComp.length > 0) {
                let payload = [];
                res.mtComp.forEach((model, index) => {
                  let params, payloadObject = {};
                  attributesToBeUpdatedMT_PAK.forEach((attribute) => {
                    let attributeValue;
                    if (attribute === 'MTCapacity') {
                      attributeValue = model.capacity.toFixed(3);
                    } else if (attribute === 'DescriptionList') {
                      attributeValue = model.compressorName;
                    }
                    params = {
                      key: `CompressorMT${index + 1}`,
                      attribute: attribute,
                      value: attributeValue,
                      nodeIndex: selectedProduct.nodeId
                    };
                    payloadObject = {
                      name: FpxCpaasFunction.AlterAttribute,
                      args: params
                    }
                    payload.push(payloadObject);
                  });
                })

                res.ltComp.forEach((model, index) => {
                  let params, payloadObject = {};

                  attributesToBeUpdatedLT_PAK.forEach((attribute) => {
                    let attributeValue;
                    if (attribute === 'LTCapacity') {
                      attributeValue = model.capacity.toFixed(3);
                    } else if (attribute === 'DescriptionList') {
                      attributeValue = model.compressorName;
                    }
                    params = {
                      key: `CompressorLT${index + 1}`,
                      attribute: attribute,
                      value: attributeValue,
                      nodeIndex: selectedProduct.nodeId
                    };
                    payloadObject = {
                      name: FpxCpaasFunction.AlterAttribute,
                      args: params
                    }
                    payload.push(payloadObject);
                  });
                })
                this.updateFlexDataIntoCpq(payload);
              }
              else {
                console.log('No compressor data found');
              }
            },
            error: err => {
              console.log('Error on flex models load', err);
              let payload = [];
            },
            complete: () => {
              console.log('set parameters for selected model is completed.');
              let payload = [];
            }
          });
      }

    });
  }

  private updateFlexDataIntoCpq(payload: any[]) {
    if (payload && payload.length > 0) {
      this.updateMassFlowSubscription = this.productService.alterAttributeValue(this.configId, payload).subscribe(
        {
          next: (res) => {
            this.submitConfiguration();
            console.log('updated flex model compressor data');
          },
          error: err => { console.log('Error while fetching the flex data') },
          complete: () => {
          }
        });
    }
  }

  private refreshGetGroup(nodeId: string): void {
    this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
    this.loaderCount++;
    if (this.configSubscription && !this.configSubscription.closed) {
      this.configSubscription.unsubscribe();
    }
    this.configSubscription = this.productService
      .getGroup(this.configId, nodeId)
      .subscribe({
        next: data => {
          this.designCriteriaData = data.groups?.preSelect?.subGroups[0];
          this.rootDesignCriteria = data.groups?.preSelect;
          this.rackDesignCriteria = data?.groups?.mainGroup;
        },
        error: err => {
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
          }
          this.toastr.error('There was an error while fetching data set', 'Error', {
            disableTimeOut: true,
            closeButton: true
          });
        }, complete: () => {
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
          }
        }
      });
  }

  saveConfiguration() {
    let attributeDesignGroup = cds.configurator.runtime.attributeOrder.design;
    let attributeDetails = cds.configurator.runtime.attributes;
    let selectedOptions: any[] = [];


    attributeDesignGroup.forEach(attribute => {
      let attributeData = attributeDetails[attribute];
      if (attributeData.classId !== cdsConfigurator.RangeAttribute) {
        let attributesValues = cds.configurator.runtime.manager.getAttribute(attribute).values;
        attributesValues.forEach(value => {
          if (value.isSelected) {
            var objOptionsData = new SelectedOptions
            objOptionsData.optionId = attributeData.id;
            objOptionsData.name = value.label;
            objOptionsData.optionId = value.id;
            objOptionsData.value = value.isSelected;
          }
          if (objOptionsData) {
            selectedOptions.push(objOptionsData);
          }
          objOptionsData = null;
        });
      }
      else {
        var objOptionsData = new SelectedOptions
        objOptionsData.optionId = attributeData.id;
        objOptionsData.value = cds.configurator.runtime.manager.getAttribute(attribute).value;

        if (objOptionsData) {
          selectedOptions.push(objOptionsData);
        }
        objOptionsData = null;
      }
    });
  }

  checkProductSelected() {
    if (this.availableProducts) {
      this.availableProducts.forEach((product) => {
        if (product?.Selected) {
          this.productSelected = true;
        }
      });
      if (this.productSelected) {
        const dialogRef = this.dialog.open(DialogBoxComponent, {
          data: { message: 'There is a selected product.Do you want to continue?' },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            // if clicked Yes
            this.isDesignConfigurationChanged = true;
            this.isConfigurationChanged = true;
            this.AoctMasking();
            this.productSelected = false;
          } else {
            console.log('You clicked No!');
          }
        });
      } else {
        this.isDesignConfigurationChanged = true;
        this.isConfigurationChanged = true;
        this.AoctMasking();
        this.productSelected = false;
      }
    }
  }
  productsCompare(product) {
    if (product?.addedToCompare) {
      this.selectedCompareProducts = [...this.selectedCompareProducts, product]
    }
    else {
      const index = this.selectedCompareProducts.map(function (x) { return x.ProductId; }).indexOf(product?.ProductId);
      if (index !== -1) this.selectedCompareProducts.splice(index, 1);
    }
  }
  prepareCompareProducts() {
    this.compareProducts.forEach(x=>{
      x.IsCriteriaMatched= this.availableProducts[0]?.IsCriteriaMatched;
    });
    
    const instance = this.modalService.open(CompareProductsComponent, {
      size: 'xl',
      windowClass: 'previewModal'
    }).componentInstance;
    instance.compareProductData = this.compareProducts;
    instance.compareProductLable = this.compareProductsLables;
  }

  getCompareProducutData() {
    this.getcompareProductsData = [];
    this.spinner.show(this.DESIGN_CRITERIA_SPINNER);
    const compareData = this.cartService.getAvailableProducts(this.rackId);
    const compareFields = this.cartService.getConfigCompareProducts();

    forkJoin([compareData, compareFields]).subscribe({
      next: (res) => {
        this.getcompareProductsData = res[0].Products;
        this.compareProductsLables = res[1].Tables;
        this.compareProducts = this.getcompareProductsData.reduce(
          (acc, obj) => {
            if (
              this.selectedCompareProducts.some(
                (filterObj) => filterObj.ProductId === obj.ProductId
              )
            ) {
              acc.push(obj);
            }
            return acc;
          },
          []
        );
        if (this.getcompareProductsData.length > 0) {
          this.getcompareProductsData.forEach((product) => {
            product[
              "imageUrl"
            ] = `${environment.cds.ImageBaseUrl}/domains/dfr/images/${product.Product["Image"]["Value"]}`;
          });
        }

        this.prepareCompareProducts();
        this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
      },
      error: (err) => {
        this.toastr.error(
          "There was an error while fetching data set",
          "Error",
          {
            disableTimeOut: true,
            closeButton: true,
          }
        );
        this.spinner.hide(this.DESIGN_CRITERIA_SPINNER);
      },
    });
  }

  ngOnDestroy() {
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
    this.init$.forEach(sub => sub.unsubscribe());
    this.subscription$.forEach(sub => sub.unsubscribe());
    this.configSubscription?.unsubscribe();
    this.saveSubscription?.unsubscribe();
    this.updateSubscription?.unsubscribe();
    this.deleteNodeSubscription?.unsubscribe();
    this.moveNodeSubscription?.unsubscribe();
    this.createNodeSubscription?.unsubscribe();
    this.nodeGroupSubscription?.unsubscribe();
    this.copyNodeSubscription?.unsubscribe();
    this.nodeGroupSubscription1?.unsubscribe();
    this.mainFlexSubscription?.unsubscribe();
    this.flexSubscriptionGuided?.unsubscribe();
    this.flexSubscriptionQuickSearch?.unsubscribe();
    this.setPramsSubscription?.unsubscribe();
    this.updateMassFlowSubscription?.unsubscribe();
    this.getModelDetailSubscription?.unsubscribe();
    this.setMassFlowAndCapacitySubscription?.unsubscribe();
    this.mainPakSubscription?.unsubscribe();
    this.setCompressorCapacitySubscription?.unsubscribe();
  }
}
