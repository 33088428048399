// for prod replace `environment.ts` with `environment.cpq.ts` in deploy/profile/CPQTWGDEV folder
import { InjectionToken } from '@angular/core';
export const environment = {
  production: false,
  version: '0.0.0.0',
  B2CConfigs: {
    BackendURL: 'https://co2-uat.cdsvisual.net/api',
    BlobStorageURL: 'http://entitystorage.blob.core.windows.net',
    DefaultBlobId: 'private',
    ImageBackendUrl: 'http://cpqvws-dev.centralus.cloudapp.azure.com/api',
    b2cScopes: 'https://cxportaluat.onmicrosoft.com/f52646c5-4976-49d9-b25c-61f4036f7aff/user.read',
    clientId: 'f52646c5-4976-49d9-b25c-61f4036f7aff',
    isPolicy: true,
    redirectUrl: 'https://co2-uat.cdsvisual.net',
    superAdminOid: 'bb087e07-2aa5-4838-9042-aadfd0511ce9',
    tenant: 'cxportaluat.onmicrosoft.com',
    authURL: "https://cxportaluat.b2clogin.com/tfp",
    signinPolicy: {
      TWG: 'B2C_1A_signup_signin-CPQ-TWG',
      VWS: 'B2C_1A_cxportal_signin_policy_VWS',
      DFR: 'B2C_1A_CXPORTAL_UAT_SIGNIN_POLICY_DFRHILL'
    },
    resetPasswordPolicy: {
      TWG: 'B2C_1A_PasswordReset-CPQ-TWG',
      VWS: 'B2C_1A_PasswordResetVWS',
      DFR: 'B2C_1A_PASSWORDRESETDFRHILL'
    },
    changePasswordPolicy: {
      TWG: 'B2C_1A_ProfileEditPasswordChange-CPQ-TWG',
      VWS: 'B2C_1A_PasswordChangeVWS',
      DFR: 'B2C_1A_PasswordChangeDFR'
    },
    b2cLoginURL: 'https://cxportaluat.b2clogin.com',
    passwordChangeSuccessUrl: 'https://co2-uat.cdsvisual.net/changePasswordSuccess'
  },
  cxPortal: {
    baseUrl: 'https://dfrportal-uat.hillphoenix.com',
  },
  fpx: {
    imageBaseURL: 'https://sbx.fpx.com',
    BlobDFRStorageURL: 'https://dfrco2devstg.blob.core.windows.net/dfrco2',
    energyAPI: 'https://dfr-co2-dev-webapp02.azurewebsites.net/api'
  },
  cds: {
    endpoint: 'qa',
    viewerUrl: 'https://www.product-config.net/cadviewer/js/cds-cad-viewer.js',
    proxyApiPath: '/cpq/cds',
    ImageBaseUrl:'https://dpk3n3gg92jwt.cloudfront.net'
													   
  },
  googleApiKey: {
    key: 'AIzaSyAepxInALuNE5sFvz8WWAz4nB1J7vqxSWQ',
  },
	salesForce: {
	  dfrAccount: '0011N00001kkC3XQAU',
	},
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.
