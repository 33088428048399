import { Injectable } from '@angular/core';
import { MSALService } from '../services/msal.service';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private msalService: MSALService) {}

  canActivate(): boolean {
    if (this.msalService.isOnline()) {
      localStorage.setItem('logged_in', 'true');
      return true;
    }
    this.msalService.login(window.location.pathname);
    return false;
  }
}
