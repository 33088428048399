import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyPipe } from '@angular/common';
import { UsersService } from '@cpq-app/adminstration/users/users.service';

declare const cds: any;
@Component({
  selector: 'app-compare-products',
  templateUrl: './compare-products.component.html',
  styleUrls: ['./compare-products.component.scss'],
  providers: [CurrencyPipe]
})


export class CompareProductsComponent implements OnInit {
  @Input() compareProductData: any[];
  @Input() compareProductLable: any[];

  isInternalUser: boolean;
  // Mapping property names to display names
  constructor(public modal: NgbActiveModal, public currencyPipe: CurrencyPipe, private userService: UsersService,) { }

  ngOnInit(): void {
    this.isInternalUser = this.userService.checkLoggedInUserIsInternalUser();
  }

  getBaseUnit(property) {
    if (property == null) {
      return 'N/A';
    }
    if (this.isInternalUser) {
      let formattedBaseUnit = this.currencyPipe.transform(property, 'USD', 'symbol', '1.0-0');
      return formattedBaseUnit ? formattedBaseUnit : 'N/A';
    } else {
      return '*****'
    }

  }

  updateValueFormate(formate, value) {
    if (!value || value === 'N/A' || !formate) {
      return value;
    }
    if (formate === 'currency') {
      return this.getBaseUnit(value);
    }
    if (formate === 'percentage') {
      return `${value}%`;
    }
  }

  getValueFromPath(attributeObj: any, product: any): any {
    if (attributeObj && product) {
      const idPath = attributeObj.Id;
      const keys = idPath.split('.');
      let value = product;
      for (const keyString of keys) {
        const key = keyString.replace('[]', '');
        if (Array.isArray(value.Value)) {
          // value = value.Value.map(obj => obj[key]).join('\n');
          const mappedValues = value.Value
            .map(obj => obj[key])
            .filter(val => val !== null && val !== undefined && val !== '');
          value = mappedValues.length ? mappedValues.join('\n') : 'N/A';
          break;
        } else {
          value = value[key];
        }
        if (!value) break;
      }

      if (value?.Value !== undefined && typeof value === 'object') {
        value = value.Value;
      }
      const productAttributeValue = (value === null || value === undefined || value === '') ? 'N/A' : value;
      return this.updateValueFormate(attributeObj.Format, productAttributeValue);
    }
    return 'N/A';
  }

}
