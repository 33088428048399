import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable()
export class LandingTileDataService {

    constructor() { }
    // TWG - Landing page Tile data

    actionTileInfo: Array<object> = [
        {
            title: 'Start a New Job',
            description: 'Answer a few questions to have products recommended to you..',
            btnText: 'START',
            scopedFunction: 'navigateToCreateJob'
        },
        {
            title: 'Accounts',
            description: 'You can create new account by providing some basic informations.',
            btnText: 'VIEW',
            scopedFunction : 'navigateToViewAccounts'
        }
    ];

    featureTileInfo: Array<object> = [
        {
            title: 'Video Tutorials',
            img_path: 'assets/images/video.svg'
        },
        {
            title: 'Frequently Asked Questions',
            img_path: 'assets/images/faqs-1.svg'
        }

    ];


    getActionTileInfo() {
        return of(this.actionTileInfo);
    }
    getFeatureTileInfo() {
        return of(this.featureTileInfo);
    }


}