import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { CartService, CpqQueryObjects } from '@cpq-app/services/cart.service';
import { CpqAccounts } from '@cpq-app/tenants/Cpq.interfaces.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CpqOperations } from '../cpqOperations.service';


@Component({
  selector: 'app-energy-estimation',
  templateUrl: './energy-estimation.component.html',
  styleUrls: ['./energy-estimation.component.scss']
})
export class EnergyEstimationComponent implements OnInit {
 single1:any[];
 view: any[] = [700, 400];
 // options
 showXAxis = true;
 showYAxis = true;
 gradient = false;
 showLegend = false;
 showXAxisLabel = true;
 xAxisLabel = 'Months';
 showYAxisLabel = true;
 yAxisLabel = 'Average Temperature';

 colorScheme = {
   domain: ['#5AA454']//, '#A10A28', '#C7B42C', '#AAAAAA','#0703fc','#d203fc','#03fcf0','#0380fc','#985fc7','#c75f85','#5f98c7','#344540']
 };

  single= [
    {
        month: 1,
        averageTemp: 29.209784411276935,
        coolerEnergy: 0,
        ltCompEnergy: 0,
        mtCompEnergy: 35584.30604093604
    },
    {
        month: 2,
        averageTemp: 28.3610119047619,
        coolerEnergy: 0,
        ltCompEnergy: 0,
        mtCompEnergy: 41396.42645758452
    },
    {
        month: 3,
        averageTemp: 45.5493279569894,
        coolerEnergy: 0,
        ltCompEnergy: 0,
        mtCompEnergy: 80553.84028190511
    },
    {
        month: 4,
        averageTemp: 51.20291666666672,
        coolerEnergy: 0,
        ltCompEnergy: 0,
        mtCompEnergy: 97292.98646876961
    },
    {
        month: 5,
        averageTemp: 57.900134408602156,
        coolerEnergy: 0,
        ltCompEnergy: 0,
        mtCompEnergy: 130452.99941030212
    },
    {
        month: 6,
        averageTemp: 69.93638888888894,
        coolerEnergy: 0,
        ltCompEnergy: 0,
        mtCompEnergy: 198021.84906972916
    },
    {
        month: 7,
        averageTemp: 71.40416666666664,
        coolerEnergy: 0,
        ltCompEnergy: 0,
        mtCompEnergy: 210626.08912674224
    },
    {
        month: 8,
        averageTemp: 72.54811827956985,
        coolerEnergy: 0,
        ltCompEnergy: 0,
        mtCompEnergy: 224863.5156923881
    },
    {
        month: 9,
        averageTemp: 65.11861111111114,
        coolerEnergy: 0,
        ltCompEnergy: 0,
        mtCompEnergy: 159574.21398053697
    },
    {
        month: 10,
        averageTemp: 58.628091397849424,
        coolerEnergy: 0,
        ltCompEnergy: 0,
        mtCompEnergy: 127211.32155406424
    },
    {
        month: 11,
        averageTemp: 39.64638888888888,
        coolerEnergy: 0,
        ltCompEnergy: 0,
        mtCompEnergy: 59108.74125512484
    },
    {
        month: 12,
        averageTemp: 42.330972222222314,
        coolerEnergy: 0,
        ltCompEnergy: 0,
        mtCompEnergy: 65567.86608207242
    }
]

  constructor(public modal: NgbActiveModal,
    private router: Router,
    private dfrCpqOperations: CpqOperations,
    private toastr: ToastrService,
    private cartService: CartService,
    private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.single1 = this.single.map((item) => ({
      "name": item.month,
      "value": item.averageTemp
  }));
  }
  onSelect(event) {
    console.log(event);
  }

}
