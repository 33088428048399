import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {  Categories, ProposalPreview, QuoteLinesWithNestedPartsByCategory } from '../Cpq.interfaces.service';
import { DfrCpqOpportunity } from '../DFR/DFR.interfaces.service';
import { TunnelOptions } from '../VWS/VWS.interfaces.service';
import { jsPDF } from 'jspdf';
import domtoimage from 'dom-to-image';

  export interface User {
    Name: string;
    Title: string;
    Street: string;
    City: string;
    State: string;
    PostalCode: string;
    Country: string;
    Email: string;
    LocaleSidKey: string;
  }

  export interface Account {
    Name: string;
    BillingCountry: string;
  }

  export interface Owner {
    Name: string;
    Title: string;
    Street: string;
    City: string;
    State: string;
    PostalCode: string;
    Country: string;
    Email: string;
    LocaleSidKey: string;
  }

  export interface QuoteLineEntry {
    Description: string;
    ExtendedDescription: string;
    Id: string;
    Key: string;
    ListPrice: number;
    Name: string;
    OptionId: string;
    OptionType: string;
    OrderCode: string;
    Quantity: number;
    SellingPrice: number;
    Option_Sequence: number;
    ValueDouble: number;
    Option_SubcategoryDescription: string;
    Option_ProposalDescription__c: string;
    Option_ShowInProposal: boolean;
    Option_ProposalScope__c: string;
  }

  export interface Product {
    IsSelected: boolean;
    Product_Requested_Delivery_Date__c: string;
    Product_Lineup_Name__c: string;
    Product_OrderCode: string;
    Product_Description: string;
    Product_Proposal_Options_Concat__c: string;
    Product_Quantity: number;
    TotalSellingPrice: number;
    Product_rackName__c: string;
    Product_modelName__c: string;
    Product_mtTotalLoad__c: number;
    Product_mtEvaporatorTemp__c: number;
    Product_ltTotalLoad__c: number;
    Product_ltEvaporatorTemp__c: number;
    Product_Total_Price__c: number;
    Product_Power__c: string;
    Product_Dimensions__c: string;
    Product_Weight__c: string;
    Product_MCA__c: string;
    Product_MOPD__c: string;
    Product_GasCoolerOutletTemp__c: number;
    Product_GasCoolerOutletPressure__c: number;
    Product_LowTempExcessCapacity__c: number;
    Product_MedTempExcessCapacity__c: number;
    configSummary: string;
    level: number;
    QuoteLines: any[];
    QuoteLineEntries: QuoteLineEntry[];
  }

  export interface RackCriteria {
    Description: string;
    ExtendedDescription: string;
    Id: string;
    Key: string;
    ListPrice: number;
    Name: string;
    OptionId: string;
    OptionType: string;
    OrderCode: string;
    Quantity: number;
    SellingPrice: number;
    Option_Sequence: number;
    ValueDouble: number;
    Option_SubcategoryDescription: string;
    Option_ProposalDescription__c: string;
    Option_ShowInProposal: boolean;
    Option_ProposalScope__c: string;
  }

  export interface Rack {
    IsSelected: boolean;
    Product_Requested_Delivery_Date__c: string;
    Product_Lineup_Name__c: string;
    Product_OrderCode: string;
    Product_Description: string;
    Product_Proposal_Options_Concat__c: string;
    Product_Quantity: number;
    TotalSellingPrice: number;
    Product_rackName__c: string;
    Product_modelName__c: string;
    Product_mtTotalLoad__c: number;
    Product_mtEvaporatorTemp__c: number;
    Product_ltTotalLoad__c: number;
    Product_ltEvaporatorTemp__c: number;
    Product_Total_Price__c: number;
    Product_Power__c: string;
    Product_Dimensions__c: string;
    Product_Weight__c: string;
    Product_MCA__c: string;
    Product_MOPD__c: string;
    Product_GasCoolerOutletTemp__c: number;
    Product_GasCoolerOutletPressure__c: number;
    Product_LowTempExcessCapacity__c: number;
    Product_MedTempExcessCapacity__c: number;
    configSummary: string;
    level: number;
    QuoteLines: Product[];
    QuoteLineEntries: RackCriteria[];
  }

  export interface RootDesignCriteria {
    Description: string;
    ExtendedDescription: string;
    Id: string;
    Key: string;
    ListPrice: number;
    Name: string;
    OptionId: string;
    OptionType: string;
    OrderCode: string;
    Quantity: number;
    SellingPrice: number;
    Option_Sequence: number;
    ValueDouble: number;
    Option_SubcategoryDescription: string;
    Option_ProposalDescription__c: string;
    Option_ShowInProposal: boolean;
    Option_ProposalScope__c: string;
  }

  export interface QuoteLine {
    IsSelected: boolean;
    Product_Requested_Delivery_Date__c: string;
    Product_Sequence: number;
    Product_Lineup_Name__c: string;
    Product_OrderCode: string;
    Product_Description: string;
    Product_Proposal_Options_Concat__c: string;
    Product_Quantity: number;
    TotalSellingPrice: number;
    Product_rackName__c: string;
    Product_modelName__c: string;
    Product_mtTotalLoad__c: number;
    Product_mtEvaporatorTemp__c: number;
    Product_ltTotalLoad__c: number;
    Product_ltEvaporatorTemp__c: number;
    Product_Total_Price__c: number;
    Product_Power__c: string;
    Product_Dimensions__c: string;
    Product_Weight__c: string;
    Product_MCA__c: string;
    Product_MOPD__c: string;
    Product_GasCoolerOutletTemp__c: number;
    Product_GasCoolerOutletPressure__c: number;
    Product_LowTempExcessCapacity__c: number;
    Product_MedTempExcessCapacity__c: number;
    configSummary: string;
    level: number;
    QuoteLines: Rack[];
    QuoteLineEntries: RootDesignCriteria[];
  }

  export interface Quote {
    FormattedId: string;
    FormattedIdBase: string;
    CreatedDate: Date;
    ExpirationDate: Date;
    Freight_Required_Flag__c: boolean;
    RFQ_Receive_Date__c: Date;
    RFQ_Due_Date__c: Date;
    ShipmentDate__c: Date;
    Hide_Surcharge__c: boolean;
    TotalAmount: number;
    LastModifiedDate: Date;
    TotalPrice__c: number;
    QuoteLines: QuoteLine[];
  }

  export interface Opportunity {
    Name: string;
    AccountId: string;
    OwnerId: string;
    CreatedDate: Date;
    LastModifiedDate: Date;
    LanguageLocaleKey: string;
    LocaleSidKey: string;
    CurrencyIsoCode: string;
    storeType__c: string;
    Account: Account;
    Owner: Owner;
    CurrencyPattern: string;
    Quote: Quote;
  }

  export interface Organization {
    Name: string;
    Street: string;
    City: string;
    State: string;
    PostalCode: string;
    Country: string;
    Phone: string;
    Fax: string;
    Id: string;
  }

  export interface PreviewData {
    CurrentDate: Date;
    User: User;
    Opportunity: Opportunity;
    Organization: Organization;
  }

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  constructor(
    public modal: NgbActiveModal
  ) { }
  @Input() jobPreviewData: PreviewData;
  quoteLinesWithNestedParts: QuoteLinesWithNestedPartsByCategory;
  standardPartsByCategories: Categories;
  tunnelOptions: TunnelOptions;
  additionalPartsByCategories: Categories;
  quoteInfo: Quote;
  user: User;
  project: Opportunity;
  rootDesignCriteria: RootDesignCriteria[];
  racks: Rack[];

  ngOnInit() {  
    this.quoteInfo = this.jobPreviewData?.Opportunity?.Quote;
    this.project = this.jobPreviewData?.Opportunity;
    this.user = this.jobPreviewData?.User;
    this.rootDesignCriteria = this.jobPreviewData?.Opportunity?.Quote.QuoteLines[0].QuoteLineEntries;
    this.racks = this.jobPreviewData?.Opportunity?.Quote.QuoteLines[0].QuoteLines;   
  }

  downloadToPdf() {
    try{ 
    const preview = document.getElementById('preview');

    const previewHeight = preview.clientHeight;
    const previewWidth = preview.clientWidth;
    const options = { background: 'white', width: previewWidth, height: previewHeight };

    domtoimage.toPng(preview, options).then((imgData) => {
         const doc = new jsPDF(previewWidth > previewHeight ? 'l' : 'p', 'mm', [previewWidth, previewHeight]);
         const imgProps = doc.getImageProperties(imgData);
         const pdfWidth = doc.internal.pageSize.getWidth();
         const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

         doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
         doc.save(`ExpiredProposal_${this.quoteInfo?.FormattedId}.pdf`);
    });
  }
  catch(error){
    console.log('Error while preview proposal',error);
  }
}
}
