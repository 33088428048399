import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CartService } from '@cpq-app/services/cart.service';
import { environment } from '@cpq-environments/environment';
import { CadData, ConfigData, ProductService } from '@cpq-app/services/product.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { CadDataCds, DFRProduct } from '../DFR.interfaces.service';
import { CdsAssemblyInfo } from '@cpq-app/tenants/VWS/visualize/visualize.component';

// CDS provides JS libraries which they expect to be loaded into global space
declare const cdslib: any;
declare const cds: any;


enum QueryParam {
  ACTIVATEDNODE_TRUE = '1',
  ACTIVATEDNODE_FALSE = '0',
  ACTIVATEDNODE = 'ActiveNode',
  QUOTEPRODUCT = 'quoteproduct',
  CONFIGID = 'configId',
  PRODUCTID = 'productId',
  PRODUCTCADID = 'productCadId',
  NODEID = 'nodeId',
  SELECTEDNODE = 'selectedNode'
}

interface cadParams {
  name: string,
  type: string,
  value: string
}

@Component({
  selector: 'app-visualize',
  templateUrl: './visualize.component.html',
  styleUrls: ['./visualize.component.scss']
})
export class VisualizeComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('cds-cad-viewer-container') viewerTab: ElementRef;
  private subscriptions: Subscription[] = [];
  private cadSubscription = new Subscription();
  private configSubscription = new Subscription();

  cdsInitialized = false;
  cdslib: any; // CDS global library, loaded into memory by JS directly
  enable3d = true;
  configData: ConfigData;
  configId: string;
  //productBaseId: string;
  @Input() isOptionsChange: boolean;
  @Input() productBaseId: string;
  @Input() productCadId: string;
  @Input() toggleEnclosureFlow: boolean;
  @Input() productNodeId: string;
  @Input() cadData: CadDataCds;
  subscription: Subscription;
  product: DFRProduct;
  productName: string;
  selectedFormat: string;
  activatedNodeFlow: string;
  cadApiRootUrl = `${environment.B2CConfigs.BackendURL}${environment.cds.proxyApiPath}/catalog3/cad?d=dfr&id=`;
  @Output() isCadModelAvailable: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isActionChange: EventEmitter<any> = new EventEmitter<any>();
  cadStatusSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private cart: CartService,
    private productService: ProductService,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.cdsInitialized = false;
    this.getQueryParam();
    if (!this.cdsInitialized) {
      const routeSub = this.route.paramMap.subscribe((route) => {
        this.configId = route.get(QueryParam.CONFIGID);
        this.productBaseId = route.get(QueryParam.PRODUCTID);
        this.enable3d = this.cdsLibrariesAreValid();
        this.productNodeId = route.get(QueryParam.SELECTEDNODE);
        if (this.enable3d && this.productBaseId) {
          console.log('%cInit 3D Vis', 'background-color: green; color: white');
          this.subscribeCad(this.cadData);
        } else {
          console.log('%cInit Image Vis', 'background-color: orange; color: black');
          this.subscribeConfig();
        }
      });
      this.subscriptions.push(routeSub);
    }
  }

  private getQueryParam() {
    return this.activatedRoute.queryParamMap.subscribe(
      (queryParamMap) => {
        this.activatedNodeFlow = queryParamMap.get(QueryParam.ACTIVATEDNODE);
      },
      (err) => {
        console.log("error while loading params");
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.subscribeCad(this.cadData);
  }

  ngOnDestroy() {
    try {
      // CDS library, via three.js, places a lot of data into GPU memory
      // and the library is typically unloaded via page navigation, so it
      // does not typically worry about memory leak cleanup.

      if (cds) {
        const viewer = cds.cadViewer;
        if (viewer) {
          viewer.closeScene();
          viewer.urlToSceneObjectMap = []; // This is used in their part streaming to hold incoming data

          // The following "renderer" objects are THREE.js objects, and they expose a dispose method
          // however there are leaks in renderList that must be manually addressed first
          if (viewer.renderer) {
            viewer.renderer?.renderList?.dispose();
            viewer.renderer?.dispose();
          }
          if (viewer.renderer2) {
            viewer.renderer2?.renderList?.dispose();
            viewer.renderer2?.dispose();
          }

          // CDS exposes a dispose method, inline with THREE.js approach. This method nulls the renderer
          // and so can not be alled prior to the above code. It does not null renderer2.
          viewer.dispose();
          viewer.renderer2 = null;
        }
        cds.cadViewer.dispose();
      }
    } catch (err) {
      console.warn("3D memory may be retained");
    }

    try {
      // CDS is keeping track when a page is being loaded but is not releasing it on navigation
      cdslib.dfrCAD.dispose();
    } catch (err) {
      console.warn("3D viewer may remain loaded");
    }

    this.cadSubscription?.unsubscribe();
    this.configSubscription?.unsubscribe();
    this.subscriptions?.forEach((sub) => sub.unsubscribe());
  }

  private subscribeCad(CadOptions) {
    this.applyCadData(CadOptions);
  }

  private subscribeConfig() {
    this.configSubscription = this.productService
      .publicationForConfig(this.configId)
      .subscribe({
        next: (data) => (this.configData = data),
        complete: () => this.subscribeConfig(),
      });
  }

  /**
   * CDS libraries are plain javascript that are loaded into global space
   * and must be validated before being accessed.
   */
  private cdsLibrariesAreValid(): boolean {
    try {
      if (!cdslib) {
        return false;
      }

      if (!cds) {
        return false;
      }
      cdslib.cds = cds; // CDS requires us to bind these two together

      if (!cdslib.dfrCAD) {
        return false;
      }
    } catch (err) {
      return false;
    }

    return true;
  }

  private applyCadData(data: CadData) {
    if (!data || !data.productId) {
      console.warn("No CAD data", data);
      this.switchToImageVis();
      return;
    }

    const url = data.url; // No longer used by CDS
    const domain = (data.domain || "").toLowerCase();
    const product = (data.productId || "").toUpperCase();

    // Check to see if the CDS library appears initialized
    if (!this.cdsInitialized || !cdslib?.dfrCAD?.domain || !cdslib?.dfrCAD?.product) {
      //   if (!this.cdsInitialized || !cdslib?.cds?.domain || !cdslib?.cds?.product) {
      console.log("%cInitializing Viewer for domain:%s, product:%s, params:%o", "color: orange", domain, product, data.options);

      try {
        cdslib.dfrCAD.init({
          domain,
          endpoint: `${environment.B2CConfigs.BackendURL}${environment.cds.proxyApiPath}`,
          containerElementId: "cds-cad-container mt-2",
          formatSelectElementId: "cds-cad-download-formats",
          downloadButtonElementId: "cds-cad-download-button",
          view2DButtonElementId: "cds-cad-view-2D-button",
          viewerContainerElementId: "cds-cad-viewer-container",
          viewerIconsContainerElementId: "cds-cad-viewer-icons-container",
        });
        this.cdsInitialized = true;
      } catch (err) {
        // CDS encountered a problem. Switching to fallback display
        this.switchToImageVis();
        return;
      }
    }

    console.log("%cRetrieved new CAD attributes", "color: blue", data.options);
    data.options.product = product;

    const filename = product; //data?.options.PART_NO || "";
    console.log("%cSetting filename to %s", "color: green", filename);
    data.options.cadResultFileName = filename;

    console.log("%c Final CAD attributes", "color: green", data.options);
    try {
      console.log("loading cad")
      cdslib.dfrCAD.load(data.options);
      this.cadStatusSubscription = this.getCadProductAvailability(data?.productId).subscribe({
        next: (data: any) => {
          this.isCadModelAvailable.emit(data?.cadAvailable);
        },
        error: (err) => {
          this.isCadModelAvailable.emit(true);
        },
        complete: () => { }
      });
    } catch (err) {
      // CDS must have encountered a problem. Switching to fallback
      this.switchToImageVis();
      return;
    }
  }

  getCadProductAvailability(productId: string) {
    let apiURL = `${this.cadApiRootUrl}${productId}`;
    return this.http.get(apiURL);
  }

  private switchToImageVis() {
    console.log('%cSwitch to Image Vis', 'background-color: orange; color: black');
    this.enable3d = false;
    this.cadSubscription.unsubscribe();
    this.configSubscription.unsubscribe();
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  onDownLod3D(event) {
    const eventData = {
      entity: 'CDS',
      name: event.target.innerHTML,
      format: this.selectedFormat
    };
    if (this.selectedFormat) {
      this.isActionChange.emit(eventData);
    }
  }

  onDownLodSunmittal(event) {
    this.subscribeCad(this.cadData);
    const eventData = {
      entity: 'CDS',
      name: event.target.innerHTML,
      format: ''
    };
    this.isActionChange.emit(eventData);
  }

}
