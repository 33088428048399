import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
declare var google: any;
declare global {
  interface Window {
    googleMapsLoaded: () => void;
  }
}

@Injectable({
  providedIn: 'root'
})
export class GeoLocationService {
  private map: google.maps.Map;
  private scriptLoadingPromise: Promise<void>;

  constructor() {
    this.scriptLoadingPromise = new Promise<void>((resolve: Function) => {
      window['googleMapsLoaded'] = () => {
        resolve();
      };
      const script = document.createElement('script');
      const apiKey = environment.googleApiKey.key;
      script.type = 'text/javascript';
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=googleMapsLoaded`;
      document.body.appendChild(script);
    });
  }

  loadGoogleMapsApi(callback: Function): void {
    this.scriptLoadingPromise.then(() => {
      callback(google);
    });
  }

  // new code 
  initializeMap(mapElement: HTMLElement, options: google.maps.MapOptions): Promise<google.maps.Map> {
    return new Promise<google.maps.Map>((resolve) => {
      this.loadGoogleMapsApi((google) => {
        this.map = new google.maps.Map(mapElement, options);
        resolve(this.map);
      });
    });
  }

  getMap(): google.maps.Map {
    return this.map;
  }


}


