<div class="modal-header">
    <div class="model-name model-info ">
        <div class="product-name">
            {{productDetailData?.ProductId}}
        </div>
    </div>
    <div *HidePrice class="mb-2 model-details model-pricing d-flex flex-column flex-wrap justify-content-start">
        <div *ngIf="productDetailData?.Selected && showPrice" class="d-flex  flex-wrap justify-content-between mb-2">
            <span class="pricing-block-text">Base Unit Price
                <span class="pricing-smalltext">(included
                    discount)</span></span><span class="pricing-block-value">${{productDetailData?.BaseSellingPrice |
                number:'1.2-2'}}</span>
        </div>
        <div *ngIf="productDetailData?.Selected && configurationChange && showPrice"
            class="d-flex  flex-wrap justify-content-between">
            <span class="pricing-block-text">Total Price <span class="pricing-smalltext">(with options and
                    discounts)</span></span><span class="pricing-block-value">${{productDetailData?.TotalSellingPrice |
                number:'1.2-2'}}</span>
        </div>
        <!-- with out price -->
        <div *ngIf="productDetailData?.Selected && !configurationChange && showPrice"
            class="d-flex  flex-wrap justify-content-between"
            title="For Total Price with Options, Complete Configuring Product and Save.">
            <span class="pricing-block-text">Total Price <span class="pricing-smalltext">(with options and
                    discounts)</span></span>
            <span class="pricing-block-value">
                <span style="font-size: 24px;">###</span>
                <mat-icon style="color: #3f51b5;font-size: 20px;">info</mat-icon>
            </span>

        </div>
        <!-- end -->
    </div>
</div>

<div class="modal-body page_margin">
    <ngx-spinner class="spinner-attributes" bdOpacity=3 bdColor="rgba(0, 0, 0, 0.3)" size="medium" color="#4054B2"
        type="line-scale-party" [fullScreen]="true" [name]="DISPLAY_MODEL_SPINNER">
        <p class="login-spinner spinner mt-3">Please wait...</p>
    </ngx-spinner>
    <div class="product-detail">
        <div class="d-flex flex-row flex-wrap justify-content-between ml-3">
            <nav class="custom-breadcrumb" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><mat-icon class="breadcrumb-icons">home</mat-icon>
                        <a [routerLink]="['/']">Home</a>
                    </li>
                    <li class="breadcrumb-item"><mat-icon class="breadcrumb-icons">inventory</mat-icon><a
                            [routerLink]="[]" (click)="
                                goBacktoProjectDetails()">Project Details</a></li>
                    <li class="breadcrumb-item"><mat-icon class="breadcrumb-icons">fact_check</mat-icon><a
                            [routerLink]="[]" (click)="
                                    navigateBackToRecommendations()">Model Selection</a></li>
                    <li class="breadcrumb-item active" aria-current="page"> <mat-icon
                            class="breadcrumb-icons">picture_in_picture</mat-icon>Model Details</li>
                </ol>
            </nav>
            <div *ngIf="productDetailData?.IsCriteriaMatched">
                <button mat-flat-button color="primary" [hidden]="!productDetailData?.Selected" class="add-rack-btn"
                    (click)="saveConfigurationDetail()">
                    Save Configuration
                    &nbsp; <mat-icon>save_circle</mat-icon>
                </button>
                <button mat-flat-button color="primary" [hidden]="productDetailData?.Selected" class="add-rack-btn"
                    (click)="selectProduct()">
                    Select Product
                    &nbsp; <mat-icon>check_circle</mat-icon>
                </button>
            </div>
        </div>
        <div class="containerProductDetails">
            <div class="ModelDetails">
                <div class="image-wrapper mb-4">
                    <div class="product-image-carousels">
                        <div class="thumbnails-slider">
                            <span *ngIf="isCadModelAvailable">
                                <button [disabled]="show3DViewer" class="thumbnail-button" aria-current="true"
                                    (click)="toggle3D()">
                                    <img src="./assets/images/dfr/3d_Image.svg">
                                </button>
                            </span>
                            <ng-container *ngFor="let image of productDetailData?.Attachments; index as i;">
                                <button class="thumbnail-button" aria-current="true" (click)="showImages(image.Url)">
                                    <img [src]="displayThumbnail(image.Url)">
                                </button>
                            </ng-container>
                        </div>
                    </div>
                    <div class="image-viewer">
                        <img [src]="currentImagePath" *ngIf="!show3DViewer" class="img_2d visual_search_hide">
                        <div [ngClass]="{'viewerShow' : show3DViewer,'viewerHide' : !show3DViewer}">
                            <app-visualize [cadData]="cadData" (isCadModelAvailable)="checkCadModelAvailable($event)"
                                (isActionChange)="checkisActionChange($event)">
                            </app-visualize>
                        </div>
                        <div class="mt-2"
                            *ngIf="productDetailData?.type !==productTypes?.Co2Model && productDetailData?.Selected &&  this.GasCoolerCondenser!=undefined">
                            <button [disabled]="(this.GasCoolerCondenser?.data?.length || 0) === 0" mat-flat-button
                                color="primary" [hidden]="!productDetailData?.Selected" class="add-rack-btn"
                                (click)="openGasCoolerCondenserSubmittalDrawing()">
                                Gas Cooler / Condenser Submittal
                                &nbsp; <mat-icon>download_circle</mat-icon>
                            </button>
                        </div>

                        <div class="mt-4">
                            <ng-container *ngFor="let doc of product?.documents">
                                <button class="download-btn" *ngIf="doc?.type !== 'Brochure'"
                                    [disabled]="product?.documents.length === 0">
                                    <a [href]="productDocumentURL" download target="_blank">Download Product
                                        Information</a>
                                </button>
                                <button class="download-btn" *ngIf="doc?.type === 'Brochure'"
                                    [disabled]="product?.documents.length === 0">
                                    <a [href]="productDocumentURL" download target="_blank">Download Brochure</a>
                                </button>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="viewwerCds">
                <div class="ModelsInfoContainer">
                    <div class="ModelsBlocksInfo">
                        <div class="cds-subheader">Model Details</div>
                        <div style="overflow: hidden;">
                            <div class="row_internal">
                                <div class="product-info-term">Regulatory Compliance</div>
                                <div class="product-info-value">{{productDetailData?.RegulatoryCompliance}}</div>
                            </div>

                            <div class="row_internal product-info">
                                <div class="product-info-term"
                                    [textTransformer]="{ modelType: productDetailData?.type, text:mtTotalLoad?.ValueDouble === 0 ? 'LT Max Capacity' : 'MT Max Capacity' }">
                                    Medium Temp Capacity (at ambient conditions)
                                </div>
                                <div class="product-info-value">
                                    <span>{{productDetailData?.MedTempCompressorCapacity}}</span>
                                </div>
                            </div>
                            <div class="row_internal product-info"
                                *temperatureVisibility="{ modelType: productDetailData?.type, mtValue:productDetailData?.MedTempExcessPercent }">
                                <div class="product-info-term"
                                    [textTransformer]="{ modelType: productDetailData?.type, text: 'MT Spare Capacity' }">
                                    MT Spare Capacity Percent</div>
                                <div class="product-info-value">{{productDetailData?.MedTempExcessPercent |
                                    number:'1.2-2'}}%</div>
                            </div>
                            <div class="row_internal">
                                <div class="product-info-term"
                                    [textTransformer]="{ modelType: productDetailData?.type, text: ltTotalLoad?.ValueDouble === 0 ? 'MT Min Capacity' : 'LT Min Capacity' }">
                                    Low Temp Capacity (at ambient conditions)
                                </div>
                                <div class="product-info-value">{{productDetailData?.LowTempCompressorCapacity}}</div>
                            </div>

                            <div class="row_internal product-info"
                                *temperatureVisibility="{ modelType: productDetailData?.type, ltValue:productDetailData?.LowTempExcessPercent }">
                                <div class="product-info-term"
                                    [textTransformer]="{ modelType: productDetailData?.type, text: 'LT Spare Capacity' }">
                                    LT Spare Capacity Percent</div>
                                <div class="product-info-value">{{productDetailData?.LowTempExcessPercent|
                                    number:'1.2-2'}}%
                                </div>
                            </div>

                            <div class="row_internal">
                                <div class="product-info-term">Dimensions (D x W x H)</div>
                                <div class="product-info-value">{{productDetailData?.Size}}</div>
                            </div>
                            <div class="row_internal">
                                <div class="product-info-term">Weight</div>
                                <div class="product-info-value">{{productDetailData?.Weight}}</div>
                            </div>
                            <div class="row_internal">
                                <div class="product-info-term">Refrigerant Type</div>
                                <div class="product-info-value">{{productDetailData?.RefrigerantType}}</div>
                            </div>
                            <div class="row_internal" *ngIf="productDetailData?.type !==productTypes?.Co2Model">
                                <div class="product-info-term">Required THR</div>
                                <div class="product-info-value">{{productDetailData?.Thr}}</div>
                            </div>
                            <div class="row_internal"
                                *ngIf="productDetailData?.type !==productTypes?.Co2Model && productDetailData?.Selected &&  this.GasCoolerCondenser!=undefined && this.ratedThr != undefined">
                                <div class="product-info-term">Gas Cooler THR</div>
                                <div class="product-info-value">{{ratedThr}}</div>
                            </div>
                            <div class="row_internal" *ngIf="productDetailData?.type !==productTypes?.Co2Model">
                                <div class="product-info-term">Gas cooler inlet temperature</div>
                                <div class="product-info-value">{{productDetailData?.GasCoolerInletTemp}}</div>
                            </div>
                            <!-- <div class="row_internal"
                                *ngIf="productDetailData?.type !== productTypes?.Co2Model && productDetailData?.Selected && this.GasCoolerCondenser!=undefined && this.gasCoolerbrandName === 'randomString'">
                                <div class="product-info-term">Gas Cooler Model
                                </div>
                                <div class="product-info-value">{{modelName}}</div>
                            </div> -->
                            <!-- for modine -->
                            <div class="row_internal"
                                *ngIf="productDetailData?.type !== productTypes?.Co2Model &&
                                    productDetailData?.Selected &&
                                    GasCoolerCondenser != undefined && GasCoolerCondenser?.data?.length > 0">
                                <div class="product-info-term">Gas Cooler Model</div>
                                <select *ngIf="GasCoolerCondenser?.data?.length > 0" 
                                        [(ngModel)]="selectedGasCooler"
                                        (ngModelChange)="onModelChange($event)"
                                        class="model-dropdown" 
                                        aria-label="Gas Cooler Model">
                                    <option *ngFor="let condenser of GasCoolerCondenser.data" 
                                            [ngValue]="condenser">
                                        {{ getDropdownLabel(condenser) }}
                                    </option>
                                </select>
                            </div>
                            <div class="row_internal" *ngIf="GasCoolerCondenser?.data?.length === 0">
                                <div class="product-info-term">Gas Cooler Model</div>
                                <div class="product-info-value">{{GasCoolerCondenser?.name}}</div>
                            </div>
                        </div>

                        <div class="row_internal"
                            *ngIf="selectedGasCoolerLabel === this.GasCoolerBrand.BAC && this.subcriticalPressure">
                            <div class="noteCoTwoOne">Note : This may be a subcritical application. Please confirm your
                                gas cooler selection with ISE/TPS.</div>
                        </div>
                        <div class="row_internal" *ngIf="productDetailData?.type === productTypes?.Co2Model">
                            <div class="noteCoTwoOne">Note : This is a standalone unit, no gas cooler selection will be
                                made.</div>
                        </div>
                    </div>
                    <div class="ModelsBlocksConfig">
                        <div [hidden]="!productDetailData?.Selected">
                            <div class="cds-subheader">Model Configurator</div>
                            <div class="cds-config-options_pd" id="cds-config-options-product"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

