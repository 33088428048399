<div class="modal-header preview-header">
    <div class="header">Preview - Expired Quote</div>
    <div>
        <button type="button" class="download-btn mr-2" aria-label="Close" (click)="downloadToPdf()">
            <span aria-hidden="true"><i class="fa fa-download mr-2" aria-hidden="true"></i> Download</span>
        </button>
        <button type="button" class="close-btn" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true"><i class="fa fa-times mr-2" aria-hidden="true"></i> Close</span>
        </button>
    </div>

</div>

<div class="modal-body preview" id="preview">
    <div class="sold-to-info">
        <div class="">
            <h2 class="header_table">Sold To</h2>
            <h3 class="text_table">{{project?.Account?.Name}}</h3>
        </div>
        <div class="">
            <h2 class="header_table">Shipped To </h2>
            <h3 class="text_table"></h3>
        </div>
        <div class="header_table">
            <h2 class="header_table">Budgetary Pricing </h2>
            <h3 class="text_table">{{project?.Quote?.TotalPrice__c | currency}}</h3>
        </div>
        <div class="">
            <h2 class="header_table">Account Number</h2>
            <h3 class="text_table">{{project?.AccountId}}</h3>
        </div>
    </div>
    <div class="preview-table-info mb-3">
        <table>
            <thead>
                <tr>
                    <td class="header-details">Sales Person</td>
                    <td class="header-details">Revision Date</td>
                    <td class="header-details">Revision Expiration Date</td>
                    <td class="header-details">Payment Terms</td>
                    <td class="header-details">Ship By</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text_table">{{user?.Name}}</td>
                    <td class="text_table">{{quoteInfo?.CreatedDate | date : 'dd/MMM/yyyy'}}</td>
                    <td class="text_table">{{quoteInfo?.ExpirationDate | date : 'dd/MMM/yyyy'}}</td>
                    <td class="text_table"></td>
                    <td class="text_table">FOB</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="preview-table-info mb-3">
        <table>
            <tbody>
                <tr>
                    <td class="header-color" style="width: 50%;">
                        <span class="header-details">Revision Number</span>
                    </td>
                    <td class="text_table">{{quoteInfo?.FormattedId}}</td>
                </tr>
                <tr>
                    <td class="header-color" style="width: 50%;">
                        <div class="header-details">Division</div>
                    </td>
                    <td class="text_table">Systems</td>
                </tr>
                <tr>
                    <td class="header-color" style="width: 50%;">
                        <div class="header-details">Comments Or Special Instructions</div>
                    </td>
                    <td class="text_table"></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="preview-table-info mb-3">
        <table>
            <thead>
                <tr>
                    <td class="header-details">Rack Name</td>
                    <td class="header-details">Model</td>
                    <td class="header-details">Amount</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let rack of racks; index as i;">
                    <td class="text_table">{{rack?.Product_rackName__c}}</td>
                    <td class="text_table">{{rack?.QuoteLines[0]?.Product_modelName__c}}</td>
                    <td class="text_table">{{rack?.Product_Total_Price__c | currency}}</td>
                </tr>
                <tr>
                    <td colspan="2" class="header-color header-details">Budgetary Pricing (rack-only pricing does
                        not include gas cooler,
                        evaporators, control panel, etc)</td>
                    <td class="bold-text">{{project?.Quote?.TotalPrice__c | currency}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="preview-table-info">
        <table>
            <thead>
                <tr>
                    <td colspan="2" class="header-details">Design Criteria</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let criteria of rootDesignCriteria; index as i;">
                    <td class="text_table">{{criteria?.Option_SubcategoryDescription}}</td>
                    <td class="text_table">{{criteria?.Option_ProposalDescription__c}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="sub-header_t">
        Detailed Racks/Model Information
    </div>

    <div class="preview-table-info">
        <table>
            <thead>
                <tr>
                    <td class="header-details">Rack Name</td>
                    <td class="header-details">Description</td>
                    <td class="header-details">Amount</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let rack of racks; index as i;">
                    <td class="header-details">{{rack?.Product_rackName__c}}</td>
                    <td>
                        <table>
                            <tr class="header-color">
                                <td colspan="2" class="header-details">Model Information</td>
                            </tr>
                            <tr>
                                <td class="text_table">Model Name</td>
                                <td class="text_table">{{rack?.QuoteLines[0]?.Product_modelName__c}}</td>
                            </tr>
                            <tr>
                                <td class="text_table">MT Capacity (KBTUH)</td>
                                <td class="text_table">{{rack?.QuoteLines[0]?.Product_mtTotalLoad__c}}</td>
                            </tr>
                            <tr>
                                <td class="text_table">MT Excess Capacity (KBTUH)</td>
                                <td class="text_table">{{rack?.QuoteLines[0]?.Product_MedTempExcessCapacity__c}}</td>
                            </tr>
                            <tr>
                                <td class="text_table">LT Capacity (KBTUH)</td>
                                <td class="text_table">{{rack?.QuoteLines[0]?.Product_ltTotalLoad__c}}</td>
                            </tr>
                            <tr>
                                <td class="text_table">LT Excess Capacity (KBTUH)</td>
                                <td class="text_table">{{rack?.QuoteLines[0]?.Product_LowTempExcessCapacity__c}}</td>
                            </tr>
                            <tr>
                                <td class="text_table">Power</td>
                                <td class="text_table">{{rack?.QuoteLines[0]?.Product_Power__c}}</td>
                            </tr>
                            <tr>
                                <td class="text_table">Weight</td>
                                <td class="text_table">{{rack?.QuoteLines[0]?.Product_Weight__c}}</td>
                            </tr>
                            <tr>
                                <td class="text_table">Dimension</td>
                                <td class="text_table">{{rack?.QuoteLines[0]?.Product_Dimensions__c}}</td>
                            </tr>
                            <tr>
                                <td class="text_table">MOPD @ (Voltage Selected)</td>
                                <td class="text_table">{{rack?.QuoteLines[0]?.Product_MOPD__c}}</td>
                            </tr>
                            <tr>
                                <td class="text_table">MCA @ (Voltage Selected)</td>
                                <td class="text_table">{{rack?.QuoteLines[0]?.Product_MCA__c}}</td>
                            </tr>
                            <tr>
                                <td class="text_table">Gas Cooler Outlet Temperature</td>
                                <td class="text_table">{{rack?.QuoteLines[0]?.Product_GasCoolerOutletTemp__c}}</td>
                            </tr>
                            <tr>
                                <td class="text_table">Gas Cooler Outlet Pressure</td>
                                <td class="text_table">{{rack?.QuoteLines[0]?.Product_GasCoolerOutletPressure__c}}</td>
                            </tr>
                            <tr class="background-blue-color">
                                <td colspan="2" class="header-details">Rack Design Criteria</td>
                            </tr>
                            <tr *ngFor="let rackCriteria of rack?.QuoteLineEntries; index as i;">
                                <td class="text_table">{{rackCriteria?.Option_SubcategoryDescription}}</td>
                                <td class="text_table">{{rackCriteria?.Option_ProposalDescription__c}}</td>
                            </tr>
                        </table>
                    </td>
                    <td class="text_table">{{rack?.Product_Total_Price__c | currency}}</td>
                </tr>
                <tr>
                    <td colspan="2" class="background-blue-color">
                        Budgetary Pricing (rack-only pricing does not include gas cooler,
                        evaporators, control panel, etc)
                    </td>
                    <td class="bold-text">
                        {{project?.Quote?.TotalPrice__c | currency}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>