<div class="project-archive mt-4">
    <div>
        <div class="sub-header side-gutter mb-3">{{pageTitle}}</div>
        <div class="side-gutter">
            <div [formGroup]="accountEditForm" *ngIf="pageTitle !== 'Add New Account'">
                <div>
                    <ngx-spinner class="spinner-attributes" bdOpacity=3 bdColor="rgba(0, 0, 0, 0.3)" size="medium"
                        color="#4054B2" type="line-scale-party" [fullScreen]="false" [name]="ACCOUNT_SPINNER">
                        <p class="login-spinner spinner mt-3">Please wait...</p>
                    </ngx-spinner>
                    <div class="same-row-controls">
                        <div class="child-control">
                            <label for="parentAccountId" class="form-labels f-label mr-1">Sold to Customer</label>
                            <mat-form-field class="view-account-style example-full-width" appearance="outline">
                                <input type="text" matInput formControlName="parentAccountId" [matAutocomplete]="auto"
                                    [value]="selectedAccount" placeholder="Select sold to customer"
                                    (change)="onInputChange($event)">
                                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                                    (optionSelected)="onAccountSelect($event)">
                                    <mat-option *ngFor="let option of filteredAccounts | async" [value]="option">
                                        {{option.Name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <label for="childAccountId" class="ml-3 form-labels f-label mr-1">End Customer</label>
                            <mat-form-field class=" view-account-style  example-full-width" appearance="outline">
                                <input type="text" matInput formControlName="childAccountId"
                                    [matAutocomplete]="childAccount" [value]="selectedChildAccount"
                                    placeholder="Select end customer">
                                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                                <mat-autocomplete #childAccount="matAutocomplete" [displayWith]="displayFn">
                                    <mat-option *ngFor="let option of filteredChildAccounts | async" [value]="option">
                                        {{option.Name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <ng-container *ngIf="isAdmin">
                                <button type="submit" [ngClass]="{'disable':!isValid()}" class="dfr-btn-dark ml-4"
                                    (click)="updateAccount()">Edit</button>
                                <button type="submit" [ngClass]="{'disable':!isValid()}" class="dfr-btn-dark ml-2"
                                    (click)="deleteAccount()">Delete</button>
                                <span class="ml-1"> <b>OR</b></span>
                            </ng-container>
                            <button type="submit" class="dfr-btn-dark ml-2" (click)="createAccount()">Create</button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <app-create-accounts *ngIf="showCreateUpdateForm" [parent]="parent" (backEvent)="backEvent()"
                    [availableAccounts]="availableAccounts" (saveEvent)="saveEvent()"
                    [child]="child"></app-create-accounts>
            </div>
        </div>
    </div>