import { Observable, Subject } from 'rxjs';

export interface CadData {
    url?: string;
    domain?: string;
    productId?: string;
    options?: Options;
}
export interface Options {
    CUSTOMER: string;
    DATE: string;
    MODEL_NUMBER: string;
    PROJECT: string;
    PROJ_VER: string;
    cadResultFileName?: string;
    PART_NO?: string
    product?: string;
}

export interface Option {
    classname: string;
    id: string;
    name?: string;
    desc?: string;
    label?: string;
    category: string;
    selected: number;
    quantity: number;
    value?: string;
    imageUrl?: string;
    textValue?: string;
    shortDescription?: string;
    // "classname": "GlobalNumericOptions",
    // "id": 6,
    // "name": "TunnelLength",
    // "value": 10,
    // "textValue": null,
    // "selected": 0,
    // "quantity": 0,
    // "label": "Tunnel Length",
    // "PriceList": -1,
    // "PrExtendedNetPrice": 0,
    // "ExtDescription": "",
    // "imageUrl": "",
    // "disabled": 0
}

export interface Node {
    classname: string;
    id: string;
    name?: string;
    desc?: string;
    label?: string;
    category: string;
    value: string;
    selected: number;
    quantity: number;
    imageUrl?: string;
    // "PriceList": 11330,
    // "PrExtendedNetPrice": 0,
    // "ExtDescription": "Reliable operation & reduced maintenance with zero grease points construction. Complete routine maintenance from a workbench & enjoy easy access to all conveyor areas.",
    // "imageUrl": "<img src=\"/rs/19/cpq/images/Duratransconv.jpg\" width=\"200\" height=\"125\"/>",
    // "disabled": 0
    nodeId: string;
    available?: number;
}
export interface Grouping {
    classname: string;
    id: string;
    desc?: string;
    name?: string;
    label?: string;
    minimum: number;
    maximum: number;
    uiView?: string;
    nodes?: Node[];
    options?: Option[] | any[];
    subGroups?: Grouping[];
}

export interface ConfigData {
    products: {
        available: [];
        related?: [];
        selected?: [];
    };
    parts?: {
        available?: any[];
        related?: any[];
        selected?: any[];
        summary?: {
            price: number;
            discountedPrice: string;
        };
    };
    groups: {
        preSelect?: Grouping;
        mainGroup: Grouping;
        postSelect?: Grouping;
    };
    summary: {
        modelCode: string;
        name: string;
        graphicName: string;
        notes: {
            classname: string;
            id: string;
            value: string;
        };
        options: [];
        calculators: [];
        price: string;
        discountedPrice: string;

    };
}

export interface ConfigNodeData {
    success?: boolean;
    results?: Result[];
}

export interface CopyNodeData {
    success?: boolean;
    nodes: number[];
    newNode: string;
}

export interface SaveDataRCresponse {
    Id: string;
    configId: string;
    configData: string;
    isActive: boolean;
}

export interface CopyNodeConfig {
    result?: string;
}
export interface Result {
    newNodeIndex?: string;
    quantity?: string;
    success?: boolean;
    errors?: Errors[];
}
export interface Errors {
    message?: string;
}

export interface ServerFunctionReply {
    result: string; // Typically a JSON string but not guarunteed
    metrics?: {
        instructionCount?: number;
        callTimeMs?: number;
    };
}

export interface ExecuteResponse {
    success: boolean;
    results: ExecuteResult[];
}

export interface ExecuteResult {
    success?: boolean;
    errors?: ExecuteError[];
}

export interface ExecuteError {
    id?: string;
    message?: string;
    msgParams?: string[];
}

export interface NodeInfo {
    configID: string;
    nodeID?: string;
}

export enum OptionType {
    Value,
    Quantity,
}

export interface OptionUpdatePayload {
    configId: string;
    optionId: string;
    nodeId: string;
    optionType: OptionType;
    value?: string;
    quantity?: number;
    success: Subject<ExecuteResponse>;
}

export interface ActionLogs {
    entity: string;
    name?: string;
    format?: string;
    id?: string;
}