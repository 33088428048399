<div *ngIf="showHeader" class="table-header d-flex justify-content-between">
    <div class="search-box" *ngIf="showSearchBar">
        <form>
            <div class="form-group form-inline">
                <i class="fa fa-search search-icon"></i>
                <input class="form-control ml-2 search-input" [(ngModel)]="searchTerm" placeholder="Search ..."
                    type="text" [formControl]="filter" />
            </div>
            <span class="ml-3" *ngIf="loading$ | async">Loading...</span>
        </form>
    </div>
    <div class="pagination-section d-flex justify-content-between">
        <div class="d-none d-sm-block">
            <span class="m-2">Showing</span>
            <select class="custom-select" style="width: auto" [(ngModel)]="pageSize">
                <option [ngValue]="10">10</option>
                <option [ngValue]="20">20</option>
                <option [ngValue]="30">30</option>
            </select>
            <span class="m-2">Per Page</span>
        </div>
        <div class="mr-auto">
            <span class="d-flex justify-content-between">
                <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="page" [maxSize]="5" [rotate]="true"
                    [pageSize]="pageSize">
                </ngb-pagination>
                <span class="m-2 d-none d-sm-block">of {{(total$ | async)!}} records</span>
                <span *ngIf="showDataLoading" class='m-2'>

                </span>
            </span>
        </div>

    </div>
</div>
<table class="table  datatableComponent small-device-table">
    <thead>
        <tr>
            <th [ngClass]="key.isPrimary ? 'primary-column-'+count : 'other-column-'+count" scope="col"
                *ngFor="let key of ColData; count as count; trackBy:trackByIndex" [hidden]="!key.visibility">
                <span *ngIf="key.sortable else staticcolumn" [sortable]='key.field' (sort)="onSort($event)"
                    class="sortable">{{key.header}}</span>
                <ng-template #staticcolumn> <span>{{key.header}}</span> </ng-template>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let record of objects$ | async; index as i; trackBy:trackByIndex">
            <td *ngFor="let key of ColData; trackBy:trackByIndex" [hidden]="!key.visibility">
                <span *ngIf="key.field!=='actions'; else elseActionBlock">
                    <span *ngIf="!key.hasOwnProperty('control'); else elseControlBlock">

                        <span [class]='key.className(record)'
                            *ngIf="key.hasOwnProperty('handler'); else elseHandlerBlock" (click)="key.handler(record)">
                            <ng-container
                                *ngTemplateOutlet="displayColumnValue; context:{ $implicit: key, item:record }">
                            </ng-container>
                        </span>
                        <ng-template #elseHandlerBlock>
                            <ng-container
                                *ngTemplateOutlet="displayColumnValue; context:{ $implicit: key, item:record }">
                            </ng-container>
                        </ng-template>
                        <!-- Common template to display column data -->
                        <ng-template #displayColumnValue let-key let-record="item">

                            <span *ngIf="isSmallDevice">
                                <span class="small-device-header">{{key.header}} :</span>
                                <span>
                                    <ngb-highlight [class]="key.hasOwnProperty('className') ? key.className(record) : '' "
                                    [title]="key.hasOwnProperty('titleText') ? key.titleText(record) : '' "
                                    [result]="key.value(record)" [term]="filter.value">
                                </ngb-highlight>
                                </span>
                            </span>
                            <span *ngIf="!isSmallDevice">
                                <ngb-highlight [class]="key.hasOwnProperty('className') ? key.className(record) : '' "
                                    [title]="key.hasOwnProperty('titleText') ? key.titleText(record) : '' "
                                    [result]="key.value(record)" [term]="filter.value">
                                </ngb-highlight>
                            </span>
                        </ng-template>
                    </span>
                    <ng-template #elseControlBlock>
                        <div class="datatable_controls icon-adjust">
                            <span *ngIf="isSmallDevice" class="small-device-header">{{key.header}} :</span>
                            <input type={{key.control}} (click)="key?.handler(record)" [checked]=(record[key.field])
                                [id]="i" [value]="i" />
                            <label [for]="i"></label>
                        </div>
                    </ng-template>
                </span>
                <ng-template #elseActionBlock>
                    <span *ngIf="isSmallDevice" class="small-device-header">{{key.header}} :</span>
                    <div class="arrange-action-icons">
                        <div [class.col-1]=isSmallDevice class="icon"
                            *ngFor="let option of key.value(record); trackBy:trackByIndex" title="{{option?.action}}">
                            <a *ngIf="option?.hrefHandler == undefined" class="{{option?.action}} pointer"
                                (click)="option?.handler()"></a>
                            <a *ngIf="option?.hrefHandler != undefined" target="_blank"
                                class="{{option?.action}} pointer" [href]="option?.hrefHandler()"></a>
                        </div>
                    </div>
                </ng-template>
            </td>
        </tr>
        <tr *ngIf="showLoading && showDataLoading">
            <td class="noRecords" colspan="8">Loading...</td>
        </tr>
        <tr *ngIf="hasNoData && !showLoading && !showDataLoading">
            <!-- Condition for search and initial values-->
            <td class="noRecords" colspan="8">No records found.</td>
        </tr>
    </tbody>
</table>