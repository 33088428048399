
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CartService, CpqObjectType, CpqQueryObjects } from '@cpq-app/services/cart.service';
import { CpqAccounts } from '@cpq-app/tenants/Cpq.interfaces.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { map, Observable, startWith, Subscription, of } from 'rxjs';
import { DfrAccounts } from '../../DFR.interfaces.service';
import { ToastrService } from 'ngx-toastr';
import { SessionVariables } from '@cpq-app/header/header.component';


export enum ACCOUNT_PAGE_TITLES {
  ADD_ACCOUNT = 'Add New Account',
  EDIT_ACCOUNT = 'Edit Account',
  DEFAULT = "Accounts"
}

@Component({
  selector: 'app-view-accounts',
  templateUrl: './view-accounts.component.html',
  styleUrls: ['./view-accounts.component.scss']
})
export class ViewAccountsComponent implements OnInit , OnDestroy{

  accounts: any[] = [];
  dataLoading = false;
  subscription$: Subscription[] = [];
  accountEditForm: UntypedFormGroup;
  availableAccounts = [];
  filteredAccounts: Observable<DfrAccounts[]>;
  selectedAccount: string;
  subscriptions$: Subscription[] = [];
  childAccounts = [];
  selectedChildAccount;
  filteredChildAccounts: Observable<DfrAccounts[]>;
  showCreateUpdateForm = false;
  pageTitle = ACCOUNT_PAGE_TITLES.DEFAULT
  parent;
  child;
  ACCOUNT_SPINNER = 'account spinner';
  ADMIN_ROLE = 'Global System Admin';
  isAdmin = false
  constructor(private router: Router,
    private formBuilder: UntypedFormBuilder,
    private cartService: CartService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    if (sessionStorage.getItem(SessionVariables.PERSONA) === this.ADMIN_ROLE) {
      this.isAdmin = true
    }
    this.createAccountForm();
  }



  createAccountForm() {
    this.accountEditForm = this.formBuilder.group({
      parentAccountId: [null],
      childAccountId: [null]
    });
    this.getAccountNames();
  }


  getAccountNames() {
    this.spinner.show(this.ACCOUNT_SPINNER)
    this.availableAccounts = [];
    this.subscriptions$.push(this.cartService.getCpqObjects<CpqAccounts>(CpqQueryObjects.Accounts, { resolveNames: 'true' })
      .subscribe(
        (results) => {

          const accountsWithExternalId = results.filter(account => account.ExternalId);
          this.availableAccounts = [...this.availableAccounts, ...accountsWithExternalId];
          this.filteredAccounts = this.accountEditForm.controls['parentAccountId'].valueChanges.pipe(
            startWith(""),
            map(value => (typeof value === "string" ? value : value?.Name)),
            map(name => (name ? this._filter(name) : this.availableAccounts.slice()))
          );
          this.spinner.hide(this.ACCOUNT_SPINNER)
          const selectedAcc = this.availableAccounts.find(acc => acc.Id === this.accountEditForm.controls.parentAccountId.value);
          if (selectedAcc) {
            this.childAccounts = selectedAcc?.ChildAccounts;
            if (selectedAcc?.hasOwnProperty('ChildAccounts')) {
              this.childAccounts = selectedAcc.ChildAccounts;
            } else {
              this.childAccounts = [{
                Id: selectedAcc?.Id,
                Name: selectedAcc?.Name
              }];
              this.selectedChildAccount = this.childAccounts[0]?.Name;
              this.accountEditForm.controls.childAccountId.setValue(this.childAccounts[0]);
            }
            this.filteredChildAccounts = this.accountEditForm.controls['childAccountId'].valueChanges.pipe(
              startWith(""),
              map(value => (typeof value === "string" ? value : value?.Name)),
              map(name => (name ? this._filterChildAccount(name) : this.childAccounts.slice()))
            );
          }
        },
        err => {
          this.spinner.hide(this.ACCOUNT_SPINNER)
        }
      ));
  }


  onAccountSelect(event) {
    this.childAccounts = [];
    this.selectedChildAccount = null;
    this.accountEditForm.controls.childAccountId.setValue(null);
    if (event.option.value.hasOwnProperty('ChildAccounts')) {
      this.childAccounts = event.option.value.ChildAccounts;
    } else {
      this.childAccounts = [{
        Id: event.option.value.Id,
        Name: event.option.value.Name
      }];
      this.selectedChildAccount = this.childAccounts[0]?.Name;
      this.accountEditForm.controls.childAccountId.setValue(this.childAccounts[0]);
    }
    this.filteredChildAccounts = this.accountEditForm.controls['childAccountId'].valueChanges.pipe(
      startWith(""),
      map(value => (typeof value === "string" ? value : value?.Name)),
      map(name => (name ? this._filterChildAccount(name) : this.childAccounts.slice()))
    );
  }

  displayFn(account?: DfrAccounts): string | undefined {
    return account ? account.Name : undefined;
  }

  onInputChange(event) {
    if (event.target.value === '') {
      this.showCreateUpdateForm = false;
      this.childAccounts = [];
      this.selectedChildAccount = null;
      this.accountEditForm.controls.childAccountId.setValue(null);
    }
  }



  private _filterChildAccount(Name: string): DfrAccounts[] {
    const filterValue = Name.toLowerCase();

    return this.childAccounts.filter(
      option => option.Name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private _filter(Name: string): DfrAccounts[] {
    const filterValue = Name.toLowerCase();

    return this.availableAccounts.filter(
      option => option.Name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  updateAccount() {
    this.pageTitle = ACCOUNT_PAGE_TITLES.EDIT_ACCOUNT;
    const parent = this.accountEditForm.controls.parentAccountId.value;
    const child = this.accountEditForm.controls.childAccountId.value
    this.parent = parent.Id;
    this.child = child.Id;
    this.showCreateUpdateForm = true;
  }

  deleteAccount() {
    const child = this.accountEditForm.controls.childAccountId.value;
    this.spinner.show(this.ACCOUNT_SPINNER);
    this.subscription$.push(this.cartService.deleteObjectByObjectId(CpqObjectType.Account, child.Id).subscribe({
      next: () => {
        this.clearDropdown();
        this.showCreateUpdateForm = false;
        this.getAccountNames();
        this.spinner.hide(this.ACCOUNT_SPINNER);
        this.toastr.success('Account deleted successfully');
      },
      error: (err) => {
        this.spinner.hide(this.ACCOUNT_SPINNER);
      }
    }));
  }



  createAccount() {
    this.pageTitle = ACCOUNT_PAGE_TITLES.ADD_ACCOUNT;
    this.showCreateUpdateForm = true;
    this.clearDropdown();
  }

  clearDropdown() {
    this.accountEditForm.controls.parentAccountId.setValue(null);
    this.accountEditForm.controls.childAccountId.setValue(null);
    this.selectedAccount = null;
    this.selectedChildAccount = null;
    this.parent = this.selectedAccount;
    this.child = this.selectedChildAccount;
    this.filteredChildAccounts = of([]);
  }

  isValid() {
    const parent = this.accountEditForm.controls.parentAccountId.value;
    const child = this.accountEditForm.controls.childAccountId.value;
    if (parent?.Id === null || child?.Id === null || child?.Id === undefined || parent?.undefined === null) {
      return false
    } else {
      return true;
    }
  }

  saveEvent() {
    this.getAccountNames();
    this.showCreateUpdateForm = false;
    this.clearDropdown();
    this.pageTitle = ACCOUNT_PAGE_TITLES.DEFAULT;

  }

  backEvent() {
    this.showCreateUpdateForm = false;
    this.clearDropdown();
    this.pageTitle = ACCOUNT_PAGE_TITLES.DEFAULT;
  }

  ngOnDestroy(): void {
    this.subscriptions$.forEach(sub => sub.unsubscribe());
  }

}
