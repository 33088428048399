import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService, CpqQueryObjects, QuoteWorkflowStatus, CpqObjectType } from '@cpq-app/services/cart.service';
import { LoginService } from '@cpq-app/services/login.service';
import { DatatableColumn, ExpiryCssClass, StatusCssClass, RowActions as TableRowActions } from '@cpq-app/shared/datatable/datatable';
import { CpqJob } from '@cpq-app/tenants/Cpq.interfaces.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CpqOperations } from '../cpqOperations.service';
import { DfrCpqOpportunity, DfrCpqQuote, ProjectStatus, DfrJobs } from '../DFR.interfaces.service';
import { differenceInCalendarDays } from 'date-fns';
import { DatePipe } from '@angular/common';
const DATE_FMT = 'yyyy-MM-dd';

@Component({
  selector: 'app-archived-projects',
  templateUrl: './archived-projects.component.html',
  styleUrls: ['./archived-projects.component.scss']
})
export class ArchivedProjectsComponent implements OnInit, OnDestroy {
  archivedProjects: CpqJob[] = [];
  filteredArchivedProjects: CpqJob[] = [];
  subscription$: Subscription[] = [];
  archivedProjectSubscription: Subscription;
  projectId: string;
  revisionId: string;
  dataLoading = false;
  currencyPipe: any;
  columnDefinitions$: Subject<DatatableColumn<CpqJob>[]>;
  searchString: string;
  enableNoMatchFound = true;

  constructor(
    private cartService: CartService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService,
    private dfrCpqOperations: CpqOperations,
    private loginService: LoginService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.fetchArchivedProject();
    const colDef = this.columnDefinitionFactory();
    this.columnDefinitions$ = new BehaviorSubject<DatatableColumn<CpqJob>[]>(colDef);
  }
  applyFilters() {
    try {
      this.filteredArchivedProjects = [];
      const searchStr = this.searchString?.trim().toLowerCase() || '';
      this.filteredArchivedProjects = this.archivedProjects?.filter(item => item.Name?.toLowerCase().includes(searchStr) || item.Owner?.Name.toLowerCase().includes(searchStr));

      this.dataLoading = false;
    } finally {
      this.dataLoading = false;
    }
  }

  fetchArchivedProject() {
    this.archivedProjects = [];
    this.subscription$.push(this.cartService.getCpqObjects<CpqJob>(
      CpqQueryObjects.Opportunities)
      .subscribe({
        next: (data: CpqJob[]) => {
          this.archivedProjects = [...this.archivedProjects, ...data];
          this.archivedProjects = this.archivedProjects.filter(x => {
            return x.Source__c == 'CO2' && x.Status__c != 'Active'
          }).sort((a, b) => Number(b?.ModifiedTime) - Number(a?.ModifiedTime));
          this.spinner.hide();
        },
        complete: () => {
          this.dataLoading = false;
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(
            'There is fatal error while fetching project', 'Error', {
            disableTimeOut: true,
            closeButton: true
          });
        }
      }));
  }

  columnDefinitionFactory(): DatatableColumn<CpqJob>[] {
    const colData: DatatableColumn<CpqJob>[] = [
      {
        field: 'Name', header: 'Project Name', isPrimary: true,
        visibility: true, sortable: true, filterable: true,
        value: (project: CpqJob) => project?.Name
      },
      {
        field: 'ModifiedTime', header: 'Last Update',
        initialSort: true, sortable: true, visibility: true,
        value: (project: CpqJob) => this.datePipe.transform(project.ModifiedTime, DATE_FMT),
        compare: (a: CpqJob, b: CpqJob) => {
          const aD = new Date(a.ModifiedTime);
          const bD = new Date(b.ModifiedTime);
          return Number(aD > bD) - Number(aD < bD);
        }
      },
      {
        field: 'BudgetaryNumber', header: 'Quote Number',
        initialSort: true, sortable: true, visibility: true,
        value: (project: DfrJobs) => project.PrimaryQuote?.BudgetaryNumber,
        compare: (a: DfrJobs, b: DfrJobs) => Number(a.PrimaryQuote?.BudgetaryNumber > b.PrimaryQuote?.BudgetaryNumber) - Number(a.PrimaryQuote?.BudgetaryNumber < b.PrimaryQuote?.BudgetaryNumber)
      },
      {
        field: 'Owner', header: 'Creator',
        visibility: true, sortable: true, filterable: true,
        value: (project: CpqJob) => project.Owner?.Name,
        compare: (a: CpqJob, b: CpqJob) => a.OwnerId?.Name?.localeCompare(b.Owner?.Name)
      },
      {
        field: 'Status__c', header: 'Status',
        visibility: true, sortable: true, filterable: false,
        value: (project: CpqJob) => DfrCpqQuote.projectStatus(project),
        className: (project: CpqJob) => this.returnStatusClass(project)
      },
      {
        field: 'actions', header: 'Actions', visibility: true, value: (record) => {
          const actionsArray = [
            { action: TableRowActions.VIEW, handler: () => this.onClickView(record) },
            { action: TableRowActions.COPY, handler: () => this.onClickCopy(record) }
          ];
          return actionsArray;
        }
      }
    ];
    return colData;
  }

  onClickView(project: CpqJob) {
    try {
      this.router.navigate([
        'productSelector',
        project?.Id
      ]);

    } catch (error) {
      console.log(error);
    }
  }

  returnStatusClass(project: CpqJob): string {
    switch (project.Status__c) {
      case ProjectStatus.Active:
        return StatusCssClass.ACTIVE;

      case ProjectStatus.Closed:
        return StatusCssClass.CLOSE;

      case ProjectStatus.Abandoned:
        return StatusCssClass.CLOSE;

      case ProjectStatus.Completed:
        return StatusCssClass.CLOSE;

      default:
        return StatusCssClass.INACTIVE;
    }
  }

  returnExpireClass(record: CpqJob): string {
    const diff = this.returnExpire(record);
    try {
      if (diff === '') {
        return ExpiryCssClass.EXPIRED;
      } else if (diff === '99+') {
        return ExpiryCssClass.NORMAL_TRIPLE_DIGIT;
      } else if (diff >= 0 && diff <= 2) {
        return ExpiryCssClass.DANGER;
      } else if (diff > 2 && diff <= 4) {
        return ExpiryCssClass.WARNING;
      } else if (diff > 4 && diff <= 7) {
        return ExpiryCssClass.MODERATE;
      } else if (diff > 7 && diff <= 9) {
        return ExpiryCssClass.NORMAL_SINGLE_DIGIT;
      } else {
        return ExpiryCssClass.NORMAL;
      }
    } catch (err) {
      console.log(err);
    }
  }

  returnTitleText(record: CpqJob): string {
    const differences = this.getDifferenceInCalendarDays(record);
    let value = `The primary configuration will expire in ${differences} days`;
    if (differences < 0) {
      value = `The primary configuration is expired`;
    }
    return value;
  }

  getDifferenceInCalendarDays(record: any): number {
    return differenceInCalendarDays(
      new Date(record.PrimaryQuote.ExpirationTime), new Date());
  }

  // This function is for display value
  returnExpire(record: CpqJob) {
    const difference = this.getDifferenceInCalendarDays(record);
    let value: number | string = difference;
    if (difference > 99) {
      value = '99+';
    } else if (difference < 0) {
      value = ''; // Passing empty value to apply No Entry class
    }
    return value;
  }

  showToasterMsg() {
    this.toastr.error(
      'There is fatal error while creating project/Revision', 'Error', {
      disableTimeOut: true,
      closeButton: true
    }
    );
  }

  onClickCopy(record: CpqJob) {
    this.spinner.show();
    const newName = `Copy of ${record?.Name}`;
    const newRecord: CpqJob = Object.assign({}, record);
    this.subscription$.push(this.cartService.copyObjectById(CpqObjectType.Opportunity, record?.Id, newName)
      .subscribe({
        next: (results: CpqJob) => {
          this.updateProjectStatus(record);
        },
        error: (err) => {
          this.spinner.hide();
          this.toastr.error(
            'There is fatal error while copying Project', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      }));
  }

  updateProjectStatus(record: CpqJob) {
    const projectStatus = {
      Status__c: ProjectStatus.Active
    };
    this.subscription$.push(this.cartService.updateObjectById(CpqObjectType.Opportunity, record?.Id, projectStatus).subscribe(
      (res) => {
        this.dfrCpqOperations.changeArchiveProjectStatus.next();
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        this.toastr.error(
          'There is fatal error while Updating Project', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      }));
  }

  ngOnDestroy() {
    this.archivedProjectSubscription?.unsubscribe();
    this.subscription$?.forEach(sub => sub?.unsubscribe());
  }

}

