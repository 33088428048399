import { Component, OnInit, Input } from '@angular/core';
import { CartService } from '@cpq-app/services/cart.service';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { DatePipe } from '@angular/common';
import { reduceTicks } from '@swimlane/ngx-charts';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { isUndefined } from 'lodash';

(<any>pdfMake).vfs = pdfFonts?.pdfMake?.vfs;

@Component({
  selector: 'app-proposal-report',
  templateUrl: './proposal-report.component.html',
  styleUrls: ['./proposal-report.component.scss']
})
export class ProposalReportComponent implements OnInit {
  @Input() reportData;
  @Input() QuoteId;
  @Input() isDisable;
  proposalResult: any;
  tableDesigCriteria: any;
  tableRackData: any;
  headerImageLeft: any;
  headerImageRight: any;
  soldToAddress: any;
  shippedToAddress: any;
  reportSelect = 'default';
  isEorUser: boolean;

  constructor(private cartService: CartService, private toastr: ToastrService, private datePipe: DatePipe, private userService: UsersService,) { }

  ngOnInit(): void {
    this.isEorUser = this.userService.checkLoggedInUserIsEorUser();
    // get image first image
    const imageURL = 'assets/image1.PNG';
    this.cartService.getImageAsBase64(imageURL).subscribe((imageBase64: string) => {
      this.headerImageLeft = imageBase64;
    }, error => {
      console.error('Error:', error);
    });

    // 2nd image
    const imageURL2 = 'assets/DoverImage.PNG';
    this.cartService.getImageAsBase64(imageURL2).subscribe((imageBase64: string) => {
      this.headerImageRight = imageBase64;
    }, error => {
      console.error('Error:', error);
    });
  }

  // report function

  DownloadReport(option) {
    if (this.QuoteId && option) {
      this.getQuoteProposalData(option);
    }
  }

  exportTable(option) {
    // style code
    const styles = {
      headerCenter: {
        fontSize: 15,
        bold: true,
      },
      // headerLeft: {
      //   alignment: 'left',
      // },
      // headerRight: {
      //   alignment: 'right',
      // },

      // subheader: {
      //   margin: [0, 10, 0, 5]
      // },
      tableStructure: {
        fontSize: 9,
        margin: [0, 5, 0, 10]
      },
      tableExample1: {
        fontSize: 9,
      },
      tableHeader: {
        bold: true,
        fontSize: 10,
        color: '#fff',
        fillColor: '#1d3d7c',
        margin: [2, 2, 2, 2]
      },

      rackHeader: {
        bold: true,
        fontSize: 10,
        color: '#fff',
        fillColor: '#1d3d7c',
        // margin: [2, 2, 2, 2]
      },
      verticalRow: {
        bold: true,
        fontSize: 10,
        color: '#212529',
        fillColor: '#ECECEC',
      },
      footer: {
        fontSize: 8,
        margin: [40, 1, 40, 10],
        // alignment: 'left',
      },
      footerLink: {
        fontSize: 8,
        margin: [0, 0, 100, 0],
        alignment: 'left',
        color: 'blue'
      },

    };

    const pageMargins: [number, number, number, number] = [40, 60, 40, 60];
    const pageSize = {
      width: 595.276,
      height: 841.890,
    };
    const headerMargins: [number, number, number, number] = [40, 0, 40, 0];

    //  header code
    const header = {
      columns: [
        {
          margin: headerMargins,
          table: {
            headerRows: 2,
            style: 'tableStructure',
            widths: ['*', '*', '*'],
            body: [
              [
                { image: this.headerImageLeft, alignment: 'left', border: [false, false, false, false], width: 100, height: 25, margin: [0, 8, 0, 0] },
                { text: 'Project Summary Sheet', style: 'headerCenter', alignment: 'center', border: [false, false, false, false], margin: [0, 8, 0, 0] },
                { image: this.headerImageRight, alignment: 'right', border: [false, false, false, false], width: 50, margin: [0, 8, 0, 0] }
              ],
              [{ text: 'This quote is for budgetary purposes only. For actual quote please contact DFR', colSpan: 3, alignment: 'center', fontSize: 8, color: 'red', border: [false, false, false, true] }]
            ]

          },
        }
      ]

    };

    //  footer code
    const footer = function (currentPage: any, pageCount: any) {
      return {
        style: 'footer',
        table: {
          widths: ['auto', '*', '*'],
          body: [
            [
              { text: 'For Terms and conditions', alignment: 'left', border: [false, true, false, false], margin: [0, 0, 0, 0] },
              { text: 'click here', link: 'https://www.hillphoenix.com/terms-and-conditions/', style: 'footerLink', alignment: 'left', border: [false, true, false, false], decoration: 'underline' },
              { text: `Page ${currentPage} of ${pageCount}`, alignment: 'right', border: [false, true, false, false] },
            ],
            [{ text: 'This quote is for budgetary purposes only. For actual quote please contact DFR', colSpan: 3, alignment: 'center', fontSize: 8, color: 'red', border: [false, false, false, false] }],
          ],
        },
      };
    };


    // Main PDf code
    const docDefinition = {
      pageSize: pageSize,
      pageMargins: pageMargins,
      header,
      content: [
        // First table

        {
          style: 'tableExample1',
          table: {
            widths: ['*', '*', '*'],
            body: [
              [{ text: 'Sold To', border: [false, false, false, false], bold: true }, { text: 'Project Name', border: [false, false, false, false], bold: true }, { text: 'Budgetary Pricing', color: '#fff', fillColor: '#1d3d7c', fontSize: 10, bold: true, border: [true, true, true, true], alignment: 'center' }],
              [{ text: this.soldToAddress ? this.soldToAddress : '', fontSize: 8, border: [false, false, false, false] },
              { text: this.proposalResult?.Opportunity?.Name ? this.proposalResult.Opportunity.Name : '', fontSize: 8, border: [false, false, false, false] },
              { text: option == 1 ? this.formatCurrency(this.proposalResult?.Opportunity?.Quote.TotalAmount) : '*****', fontSize: 10, border: [true, true, true, true], bold: true, alignment: 'center' }],
            ]
          }
        },

        // Second table
        {
          style: 'tableStructure',
          table: {
            widths: ['*', '*', '*'],
            body: [
              [{ text: 'Account Number', border: [false, false, false, false], bold: true }, { text: 'Site Address', border: [false, false, false, false], bold: true }, { text: '', border: [false, false, false, false] }],
              [{ text: this.proposalResult?.Opportunity?.Account.AccountNumber ? this.proposalResult.Opportunity.Account.AccountNumber : '', fontSize: 8, color: '', border: [false, false, false, false] },
              { text: this.shippedToAddress, fontSize: 8, color: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] }],
            ]
          }
        },

        // Third table

        {
          style: 'tableStructure',
          table: {
            widths: ['*', '*', '*', '*', '*'],
            body: [
              [{ text: 'Salesperson', style: 'tableHeader', alignment: 'center' }, { text: 'Revision Date', style: 'tableHeader', alignment: 'center' }, { text: 'Revision Expiration Date', style: 'tableHeader', alignment: 'center' }, { text: 'Payment Terms', style: 'tableHeader', alignment: 'center' }, { text: 'Ship by', style: 'tableHeader', alignment: 'center' }],
              [{ text: this.proposalResult?.Opportunity?.Owner.Name ? this.proposalResult.Opportunity.Owner.Name : '', alignment: 'center' }, { text: this.convertDate(this.proposalResult?.Opportunity?.Quote.CreatedDate), alignment: 'center' }, { text: this.convertDate(this.proposalResult?.Opportunity?.Quote.ExpirationDate), alignment: 'center' }, { text: 'Net 30', alignment: 'center' }, { text: 'FOB', alignment: 'center' }]
            ]
          }
        },

        // Fourth table
        {
          style: 'tableStructure',
          table: {
            widths: ['auto', '*'],
            body: [
              [{ text: 'Budgetary Quote Number', style: 'tableHeader' }, { text: this.proposalResult?.Opportunity?.Quote?.BudgetaryNumber ? this.proposalResult.Opportunity.Quote.BudgetaryNumber : '', style: 'verticalRow' }],
              [{ text: 'Division', style: 'tableHeader' }, { text: 'Systems', style: 'verticalRow' }],
              [{ text: 'Comments or Special Instructions', style: 'tableHeader' }, { text: this.proposalResult?.Opportunity?.Quote?.Note ? this.proposalResult.Opportunity.Quote.Note : '', style: 'verticalRow' }]
            ]
          }
        },

        // Fifth table
        {
          style: 'tableStructure',
          table: {
            widths: ['*', '*', '*'],
            body: [
              [{ text: 'Rack Name', style: 'tableHeader' }, { text: 'Model', style: 'tableHeader' }, { text: 'Amount', style: 'tableHeader' }],
              ...this.tableRackData.map((rack: any) => [
                [{ text: rack?.Name ? rack.Name : '' }],
                [{ text: rack?.ProductId ? rack.ProductId : '' }],
                [{ text: option == 1 ? this.formatCurrency(rack.TotalSellingPrice) : '*****' }],
              ]),
              [{
                colSpan: 2,
                fillColor: '#1d3d7c',
                text: [
                  { text: 'Budgetary Pricing', bold: true, color: '#fff', fontSize: 8 },
                  { text: '(rack-only pricing does not include gas cooler,evaporators,', color: '#fff', fontSize: 8 },
                  { text: 'auxiliary control panels, loose parts,', color: '#FFFF00', fontSize: 8 },
                  { text: 'etc.', color: '#fff', fontSize: 8 }
                ]
              }, {}, option == 1 ? this.formatCurrency(this.proposalResult?.Opportunity?.Quote.TotalAmount) : '*****']
            ]
          }
        },

        // Sixth table
        {
          style: 'tableStructure',
          color: '#444',
          table: {
            widths: ['*', '*'],
            headerRows: 2,
            body: [
              [{ text: 'Design Criteria', style: 'tableHeader', colSpan: 2 }, {}],
              ...this.tableDesigCriteria,  // dynamically coming

            ]
          }
        },

        { text: 'Detailed Racks/Model Information', pageBreak: 'before', bold: true, fontSize: 10 },

        // 5th table
        {
          style: 'tableStructure',
          table: {
            widths: ['100%'],
            body: [
              [{ text: 'Racks Details', style: 'rackHeader' }],
              ...this.tableRackData.map((rack: any) => [
                // [{ text: rack.Name }],
                [
                  [{ text: 'Rack Name: ' + rack.Name, style: '', fontSize: 12, bold: true }],
                  {
                    table: {
                      widths: ['30%', '70%'],
                      // heights: [20, 20],
                      body: [
                        [{ text: 'Model Information', style: 'rackHeader', colSpan: 2 }, {}],
                        ...rack.ModelInformation.map((entry: any) => [
                          entry.Name,
                          this.appendUnitIfPresent(entry),
                        ]),

                        // Rack design Criteria
                        [{ text: 'Rack Design Criteria', style: 'rackHeader', colSpan: 2 }, {}],
                        ...rack.RackCriteria.map((entry: any) => [
                          entry.Name,
                          this.appendUnitIfPresent(entry),
                        ]),

                        // Gas Cooler Details
                        ...(rack.GasCoolerCondenser && rack.GasCoolerCondenser.length > 0 ?
                          [
                            [{ text: 'Gas Cooler Details' + '(' + rack.Name + ')', style: 'rackHeader', colSpan: 2 }, {}],
                            ...rack.GasCoolerCondenser.map((entry: any) => [
                              entry.Name,
                              this.appendUnitIfPresent(entry),
                            ]),
                          ] : []
                        ),

                        // Condensing Unit Only section
                        ...(rack.CondensingUnit && rack.CondensingUnit.length > 0 ?
                          [
                            [{ text: 'Condensing Unit Only section', style: 'rackHeader', colSpan: 2 }, {}],
                            ...rack.CondensingUnit.map((entry: any) => [
                              entry.Name,
                              this.appendUnitIfPresent(entry),
                            ]),
                          ] : []
                        ),

                        // Split System 
                        ...(rack.SplitSystemModel && rack.SplitSystemModel.length > 0 ?
                          [
                            [{ text: 'Split System section ', style: 'rackHeader', colSpan: 2 }, {}],
                            ...rack.SplitSystemModel.map((entry: any) => [
                              entry.Name,
                              this.appendUnitIfPresent(entry),
                            ]),
                          ] : []
                        ),

                        // Unit cooler Model
                        ...(rack.UnitCoolerModel && rack.UnitCoolerModel.length > 0 ?
                          [
                            [{ text: 'Unit Cooler Model Section', style: 'rackHeader', colSpan: 2 }, {}],
                            ...rack.UnitCoolerModel.map((entry: any) => [
                              entry.Name,
                              this.appendUnitIfPresent(entry),
                            ]),
                          ] : []
                        ),
                        // options heading
                        [{ text: 'Options' + '(' + rack.Name + ')', pageBreak: 'before', style: 'rackHeader', colSpan: 2 }, {}],
                        ...rack.ModelOptions.map((entry: any) => [
                          entry.Name,
                          this.appendUnitIfPresent(entry),
                        ]),
                      ]
                    },
                  },
                ]
              ]),
            ]
          },
        },
      ],
      styles,
      footer,
    };

    const pdfDoc = pdfMake.createPdf(docDefinition);

    pdfDoc.open();
  }

  getQuoteProposalData(option) {
    const actionName = option == 1 ? 'Budgetary Estimate With Price' : 'Budgetary Estimate Without Price';
    (this.cartService.getQuoteProposalData(this.QuoteId, actionName).subscribe({
      next: (response) => {
        this.proposalResult = response;
        this.getDesignCriteria(response);
        this.getRackDate(response);
        this.accountDetail(response);
        this.exportTable(option);
      },
      complete: () => {
        this.reportSelect = 'default';
      },
      error: err => {
        this.toastr.error('Error in create a Report');
      }
    })
    );
  }

  // ============Functions====================================================//

  // function for fetch data
  getDesignCriteria(result: any) {
    const designEntries = result?.Opportunity?.Quote?.DesignCriteria;
    this.tableDesigCriteria = designEntries.map((entry: { Name: any; Value: any; }) => [entry.Name, this.appendUnitIfPresent(entry)]);
  }

  // function to fetch data for rack design.
  getRackDate(result: any) {
    const quoteLineResult = result?.Opportunity?.Quote?.QuoteLines;
    this.tableRackData = quoteLineResult.sort((a, b) => a.Sequence - b.Sequence);
  }

  // function to fetch Account details.
  accountDetail(data: any) {
    this.soldToAddress =
      (data?.Opportunity?.Account?.Name ? data.Opportunity.Account.Name + '\n' : '') +
      (data?.Opportunity?.Account?.BillingStreet ? data.Opportunity.Account.BillingStreet + '\n' : '') +
      (data?.Opportunity?.Account?.BillingCity ? data.Opportunity.Account.BillingCity + ',' : '') +
      (data?.Opportunity?.Account?.BillingState ? data.Opportunity.Account.BillingState + '\n' : '') +
      (data?.Opportunity?.Account?.BillingPostalCode ? data.Opportunity.Account.BillingPostalCode : '');
    this.shippedToAddress = data?.Opportunity?.drop_ship_address_line_1__c ? data.Opportunity.drop_ship_address_line_1__c : '';
  }

  // to convert date
  convertDate(dateString: string) {
    if (dateString === null) {
      return '';
    }
    const inputDate = new Date(dateString);
    return this.datePipe.transform(inputDate, 'dd-MMM-yyyy');
  }

  formatCurrency(number) {
    let value = +number; //convert string value number
    if (value) {
      return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return 0;
    }

  }

  appendUnitIfPresent(entry) {
    let value = entry.Value;
    if (value === '' || value === 'N/A' || isUndefined(value)) {
      return 'N/A';
    }
    if (entry.Unit) {
      return `${value} ${entry.Unit}`;
    }
    return value;
  }
}
