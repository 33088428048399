import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { country } from '@cpq-app/country';
import { CartService, CpqObjectType, CpqQueryObjects } from '@cpq-app/services/cart.service';
import { Country, CpqAccounts } from '@cpq-app/tenants/Cpq.interfaces.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { filter, map, Observable, startWith, Subscription } from 'rxjs';
import { DfrAccounts } from '../../DFR.interfaces.service';


export enum ACCOUNT_PAGE_TITLES {
  ADD_ACCOUNT = 'Add New Account',
  EDIT_ACCOUNT = 'Edit Account'
}
const INITIAL_COUNTRY = 'USA';
function autocompleteObjectValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === 'string') {
      return { 'invalidAutocompleteObject': { value: control.value } }
    }
    return null  /* valid option selected */
  }
}

@Component({
  selector: 'app-create-accounts',
  templateUrl: './create-accounts.component.html',
  styleUrls: ['./create-accounts.component.scss']
})
export class CreateAccountsComponent implements OnInit, OnChanges, OnDestroy {
  ACCOUNT_SPINNER = 'account spinner'
  accountCreationForm: UntypedFormGroup;
  submitted = false;
  parentId: string = null;
  configID: string;
  accountID: string = null;
  data;
  countriesList: Country[];
  subscriptions$: Subscription[] = [];
  filteredAccounts: Observable<DfrAccounts[]>;
  selectedAccount;
  parentValue: string;
  navigationFromDashboard = false
  @Input() parent = null;
  @Input() child = null;
  @Input() availableAccounts = [];
  @Output() saveEvent = new EventEmitter();
  @Output() backEvent = new EventEmitter();


  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private cartService: CartService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (event.url.includes('new')) {
        this.navigationFromDashboard = true;
        this.getAllCountries();
        this.createAccountForm();
      }
    });
   }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  init() {
    this.parentId = this.parent;
    this.accountID = this.child;
    this.getAllCountries();
    this.createAccountForm();
  }


  getAccountNames() {
    this.spinner.show(this.ACCOUNT_SPINNER)
    this.availableAccounts = [];
    this.subscriptions$.push(this.cartService.getCpqObjects<CpqAccounts>(CpqQueryObjects.Accounts, { resolveNames: 'true' })
      .subscribe(
        (results) => {
          const accountsWithExternalId = results.filter(account => account.ExternalId);
          this.availableAccounts = [...this.availableAccounts, ...accountsWithExternalId];
          this.filteredAccounts = this.accountCreationForm.controls['accountSelection'].valueChanges.pipe(
            startWith(""),
            map(value => (typeof value === "string" ? value : value?.Name)),
            map(name => (name ? this._filter(name) : this.availableAccounts.slice()))
          );
          if (this.availableAccounts.length === 1) {
            this.accountCreationForm.controls['accountSelection'].setValue(this.availableAccounts[0]);
            this.parentValue = this.availableAccounts[0].Name;
          }
          this.spinner.hide(this.ACCOUNT_SPINNER);
          if (this.accountID != null) {
            this.updateAccountValues();
          }

        },
        err => {
          this.spinner.hide(this.ACCOUNT_SPINNER)
        }
      ));
  }


  displayFn(account?: DfrAccounts): string | undefined {
    return account ? account.Name : undefined;
  }

  private _filter(Name: string): DfrAccounts[] {
    const filterValue = Name.toLowerCase();

    return this.availableAccounts.filter(
      option => option.Name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  getAllCountries() {
    this.subscriptions$.push(this.cartService.getAllCountries().subscribe({
      next: (data: Country[]) => {
        const country = data.find(country => country.alpha3Code === INITIAL_COUNTRY);
        this.countriesList = data;
        this.countriesList.unshift(country);
      },
      error: err => {
        console.log(err);
        this.countriesList = country;
        this.countriesList.forEach((country) => {
          country.alpha3Code = country.sortname;
        });
      }
    }));
  }

  createAccountForm() {
    const zipCode: RegExp = new RegExp(/^[a-z0-9]+([-_\s]{1}[a-z0-9]+)*$/i);
    const nonWhiteSpaceRegExp: RegExp = new RegExp('\\S');
    this.accountCreationForm = this.formBuilder.group({
      accountSelection: [null, [Validators.required, autocompleteObjectValidator()]],
      Id: [{ value: null, disabled: true }],
      Name: [null, [Validators.required, Validators.pattern(nonWhiteSpaceRegExp), Validators.maxLength(100)]],
      ExternalId: [null],
      Website: [null],
      Email__c: [null, [Validators.pattern(nonWhiteSpaceRegExp), Validators.email]],
      BillingCity: [null],
      BillingCountry: [null],
      BillingPostalCode: [null, [Validators.pattern(zipCode)]],
      BillingState: [null],
      BillingStreet: [null],
      Fax__c: [null],
      Phone__c: [null],
      ShippingCity: [null],
      ShippingCountry: [null],
      ShippingPostalCode: [null, [Validators.pattern(zipCode)]],
      ShippingState: [null],
      ShippingStreet: [null],
      Terms_Conditions__c: [null]
    });
    this.data = [];
    if (this.availableAccounts.length === 0) {
      this.getAccountNames();
    } else {
      this.filteredAccounts = this.accountCreationForm.controls['accountSelection'].valueChanges.pipe(
        startWith(""),
        map(value => (typeof value === "string" ? value : value.Name)),
        map(name => (name ? this._filter(name) : this.availableAccounts.slice()))
      );
      if (this.accountID != null) {
        this.updateAccountValues();
      }
    }


    // this.getAccountNames();
  }

  updateAccountValues() {
    this.spinner.show(this.ACCOUNT_SPINNER)
    const parent = this.availableAccounts.find(x => x.Id === this.parentId);
    this.parentValue = null;
    try {
      this.subscriptions$.push(this.cartService.getCPQObjectById<any>(CpqObjectType.Account, this.accountID).subscribe({
        next: res => {
          this.data = res;
          this.accountCreationForm.patchValue({
            accountSelection: [parent, [Validators.required, autocompleteObjectValidator()]],
            Id: this.data.Id,
            Name: this.data.Name,
            Phone__c: this.data?.Phone__c,
            Fax__c: this.data?.Fax__c,
            Email__c: this.data?.Email__c,
            Terms_Conditions__c: this.data.Terms_Conditions__c,
            Website: this.data.Website,
            ExternalId: this.data.ExternalId,

            BillingCity: this.data.BillingAddress.City,
            BillingCountry: this.data.BillingAddress.Country,
            BillingPostalCode: this.data.BillingAddress.PostalCode,
            BillingState: this.data.BillingAddress.State,
            BillingStreet: this.data.BillingAddress.Street,

            ShippingCity: this.data.ShippingAddress.City,
            ShippingCountry: this.data.ShippingAddress.Country,
            ShippingPostalCode: this.data.ShippingAddress.PostalCode,
            ShippingState: this.data.ShippingAddress.State,
            ShippingStreet: this.data.ShippingAddress.Street,
          });
          setTimeout(() => {
            this.selectedAccount = parent;
            this.parentValue = parent.Name;
            this.accountCreationForm.controls.accountSelection.disable();
          }, 100)
          this.spinner.hide(this.ACCOUNT_SPINNER);

        },

        error: err => {
          console.log(err);
          this.spinner.hide(this.ACCOUNT_SPINNER)
        }
      }));
    } catch (error) {
      console.log(error);
    }
  }


  onSubmitAccount() {
    this.spinner.show(this.ACCOUNT_SPINNER)
    try {
      this.submitted = true;
      const account = this.accountCreationForm.controls.accountSelection.value
      const obj = {
        Name: this.accountCreationForm.controls.Name.value,
        ParentAccountId: account?.Id,
        Website: this.accountCreationForm.controls.Website.value,
        Terms_Conditions__c: this.accountCreationForm.controls.Terms_Conditions__c.value,
        ShippingAddress: {
          Street: this.accountCreationForm.controls.ShippingStreet.value,
          City: this.accountCreationForm.controls.ShippingCity.value,
          State: this.accountCreationForm.controls.ShippingState.value,
          PostalCode: this.accountCreationForm.controls.ShippingPostalCode.value,
          Country: this.accountCreationForm.controls.ShippingCountry.value,
        },
        BillingAddress: {
          Street: this.accountCreationForm.controls.BillingStreet.value,
          City: this.accountCreationForm.controls.BillingCity.value,
          State: this.accountCreationForm.controls.BillingState.value,
          PostalCode: this.accountCreationForm.controls.BillingPostalCode.value,
          Country: this.accountCreationForm.controls.BillingCountry.value,
        },
        Id: this.accountCreationForm.controls.Id.value,
        Phone__c: this.accountCreationForm.controls.Phone__c.value,
        Fax__c: this.accountCreationForm.controls.Fax__c.value,
        Email__c: this.accountCreationForm.controls.Email__c.value,
      }
      if (this.accountCreationForm.valid) {
        if (this.data?.Id != null) {
          obj.ParentAccountId = this.selectedAccount.Id
          this.updateAccount(obj);
        } else {
          this.createAccount(obj);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }



  updateAccount(obj) {
    this.subscriptions$.push(this.cartService.updateAccount(this.data.Id, obj).subscribe(
      (res) => {
        this.spinner.hide(this.ACCOUNT_SPINNER)
        this.toastr.success('Account updated successfully');
        this.saveEvent.emit()
      }, err => {
        this.spinner.hide(this.ACCOUNT_SPINNER)
        this.toastr.error(
          'There is fatal error while updating', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      }
    ));
  }

  createAccount(obj) {
    this.subscriptions$.push(this.cartService.createObject(CpqObjectType.Account, obj).subscribe(
      (res) => {
        this.toastr.success('Account created successfully');
        this.spinner.hide(this.ACCOUNT_SPINNER)
        if (this.navigationFromDashboard) {
          this.router.navigate(['/']);
        } else {
          this.saveEvent.emit()
        }
      }, err => {
        this.spinner.hide(this.ACCOUNT_SPINNER)
        this.toastr.error(
          'There is fatal error while Creating New Account', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      }
    ));
  }

  copyBillingAddress(chkEvent: Event) {
    const sameAddressCopyCheckbox = chkEvent.target as HTMLInputElement;
    if (sameAddressCopyCheckbox.checked) {
      try {
        const formData = this.accountCreationForm;
        formData.controls.ShippingCity.setValue(formData.value.BillingCity);
        formData.controls.ShippingCountry.setValue(formData.value.BillingCountry);
        formData.controls.ShippingPostalCode.setValue(formData.value.BillingPostalCode);
        formData.controls.ShippingState.setValue(formData.value.BillingState);
        formData.controls.ShippingStreet.setValue(formData.value.BillingStreet);
      } catch (error) {
        console.log(error);
      }
    }
  }

  navigateToPreviousPage() {
    if (this.navigationFromDashboard) {
      this.router.navigate(['/']);
    } else {
      this.backEvent.emit();
    }
  }

  ngOnDestroy() {
    this.subscriptions$.forEach(sub => sub.unsubscribe());
  }
}
