import {
  Directive,
  ElementRef,
  Input,
  ViewContainerRef,
  TemplateRef,
} from "@angular/core";
import { ProductTypes } from "@cpq-app/tenants/DFR/DFR.interfaces.service";

@Directive({
  selector: "[temperatureVisibility]",
})
export class temperatureVisibilityDirective {
  @Input("temperatureVisibility") temperatureVisibility: any;
  productTypes = ProductTypes;
  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<ElementRef>
  ) { }

  ngOnChanges() {
    this.updateVisibility();
  }

  private updateVisibility() {
     this.viewContainerRef.clear();
    if (this.temperatureVisibility?.modelType === this.productTypes.Co2Model) {
      if (
        this.temperatureVisibility?.ltValue === 0 &&
        this.temperatureVisibility?.mtValue === undefined
      ) {
        this.viewContainerRef.clear();
      } else if (
        this.temperatureVisibility?.mtValue === 0 &&
        this.temperatureVisibility?.ltValue === undefined
      ) {
        this.viewContainerRef.clear();
      } else{
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    } else {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
