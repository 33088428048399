<div class="footer">
    <div class="bottom-footer">
        <div>
            <div class="links-text">
                <a href="https://www.hillphoenix.com/resource/dealer-group/" target="_blank"
                   class="links-text">Dealer Group</a> <span class="separator">|</span>
                <a href="https://www.hillphoenix.com/company/contact-us/" target="_blank"  class="links-text">Contact Us</a> <span class="separator">|</span>
                <a href="https://newsite.hillstaging.com/privacy-policy-2/" target="_blank"  class="links-text">Privacy Policy</a><span class="separator">|</span>
                <a href="https://hillrmtstoragedev.blob.core.windows.net/hilldrive/Resources/Products/Resources/Warranty%20or%20Authorization%20Procedure/Hill-Phoenix-Warranty-New-toll-Free.pdf"
                    target="_blank"  class="links-text">Terms & Conditions</a> <span class="separator">|</span>
                <a href="https://performancemanager.successfactors.eu/sf/start?_s.crb=Iz4O9%252bM8kGVvOOlm69rdhv7X5bXgVE3Rixzgmx37LFk%253d"
                    target="_blank" class="links-text">HR Central</a> <span class="separator">|</span>
                <a href="https://newsite.hillstaging.com/wp-content/uploads/2022/05/Transparency-in-Supply-Chains-Disclosure-2021-HPX.pdf"
                    target="_blank"  class="links-text">Transparency in Supply
                    Chains Disclosure</a>
            </div>
            <div class="justify-content-center">
                <div class="address-section justify-content-start">2016 Gees Mill Road Conyers, GA 30013 770-285-3264
                    (office) 770-285-3080 (fax)</div>
            </div>
            <div class="justify-content-center">
                <div class="address-section justify-content-start">© Hillphoenix, A Dover Company, all rights reserved
                </div>
            </div>
            <div class="justify-content-center">
                <div class="address-section justify-content-start">In North Carolina, any engineering for projects is
                    contracted to properly licensed firms and
                    individuals.</div>
            </div>
        </div>
    </div>