<nav class="navbar navbar-expand-lg administration-header body-section">
	<div>
		<a class="navbar-brand mt-1" [routerLink]="['/']">
			<img src="../assets/images/dfr/dfr_logo.png" alt="logo" class="opcoLogo" />
		</a>
	</div>
	<button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
		aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
		<a style="cursor: pointer;" class="icon">
			<i class="fa fa-bars"></i>
		</a>
	</button>
	<div class="collapse navbar-collapse" id="navbarSupportedContent">
		<ul class="navbar-nav">
			<li class="nav-item dropdown ml-3">
				<a class="nav-link" [href]="cxUrl">CX Portal</a>
			</li>
			<ng-container *ngFor="let menuItem of headerData['menuItems']">
				<!-- With Submenus -->
				<ng-container *ngIf="menuItem['subMenus'].length > 0; else noSubMenus">
					<li class="nav-item dropdown ml-3">
						<a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown"
							aria-haspopup="true" aria-expanded="false">{{ menuItem["name"] }}<i class="arrow down"></i>
						</a>
						<ul class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
							<ng-container *ngFor="let submenu of menuItem['subMenus']">
								<li *ngIf="submenu['subMenus'].length > 0; else elseBlock1">
									<button class="dropdown-item">{{submenu["name"]}}</button>
								</li>
								<ng-template #elseBlock1>
									<li><button class="dropdown-item"
											[routerLink]="[submenu['routerLink']]">{{submenu["name"] }}</button></li>
								</ng-template>
								<ng-container *ngIf="submenu['subMenus'].length > 0">
									<ng-container *ngFor="let submenu1 of submenu['subMenus']">
										<li>
											<button class="dropdown-item" [routerLink]="[submenu1['routerLink']]"
												style="font-size: 0.9rem;">
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ submenu1["name"] }}
											</button>
										</li>
									</ng-container>
								</ng-container>
							</ng-container>
						</ul>
					</li>
				</ng-container>
				<!-- With Submenus / -->
				<!-- Without Submenu -->
				<ng-template #noSubMenus>
					<li class="nav-item ml-3">
						<button class="nav-link">{{ menuItem["name"] }}</button>
					</li>
				</ng-template>
				<!-- Without Submenu / -->
			</ng-container>
			<!-- User -->
			<li class="nav-item dropdown ml-3" *ngIf="this.userName !== ''">
				<div *ngIf="headerData['persona']===ADMIN_ROLE">
					<a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">{{ this.userName }}
						<i class="arrow down"></i>
					</a>
					<div class="dropdown-menu dropdown-menu-right">
						<button class="dropdown-item" (click)="openUserProfile()">Profile</button>
						<!-- <button class="dropdown-item" (click)="changePassword()">Change Password</button> -->
						<button class="dropdown-item" (click)="signOut()">logout</button>
						<button *ngIf="headerData['persona'] === 'DistributorAdmin'" class="dropdown-item"
							(click)="openDistributorProfile()">Distributor Profile</button>
					</div>
				</div>
				<div *ngIf="headerData['persona']!==ADMIN_ROLE" class="nav-link dropdown-toggle non-admin">
					<a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">{{ this.userName }}
						<i class="arrow down"></i>
					</a>
					<div class="dropdown-menu dropdown-menu-right">
						<button class="dropdown-item" (click)="signOut()">logout</button>
					</div>
				</div>
			</li>
			<!-- User / -->
		</ul>
	</div>
</nav>