import { OnInit, Directive, ViewContainerRef, TemplateRef, OnDestroy, ElementRef } from "@angular/core";
import { Subscription } from 'rxjs';
import { UsersService } from '@cpq-app/adminstration/users/users.service';


@Directive({
  selector: '[HidePrice]'
})
export class HidePriceDirective implements OnInit, OnDestroy {

  priceViewSubscription: Subscription;

  constructor(
    private userService: UsersService,
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<ElementRef>,
  ) { }

  public ngOnInit() {
    this.subscribeToPriceView();
  }

  subscribeToPriceView() {
    this.priceViewSubscription = this.userService.getAccessToPrice()
      .subscribe({
        next: isActive => {
          (isActive) ? this.viewContainerRef.clear() : this.viewContainerRef.createEmbeddedView(this.templateRef);
        },
        complete: () => this.subscribeToPriceView(),
      });
  }

  public ngOnDestroy(): void {
    this.priceViewSubscription?.unsubscribe();
  }

}
