<div class="project-archive mt-5">
    <div *ngIf="navigationFromDashboard" class="sub-header side-gutter mb-3">Add New Account</div>
    <div [ngClass]="{'side-gutter' : navigationFromDashboard}">
        
        <div [formGroup]="accountCreationForm">
            <ngx-spinner class="spinner-attributes" bdOpacity=3 bdColor="rgba(0, 0, 0, 0.3)" size="medium"
                color="#4054B2" type="line-scale-party" [fullScreen]="false" [name]="ACCOUNT_SPINNER">
                <p class="login-spinner spinner mt-3">Please wait...</p>
            </ngx-spinner>
            <div class="vws-header mb-4 mt-3">
                <span class="float-right mr-3">
                    <span class="mr-4" *ngIf="accountID === null">
                        <button type="button" class="dfr-btn-dark" (click)="navigateToPreviousPage()">Back</button>
                    </span>
                    <span>
                        <button type="submit" [ngClass]="{'save-disable':accountCreationForm?.invalid}"
                            class="dfr-btn-dark" (click)="onSubmitAccount()">Save</button>
                    </span>
                </span>
            </div>
            <div class="account-block">
                <div class="basic-details">
                    <div class="mb-3"><span class="sub-header">Basic Details</span></div>
                    <div class="same-row-controls">
                        <div class="required child-control">
                            <label for="accountSelection" class="form-labels f-label mr-3">Sold to Customer / Parent
                                Account<span class="required-text ml-1">(Required)</span><i
                                    class='fa fa-info-circle ml-2 search-info'
                                    ngbTooltip="Select sold to customer"></i></label>
                        </div>
                    </div>
                    <div class="same-row-controls">
                        <div class="required child-control">
                            <mat-form-field class="custom-class-account example-full-width w-100" appearance="outline">
                                <input  type="text" matInput formControlName="accountSelection" [matAutocomplete]="auto"
                                    [value]="parentValue" placeholder="Select sold to customer"
                                    [ngClass]="{ 'is-invalid':  (accountCreationForm.controls['accountSelection'].dirty || accountCreationForm.controls['accountSelection'].touched || submitted) && accountCreationForm.controls['accountSelection'].errors }">
                                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                    <mat-option *ngFor="let option of filteredAccounts | async" [value]="option">
                                        {{option.Name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <mat-error
                                *ngIf="accountCreationForm.controls['accountSelection'].invalid && (accountCreationForm.controls['accountSelection'].dirty || accountCreationForm.controls['accountSelection'].touched || submitted)"
                                class="invalid-feedback">
                                <div *ngIf="accountCreationForm.controls['accountSelection'].errors?.required">
                                    <span>Account Name is
                                        required</span>
                                </div>
                                <div *ngIf="accountCreationForm.controls['accountSelection'].errors.pattern">
                                    Account Name must be a character</div>
                                <div *ngIf="accountCreationForm.controls['accountSelection'].errors.maxlength">
                                    Account Name must
                                    contain
                                    max
                                    of
                                    100 characters</div>
                                <div
                                    *ngIf="!accountCreationForm.controls['accountSelection'].errors.required && 
                                                            !accountCreationForm.controls['accountSelection'].errors.pattern && 
                                                            accountCreationForm.controls['accountSelection'].errors.invalidAutocompleteObject">
                                    Account Name not recognized. Click one of the autocomplete options.</div>
                            </mat-error>
                        </div>
                    </div>
                    <div class="same-row-controls mt-1">
                        <div class="form-group required child-control">
                            <label for="Name" class="control-label">Account Name <span
                                    class="required-text ml-1">(Required)</span><i
                                    class='fa fa-info-circle ml-2 search-info'
                                    ngbTooltip="Enter account name"></i></label>
                            <input type="text" class="form-control" id="Name" formControlName="Name" placeholder=""
                                [ngClass]="{ 'is-invalid':  (accountCreationForm.controls['Name'].dirty || accountCreationForm.controls['Name'].touched || submitted) && accountCreationForm.controls['Name'].errors }">
                            <div *ngIf="accountCreationForm.controls['Name'].invalid && (accountCreationForm.controls['Name'].dirty || accountCreationForm.controls['Name'].touched || submitted)"
                                class="invalid-feedback">
                                <div *ngIf="accountCreationForm.controls['Name'].errors.required">Account Name is
                                    required</div>
                                <div *ngIf="accountCreationForm.controls['Name'].errors.pattern">
                                    Account Name must be a character</div>
                                <div *ngIf="accountCreationForm.controls['Name'].errors.maxlength">Account Name must
                                    contain max
                                    of
                                    100 characters</div>
                            </div>
                        </div>
                        <div class="form-group child-control">
                            <label for="Phone">Phone</label>
                            <input type="text" class="form-control" id="Phone" formControlName="Phone__c"
                                placeholder="">
                        </div>

                    </div>
                    <div></div>
                    <div class="same-row-controls">
                        <div class="form-group child-control">
                            <label for="Fax">Fax</label>
                            <input type="text" class="form-control" id="Fax" formControlName="Fax__c" placeholder="">
                        </div>
                        <div class="form-group child-control">
                            <label for="email">Email</label>
                            <input type="email" class="form-control" id="email" formControlName="Email__c"
                                placeholder=""
                                [ngClass]="{ 'is-invalid':  (accountCreationForm.controls['Email__c'].dirty || accountCreationForm.controls['Email__c'].touched || submitted) && accountCreationForm.controls['Email__c'].errors }">
                            <div *ngIf="accountCreationForm.controls['Email__c'].invalid && (accountCreationForm.controls['Email__c'].dirty || accountCreationForm.controls['Email__c'].touched || submitted)"
                                class="invalid-feedback">
                                <div
                                    *ngIf="( accountCreationForm.controls['Email__c'].errors.email) || accountCreationForm.controls['Email__c'].errors.pattern">
                                    Please enter a valid email
                                </div>

                            </div>
                        </div>
                        <div class="form-group child-control">
                            <label for="Website">Website</label>
                            <input type="text" class="form-control" id="Website" formControlName="Website"
                                placeholder="">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="termsandConditions">Terms and Conditions</label>
                        <textarea class="form-control large-text-area" id="termsandConditions" rows="3" placeholder=""
                            formControlName="Terms_Conditions__c"></textarea>
                    </div>
                </div>
                <div class="vertical-row"></div>
                <div class="shipping-details">
                    <div class="mb-3 shipping-block">
                        <span class="shipping-header">
                            <span class="sub-header">Shipping Details</span>
                        </span>
                        <span class="same-address">
                            <input type="checkbox" id="isBillingShippingSame" (change)="copyBillingAddress($event)"
                                class="mr-1">
                            <label for="isBillingShippingSame">Same as Billing Address</label>
                        </span>
                    </div>
                    <div class="form-group">
                        <div class="same-row-controls">
                            <div class="form-group child-control">
                                <label for="BillingStreet">Billing Street</label>
                                <textarea class="form-control small-text-area" id="BillingStreet" rows="3"
                                    placeholder="" formControlName="BillingStreet"></textarea>
                            </div>
                            <div class="form-group child-control small-text-area">
                                <label for="ShippingStreet">Shipping Street</label>
                                <textarea class="form-control small-text-area" id="ShippingStreet" rows="3"
                                    placeholder="" formControlName="ShippingStreet"></textarea>
                            </div>
                        </div>
                        <div class="same-row-controls">
                            <div class="form-group child-control">
                                <label for="BillingCity">Billing City</label>
                                <input type="text" class="form-control" id="BillingCity" formControlName="BillingCity"
                                    placeholder="">

                            </div>
                            <div class="form-group child-control">
                                <label for="ShippingCity">Shipping City</label>
                                <input type="text" class="form-control" id="ShippingCity" formControlName="ShippingCity"
                                    placeholder="">
                            </div>
                        </div>
                        <div class="same-row-controls">
                            <div class="form-group child-control">
                                <label for="BillingState">Billing State</label>
                                <input type="text" class="form-control" id="BillingState" formControlName="BillingState"
                                    placeholder="">
                            </div>
                            <div class="form-group child-control">
                                <label for="ShippingState">Shipping State</label>
                                <input type="text" class="form-control" id="ShippingState"
                                    formControlName="ShippingState" placeholder="">
                            </div>
                        </div>
                        <div class="same-row-controls">
                            <div class="form-group child-control">
                                <label for="BillingPostalCode">Billing Postal Code</label>
                                <input type="text" class="form-control" id="BillingPostalCode"
                                    formControlName="BillingPostalCode" placeholder=""
                                    [ngClass]="{ 'is-invalid':  (accountCreationForm.controls['BillingPostalCode'].dirty || accountCreationForm.controls['BillingPostalCode'].touched || submitted) && accountCreationForm.controls['BillingPostalCode'].errors }">
                                <div *ngIf="accountCreationForm.controls['BillingPostalCode'].invalid && (accountCreationForm.controls['BillingPostalCode'].dirty || accountCreationForm.controls['BillingPostalCode'].touched || submitted)"
                                    class="invalid-feedback">
                                    <div *ngIf="(accountCreationForm.controls['BillingPostalCode'].errors.pattern)">
                                        Billing Postal Code is invalid </div>
                                </div>
                            </div>
                            <div class="form-group child-control">
                                <label for="ShippingPostalCode">Shipping Postal Code</label>
                                <input type="text" class="form-control" id="ShippingPostalCode"
                                    formControlName="ShippingPostalCode" placeholder=""
                                    [ngClass]="{ 'is-invalid':  (accountCreationForm.controls['ShippingPostalCode'].dirty || accountCreationForm.controls['ShippingPostalCode'].touched || submitted) && accountCreationForm.controls['ShippingPostalCode'].errors }">
                                <div *ngIf="accountCreationForm.controls['ShippingPostalCode'].invalid && (accountCreationForm.controls['ShippingPostalCode'].dirty || accountCreationForm.controls['ShippingPostalCode'].touched || submitted)"
                                    class="invalid-feedback">
                                    <div *ngIf="(accountCreationForm.controls['ShippingPostalCode'].errors.pattern)">
                                        Shipping Postal Code is invalid </div>
                                </div>
                            </div>
                        </div>
                        <div class="same-row-controls">
                            <div class="form-group child-control">
                                <label for="BillingCountry">Billing Country</label>
                                <select class="form-control" id="BillingCountry" formControlName="BillingCountry">
                                    <option value="null" disabled>select Country</option>
                                    <option *ngFor="let country of countriesList" value={{country.alpha3Code}}>
                                        {{country.name}}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group child-control">
                                <label for="ShippingCountry">Shipping Country</label>
                                <select class="form-control" id="ShippingCountry" formControlName="ShippingCountry">
                                    <option value="null" disabled>select Country</option>
                                    <option *ngFor="let country of countriesList" value={{country.alpha3Code}}>
                                        {{country.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>