import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CartService, CpqObjectType, CpqQueryObjects } from '@cpq-app/services/cart.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AmbientTempResult, DFRAclAccess, DfrCpqOpportunity, DfrCpqQuote, ProjectStatus } from '../DFR.interfaces.service';
import { DatePipe } from '@angular/common';
import { SharedService } from '@cpq-app/shared/services/shared.service';
import { EnergyCalculation } from '@cpq-app/shared/services/energy-calculation.service';
import { SalesforceProxyService, sfdcObjectType } from '@cpq-app/services/salesforce.service';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OpenProjectComponent } from '../open-project/open-project.component';
import { CpqOperations } from '../cpqOperations.service';

const DATE_FMT = 'yyyy-MM-dd';
@Component({
  selector: 'app-project-details-component',
  templateUrl: './project-details-component.component.html',
  styleUrls: ['./project-details-component.component.scss']
})
export class ProjectDetailsComponentComponent implements OnInit, OnChanges, OnDestroy {
  createProjectForm: FormGroup;
  @Input() projectId: string;
  @Input() revisionCount: number;
  @Input() isPrimaryChanged: boolean;
  @Input() statusComplete: boolean;
  submitted = false;
  subscription$: Subscription[] = [];
  projectName: string;
  hasRevisionRecords: boolean;
  displayEditProject = false;
  projects: DfrCpqOpportunity[] = [];
  PROJECT_SPINNER = 'project spinner'
  accountName: string;
  isInternalUser: boolean;
  project: DfrCpqOpportunity;
  @Input() zipCodeRequired: false;
  @ViewChild('zipCode', { read: ElementRef })
  zipCode: ElementRef<HTMLElement>;
  @Output() onZipCode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onProjectStatusChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onUnitChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  projectStatus = ProjectStatus;
  expirationClass: string;
  expirationText: string;
  showProjectExpirationStatus: boolean;
  productsInRevisions = [];


  constructor(
    private formBuilder: FormBuilder,
    private cartService: CartService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private energyService: EnergyCalculation,
    private salesforce: SalesforceProxyService,
    private datePipe: DatePipe,
    private sharedService: SharedService,
    private userService: UsersService,
    private dfrCpqOperations: CpqOperations,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.initialFormData();
    this.editFormInitialData();
    this.isInternalUser = this.userService.checkLoggedInUserIsInternalUser();
    this.subscription$.push(this.cartService.revisionCountUnderJob$.subscribe({
      next: (value) => { this.hasRevisionRecords = value; }
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.projectId) {
      this.projectId = changes.projectId.currentValue;
    }
    if (changes.revisionCount) {
      this.revisionCount = changes.revisionCount.currentValue;
    }
    // this.editFormInitialData();
    if (this.statusComplete) {
      this.createProjectForm.controls.Status__c.setValue('Completed');
      this.onUpdateProject();
    }
  }

  initialFormData() {
    const nonWhiteSpaceRegExp: RegExp = new RegExp('\\S');
    const zipcodePattern = '^[a-zA-Z0-9_-\\s]{5,10}$';
    this.createProjectForm = this.formBuilder.group({
      Name: [null, [Validators.required, Validators.pattern(nonWhiteSpaceRegExp), Validators.maxLength(120)]],
      Owner: [{ value: null, disabled: true }],
      ModifiedTime: [{ value: null, disabled: true }],
      CreatedDate: [{ value: null, disabled: true }],
      Status__c: [null],
      Zip_Code__c: [null, [Validators.pattern(zipcodePattern)]],
      drop_ship_address_line_1__c: [null, [Validators.required]],
      Ambient_Temp__c: [null],
      application__c: [null],
      storeType__c: [null],
      latitude__c: [null],
      longitude__c: [null],
      stationName__c: [null],
      UnitType: [null]
    });
  }

  editFormInitialData() {
    this.subscription$.push(this.cartService.getCpqObjects<DfrCpqOpportunity>(CpqQueryObjects.Opportunities, {
      Id: this.projectId, addonFields: DfrCpqOpportunity.addOnFields.join(', '), resolveNames: 'true'
    })
      .subscribe({
        next: (opportunity: any) => {
          this.project = opportunity;
          this.projectName = opportunity?.Name;
          sessionStorage.setItem('unitType', opportunity?.UnitType);
          this.expirationClass = this.dfrCpqOperations.returnExpireClass(this.project);
          this.expirationText = this.dfrCpqOperations.returnTitleText(this.project);
          this.showProjectExpirationStatus = opportunity?.PrimaryQuote?.ProductsCount > 0;
          let productCount = [];
          // this.productsInRevisions = this.getProductsAssociatedWithRevision(opportunity?.PrimaryQuoteId?.Id);
          this.createProjectForm.controls.Name.setValue(opportunity?.Name);
          this.accountName = opportunity?.Account?.Name;
          this.createProjectForm.controls.Owner.setValue(opportunity?.Owner?.Name);
          this.createProjectForm.controls.ModifiedTime.setValue(this.datePipe.transform(opportunity?.ModifiedTime, DATE_FMT));
          this.createProjectForm.controls.CreatedDate.setValue(this.datePipe.transform(opportunity?.CreatedTime, DATE_FMT));
          this.createProjectForm.controls.Status__c.setValue(opportunity?.Status__c);
          this.createProjectForm.controls.UnitType.setValue(opportunity?.UnitType);  // new added for unit measurement
          this.createProjectForm.controls.Zip_Code__c.setValue(opportunity?.Zip_Code__c ? opportunity?.Zip_Code__c : '0');
          this.createProjectForm.controls.drop_ship_address_line_1__c.setValue(opportunity?.drop_ship_address_line_1__c);
          this.createProjectForm.controls.application__c.setValue(opportunity?.application__c);
          this.createProjectForm.controls.storeType__c.setValue(opportunity?.storeType__c);
          this.createProjectForm.controls.Ambient_Temp__c.setValue(opportunity?.Ambient_Temp__c);
          this.createProjectForm.controls.latitude__c.setValue(opportunity?.latitude__c ?? 0);
          this.createProjectForm.controls.longitude__c.setValue(opportunity?.longitude__c ?? 0);
          this.createProjectForm.controls.stationName__c.setValue(opportunity?.stationName__c ? opportunity?.stationName__c : 'NA');
          (this.createProjectForm.controls.Zip_Code__c.value === '') ? this.onZipCode.emit(true) : this.onZipCode.emit(false);
        },
        error: err => {
          this.toastr.error(
            'There is fatal error while fetching revision', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      }));
  }

  getControlValue(type: string) {
    return this.createProjectForm.controls[type].value;
  }

  getUnitSymbol(): string {
    if (this.createProjectForm.controls?.UnitType.value) {
      return this.createProjectForm.controls?.UnitType.value === 'metric' ? '°C' : '°F';
    }
  }


  onUpdateProject() {
    this.spinner.show(this.PROJECT_SPINNER);
    const formGroup = this.sharedService.trimFormFieldValues(this.createProjectForm);
    const formValues = formGroup.value;

    this.subscription$.push(this.cartService.updateObjectById(CpqObjectType.Opportunity, this.projectId, formValues).subscribe(
      (res) => {
        this.spinner.hide(this.PROJECT_SPINNER);
        this.displayEditProject = false;
        this.toastr.success(
          'Project Updated Successfully'
        );
        //this.updateOppInSFDC(this.createProjectForm.value.Name);
        this.onProjectStatusChange.emit(false);

      }, (err) => {
        this.spinner.hide(this.PROJECT_SPINNER);
        this.toastr.error(
          'There is fatal error while Updating Project', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      }));
  }

  onUpdateUnit() {
    this.spinner.show(this.PROJECT_SPINNER);
    const formGroup = this.sharedService.trimFormFieldValues(this.createProjectForm);
    const formValues = formGroup.value;
    delete formValues.Ambient_Temp__c;

    this.subscription$.push(this.cartService.updateObjectById(CpqObjectType.Opportunity, this.projectId, formValues).subscribe(
      (res) => {
        this.spinner.hide(this.PROJECT_SPINNER);
        this.toastr.success(
          'Unit Updated Successfully'
        );
        this.editFormInitialData();
        this.onUnitChange.emit(true);

      }, (err) => {
        this.spinner.hide(this.PROJECT_SPINNER);
        this.toastr.error(
          'There is fatal error while Updating Project', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      }));
  }

  updateOppInSFDC(projectName) {
    if (this.project?.ExternalId) {
      const sfdcLeadPayload = { Name: projectName };
      this.salesforce.updateSfdcObjectById(this.project?.ExternalId, sfdcObjectType.OPPORTUNITY, sfdcLeadPayload).subscribe({
        next: (lead) => {
          this.toastr.success('Project Updated Successfully');
        },
        error: err => {
          this.toastr.success('Error in updating project details');
        }
      })
    }
  }

  editProject() {
    this.displayEditProject = !this.displayEditProject;
  }

  cancelEditProject() {
    if (this.createProjectForm.controls['Name'].dirty) {
      this.createProjectForm.controls['Name'].setValue(this.project.Name);
    }
    this.displayEditProject = !this.displayEditProject;
  }

  getProjectName() {
    return this.createProjectForm.controls.Name.value;
  }

  getAmbientTemperature() {
    if (this.createProjectForm.controls['latitude__c'].invalid && this.createProjectForm.controls['longitude__c'].invalid) {
      this.onZipCode.emit(true);
      confirm('Please enter valid address.');
      this.createProjectForm.controls.Zip_Code__c.setValue(null);
      this.createProjectForm.controls.latitude__c.setValue(null);
      this.createProjectForm.controls.longitude__c.setValue(null);
      this.createProjectForm.controls.Ambient_Temp__c.setValue(0);
      return;
    }
    this.onZipCode.emit(false);
    const zipCode = this.createProjectForm.controls['Zip_Code__c'].value ? this.createProjectForm.controls['Zip_Code__c'].value : '0';
    const latitude = this.createProjectForm.controls['latitude__c'].value ? this.createProjectForm.controls['latitude__c'].value : 0;
    const longitude = this.createProjectForm.controls['longitude__c'].value ? this.createProjectForm.controls['longitude__c'].value : 0;
    this.createProjectForm.patchValue({ 'Zip_Code__c': zipCode });
    this.createProjectForm.patchValue({ 'latitude__c': latitude });
    this.createProjectForm.patchValue({ 'longitude__c': longitude });
    this.energyService.getAmbientTemperature(zipCode, latitude, longitude).subscribe(
      (res: AmbientTempResult) => {
        console.log('ambient temp', res);
        if (res) {
          this.createProjectForm.patchValue({ 'Ambient_Temp__c': res.ptChart.ambientTemp });
          this.updateAmbientTemperatureAndZipcodeForProject();
        }
        else {
          confirm('Please enter valid address.');
          this.onZipCode.emit(true);
          this.createProjectForm.controls.Zip_Code__c.setValue(null);
          this.createProjectForm.controls.latitude__c.setValue(null);
          this.createProjectForm.controls.longitude__c.setValue(null);
          this.createProjectForm.controls.Ambient_Temp__c.setValue(0);
        }
      },
      err => {
        this.spinner.hide();
        this.toastr.error(
          'There was an error while starting a configuration'
        );
      }
    );
  }

  updateAmbientTemperatureAndZipcodeForProject() {
    const formGroup = this.sharedService.trimFormFieldValues(this.createProjectForm);
    const formValues = formGroup.value;
    if (this.createProjectForm.controls['Zip_Code__c'].valid && (this.createProjectForm.controls['Zip_Code__c'].dirty))
      this.subscription$.push(this.cartService.updateObjectById(CpqObjectType.Opportunity, this.projectId, formValues).subscribe(
        (res) => {
          this.spinner.hide(this.PROJECT_SPINNER);
          this.displayEditProject = false;
          this.toastr.success(
            'Project Updated Successfully'
          );
        }, (err) => {
          this.spinner.hide(this.PROJECT_SPINNER);
          this.toastr.error(
            'There is fatal error while Updating Project', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }));
  }

  // getProductsAssociatedWithRevision(quoteId: string) {
  //   this.dfrCpqOperations.getQuoteLineProducts(quoteId).subscribe({
  //     next: (quoteWithProducts: any) => {
  //       if (quoteWithProducts.QuoteLines.length > 0) {
  //         const productId = quoteWithProducts.QuoteLines[0]?.ProductId.Id;
  //         this.getQuoteProducts(productId);
  //       } else {
  //         this.productsInRevisions = [];
  //       }
  //     },
  //     error: err => {
  //       this.toastr.error('Failed to fetch data. Please contact your CPQ administrator', 'Error!', {
  //         disableTimeOut: true,
  //         closeButton: true
  //       });
  //     }
  //   });
  //   return this.productsInRevisions;

  // }
  // getQuoteProducts(productId) {
  //   this.dfrCpqOperations.getProductsAssociatedWithQuotes(productId).subscribe({
  //     next: (res) => {
  //       this.productsInRevisions = [];
  //       this.productsInRevisions = res;
  //     },
  //     error: err => {
  //       this.toastr.error(
  //         'There was an error while getting product info', 'Error', {
  //         disableTimeOut: true,
  //         closeButton: true
  //       }
  //       );
  //     }
  //   });
  // }

  openProject() {
    const instance = this.modalService.open(OpenProjectComponent, {
      // size: 'lg'
      size: 'xl'
    });
    instance.componentInstance.projectId = this.projectId;
    instance.result.then((outcome) => { this.editFormInitialData(); }, (dismiss) => {
      this.editFormInitialData();
    });

  }

  ngOnDestroy() {
    this.subscription$.forEach(sub => sub.unsubscribe());
  }
}
