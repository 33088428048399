<div class="modal-header mt-2">
  <div class="modal-title main-header pull-left">{{title}}</div>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="project-details modal-body">
  <ngx-spinner class="spinner-attributes" bdOpacity=3 bdColor="rgba(0, 0, 0, 0.3)" size="medium" color="#4054B2"
    type="line-scale-party" [fullScreen]="false" [name]="LEAD_SPINNER">
    <p class="login-spinner spinner mt-3">Please wait...</p>
  </ngx-spinner>
  <form class="pt-3" [formGroup]="leadRequestForm">
    <div class="form-group">
      <label for="commentBox">Add Comments</label>
      <textarea formControlName="Comments__c" type="text" id="commentBox"></textarea>
    </div>
    <div class="my-3">
      <button class="dfr-btn-light small-btn" (click)="submitRequestForm()">Confirm</button>
    </div>
  </form>

</div>