<h1 *ngIf="!showCustomOptions" mat-dialog-title class="alert-title">
  <mat-icon class="alert-icon">warning</mat-icon> Alert!
</h1>

<div mat-dialog-content>
  <div class="cross-icon">
    <button class="close-button" mat-dialog-close aria-label="Close">
      ✖
    </button>
  </div>
  {{ data.message }}
</div>
<div mat-dialog-actions *ngIf="!showCustomOptions">
  <button mat-button (click)="onNoClick()">No</button>
  <button mat-button color="primary" (click)="onYesClick()">Yes</button>
</div>

<div mat-dialog-actions *ngIf="showCustomOptions">
  <button mat-flat-button color="primary" (click)="onYesClick()">
    <mat-icon>arrow_back</mat-icon>&nbsp;<span class="label">{{ option2 }}</span></button>
  <button mat-flat-button color="primary" (click)="onNoClick()">
    <mat-icon>settings</mat-icon>&nbsp;<span class="label">{{ option1 }}</span></button>
</div>