<div id="cds-cad-viewer-container" class="cds-cad-viewer-container outline"></div>
<div id="cds-cad-viewer-icons-container" class="mt-3 mb-3">
    <img onclick="cds.cadViewer.setRenderMode(cds.cadViewer.MODE_SHADED)"
        src="https://www.product-config.net/cadviewer/images/icons/solid.png" />
    <img onclick="cds.cadViewer.setRenderMode(cds.cadViewer.MODE_TRANSPARENT)"
        src="https://www.product-config.net/cadviewer/images/icons/transparent.png" />
    <img onclick="cds.cadViewer.setOrientation(cds.cadViewer.VIEW_ISOMETRIC)"
        src="https://www.product-config.net/cadviewer/images/icons/camera_perspective.png" />
    <img onclick="cds.cadViewer.setOrientation(cds.cadViewer.VIEW_TOP)"
        src="https://www.product-config.net/cadviewer/images/icons/camera_top.png" />
    <img onclick="cds.cadViewer.setOrientation(cds.cadViewer.VIEW_LEFT)"
        src="https://www.product-config.net/cadviewer/images/icons/camera_left.png" />
    <img onclick="cds.cadViewer.setOrientation(cds.cadViewer.VIEW_BOTTOM)"
        src="https://www.product-config.net/cadviewer/images/icons/camera_bottom.png" />
    <img onclick="cds.cadViewer.setOrientation(cds.cadViewer.VIEW_RIGHT)"
        src="https://www.product-config.net/cadviewer/images/icons/camera_right.png" />
</div>
<div id="cds-cad-container mt-2">
    <button id="cds-cad-download-button" class="cad-button" (click)="onDownLod3D($event)">Download 3D Model</button>
    <select id="cds-cad-download-formats" class="cad-formats ml-3" [(ngModel)]="selectedFormat"></select>
    <br />
    <button id="cds-cad-view-2D-button" class="cad-button mt-2" (click)="onDownLodSunmittal($event)">
        Download submittal
    </button>
</div>