import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, retry } from 'rxjs/operators';
import { User, UserFilterResponse, UserResponse, UsersResponse } from './user.types';
import { environment } from '@cpq-environments/environment';
import { HTTP_RETRY_COUNT, Variables } from '../common.types';
import { MSALService } from '@cpq-app/shared/services/msal.service';
import { SessionVariables } from '@cpq-app/header/header.component';
import { Personas } from '@cpq-app/adminstration/users/users.service';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  // Private
  public _user: BehaviorSubject<User | null> = new BehaviorSubject(null);
  private _users: BehaviorSubject<User[] | null> = new BehaviorSubject(null);
  private _allUsers: BehaviorSubject<User[] | null> = new BehaviorSubject(null);
  private _backendUrl = environment.B2CConfigs.BackendURL;
  private _cxbackendUrl = environment.cxPortal.baseUrl;
  private customerView: BehaviorSubject<boolean>;
  private enable3d: BehaviorSubject<boolean>;

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private _msalService: MSALService) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for user
   */
  get user$(): Observable<User | null> {
    return this._user.asObservable();
  }

  /**
   * Getter for users
   */
  get users$(): Observable<User[]> {
    return this._users.asObservable();
  }

  /**
   * Getter for allusers
   */
  get allUsers$(): Observable<User[]> {
    return this._allUsers.asObservable();
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get users by filter
   */
  getUsersByFilter(filterData): Observable<UserFilterResponse> {
    const url = `${this._backendUrl}/users/filter`;
    return this._httpClient.post<UserFilterResponse>(url, filterData).pipe(
      retry(HTTP_RETRY_COUNT),
      tap((response) => {
        this._users.next(response.data);
      }),
      catchError(this.handleError)
    );
  }

  /**
   * Get all users
   */
  getAllUsers(): Observable<UsersResponse> {
    const url = `${this._backendUrl}/users/all`;
    return this._httpClient.get<UsersResponse>(url).pipe(
      retry(HTTP_RETRY_COUNT),
      tap((response) => {
        this._allUsers.next(response.data);
      }),
      catchError(this.handleError)
    );
  }

  getUserByOId(oid: string) {
    const url = `${this._backendUrl}/users/oid/${oid}`;
    return this._httpClient.get<UserResponse>(url).pipe(
      retry(HTTP_RETRY_COUNT),
      catchError(this.handleError)
    );
  }

  getUserById(id: string) {
    const url = `${this._backendUrl}/users/getUserById/${id}`;
    return this._httpClient.get<UserResponse>(url).pipe(
      retry(HTTP_RETRY_COUNT),
      catchError(this.handleError)
    );
  }

  createUser(user: User) {
    const url = `${this._backendUrl}/users/createUser`;
    return this._httpClient.post<UserResponse>(url, user).pipe(
      retry(HTTP_RETRY_COUNT),
      catchError(this.handleError)
    );
  }

  deleteUser(id: string) {
    const url = `${this._backendUrl}/users/deleteUserById/${id}`;
    return this._httpClient.delete<UserResponse>(url).pipe(
      retry(HTTP_RETRY_COUNT),
      catchError(this.handleError)
    );
  }

  editUser(id: string, user) {
    const url = `${this._backendUrl}/users/updateUserById/${id}`;
    return this._httpClient.put<UserResponse>(url, user).pipe(
      retry(HTTP_RETRY_COUNT),
      catchError(this.handleError)
    );
  }

  editProfile(user) {
    const url = `${this._backendUrl}/users/profile`;
    return this._httpClient.put<UsersResponse>(url, user).pipe(
      retry(HTTP_RETRY_COUNT),
      catchError(this.handleError)
    );
  }

  updatePrivacyTerms(data) {
    const url = `${this._backendUrl}/users/updatePrivacyStatus`;
    return this._httpClient.put<UserResponse>(url, data).pipe(
      retry(HTTP_RETRY_COUNT),
      catchError(this.handleError)
    );
  }

  getUserByUsernameFromUI(username: string) {
    let opcoName = this._msalService.getTenantName();
    if (opcoName === 'DFRUAT') {
      opcoName = 'dfr';
    }
    const url = `${this._cxbackendUrl}/api/users/${opcoName}/get-userby-username/${username}`;
    return this._httpClient.get<UserResponse>(url).pipe(
      retry(HTTP_RETRY_COUNT),
      catchError(this.handleError)
    );
  };

  getCXPortalToken() {
    const url = `${this._backendUrl}/users/getCXPortalToken`;
    return this._httpClient.get(url).pipe(
      retry(HTTP_RETRY_COUNT),
      catchError(this.handleError)
    );
  }

  updateCpqUsernameInB2C(username: string, cpqUsername: string) {
    let opcoName = this._msalService.getTenantName();
    if (opcoName === 'DFRUAT') {
      opcoName = 'dfr';
    }
    const url = `${this._cxbackendUrl}/api/users/updateCPQUserName/${opcoName}/${username}/cpqUserName/${cpqUsername}`;
    return this._httpClient.get(url).pipe(
      retry(HTTP_RETRY_COUNT),
      catchError(this.handleError)
    );
  }

  getCustomerView(): Observable<boolean> {
    if (!this.customerView) {
      // SessionStorage only stores strings, so we must convert back to boolean
      const rawValue = sessionStorage.getItem(Variables.CUSTOMER_VIEW);
      const active = Boolean(JSON.parse(rawValue) || false).valueOf();
      this.customerView = new BehaviorSubject<boolean>(active);
    }
    return this.customerView;
  }

  get3DView(): Observable<boolean> {
    if (!this.enable3d) {
      // SessionStorage only stores strings, so we must convert back to boolean
      const rawValue = sessionStorage.getItem(Variables.DISABLE_3D) || 'false';
      const disabled = Boolean(JSON.parse(rawValue) || false).valueOf();
      this.enable3d = new BehaviorSubject<boolean>(!disabled);
    }
    return this.enable3d;
  }

  setCustomerViewStatus(isActive: boolean): void {
    // SessionStorage only stores strings
    sessionStorage.setItem(Variables.CUSTOMER_VIEW, String(isActive || false));
    this.customerView.next(isActive);
  }

  set3DViewStatus(enabled = true): void {
    // SessionStorage only stores strings
    sessionStorage.setItem(Variables.DISABLE_3D, String(!enabled));
    this.enable3d.next(enabled);
  }

  checkLoggedInUserIsDistributor(): boolean {
    return sessionStorage.getItem(Variables.USER_TYPE) === Variables.DISTRIBUTOR;
  }

  getLoggedInUserType() {
    return sessionStorage.getItem(Variables.USER_TYPE);
  }

  /**
   *  b2c apis
   */
  createUserInAD = (data) => {
    const aud = this._msalService.getAudience();
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      aud,
    });
    const url = `${this._backendUrl}/b2c/createuser`;
    return this._httpClient.post(url, data, { headers: customHttpHeader }).pipe(
      retry(HTTP_RETRY_COUNT),
      catchError(this.handleError)
    );
  }

  checkUserExits = (userName: string) => {
    const aud = this._msalService.getAudience();
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      aud,
    });
    const url = `${this._backendUrl}/b2c/checkuser/${userName}`;
    return this._httpClient.get(url, { headers: customHttpHeader }).pipe(
      retry(HTTP_RETRY_COUNT),
      catchError(this.handleError)
    );
  }

  checkAccess = (userName: any) => {
    const aud = this._msalService.getAudience();
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      aud,
    });
    const url = `${this._backendUrl}/b2c/checkaccess/Platform/${userName}`;
    return this._httpClient.get(url, { headers: customHttpHeader }).pipe(
      retry(HTTP_RETRY_COUNT),
      catchError(this.handleError)
    );
  }

  addapplication = (data) => {
    const aud = this._msalService.getAudience();
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      aud,
    });
    const url = `${this._backendUrl}/b2c/addapplication`;
    return this._httpClient.post(url, data, { headers: customHttpHeader }).pipe(
      retry(HTTP_RETRY_COUNT),
      catchError(this.handleError)
    );
  }

  deleteUserInAD = (data) => {
    const aud = this._msalService.getAudience();
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      aud,
    });
    const url = `${this._backendUrl}/b2c/deleteuser`;
    return this._httpClient.post(url, data, { headers: customHttpHeader }).pipe(
      retry(HTTP_RETRY_COUNT),
      catchError(this.handleError)
    );
  }

  checkLoggedInUserIsEorUser(): boolean {
    return sessionStorage.getItem(SessionVariables.PERSONA)?.toLowerCase() === Personas.EOR?.toLowerCase();
  }

  handleError(error) {
    console.log(error);
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error.message;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
