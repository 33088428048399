import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type DialogDataSubmitCallback<T> = (row: T) => void;


@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent implements OnInit {
  input: FormControl = new FormControl(null, [
    Validators.required
  ])
  changeCount: number = 0;
  rackName_Change: string;
  isRequired: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { callback: DialogDataSubmitCallback<any>; defaultValue: any },
    private dialogRef: MatDialogRef<EditDialogComponent>
  ) { }

  ngOnInit() {
    if (this.data.defaultValue) {
      this.input.patchValue(this.data.defaultValue);
    }
  }
  isEmptyRackName() {
    if (this.input.value === '') {
      return this.isRequired = true;
    }
    return this.isRequired =false;
  }

}
