<div class="modal-header mt-2">
    <h4 class="modal-title main-header pull-left"> Energy Consumption </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="project-details modal-body">

    <form class="pt-3">
        <!-- [formGroup]="energyEstForm"> -->
        <ngx-charts-bar-vertical
        [view]="view"
        [scheme]="colorScheme"
        [results]="single1"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        (select)="onSelect($event)">
      </ngx-charts-bar-vertical>
    </form>  

</div>