import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { LogoutService } from '@cpq-app/shared/services/logoutService';
import { MSALService } from '@cpq-app/shared/services/msal.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent implements OnInit {
    constructor( private logoutService: LogoutService,  private spinner: NgxSpinnerService) { }
    ngOnInit(): void {
      this.logout();
    }
    logout() {
      this.spinner.show();
      this.logoutService.logoutRedirectFromCXPortal();
    }
}
