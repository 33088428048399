import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appPreventSpace]'
})
export class PreventSpaceDirective {
  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === ' ' && this.el.nativeElement.value.trim() === '') {
      event.preventDefault();
    }
  }
}
