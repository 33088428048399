export class AppConstant {
  public static deviceTypes = ['Gateway', 'Leaf'];
  public static iotDeviceTypes = ['IOT Device', 'IOT Edge'];
  public static organizationTypes = ['Customer', 'Provider', 'Site'];
  public static edgeSoftwareTypes = ['Docker'];
  public static iotSoftwareTypes = ['Firmware', 'Linux Libraries', 'zip'];
  public static allowedListType = ['Range', 'List', 'Any'];
  public static alphaNumericWithoutSpaces = '^[a-zA-Z0-9]*$';
  public static alphaNumericWithHyphen = '^[a-zA-Z0-9\-]*$';
  public static namePattern = /^(?=.*[a-zA-Z0-9])[A-Za-z0-9_\-\s]{1,}$/;
  public static identifier = '^[a-zA-Z0-9_]*$';
  public static softwareTypes = ['Docker', 'Docker Module', 'Firmware', 'Linux Libraries', 'Zip'];
  public static softwareCategories = ['Bootable', 'Connector','Runtime','Simulator' ];
  public static resources = {
    GuestConfig: 'GUEST_CONFIG',
    Dashboard: 'DASHBOARD',
    Alerts: 'ALERTS',
    Reports: 'REPORTS',
    Administrator: 'ADMINISTRATOR',
    Organizations: 'ORGANIZATIONS',
    Users: 'USERS',
    UserGroups: 'USER_GROUPS',
    DeviceManagement: 'DEVICE_MANAGEMENT',
    ManageDevices: 'MANAGE_DEVICES',
    HealthMonitoring: 'HEALTH_MONITORING',
    PublishSoftware: 'PUBLISH_SOFTWARE',
    AlertSettings: 'ALERT_SETTINGS',
    Setup: 'SETUP',
    MainMenu: 'MAIN_MENU',
    ConfigureDevice: 'CONFIGURE_DEVICE',
    ConfigureSoftware: 'CONFIGURE_SOFTWARE',
    Personas: 'PERSONAS',
    DeviceManagementSetup: 'DEVICE_MANAGEMENT_SETUP',
    PermissionManagement: 'PERMISSION_MANAGEMENT',
    Roles: 'ROLES',
    Resources: 'RESOURCES',
    OrganizationLevel: 'ORGANIZATION_LEVEL',
    Actions: 'ACTIONS',
    AlertsSetup: 'ALERTS_SETUP',
    AlertGroups: 'ALERT_GROUPS',
    SeverityLevels: 'SEVERITY_LEVELS',
    AlertDefinitions: 'ALERT_DEFINITIONS',
    InvoiceState: 'INVOICE_STATE',
    InvoiceGroup: 'INVOICE_GROUP',
    AdminInvoice: 'INVOICE_ADMIN',
    AuditLogs: 'AUDIT_LOGS',
    productDefinitions: 'PRODUCT_DEFINITION',
    productGroup: 'PRODUCT_GROUP',
    productOfferings: 'PRODUCT_OFFERINGS',
    adminProductCatalog: 'PRODUCT_CATALOG_ADMIN',
    LocationTypeSetup: 'LOCATIONTYPE_SETUP',
    Location: 'LOCATION_ADMIN'
  };

  public static actions = {
    Create: 'CREATE',
    Read: 'READ',
    Update: 'UPDATE',
    Delete: 'DELETE'
  };

  public static pattern = {
    name: {
      value: /^(?=[a-zA-Z_].*)[a-zA-Z0-9_ \d'\-]+$/,
      message: '[a-z],[A-Z],[0-9],[space,_,-,\']'
    },
    alias: {
      value: /^(?=[a-zA-Z0-9_].*)[a-zA-Z0-9_\d'\-]+$/,
      message: '[a-z],[A-Z],[0-9],[_,-,\']'
    },
    identifier: {
      value: /^(?=[a-zA-Z0-9].*)[a-zA-Z0-9_\d'\-]+$/,
      message: '[a-z],[A-Z],[0-9],[_,-,\'] and it should not start with special character'
    },
  };

  public static maxLength = {
    name: 30,
    alias: 10,
    identifier: 20,
    description: 200,
  };

  public static minLength = {
    name: 2,
    alias: 2,
    identifier: 2,
    description: 2,
  };
}
