import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GuidedSellingComponent } from './guided-selling/guided-selling.component';
import { ModelSearchComponent } from './model-search/model-search.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductSelectorComponent } from './product-selector/product-selector.component';
import { ProjectDetailsComponentComponent } from './project-details-component/project-details-component.component';
import { CreateAccountsComponent } from './accounts/create-accounts/create-accounts.component';
import { ViewAccountsComponent } from './accounts/view-accounts/view-accounts.component';


export let DFRRoutes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    title:'DFR Portal'
  },
  {
    path: 'accounts',
    component: ViewAccountsComponent,
    title: 'View Account'
  },
  {
    path: 'accounts/new',
    component: CreateAccountsComponent,
    title: 'Create Account'
  },
  {
    path: 'productSelector/:projectID',
    component: ProductSelectorComponent,
    title:'Project Details'
  },
  {
    path: 'product/selection/:revisionName/:projectId/:revisionId/:configId/:ambientTemp',
    component: GuidedSellingComponent
  },
  {
    path: 'product/details/:revisionName/:projectId/:revisionId/:configId/:productId/:nodeId/:productCadId/:ambientTemp',
    component: ProductDetailComponent
  },
  {
    path: 'project/:projectID',
    component: ProjectDetailsComponentComponent,
  },
  {
    path: 'model/results/:projectId/:revisionId/:configId',
    component: ModelSearchComponent,
  }
];
