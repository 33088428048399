import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UserService } from '@cpq-app/_administration/users/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DFRProduct } from '../DFR.interfaces.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

declare const cds: any;
@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Input('product') public product: DFRProduct;
  @Output() productSelectEvent = new EventEmitter();
  @Output() productDetailEvent = new EventEmitter();
  @Output() productsCompareEvent = new EventEmitter();
  @Input() canEdit: boolean;
  @Input('compairProducts') public compairProducts: any[];
  MODEL_CARD_SPINNER = "model card info";
  isEorUser: boolean;
  KBTU = "KBTU";
  KBTUH = "KBTUH";
  mtTotalLoad;
  ltTotalLoad;
  productTypes = {
    Co2Model: "Co2Model",
    FlexModel: "FlexModel"
  }

  icons = {
    price: "monetization_on",
    energy: "energy_savings_leaf",
    redundancy: "sync",
    environment: "eco",
    installation: "settings",
    exposure: "water_damage"
  }

  product_ToolTip = {
    priceTooltip: 'How the price of this model compares to other proposed models',
    energyUsageToolTip: 'How the energy consumption of this model compares to other proposed models.',
    installationCostTooltip: 'How the installation cost of this model compares to other proposed models.',
    environmentFriendlyToolTip: 'How environmentally friendly is the model compared to other proposed models.',
    addtoRackToolTip: 'Select and add product to rack'
  }

  constructor(private spinner: NgxSpinnerService, private userService: UserService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes.product !== typeof undefined) {
      this.product = changes.product.currentValue;
    }
    if (typeof changes.canEdit !== typeof undefined) {
      this.canEdit = changes.canEdit.currentValue;
    }

    this.spinner.hide(this.MODEL_CARD_SPINNER);
  }

  ngOnInit(): void {
    this.isEorUser = this.userService.checkLoggedInUserIsEorUser();
    const uom = sessionStorage.getItem('unitType');
    if (uom === 'metric') {
      this.mtTotalLoad = cds.configurator.runtime.attributes?.["aMtTotalLoadMetric"].getValue() || 0;
      this.ltTotalLoad = cds.configurator.runtime.attributes?.["aLtTotalLoadMetric"].getValue() || 0;
    } else {
      this.mtTotalLoad = cds.configurator.runtime.attributes?.["aMtTotalLoad"].getValue() || 0;
      this.ltTotalLoad = cds.configurator.runtime.attributes?.["aLtTotalLoad"].getValue() || 0;
    }

  }


  selectProduct(product, Id) {
    this.productSelectEvent.emit(product);
  }

  showProductDetails(product) {
    this.productDetailEvent.emit(product);
  }
  onEnclosureToggle(chkEvent: Event) {
    const checkbox = chkEvent.target as HTMLInputElement;
    console.log(checkbox.checked);
  }
  selectProductToCompare(chkEventCompare: MatCheckboxChange, product) {
    if (chkEventCompare.checked) {
      if (this.compairProducts === undefined || this.compairProducts.length < 3) {
        product.addedToCompare = true;
      } else {
        alert("You cannot select more than three products.");
        chkEventCompare.source.checked = false;
      }
    } else {
      product.addedToCompare = false;
    }
    this.productsCompareEvent.emit(product);
  }
}

