
    <div class="entityContainer">
          <div class="guestcard">
                <form [formGroup]="userDetail">
                    <h4 class="sub-header">User Profile</h4>
                    <div class="row">
                        <div class="col-lg-2">
                            <div class="form-group pt-3">
                                <div class="floating-label">
                                    <input class="floating-input" formControlName="title" type="text">
                                    <span class="highlight"></span>
                                    <label>Title <span class="star">*</span></label>
                                    <div class="error position-relative"
                                      *ngIf="userDetail.get('title').touched && userDetail.get('title').hasError('required')">
                                      Title is required
                                    </div>
                                    <div class="error position-relative"
                                      *ngIf="userDetail.get('title').touched && userDetail.get('title').hasError('minlength')">
                                      Minimum of 2 characters
                                    </div>
                                    <div class="error position-relative"
                                      *ngIf="userDetail.get('title').touched && userDetail.get('title').hasError('pattern')">
                                      Only alphabets are allowed
                                    </div>
                                    <div class="error position-relative"
                                      *ngIf="userDetail.get('title').touched && userDetail.get('title').hasError('maxlength')">
                                      Maximum of 3 characters
                                    </div>
                                  </div>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="form-group pt-3">
                                <div class="floating-label">
                                    <input class="floating-input" formControlName="firstName" type="text">
                                    <span class="highlight"></span>
      
                                    <label class="pointer-events-auto">First Name <span class="star">*</span>
                                    </label>
                                    <div class="error"
                                      *ngIf="userDetail.get('firstName').touched && userDetail.get('firstName').hasError('required')">
                                      First Name is required
                                    </div>
                                    <div class="error"
                                      *ngIf="userDetail.get('firstName').touched && userDetail.get('firstName').hasError('minlength')">
                                      Minimum of 2 characters
                                    </div>
                                    <div class="error position-relative"
                                      *ngIf="userDetail.get('firstName').touched && userDetail.get('firstName').hasError('pattern')">
                                      First Name is not valid
                                    </div>
                                    <div class="error"
                                      *ngIf="userDetail.get('firstName').touched && userDetail.get('firstName').hasError('maxlength')">
                                      Maximum of 30 characters
                                    </div>
                                  </div>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="form-group pt-3">
                                <div class="floating-label">
                                    <input class="floating-input" formControlName="lastName" type="text">
                                    <span class="highlight"></span>
      
                                    <label class="pointer-events-auto">Last Name <span class="star">*</span>
                                      </label>
                                    <div class="error position-relative"
                                      *ngIf="userDetail.get('lastName').touched && userDetail.get('lastName').hasError('required')">
                                      Last Name is required
                                    </div>
                                    <div class="error position-relative"
                                      *ngIf="userDetail.get('lastName').touched && userDetail.get('lastName').hasError('minlength')">
                                      Minimum of 2 characters
                                    </div>
                                    <div class="error position-relative"
                                      *ngIf="userDetail.get('lastName').touched && userDetail.get('lastName').hasError('pattern')">
                                      Last Name is not valid
                                    </div>
                                    <div class="error position-relative"
                                      *ngIf="userDetail.get('lastName').touched && userDetail.get('lastName').hasError('maxlength')">
                                      Maximum of 30 characters
                                    </div>
                                  </div>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="form-group pt-3">
                                <div class="floating-label">
                                    <input class="floating-input text-overflow" [title]="userDetail.get('userName').value" formControlName="userName" type="text">
                                    <span class="highlight"></span>
      
                                    <label class="pointer-events-auto">User Name <span class="star">*</span>
                                      </label>
                                  </div>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="form-group pt-3">
                                <div class="floating-label">
                                    <input class="floating-input text-overflow" [title]="userDetail.get('email').value" formControlName="email" type="text">
                                    <span class="highlight"></span>
                                    <label>Email ID <span class="star">*</span></label>
                                    <div *ngIf="userDetail.get('email').touched && userDetail.get('email').errors"
                                      class="error position-relative">
                                      <div *ngIf="userDetail.get('email').errors.required">Email is required</div>
                                      <div *ngIf="userDetail.get('email').hasError('pattern')">Email must be a valid email address
                                      </div>
                                    </div>
                                  </div>
                            </div>
                        </div>
                    </div>
                    <h4 class="sub-header">Address Details</h4>
                    <div class="row">
                        <div class="col-lg-2">
                            <div class="form-group pt-3">
                                <div class="floating-label">
                                    <input class="floating-input" formControlName="address1" type="text" placeholder=" ">
                                    <span class="highlight"></span>
                                    <label>Address Line 1</label>
                                    <div class="error"
                                      *ngIf="userDetail.get('address1').touched && userDetail.get('address1').hasError('minlength') && !userDetail.get('address1').hasError('pattern')">
                                      Minimum of 2 characters
                                    </div>
                                    <div class="error"
                                      *ngIf="userDetail.get('address1').touched && userDetail.get('address1').hasError('pattern')">
                                      only alphanumeric and special characters(._,-) are allowed.
                                    </div>
      
                                  </div>
                            </div>
                        </div>
        
                        <div class="col-lg-2">
                            <div class="form-group pt-3">
                                <div class="floating-label">
                                    <input class="floating-input" formControlName="address2" type="text" placeholder=" ">
                                    <span class="highlight"></span>
                                    <label>Address Line 2</label>
                                    <div class="error position-relative"
                                      *ngIf="userDetail.get('address2').touched && userDetail.get('address2').hasError('pattern')">
                                      only alphanumeric and special characters(._,-) are allowed.
                                    </div>
                                  </div>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="form-group pt-3">
                                <div class="floating-label">
                                    <input class="floating-input" formControlName="zipcode" type="text" placeholder=" ">
                                    <span class="highlight"></span>
                                    <label class="top-9">Postal Code</label>
                                    <div class="error"
                                      *ngIf="userDetail.get('zipcode').touched && userDetail.get('zipcode').hasError('pattern')">
                                      Postal Code is not valid
                                    </div>
                                  </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-2">
                        <div class="form-group pt-3">
                            <div class="floating-label">
                                <select class="floating-select" [attr.selectValue]="countryAttrValue"
                                  (change)="onCountrySelect(userDetail.controls['country'].value)" formControlName="country">
                                  <option value=""></option>
                                  <option *ngFor=" let n of countries;let i=index" [ngValue]="n">{{n.name}}</option>
                                </select>
                                <span class="highlight"></span>
                                <label class="top-9">Country</label>
                              </div>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="form-group pt-3">
                            <div class="floating-label">
                                <select class="floating-select" [attr.selectValue]="stateAttrValue"
                                  (change)="onStateSelect(userDetail.controls['state'].value)" formControlName="state">
                                  <option value=""></option>
                                  <option [disabled]="statesList.length == 0" [ngValue]="s" *ngFor=" let s of statesList">
                                    {{s.name}}</option>
                                </select>
                                <span class="highlight"></span>
                                <label class="top-9">State or Province</label>
                              </div>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="form-group pt-3">
                            <div class="floating-label">
                              <input class="floating-input" formControlName="city" type="text" placeholder="Enter city name">
                              <span class="highlight"></span>

                              <label class="top-9">City</label>
                              <div class="error"
                                *ngIf="userDetail.get('city').touched && userDetail.get('city').hasError('minlength') && !userDetail.get('city').hasError('pattern')">
                                Minimum of 2 characters
                              </div>
                              <div class="error"
                                *ngIf="userDetail.get('city').touched && userDetail.get('city').hasError('maxlength') && !userDetail.get('city').hasError('pattern')">
                                Maximum of 30 characters allowed
                              </div>
                              <div class="error"
                                *ngIf="userDetail.get('city').touched && userDetail.get('city').hasError('pattern')">
                                Only alphabets and space are allowed
                              </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="row">
                      <button class="guestButton submit ml-4" [disabled]="!userDetail.valid" type="button" 
                        (click)="updateUser()">
                        Submit
                      </button>
                    </div>
                </form>
          </div>
    </div>
  
 
