import {
    Directive,
    ElementRef,
    Input,
    Renderer2,
    OnChanges,
    SimpleChanges,
} from "@angular/core";
import { ProductTypes } from "@cpq-app/tenants/DFR/DFR.interfaces.service";

@Directive({
    selector: "[textTransformer]",
})
export class textTransformerDirective implements OnChanges {
    constructor(private el: ElementRef, private renderer: Renderer2) { }
    productTypes = ProductTypes;


    @Input("textTransformer") textTransformer: any;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.textTransformer) {
            this.updateText();
        }
    }

    private updateText() {
        if (this.textTransformer?.modelType === this.productTypes.Co2Model) {
            this.renderer.setProperty(this.el.nativeElement, 'innerText', this.textTransformer?.text);
        }
    }
}
