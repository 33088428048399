import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { DatatableColumn } from './datatable';
import { SortableHeaderDirective, SortColumn, SortDirection, SortEvent } from './sortable.directive';
import { isEqual as lodashIsEqual } from 'lodash';

const SMALL_DEVICE_MAX_WIDTH = 1023;
const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
const UPDATE_DEBOUNCE_TIME = 125; // in mS
const DEFAULT_INDEX = 1;

interface SearchResult {
  objects: any[];
  totalCount: number;
}

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class DatatableComponent implements OnInit, OnDestroy, OnChanges {
  @Input() GridData: any[];
  @Input() showSearchBar: boolean =  true;
  @Input() ColData: DatatableColumn<any>[];
  @Input()
  get loading(): boolean { return this.showDataLoading; }
  set loading(loading: boolean) { this.showDataLoading = loading; }
  @Input() showHeader: boolean;

  totalSubscribe: Subscription;
  setupSubscribe: Subscription;

  isSmallDevice = false;
  screenWidth: number;
  filter = new FormControl('');
  hasNoData = false;
  showLoading = false;
  showDataLoading = false; 
  

  // tslint:disable: variable-name
  _loading$ = new BehaviorSubject<boolean>(true);
  _search$ = new Subject<void>();
  _objects$ = new BehaviorSubject<any[]>([]);
  _total$ = new BehaviorSubject<number>(0);
  _toRecord$ = new BehaviorSubject<number>(0);
  _fromRecord$ = new BehaviorSubject<number>(0);

  private _state = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: SortDirection.Natural,
  };
  // tslint:enable: variable-name

  constructor() {
    this.showHeader = true;
   }

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  get objects$() { return this._objects$; }
  get total$() { return this._total$; }
  get loading$() { return this._loading$; }

  get page() { return this._state.page; }
  set page(page: number) { this._set({ page }); }

  get pageSize() { return this._state.pageSize; }
  set pageSize(pageSize: number) { this._set({ pageSize }); }

  get searchTerm() { return this._state.searchTerm; }
  set searchTerm(searchTerm: string) { this._set({ searchTerm }); }

  get toRecord() { return this._toRecord$; }
  get fromRecord() { return this._fromRecord$; }

  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }



  private _set(patch) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    this.screenWidth = window.innerWidth;
    this.isSmallDevice = (this.screenWidth < SMALL_DEVICE_MAX_WIDTH) ? true : false;
  }

  ngOnChanges(changes: SimpleChanges) {
    const gridData = changes?.GridData;
    if (gridData !== undefined) {
      if (gridData?.currentValue !== undefined) {
        this._search$.next();
      }
      if (!lodashIsEqual(gridData?.currentValue, gridData.previousValue)) {
        this.showLoading = gridData?.firstChange;
      }
    }   
    if(changes['showHeader']) {
      this.showHeader = changes?.showHeader?.currentValue;
    } 

    if(changes['showSearchBar']) {
      this.showSearchBar = changes?.showSearchBar?.currentValue;
    } 
  }

  ngOnInit(): void {
    this.showLoading = true;
    this.showDataLoading = true;
    this.onResize();
    this.setupSubscription();
    this.totalSubscribe = this._total$.subscribe({
      next: total => this.calcFromToValues(total),
    });
  }

  trackByIndex(index: number): number {
    return index;
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = SortDirection.Natural;
      }
    });

    this.sortColumn = column;
    this.sortDirection = direction;
  }

  private setupSubscription() {
    this.setupSubscribe = this._search$.pipe(
      debounceTime(UPDATE_DEBOUNCE_TIME),
      tap(() => this._loading$.next(true)),
      switchMap(() => this._search<any>(this.GridData, this._state)),
      tap(() => this._loading$.next(false))
    ).subscribe({
      next: result => {
        this._objects$.next(result.objects);
        this._total$.next(result.totalCount);
        this.hasNoData = result?.totalCount === 0;
      },
      error: err => { },
      complete: () => { }
    });
  }

  private _search<T>(records: T[], state): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = state;

    // 1. sort
    let objects = this.sort(records, sortColumn, sortDirection);

    // 2. filter
    const _searchTerm = String(searchTerm).toLowerCase();
    const filterableColumns = this.ColData.filter(col => col.filterable);
    objects = objects.filter(object => filterableColumns.some(column => this.matches(object, _searchTerm, column)));

    const totalCount = objects.length;

    // 3. paginate
    objects = objects.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ objects, totalCount });
  }

  matches(object, term: string, column: DatatableColumn<any>): boolean {
    // For each searchable column, check if value includes term
    const test = String(column.value(object)).toLowerCase();
    return test?.includes(term);
  }

  sort(objects: any[], column, direction): any[] {
    if (objects && objects instanceof Array && objects.length) {
      if (direction === SortDirection.Natural || column === '') {
        return objects;
      } else {
        return [...objects].sort((a, b) => {
          const res = this.comparePredicate(column)(a, b);
          return direction === SortDirection.Ascending ? res : -res;
        });
      }
    } else {
      // returning empty array for the first time to pass through the conditions
      return [];
    }
  }

  comparePredicate(column: string): (a: any, b: any) => number {
    const colData = this.ColData.find(col => col.field === column);

    if (colData?.compare) {
      return colData.compare;

    } else {
      return (a, b) => {
        return compare(a[column], b[column]);
      };
    }
  }

  calcFromToValues(total: number): void {
    const fromRecord = (this.pageSize * this.page) - (this.pageSize - DEFAULT_INDEX);
    this._fromRecord$.next(total ? fromRecord : 0);

    const toRecord = this.pageSize * this.page;
    this._toRecord$.next(toRecord > total ? total : toRecord);
  }

  ngOnDestroy() {
    this.totalSubscribe.unsubscribe();
    this.setupSubscribe.unsubscribe();
  }

}
