import { Injectable } from '@angular/core';
import { CpqOpportunity, CpqProposal, CpqQuote, CpqQuoteline, QueryResult, QuoteWorkflowStatus } from '@cpq-app/services/cart.service';
import { CadData as BaseCadData } from '@cpq-app/services/product.service';
import { SfdcOpportunity } from '@cpq-app/services/salesforce.service';
import { CpqAccount, CpqFavorite, CpqJob } from '../Cpq.interfaces.service';


@Injectable({
    providedIn: 'root',
})
export class DFRInterfacesService {

    constructor() { }
}

export interface DFRDtJobs {
    field?: string;
    header?: string;
    visibility?: boolean;
    value: (record: DfrJobs) => any;
}
export interface DfrDtAccounts {
    field?: string;
    header?: string;
    visibility?: boolean;
    value: (record: DfrAccounts) => any;
}
export interface DfrDtRevision {
    field?: string;
    header?: string;
    visibility?: boolean;
    control?: string;
    value: (record: DfrRevisions) => any;
}

export interface DfrJobs {
    LastModifiedDate?: string;
    Description?: string;
    AccountId?: AccountId;
    Status__c?: string;
    ExternalId?: string;
    PrimaryQuoteId?: string;
    IsClosed?: boolean;
    CurrencyIsoCode?: string;
    Name?: string;
    CreatedById: CreatedById;
    OwnerId?: OwnerId;
    CreatedDate?: string;
    Id?: string;
    IsPreferred__c?: boolean;
    ModifiedTime?: string;
    Owner?: Owner;
    Source__c?: string;
    PrimaryQuote?: PrimaryQuoteDetails;
}
export interface Owner {
    Id?: string;
    Name?: string;
}
export interface AccountId {
    Id?: string;
    Name?: string;
}

export interface CreatedById {
    Id?: string;
    Name?: string;
}

export interface OwnerId {
    Id?: string;
    Name?: string;
}

export interface PrimaryQuoteDetails {
    Id?: string;
    Name?: string;
    BudgetaryNumber?: number;
}

export interface DfrAccounts {
    LastModifiedDate: string;
    OwnerId?: OwnerId;
    ExternalId?: string;
    Id: string;
    Name: string;
    AccountNumber?: string;
}

export interface DfrRevisions {
    LastModifiedDate?: string;
    ExpirationTime?: string;
    IsPrimary: boolean;
    fpRules?: FpRules;
    Id?: string;
    TotalAmount?: number;
    FormattedId?: string;
    CurrencyIsoCode?: string;
    Proposals?: Proposals;
    Name?: string;
    Description?: string;
    WorkflowStatus?: string;
    Note?: any;
    CreatedDate?: string;

}

export interface Acl {
    canEdit: boolean;
    canDelete: boolean;
}

export interface Status {
    statusLabel: string;
}

export interface Wf {
    canExport: boolean;
    canAddQuote: boolean;
    canAddProduct: boolean;
    showMoreInfo: boolean;
    canEdit: boolean;
    canMakePrimary: boolean;
    canCopy: boolean;
    canDelete: boolean;
    canAddProposal: boolean;
    canPreview: boolean;
    status: Status;
}

export interface FpRules {
    acl: Acl;
    updateAvailable: boolean;
    isDependentDataOutOfSync: boolean;
    wf: Wf;
}

export interface Proposals {
    size: number;
}

export interface IFamilies {
    addToQuote: boolean;
    description: string;
    id: string;
    imageUrl: string;
    name: string;
}

export class DfrCpqFavorite extends CpqFavorite {
    static queryFields = ['graphicUrl__c'];

    graphicUrl__c: string;
}

export class DfrFavoritesFilter {
    static filterFields = ['parentproductId', 'null'];
    filters: string;
    addonFields: string;
    sort: string;
    resolveNames: string;
}

export interface NodeConfig {
    classname: string;
    id: number;
    name: string;
    category: string;
    value?: number;
    selected: number;
    quantity: number;
    label: string;
    unitPrice: number;
    netPrice: number;
    ExtDescription: string;
    imageUrl: string;
    available?: boolean;
    disabled: number;
    nodeId?: string;
    nodePosition?: number;
    modelCode?: string;
    selectionType?: string;
    optionSummary?: string[];
    isConfigurable?: boolean;
    isCopyable?: boolean;
    isDeleteable?: boolean;
    isMoveable?: boolean;
}

export interface SubGroup {
    classname: string;
    id: number;
    name: string;
    desc: string;
    label: string;
    minimum: number;
    maximum: number;
    uiView: string;
    subGroups: any[];
    options: NodeConfig[];
}

export interface MainGroup {
    classname: string;
    id: number;
    name: string;
    desc: string;
    label: string;
    minimum: number;
    maximum: number;
    uiView: string;
    subGroups: SubGroup[];
    options: any[];
}

export interface ActiveNode {
    nodeId: string;
    classname: string;
    id: number;
    name: string;
    category: string;
    value?: any;
    selected: number;
    quantity: number;
    label: string;
    totalPrice: number;
    ExtDescription: string;
    imageUrl: string;
    disabled: number;
    discountedPrice: string;
}

export interface Groups {
    mainGroup: MainGroup;
}

export interface Result {
    groups: Groups;
    activeNode: ActiveNode;
}

export interface GropNodeConfig {
    result: Result;
}

export interface QuoteLine {
    ExtendedDescription?: string;
    LastModifiedDate?: string;
    Description?: string;
    TotalSellingPrice?: number;
    Product_Id: string;
    OrderCode: string;
    Quantity: number;
    IsSelected: boolean;
    Id: string;
    CurrencyIsoCode: string;
    Name: string;
    ProductId?: ProductId;
}

export interface ProductId {
    Id?: string;
    Name?: string;
}

export interface CadMaterial {
    color: string;
    title: string;
    type: string;
    emissive?: string;
    roughness?: number;
    metalness?: number;
    clearCoat?: number;
    clearCoatRoughness?: number;
    reflectivity?: number;
    opacity?: number;
    transparent?: boolean;
    map?: string;
    bumpMap?: string;
    bumpScale?: number;
    side?: number;
}

export interface CadScene {
    concreteLength: number;
    concreteWidth: number;
    concreteDepth: number;

    wall?: {
        length: number;
        width: number;
        height: number;
        offset: number;
    };

    trench?: {
        length: number;
        width: number;
        depth: number;
        distFromStart: number;
        distFromLeft: number;
    };
}

export interface CadLocation {
    x?: number;
    y?: number;
    z?: number;
}

export interface CadOrientation {
    x?: number;
    y?: number;
    z?: number;
}

export interface CadPartOptions {
    powder_coat?: string;
    powder_coat_secondary?: string;
}

export interface CadSelectedOptions {
    key: string;
    value: string | number | boolean;
}

export interface CadPart {
    product: string;
    nodeId: number;
    position: CadLocation;
    orientation?: CadOrientation;
    isInteractive: boolean;
    boundariesMin?: CadLocation;
    boundariesMax?: CadLocation;
    partOptions?: CadSelectedOptions[];
    selectedOptions?: CadSelectedOptions[];
    layer: string;
    partSize: number;
}

export interface CadData extends BaseCadData {
    materials?: { [key: string]: CadMaterial };
    scene: CadScene;
    parts: CadPart[];
}

export class DfrQuoteline extends CpqQuoteline {
    TotalNetPrice__c: number;
}

/**
 * Temporary placeholder class while the query vs object-fetch API is re-written. This should be removed once
 * the base class in the service is restored to match the plain type used by the new fetch methods.
 */
export class TempCpqQuote {
    static queryFields = ['Id', 'Name', 'WorkflowStatus', 'LastModifiedDate',
        'FormattedId', 'Note', 'ExpirationDate', 'CurrencyIsoCode', 'Selling_Price__c', 'IsPreferred__c',
        CpqProposal.getNestedQueryString(),
    ];

    static guestQueryFields = ['Id', 'Name', 'WorkflowStatus', 'LastModifiedDate',
        'FormattedId', 'Note', 'ExpirationDate',
        CpqProposal.getNestedQueryString(),
    ];

    // tslint:disable: variable-name
    CreatedDate: string;
    ExpirationDate: string | Date;
    FormattedId: string;
    fpRules?: FpRules;
    Id: string;
    IsPrimary: number;
    LastModifiedDate?: string;
    Name: string;
    Note: string;
    WorkflowStatus: QuoteWorkflowStatus;
    CurrencyIsoCode: string;

    // These fields may not be available to guests
    Selling_Price__c?: string;
    // tslint:enable: variable-name

    // These are nested objects
    QuoteLines: CpqQuoteline[];

    QuoteConfiguredProducts?: any[];
    products?: any[];

    Proposals?: CpqProposal[];
    proposal?: CpqProposal;
    IsPreferred__c?: boolean;
    isExpertMode__c?: boolean;


    static getQueryString(): string {
        return `SELECT ${this.queryFields.join(', ')} FROM Quote`;
    }

    static getNestedQueryString(): string {
        return `(SELECT ${this.queryFields.join(', ')} FROM Quotes)`;
    }

    /**
     * Retruns `true` if the quote object has a closed workflow state
     * @param quote as `CpqQuote`
     */
    static checkIsClosed(quote: TempCpqQuote) {
        switch (quote?.WorkflowStatus) {
            case QuoteWorkflowStatus.None:
            case QuoteWorkflowStatus.NoApprovalRequired:
            case QuoteWorkflowStatus.InProgress:
            case QuoteWorkflowStatus.Aborted:
            case QuoteWorkflowStatus.Canceled:
            case QuoteWorkflowStatus.Invalid:
            case QuoteWorkflowStatus.Rejected:
                return false;

            case QuoteWorkflowStatus.Completed:
            case QuoteWorkflowStatus.Approved:
            case QuoteWorkflowStatus.Waiting:
            case QuoteWorkflowStatus.AutoApproved:
            default:
                return true;
        }
    }

    constructor(maybeQuote?: TempCpqQuote) {
        // console.log('%c*** Made a CpqQuote', 'background-color:red');
        this.Id = maybeQuote?.Id;
        this.Name = maybeQuote?.Name;
        this.WorkflowStatus = maybeQuote?.WorkflowStatus;
    }

    get id() {
        return this.Id;
    }

    get name() {
        return this.Name;
    }

    get workflowStatus() {
        return this.WorkflowStatus;
    }

    get isClosed(): boolean {
        switch (this.WorkflowStatus) {
            case QuoteWorkflowStatus.None:
            case QuoteWorkflowStatus.NoApprovalRequired:
            case QuoteWorkflowStatus.InProgress:
            case QuoteWorkflowStatus.Aborted:
            case QuoteWorkflowStatus.Canceled:
            case QuoteWorkflowStatus.Invalid:
            case QuoteWorkflowStatus.Rejected:
                return false;

            case QuoteWorkflowStatus.Completed:
            case QuoteWorkflowStatus.Approved:
            case QuoteWorkflowStatus.Waiting:
            case QuoteWorkflowStatus.AutoApproved:
            default:
                return true;
        }
    }
}

export class DfrCpqQuote extends TempCpqQuote {
    static queryFields = ['TotalAmount',
        , ...TempCpqQuote.queryFields];

    static guestQueryFields = [...TempCpqQuote.guestQueryFields];

    static addOnFields = ['IsPreferred__c', 'isExpertMode__c'];

    // tslint:disable: variable-name
    TotalAmount: string;

    displayEditRevision?: boolean;
    isExpiredProposal?: boolean;
    displayEditNote?: boolean;
    revisionProductCount?: number;


    fpRules?: FpRules;
    IsPrimary: number;
    LastModifiedDate?: string;
    // tslint:enable: variable-name
    QuoteConfiguredProducts: QuoteConfiguredProducts[];

    QuoteLines: DfrQuoteline[];

    static quoteStatus(quote: DfrCpqQuote): QuoteStatus {
        switch (quote.WorkflowStatus) {
            case QuoteWorkflowStatus.None:
            case QuoteWorkflowStatus.Waiting:
            case QuoteWorkflowStatus.NoApprovalRequired:
            case QuoteWorkflowStatus.InProgress:
                return QuoteStatus.Active;

            case QuoteWorkflowStatus.Approved:
            case QuoteWorkflowStatus.AutoApproved:
            case QuoteWorkflowStatus.Completed:
                return QuoteStatus.Submitted;

            case QuoteWorkflowStatus.Aborted:
            case QuoteWorkflowStatus.Canceled:
            case QuoteWorkflowStatus.Invalid:
            case QuoteWorkflowStatus.Rejected:
            default:
                return QuoteStatus.Inactive;
        }
    }

    static projectStatus(project: CpqJob): ProjectStatus {
        switch (project.Status__c) {
            case ProjectStatus.Active:
                return ProjectStatus.Active;

            case ProjectStatus.Closed:
                return ProjectStatus.Closed;

            case ProjectStatus.Abandoned:
                return ProjectStatus.Abandoned;

            case ProjectStatus.Completed:
                return ProjectStatus.Completed;

            default:
                return ProjectStatus.Closed;
        }
    }
}

export enum QuoteStatus {
    Inactive = 'Active',
    Submitted = 'Submitted',
    Active = 'Active',
    Abandoned = 'Abandoned',
    Closed = 'Closed',
    Completed = 'Completed'
}

export enum ProjectStatus {
    Active = 'Active',
    Abandoned = 'Abandoned',
    Closed = 'Closed',
    Completed = 'Completed'
}

export interface QuoteConfiguredProducts {
    Id: string;
    NodeIndex: number;
    OpportunityId: string;
    TotalList: number;
}

export class DfrCpqOpportunity extends CpqOpportunity {
    static addOnFields = ['PrimaryQuote.WorkflowStatus', 'PrimaryQuote.ExpirationDate', 'Status__c', 'IsPreferred__c', 'Zip_Code__c',
        'Ambient_temp__c', 'storeType__c', 'application__c', 'Account.ExternalId', 'drop_ship_address_line_1__c', 'latitude__c', 'longitude__c', 'AmbientTempJSON__c', 'IsSubmittedToCRM__c', 'Owner'];

    // tslint:disable: variable-name
    Description: string;
    CurrencyIsoCode: string;
    OwnerId: string;
    AccountId: string;
    Quotes?: DfrCpqQuote[];
    ExternalId?: string;
    PredictedDate__c: string;
    SfdcLead__c: string;
    Status__c: string;
    Ambient_Temp__c: string;
    Owner: OwnerId;
    // tslint:enable: variable-name
}

export enum SfdcLeadSourceCO2 {
    CO2 = 'Smart Select'
}


export interface DfrSfdcOpportunity extends SfdcOpportunity {
    Projected_Ship_Date__c?: Date;
    CPQ_Opportunity_Owner_Email__c?: string;
    Outside__c?: boolean;
    AccountId: string;
    Leadsource?: SfdcLeadSourceCO2;
}

export interface AvailableProduct {
    classname: string;
    id: number;
    name: string;
    contextHelp: string;
    desc: string;
    label: string;
    extendedDesc: string;
    longDescription: string;
    graphicName: string;
    minimum: number;
    maximum: number;
    selected: number;
    SelectionType: string;
}


// tslint:disable-next-line: no-empty-interface
export interface CpqRevisions extends DfrCpqQuote {  // TODO: replace / depreciate or make into an alias

}
interface PrimaryQuoteId {
    Id: string;
    Name: string;
}
export interface DfrJobRevision {
    LastModifiedDate: string;
    Description?: string;
    AccountId: AccountId;
    PrimaryQuote_TotalAmount: number;
    PrimaryQuote_ProductsCount: number;
    ExternalId?: any;
    PrimaryQuoteId: PrimaryQuoteId;
    Quotes: DfrRevisions[];
    IsClosed: boolean;
    CurrencyIsoCode: string;
    Name: string;
    CreatedById: CreatedById;
    OwnerId: OwnerId;
    CreatedDate: Date;
    Id: string;
}

export interface DfrCpqAccount extends CpqAccount {
    Email__c: string;
    Customer_Type__c: string;
    termsAndCondition__c: string;
}

export const QueryParams = {
    Update: 'update',
    ProductId: 'productID',
};

export interface TunnelOptions {
    Voltage: string;
    DriveSide: string;
    TunnelLength: number;
}

export interface DFRProduct {
    classname: string;
    id: number;
    name: string;
    value?: any;
    selected: number;
    quantity: number;
    label: string;
    nodeId?: string;
    imageUrl: string;
    imageName: string;
    priority: number;
    listPrice: number;
    energyUsage: number;
    redundancy: number;
    installCost: number;
    riskExposure: number;
    regulatoryCompliance: string;
    environmentalImpact: number;
    powerRequirement: string;
    refrigerationType: string;
    lowTempCapacity: string;
    mediumTempCapacity: string;
    dimensions: string;
    weight: string;
    productName: string;
    disabled: number;
    ltLoadPct: number;
    mtLoadPct: number;
    unitPrice: number;
    images: Images[];
    documents: Documents[];
    mopd: number;
    mca: number;
    mtEvapCapacity: string;
    ltEvapCapacity: string;
    ltExcessCapcPercent: number;
    mtExcessCapcPercent: number;
    thr: number;
    //new added
    Image?: string;
    RefrigerantType?: string;
    ProductId: string;
    Selected: boolean;
    LowTempCompressorCapacity?: number;
    MedTempCompressorCapacity?: number;
    LowTempExcessPercent?: number;
    MedTempExcessPercent?: number;
    BaseUnitPrice?: number;
    Thr?: number;
    GasCoolerInletTemp?: number;
    type: string;
    IsCriteriaMatched?:boolean;
}
export interface Images {
    attachment: string;
    classname: string;
    id: number
    name: string;
    type: string;
}
export interface Documents {
    classname: string;
    id: number;
    name: string;
    attachment: string;
    type: string;
}
export interface DFRAclAccess {
    fpRules?: FpRules;
}

export interface RackData {
    rackName: string;
    rackNode: string;
    modelName: string;
    modelId: string;
    modelImg: string;
    displayEditRack: boolean;
    rackNameKey: string;
}

export interface ProposalReports {
    templateType: string;
    available: boolean;
    type: string;
    templateId: string;
    mandatory: boolean;
    valid: boolean;
    itemId: string;
    sequence: number;
    allowMultiple: boolean;
    isOwner: boolean;
    isPdf: boolean;
    name: string;
    printingType: string;
    messages: any[];
    id: string;
    category: string;
    selected: boolean;
    categoryId: string;
}



export interface PtChart {
    ambientTemp: number;
    gasCoolerOutTemp: number;
    condenserTemp?: any;
    gasCoolerPSIA: number;
}

export interface Ashrae {
    country: string;
    station_Name: string;
    latitude: number;
    longitude: number;
    cooling_DB_04: number;
    cooling_MCWB_04: number;
    extreme_annual_db_Max: number;
}

export interface AmbientTempResult {
    ptChart: PtChart;
    ashrae: Ashrae;
}


export interface RequiredMassFlow {
    lowTempMassFlow: number;
    medTempMassFlow: number;
    systemMassFlow: number;
    lowTempChangeInEnthalpy: number;
    medTempChangeInEnthalpy: number;
}

export interface FlexModelResult {
    requiredMassFlow: RequiredMassFlow;
    flexModels: FlexModel[];
}

export interface FlexModel {
    modelname: string;
    totalLtCompMassFlow: number;
    totalMedCompMassFlow: number;
    lowTempCompressorCapacity: number;
    medTempCompressorCapacity: number;
    gasCoolerOutletTemp: number;
    gasCoolerOutletPressure: number;
    lowTempExcessCapacity: number;
    medTempExcessCapacity: number;
    lowTempExcessPercent: number;
    medTempExcessPercent: number;
}

export interface PakModelResult {
    requiredMassFlow: RequiredMassFlow;
    pakModels: PakModel[];
    length: number;
}

export interface PakModel {
    modelname: string;
    ltEvapCapacity: number;
    mtEvapCapacity: number;
    ltExcessCapcPercent: number;
    mtExcessCapcPercent: number;
}

export interface Comp {
    capacity: number;
    massFlow: number;
    compressorName: string;
}

export interface ModelCompData {
    modelName: string;
    gasCoolerInletTemp: number;
    thr: number;
    mtComp: Comp[];
    ltComp: Comp[];
}

export interface CreateOpportunityInSFDC {
    Name: string;
    StageName: string;
    Leadsource: string;
    AccountId: string;
    CloseDate: Date;
    ownerId: string;
    CPQ_Project_Link__c: string;
    Division_Entered__c: string;
    Created_from_CDS__c: boolean;
}
export interface CadDataCds {
    success: boolean
    domain: string
    options: OptionsCad
    productId: string,
}

export interface OptionsCad {
    EVAPORATOR: string
    ENCLOSURE: string
    SEISMIC_LEG: string
    VOLTAGE: string
    WEIGHT: string
    SUCTION_LOOP: string
    LIQUID_LOOP: string
    LIQUID_LINE: string
    MCA: string
    MOPD: string
    LT_SUCTION_LOOP: string
    LT_LIQUID_LOOP: string
    MT_LIQUID_LOOP: string
    MT_SUCTION_LOOP: string
    CUSTOMER: string
    PROJECT: string
    PROJ_VER: string
    DATE: string
    MODEL_NUMBER: string
    LT_SUCT_FROM_STORE: string,
    MT_SUCT_FROM_STORE: string,
    GAS_COOLER_RETURN: string,
    GAS_COOLER_SUPPLY: string,
    LIQUID_SUPPLY_TO_STORE: string,
    PRESSURE_RELIEF_MANIFOLD: string,
    WATER_HEAT_REC_SUP: string,
    WATER_HEAT_REC_RET: string,
    AIR_HEAT_REC_SUP: string,
    AIR_HEAT_REC_RET: string,
    HOT_GAS_DEF_SUP: string,
    HOT_GAS_DEF_RET: string,
    GAS_COOLER: string,
    [key: string]: any;
}

export interface GasCoolerCondenser {
    errorCode: number
    data: Data[]
    name: string
    fileName: string
    filePath: string
}

export interface Data {
    modelName: string;
    model: string
    Name: string,
    Model: string, // These 4 fields hold contextually same value but are named differently in different brands. Need to be consolidated for simplicity.
    numberOfPasses: number
    ratedTHR: number
    numberOfUnits: number
    airFlow: number
    switchPoint: number
    cO2MassFlow: number
    pressureDrop: number
    numberOfFans: number
    fanPowerIn: number
    fanPowerOut: number
    fla: number
    mca: number
    mocp: number
    fanVelocity: number
    soundAt3ft: number
    soundAt30ft: number
    soundPowerLevel: number
    finMaterial: number
    coilVolume: number
    mop: number
    numberOfAnchors: number
    height: number
    width: number
    length: number
    shippingWeight: number
    operatingWeight: number
    numberOfCoilInletConnection: number
    coilInletConnectionDiameter: number
    coilInletConnectionNPT: number
    numberOfCoilOutletConnection: number
    coilOutletConnectionDiameter: number
    coilOutletConnectionNPT: number
    inletHeaderDiameter: number
    outetHeaderDiameter: number
    waterInletConnectionDiameter: number
    waterOutletConnectionDiameter: number
    minimumWaterPressure: number
    maximumWaterPressure: number
    evaporationRateAtDesign: number
    designWaterFlow: number
    maximumWaterFlow: number
    unitPrice: number
    copperFieldConnectionPrice: number
    copperFieldConnectionInformation: string
    optionPrice: OptionPrice
    drawingName: string
    warnings: string
    finMaterialOptionName: string
    ratedTHRInKilo: string
    ProductTotalHeatRejection: string,
    CondenserGasCoolerBrand: string,
    SubmittalFileLink: string,
    MaterialNumber: string,
    Capacity: number,
    LeavingGasTemp: number,
    TotalAirFlow: number,
    SoundPower: number,
    SoundPressure: number,
    IsSelected?: number | boolean
}

export interface OptionPrice {
    streamers: number
    hingedFanPanels: number
    vibrationDampers: number
    finAndPadGuard: number
    repairSwitches: number
    dryTypeTransformer600VTo480V: number
    autotransformer600VTo480V: number
    baCnetMSTP: number
    baCnetIP: number
    modbusRTU: number
    modbusTCPIP: number
    ethernetIP: number
    handrailAndLadderadder: number
    crnCertification: number
}

export enum ProductTypes {
    Co2Model = "Co2Model",
    FlexModel = "FlexModel"
}

export interface CompareProducts {    
    productFamily:string;
    productSeries:string;
    length: number;
    type: string;
    ProductId: string  ;
    Attachments: Attachment[];
    RefrigerantType: string;
    TotalMedCompMassFlow: number;
    TotalLtCompMassFlow: number;
    LowTempCompressorCapacity: string;
    MedTempCompressorCapacity: string;
    LowTempExcessPercent: number;
    MedTempExcessPercent: number;
    BaseUnitPrice: string;
    RegulatoryCompliance: string;
    Size: string;
    Weight: string;
    Thr: string;
    GasCoolerInletTemp: string;
    GasCoolerOutTemp: string;
    LowTempCompressors: LowTempCompressor[];
    MedTempCompressors: MedTempCompressor[];
    Selected: boolean;
    AirOverCoilTemp: string;
    GasCoolerPsiA: number;
    imageUrl: string;
    addedToCompare: boolean;
  }
  
  export interface Attachment {
    Label: string;
    Url: string;
  }
  
  export interface LowTempCompressor {
    capacity: number;
    massFlow: number;
    thr: number;
    compressorName: string;
  }
  
  export interface MedTempCompressor {
    capacity: number;
    massFlow: number;
    thr: number;
    compressorName: string;
  }
  








