<div class="modal-header mt-2">
    <h4 class="modal-title main-header pull-left"> Project Details </h4>
    <h4 *ngIf="!isUpdateProject" class="small-header  pull-left">(Enter details to start creating a project)</h4>


    <button type="button" class="close pull-right" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="project-details modal-body">
    <ngx-spinner class="spinner-attributes" bdOpacity=3 bdColor="rgba(0, 0, 0, 0.3)" size="medium" color="#4054B2"
        type="line-scale-party" [fullScreen]="false" [name]="PROJECT_SPINNER">
        <p class="login-spinner spinner mt-3">Please wait...</p>
    </ngx-spinner>
    <div class="row">
        <div class="col-md-6">
            <form class="pt-3 mr-1 ml-2" [formGroup]="saveProjectForm" (ngSubmit)="onProjectSave()">
                <div class="same-row-controls">
                    <div class="required child-control">
                        <label for="accountSelection" class="form-labels f-label">Sold to Customer<span
                                class="required-text">(Required)</span><i class='fa fa-info-circle ml-2 search-info'
                                ngbTooltip="Select sold to customer"></i></label>

                        <mat-form-field class="open-project-mat-style example-full-width" appearance="outline">
                            <input type="text" matInput formControlName="accountSelection" [matAutocomplete]="auto"
                                [value]="selectedAccount" placeholder="Select sold to customer"
                                (change)="onInputChange($event)"
                                [ngClass]="{ 'is-invalid':  (saveProjectForm.controls['accountSelection'].dirty || saveProjectForm.controls['accountSelection'].touched || submitted) && saveProjectForm.controls['accountSelection'].errors }">
                            <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                                (optionSelected)="onAccountSelect($event)">
                                <mat-option *ngFor="let option of filteredAccounts | async" [value]="option">
                                    {{option.Name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-error
                            *ngIf="saveProjectForm.controls['accountSelection'].invalid && (saveProjectForm.controls['accountSelection'].dirty || saveProjectForm.controls['accountSelection'].touched || submitted)"
                            class="invalid-feedback">
                            <div *ngIf="saveProjectForm.controls['accountSelection'].errors?.required">Account Name is
                                required
                            </div>
                            <div *ngIf="saveProjectForm.controls['accountSelection'].errors.pattern">
                                Account Name must be a character</div>
                            <div *ngIf="saveProjectForm.controls['accountSelection'].errors.maxlength">Account Name must
                                contain
                                max
                                of
                                100 characters</div>
                            <div
                                *ngIf="!saveProjectForm.controls['accountSelection'].errors.required && !saveProjectForm.controls['accountSelection'].errors.pattern && saveProjectForm.controls['accountSelection'].errors.invalidAutocompleteObject">
                                Account Name not recognized. Click one of the autocomplete options.</div>
                        </mat-error>
                    </div>
                </div>
                <div class="same-row-controls">
                    <div class="required child-control">
                        <label for="childAccountId" class="form-labels f-label">End Customer<span
                                class="required-text">(Required)<i class='fa fa-info-circle ml-2 search-info'
                                    ngbTooltip="Select end customer"></i> </span> <span class="ml-2 mr-2">OR</span>
                            <a (click)="modal.dismiss()" [routerLink]="['/accounts/new']">(Create End Customer
                                Account)</a>
                        </label>

                        <mat-form-field class="open-project-mat-style example-full-width" appearance="outline">
                            <input type="text" matInput formControlName="childAccountId"
                                [matAutocomplete]="childAccount" [value]="selectedChildAccount"
                                placeholder="Select end customer"
                                [ngClass]="{ 'is-invalid':  (saveProjectForm.controls['childAccountId'].dirty || saveProjectForm.controls['childAccountId'].touched || submitted) && saveProjectForm.controls['childAccountId'].errors }">
                            <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                            <mat-autocomplete #childAccount="matAutocomplete" [displayWith]="displayFn">
                                <mat-option *ngFor="let option of filteredChildAccounts | async" [value]="option">
                                    {{option.Name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <mat-error
                            *ngIf="saveProjectForm.controls['childAccountId'].invalid && (saveProjectForm.controls['childAccountId'].dirty || saveProjectForm.controls['childAccountId'].touched || submitted)"
                            class="invalid-feedback">
                            <div *ngIf="saveProjectForm.controls['childAccountId'].errors?.required">End Customer is
                                required</div>
                            <div
                                *ngIf="!saveProjectForm.controls['childAccountId'].errors?.required && 
                                                                !saveProjectForm.controls['childAccountId'].errors.pattern && 
                                                                saveProjectForm.controls['childAccountId'].errors.invalidAutocompleteObject">
                                End Customer not recognized. Click one of the autocomplete options.</div>
                        </mat-error>
                    </div>
                </div>
                <div class="same-row-controls">
                    <div class=" child-control">
                        <label for="Name" class="form-labels f-label">Project Name<span
                                class="required-text">(Required)</span></label>
                        <input type="text" class="form-control" id="Name" formControlName="Name"
                            [ngClass]="{ 'is-invalid':  (saveProjectForm.controls['Name'].dirty || saveProjectForm.controls['Name'].touched || submitted) && saveProjectForm.controls['Name'].errors }">
                        <div *ngIf="saveProjectForm.controls['Name'].invalid && (saveProjectForm.controls['Name'].dirty || saveProjectForm.controls['Name'].touched || submitted)"
                            class="invalid-feedback">
                            <div *ngIf="saveProjectForm.controls['Name'].errors?.required">
                                Project Name is required</div>
                            <div *ngIf="saveProjectForm.controls['Name'].errors?.pattern">
                                Project Name must be a character</div>
                            <div *ngIf="saveProjectForm.controls['Name'].errors?.maxlength">Project Name
                                must
                                contain max of
                                120
                                characters</div>
                        </div>
                    </div>
                </div>
                <!-- Code for address -->
                <div class="same-row-controls" *ngIf="!isUpdateProject">
                    <div class=" child-control">
                        <label for="Zip_Code__d" class="form-labels f-label">Site Address<span
                                class="required-text">(Required)</span><i class='fa fa-info-circle ml-2 search-info'
                                ngbTooltip="This address is used to determine the Ambient temperature and cannot be changed in the next steps."></i></label>
                        <input type="text" class="form-control" #Zip_Code__d formControlName="Zip_Code__d"
                            [ngClass]="{ 'is-invalid':  (saveProjectForm.controls['Zip_Code__d'].dirty || saveProjectForm.controls['Zip_Code__d'].touched || submitted) && saveProjectForm.controls['Zip_Code__d'].errors }">
                        <div *ngIf="saveProjectForm.controls['Zip_Code__d'].invalid && (saveProjectForm.controls['Zip_Code__d'].dirty || saveProjectForm.controls['Zip_Code__d'].touched || submitted)"
                            class="invalid-feedback">
                            <div *ngIf="saveProjectForm.controls['Zip_Code__d'].errors?.required">
                                Address is required</div>
                            <div *ngIf="saveProjectForm.controls['Zip_Code__d'].errors?.pattern">
                                Invalid Address.</div>
                        </div>
                    </div>
                    <div class=" child-control">
                        <div for="revisionCount" class="form-labels f-label">ASHRAE Ambient Temperature</div>
                        <div class="label-value mt-2"
                            [ngClass]="{'is-invalid': getControlValue('Ambient_Temp__Station') === 0}">
                            {{getControlValue('Ambient_Temp__Station') !== null
                            ?getControlValue('Ambient_Temp__Station') : 'NA'}}</div>

                        <div *ngIf="getControlValue('Ambient_Temp__Station') === 0" class="error-text">
                            Pick a Weather Station From Map.
                        </div>
                    </div>

                </div>
                <!-- new added for station name -->
                <div class="same-row-controls" *ngIf="!isUpdateProject">
                    <div class=" child-control">
                        <div for="revisionCount" class="form-labels f-label">Selected ASHRAE weather station</div>
                        <div class="label-value mt-2">{{getControlValue('stationName__c') !== null
                            ?getControlValue('stationName__c') : 'NA'}}</div>
                    </div>
                    <!-- add unit attribute -->
                    <div class=" child-control">
                        <div class="form-labels  f-label mb-2" for="UnitType">Units of Measurement</div>
                        <select class="form-control status label-value" id="UnitType"
                            (change)="onChangeUnit($event.target.value)" formControlName="UnitType">
                            <option value="metric">Metric</option>
                            <option value="imperial">Imperial</option>
                        </select>
                    </div>
                </div>
                <!-- end -->
                <div class="same-row-controls">
                    <div class=" child-control">
                        <div class="form-labels  f-label mb-2" for="storeType__c">Store Type</div>
                        <select class="form-control status label-value" id="storeType__c"
                            formControlName="storeType__c">
                            <option value="null" disabled selected>Store Type</option>
                            <option value="C-store">C-store</option>
                            <option value="Small Format">Small Format</option>
                            <option value="Supermarket">Supermarket</option>
                            <option value="Club Store">Club Store</option>
                        </select>
                    </div>
                    <div class=" child-control">
                        <div class="form-labels  f-label mb-2" for="application__c">Application Type</div>
                        <select class="form-control status label-value" id="application__c"
                            formControlName="application__c">
                            <option value="null" disabled selected>Application Type</option>
                            <option value="New Store">New Store</option>
                            <option value="Remodel">Remodel</option>
                            <option value="Conversion">Conversion</option>
                        </select>
                    </div>
                </div>

                <div class="same-row-controls" *ngIf="!isUpdateProject">
                    <div class="child-control">
                        <div class="d-flex justify-content-between flex-nowrap">
                            <button class="dfr-btn-dark dark"
                                [disabled]="saveProjectForm.invalid || isAmbientTemperatureAvailable === false"
                                title="Select products using guided selection workflow."
                                (click)="openGuidedProject()">Model
                                Selection</button>
                            <!-- <button class="dfr-btn-dark dark"
                                [disabled]="saveProjectForm.invalid || isAmbientTemperatureAvailable === false"
                                title=" know your product? Search by Model number or series name."
                                (click)="openModelSearch()">Model Search</button> -->
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-flex">
                    <button type="button" class="dfr-btn-light" (click)="modal.dismiss()">Cancel</button>
                    <span *ngIf="isUpdateProject"><button type="button" class="dfr-btn-dark"
                            [disabled]="saveProjectForm.invalid" (click)="onProjectSave()">Update</button></span>
                </div>


            </form>
        </div>
        <div class="col-md-6">
            <div #mapContainer id='mapcontainer' style="height: 525px;"></div>
        </div>
    </div>

</div>