import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export abstract class RowActions {
    static readonly EDIT = 'edit';
    static readonly COPY = 'copy';
    static readonly DELETE = 'delete';
    static readonly DOWNLOAD = 'download';
    static readonly SHARE = 'share';
    static readonly VIEW = 'view';
}

export abstract class CursorCssClass {
    static readonly DISABLE_POINTER = 'disable_pointer_events';
    static readonly ALLOW_CLICK = 'allow_click';
}

export abstract class StatusCssClass {
    static readonly ACTIVE = 'status-active';
    static readonly CLOSE = 'status-close';
    static readonly INACTIVE = 'status-inactive';
}

export abstract class ExpiryCssClass {
    static readonly EXPIRED = 'expire-expired';
    static readonly DANGER = 'expire-danger';
    static readonly WARNING = 'expire-warning';
    static readonly MODERATE = 'expire-moderate';
    static readonly NORMAL_SINGLE_DIGIT = 'expire-normal-single-digit';
    static readonly NORMAL = 'expire-normal';
    static readonly NORMAL_TRIPLE_DIGIT = 'expire-normal-triple-digit';
}

export interface DatatableColumn<T> {
    field?: string;
    header?: string;
    visibility?: boolean;
    control?: string;
    initialSort?: boolean;
    sortable?: boolean;
    filterable?: boolean;
    isPrimary?: boolean;
    handler?: (record: T) => void;
    value?: (record: T) => any;
    compare?: (a: T, b: T) => number;
    className?: (record: T) => string;
    titleText?: (record: T) => string;
}
export interface DataTableEvent {
    action?: string;
    handler?: () => void;
    hrefHandler?: () => string;
}
