import { Injectable } from '@angular/core';

@Injectable()
export class CsvService {
  downloadFile(data, headerList, filename = 'data') {
    const csvData = this.ConvertToCSV(data, headerList);

    const blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;'
    });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser =
      navigator.userAgent.indexOf('Safari') !== -1 &&
      navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    const date = this.getDateString(new Date());
    dwldLink.setAttribute('download', filename + '_' + date + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    const replacer = (key, value) => (value === null ? '' : value);
    const csv = objArray.map(row =>
      headerList
        .map(fieldName => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(headerList.join(','));
    const csvData = csv.join('\r\n');
    return csvData;
  }

  getDateString(day) {
    const year = day.getFullYear();
    let date = day.getDate();
    let month = day.getMonth() + 1;

    if (date < 10) {
      date = '0' + date;
    }

    if (month < 10) {
      month = '0' + month;
    }

    const hours = day.getHours();
    const minutes = day.getMinutes();
    const seconds = day.getSeconds();

    return (
      year +
      '-' +
      month +
      '-' +
      date +
      'T' +
      hours +
      '_' +
      minutes +
      '_' +
      seconds
    );
  }
}
