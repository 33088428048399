import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { CartService, CpqObjectType, CpqProposalReport, CpqQueryObjects, CpqReport, CpqUser } from '@cpq-app/services/cart.service';
import { ExecuteResponse, ProductService } from '@cpq-app/services/product.service';
import { differenceInCalendarDays } from 'date-fns';
import { environment } from '@cpq-environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CpqOperations } from '../cpqOperations.service';
import { DFRAclAccess, DfrCpqOpportunity, DfrCpqQuote, DfrRevisions, ProjectStatus, ProposalReports, SfdcLeadSourceCO2 } from '../DFR.interfaces.service';
import { EnergyEstimationComponent } from '../energy-estimation/energy-estimation.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SalesforceProxyService, sfdcObjectType } from '@cpq-app/services/salesforce.service';
import { CreateLeadSfdcComponent } from '../create-task-sfdc/create-task-sfdc.component';
import { ProposalPreview } from '@cpq-app/tenants/Cpq.interfaces.service';
import { PreviewComponent } from '@cpq-app/tenants/preview/preview.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { error } from 'console';
import { MSALService } from '@cpq-app/shared/services/msal.service';

const FIRST_PANEL_REVISION_CONFIG = 'ngb-panel-0';
enum aclSelectionTypes {
  PROJECT = 'Project',
  REVISION = 'Revision'
}
enum RevisionTypes {
  NEWPROJECT = '1',
  NEWPROJECT_QUICKSEARCH = '2'
}

export const SFDC_DEFAULT_OPP_NAME = 'Cpq Opportunity';
export enum DfrSfdcOpportunityStage {
  DEFAULT = 'Needs Analysis',
  SYSTEMS = 'Systems'
}

@Component({
  selector: 'app-product-selector',
  templateUrl: './product-selector.component.html',
  styleUrls: ['./product-selector.component.scss']
})
export class ProductSelectorComponent implements OnInit, OnDestroy {
  subscription$: Subscription[] = [];
  projectID: string;
  revisionID: string;
  configID: string;
  hasRevisionRecords: boolean;
  revisions: DfrCpqQuote[] = [];
  revisionName: string;
  displayEditRevision = false;
  activeId: string;
  currentRevisionEditId: string;
  canDelete: boolean;
  productsData: any;
  REVISION_SPINNER = 'revision update';
  revisionForm: FormGroup;
  submitted = false;
  isInternalUser: boolean;
  productsInRevisions = [];
  Display_ProductsInRevisions = [];
  proposalUrl: string;
  currentActiveRevision: DfrCpqQuote;
  proposalReports: CpqReport[] = [];
  searchString = '';
  revisionIdQS: string;
  configIdQS: string;
  showSearchProduct = false;
  createLeadSubscription: Subscription;
  project: DfrCpqOpportunity;
  isEorUser;
  isZipCodeEmpty: boolean;
  projectStatus = ProjectStatus;
  expirationClass: string;
  expirationText: string;
  isPrimaryChanged: boolean;

  proposalPreview: ProposalPreview;
  btnSpinner = false;
  subscriptions$: Subscription[] = [];
  completeProposalBtn: boolean = true;
  isSubmittedToCRM: boolean;
  totalUnit: string;

  isInternalUse: boolean;
  displayedColumns = ['ProductId', 'MedTempCompressorCapacity', 'LowTempCompressorCapacity', 'Total_Capacity__c'];
  proposalResult: any;

  quoteId: any;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private cartService: CartService,
    private toastr: ToastrService,
    private dfrCpqOperations: CpqOperations,
    private productService: ProductService,
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private modalService: NgbModal,
    private salesforce: SalesforceProxyService,
    private http: HttpClient,
    private _msalService: MSALService
  ) { }

  ngOnInit(): void {
    this.isEorUser = this.userService.checkLoggedInUserIsEorUser();
    this.spinner.hide();
    this.subscription$.push(this.route.params.subscribe(params => {
      this.projectID = params.projectID;
      this.configID = params?.configID;
      this.initialFormData();
      this.getRevisionData();
      this.activeId = FIRST_PANEL_REVISION_CONFIG;
      this.isInternalUser = this.userService.checkLoggedInUserIsInternalUser();

    }));
    this.subscription$.push(this.cartService.revisionCountUnderJob$.subscribe({
      next: (value) => { this.hasRevisionRecords = value; }
    }));
    this.isInternalUser = this.userService.checkLoggedInUserIsInternalUser();
  }

  getOppurtunity() {
    this.subscription$.push(this.cartService.getCpqObjects<DfrCpqOpportunity>(CpqQueryObjects.Opportunities, {
      Id: this.projectID, addonFields: DfrCpqOpportunity.addOnFields.join(', '), resolveNames: 'true'
    })
      .subscribe({
        next: (opportunity: any) => {
          this.project = opportunity;
          this.isSubmittedToCRM = this.project['IsSubmittedToCRM__c'];
        },
        error: err => {
          this.toastr.error(
            'There is fatal error while fetching revision', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      }));
  }

  initialFormData() {
    const nonWhiteSpaceRegExp: RegExp = new RegExp('\\S');
    this.revisionForm = this.formBuilder.group({
      quoteName: [null, [Validators.required, Validators.pattern(nonWhiteSpaceRegExp), Validators.maxLength(120)]],
      note: [null]
    });
  }
  onSelect(event) {
    console.log(event);
  }

  onRevisionNameUpdate(revisionName: string, revisionData: DfrCpqQuote) {
    this.spinner.show(this.REVISION_SPINNER);
    this.updateDisplayEditRevision(revisionData);
    if (revisionName) {
      this.revisionName = revisionName;
      this.cartService.updateObjectById(CpqObjectType.Quote, revisionData?.Id, {
        Name: revisionName
      }).subscribe({
        next: () => {
          this.getRevisionData();
          this.spinner.hide(this.REVISION_SPINNER);
        },
        complete: () => {
          this.displayEditRevision = false;
        },
        error: (err) => {
          this.toastr.error(
            'There was an error while updating revision name', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
          this.spinner.hide(this.REVISION_SPINNER);
        }
      });
    }
    else {
      this.displayEditRevision = false;
      this.getRevisionData();
    }
  }

  requestPrice() {
    const instance = this.modalService.open(CreateLeadSfdcComponent, {
      size: 'md',
      windowClass: 'previewModal'
    }).componentInstance;
    instance.projectId = this.projectID;
    instance.title = 'Request for price';
    instance.context = 'price';
  }
  getRevisionData() {
    this.revisions = [];
    this.subscription$.push(this.cartService.getCPQObjectById(CpqObjectType.Opportunity, this.projectID)
      .subscribe({
        next: (data: any) => {
          this.project = data;
          this.revisions = data.Quotes;
          this.quoteId = data?.Quotes[0].Id;
          this.revisions.forEach((rev) => {
            rev.displayEditRevision = false;
            rev["IsPreferred__c"] = this.convertStringToBoolean(rev["IsPreferred__c"]);
            const days = this.calculateDaysUntilExpired(rev);
            rev.isExpiredProposal = days <= 0;
            rev.displayEditNote = false;
          });
          this.revisions = this.revisions.sort((a, b) => Number(b?.IsPreferred__c) - Number(a?.IsPreferred__c));
          this.canDelete = (sessionStorage.getItem('userId') === data?.CreatedById?.Id);
          this.setFirstRevisionReports();
        },
        complete: () => {
          this.spinner.hide(this.REVISION_SPINNER);
        },
        error: (err) => {
          this.toastr.error(
            'There was an error while fetching revision', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
          this.spinner.hide(this.REVISION_SPINNER);
        }
      }));
  }

  private setFirstRevisionReports() {
    if (this.activeId = FIRST_PANEL_REVISION_CONFIG) {
      if (this.revisions.length > 0) {
        this.currentActiveRevision = this.revisions[0];
        this.getRevisionProposals();
        this.getProductsAssociatedWithRevision(this.revisions[0]);
      }
    }
  }

  goToDashboard() {
    try {
      this.router.navigate(['/']);
    } catch (error) {
      console.log(error);
    }
  }

  downloadProposal() {
    if (this.currentActiveRevision?.Proposals.length > 0) {
      const reportIndex = document.getElementById("proposalReport") as HTMLSelectElement | null;;
      if (this.proposalReports.length > 0) {
        let selectedReport: CpqReport = this.proposalReports[reportIndex.selectedIndex - 1];
        if (selectedReport) {
          const [proposal] = this.currentActiveRevision?.Proposals;
          this.proposalUrl = this.cartService.getProposalUrl(proposal?.Id, selectedReport?.id);
          window.open(this.proposalUrl, '_blank');
        }
      }
    }
  }

  getRevisionProposals() {
    const [proposal] = this.currentActiveRevision?.Proposals;
    if (proposal) {
      this.subscription$.push(this.cartService.addProposalReports(proposal?.Id).subscribe({
        next: (value: CpqProposalReport) => {
          this.proposalReports = value?.selectedList;
          this.proposalReports = this.proposalReports.filter(report => report?.available === true);
        },
        error: (err) => {
          this.toastr.error(
            'There was an error while updating proposals name', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
          this.spinner.hide(this.REVISION_SPINNER);
        }
      }));
    }
  }

  onClickPreview(revision: DfrCpqQuote) {
    this.btnSpinner = true;
    this.subscriptions$.push(this.productService.getProposalDataRacks(revision.Id)
      .subscribe({
        next: (record: any) => {
          if (record) {
            this.proposalPreview = record;
            this.openPreviewDialog(this.proposalPreview);
            this.btnSpinner = false;
          }
          else {
            this.toastr.info('No Preview available', 'Information', {
              disableTimeOut: true,
              closeButton: true
            });
            this.btnSpinner = false;
          }
        },
        error: (err) => {
          console.log(err);
          this.btnSpinner = false;
        }
      }));
  }

  openPreviewDialog(record: ProposalPreview) {
    const instance = this.modalService.open(PreviewComponent, {
      size: 'xl',
      windowClass: 'previewModal'
    }).componentInstance;
    instance.jobPreviewData = record;
  }

  editRevisionName(revisionData) {
    this.revisionName = revisionData?.Name;
    this.updateDisplayEditRevision(revisionData);
  }

  cancelEditRevisionName(revisionData) {
    this.spinner.show(this.REVISION_SPINNER);
    if (this.revisionForm.controls['quoteName'].dirty) {
      this.getRevisionData();
    }
    this.updateDisplayEditRevision(revisionData);
    this.spinner.hide(this.REVISION_SPINNER);
  }

  updateDisplayEditRevision(revisionData) {
    revisionData.displayEditRevision = !revisionData.displayEditRevision;
  }

  editRevisionNote(revisionData) {
    this.revisionName = revisionData?.Name;
    this.updateDisplayEditNote(revisionData);
  }

  updateDisplayEditNote(revisionData) {
    revisionData.displayEditNote = !revisionData.displayEditNote;
  }

  cancelEditRevisionNote(revisionData) {
    this.updateDisplayEditNote(revisionData);
  }

  onRevisionNoteUpdate(revisionNote: string, revisionData: DfrCpqQuote) {
    this.updateDisplayEditNote(revisionData);
    if (revisionNote) {
      this.cartService.updateObjectById(CpqObjectType.Quote, revisionData?.Id, {
        Note: revisionNote
      }).subscribe({
        next: () => {
        },
        complete: () => {
          revisionData.displayEditNote = false;
        },
        error: (err) => {
          this.toastr.error(
            'There was an error while updating revision name', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      });
    }
    else {
      revisionData.displayEditNote = false;
    }
  }


  toggleAccordion(accordian, revision) {
    this.activeId = accordian?.panelId;
    this.getProductsAssociatedWithRevision(revision);
    this.currentActiveRevision = revision;
    this.getRevisionProposals();
  }
  getProductsAssociatedWithRevision(quote) {
    this.productsInRevisions = [];
    if (quote) {
      quote?.Lines?.forEach(Line => {
        Line?.AvailableProducts?.forEach(product => {
          if (product.Selected) {
            this.productsInRevisions.push(product);
          }
        });

      });
    }
  }

  // getProductsAssociatedWithRevision(quote: DfrCpqQuote) {
  //   this.productsInRevisions = [];
  //   if (quote) {
  //     this.dfrCpqOperations.getQuoteLineProducts(quote.Id).subscribe({
  //       next: (quoteWithProducts: any) => {
  //         if (quoteWithProducts.QuoteLines.length > 0) {
  //           const productId = quoteWithProducts.QuoteLines[0]?.ProductId.Id;
  //           this.getQuoteProducts(productId, quote);
  //         } else {
  //           this.productsInRevisions = [];
  //           this.revisions.forEach((resQuote) => {
  //             if (quote?.Id === resQuote?.Id) {
  //               resQuote.revisionProductCount = 0;
  //             }
  //           });
  //         }
  //       },
  //       error: err => {
  //         this.toastr.error('Failed to fetch data. Please contact your CPQ administrator', 'Error!', {
  //           disableTimeOut: true,
  //           closeButton: true
  //         });
  //       }
  //     });
  //     return this.productsInRevisions;
  //   }
  // }

  // getQuoteProducts(productId, quote) {
  //   this.dfrCpqOperations.getProductsAssociatedWithQuotes(productId).subscribe({
  //     next: (res) => {
  //       this.productsInRevisions = [];
  //       this.productsInRevisions = res;
  //       this.revisions.forEach((resQuote) => {
  //         if (quote?.Id === resQuote?.Id) {
  //           resQuote.revisionProductCount = res.length;
  //         }
  //       });
  //       this.productsInRevisions.forEach((product) => {
  //         product['ltEvaporatorTemp__c'] = product['ltEvaporatorTemp__c'].toFixed(2);
  //         product['ltTotalLoad__c'] = product['ltTotalLoad__c'];
  //         product['mtEvaporatorTemp__c'] = product['mtEvaporatorTemp__c'].toFixed(2);
  //         product['mtTotalLoad__c'] = product['mtTotalLoad__c'];
  //         product['MTAnnualEnergyConsumption__c'] = product['MTAnnualEnergyConsumption__c'];
  //         product['LTAnnualEnergyConsumption__c'] = product['LTAnnualEnergyConsumption__c'];
  //         product['total_annual_energy_cons__c'] = product['total_annual_energy_cons__c'];
  //         product.imageName = product['imageName__c'].replace(/["']/g, '');
  //         product['imageUrl'] = `${environment.B2CConfigs.BackendURL}/cpq/images/${product?.imageName}`;
  //       });
  //       // new code
  //       if (this.revisions.length && this.productsInRevisions.length) {
  //         this.completeProposalBtn = false;
  //       }
  //       console.log(this.productsInRevisions);
  //     },
  //     error: err => {
  //       this.toastr.error(
  //         'There was an error while getting product info', 'Error', {
  //         disableTimeOut: true,
  //         closeButton: true
  //       }
  //       );
  //       this.spinner.hide(this.REVISION_SPINNER);
  //     }
  //   });
  // }

  copyRevision(sourceQuote: DfrCpqQuote) {
    this.spinner.show(this.REVISION_SPINNER);
    this.copyNewRecord(sourceQuote);
    const newName = `Copy of ${sourceQuote?.Name}`;
    this.cartService.copyObjectById(CpqObjectType.Quote, sourceQuote?.Id, newName).subscribe({
      next: (results) => {
        this.revisions.forEach(quote => {
          if (quote.Id === null) {
            quote.Id = results.Id;
          }
        });
        this.getRevisionData();
        this.spinner.hide(this.REVISION_SPINNER);
      },
      error: (err) => {
        this.toastr.error(
          'There was an error while copying revisions', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
        this.spinner.hide(this.REVISION_SPINNER);
      }
    });
  }
  copyNewRecord(sourceQuote) {
    const newQuote = Object.assign({}, sourceQuote);
    const date = new Date();
    const ISOFormate = date.toISOString().split('.')[0] + 'Z';
    newQuote.Id = null;
    newQuote.RFQ_Due_Date__c = ISOFormate;
    newQuote.RFQ_Receive_Date__c = ISOFormate;
    newQuote.ShipmentDate__c = ISOFormate;
    newQuote.IsPrimary = 0;
    this.revisions.push(newQuote);
  }

  deleteRevision(revision: DfrCpqQuote) {
    // immediately delete, temporarily set first quote as primary
    if (confirm('Are you sure you would like to delete this revision - ' + revision?.Name + '?')) {
      this.spinner.show(this.REVISION_SPINNER);
      this.revisions = this.revisions.filter(item => (item.Id.indexOf(revision.Id) === -1));
      if (this.revisions.length) {
        this.revisions[0].IsPrimary = 1;
      }
      this.cartService.revisionCountUnderJob$.next(Boolean(this.revisions?.length));
      this.cartService.deleteObjectByObjectId(CpqObjectType.Quote, revision.Id).subscribe({
        next: () => {
          this.getRevisionData();
          this.spinner.hide(this.REVISION_SPINNER);
        },
        error: (err) => {
          this.toastr.error(
            'There was an error while deleting revisions', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
          this.spinner.hide(this.REVISION_SPINNER);
        }
      });
    }
  }

  newRevision() {
    this.spinner.show(this.REVISION_SPINNER);
    let primaryRevision: DfrCpqQuote[];
    primaryRevision = this.revisions.filter(revision => revision?.IsPrimary === 1); // FIX ME: 1 is replace in to boolean

    if (primaryRevision.length > 0) {
      primaryRevision[0].IsPrimary = 0;
      this.copyNewRecord(primaryRevision[0]);
      const newName = this.dfrCpqOperations.getCpqObjectName('Revision');
      this.cartService.copyObjectById(CpqObjectType.Quote, primaryRevision[0]?.Id, newName).subscribe({
        next: (results) => {
          this.revisions.forEach(quote => {
            if (quote.Id === null) {
              quote.Id = results.Id;
            }
          });
          this.getRevisionData();
          this.spinner.hide(this.REVISION_SPINNER);
        },
        error: (err) => {
          this.toastr.error(
            'There was an error while Creating new revisions', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
          this.spinner.hide(this.REVISION_SPINNER);
        }
      });
    } else {
      // Test env had scenarios where primary revision was not found, blocking user in the spinner
      console.log('primary revision not found');
      this.spinner.hide(this.REVISION_SPINNER);
    }
  }
  showToasterMsg() {
    this.toastr.error(
      'There was an error while creating project/Revision', 'Error', {
      disableTimeOut: true,
      closeButton: true
    }
    );
  }

  onPrimaryQuote(targetQuote: DfrCpqQuote, event: Event): void {
    // Confirm a change is required   
    if (targetQuote.IsPrimary) {
      const element = document.getElementById('primaryRevision') as HTMLInputElement;
      element.checked = true;
      return;
    }
    this.spinner.show(this.REVISION_SPINNER);
    // Deselect any other primary quotes, then set the new one as primary
    this.revisions.forEach(quote => quote.IsPrimary = 0);
    targetQuote.IsPrimary = 1;
    this.isPrimaryChanged = !this.isPrimaryChanged;

    // Send change to backend, then refresh data
    const obj = {
      PrimaryQuoteId: targetQuote.Id
    };
    this.cartService.updateObjectById(CpqObjectType.Opportunity, this.projectID, obj).subscribe({
      next: (id) => {
        this.getRevisionData();
        this.spinner.hide(this.REVISION_SPINNER);
      },
      error: (err) => {
        this.getRevisionData();
        this.spinner.hide(this.REVISION_SPINNER);
        this.toastr.error(
          'There was an error while changing the Primary Revision, please try again', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      }
    });
  }

  convertStringToBoolean(value: any | undefined): boolean {
    return value ? value.toLowerCase() === 'true' : false;
  }

  onSettingPreferredRevisions(targetQuote: DfrCpqQuote, eventValue: any): void {
    eventValue.preventDefault();
    this.spinner.show(this.REVISION_SPINNER);
    const obj = {
      IsPreferred__c: eventValue.target.checked || false
    };
    this.cartService.updateObjectById(CpqObjectType.Quote, targetQuote?.Id, obj).subscribe({
      next: (id) => {
        this.getRevisionData();
        this.spinner.hide(this.REVISION_SPINNER);
      },
      error: (err) => {
        this.getRevisionData();
        this.spinner.hide(this.REVISION_SPINNER);
        this.toastr.error(
          'There was an error while changing the preferred Revision, please try again', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      }
    });
  }

  editRevision(quote: DfrCpqQuote) {
    console.log('edit click');
    this.router.navigate(['product/selection', quote?.Name, this.projectID, quote?.Id, 0, this.project?.Ambient_Temp__c],
      { queryParams: { update: CpqObjectType.Product } });
  }

  getQuoteLineProducts(quote: DfrCpqQuote) {
    this.spinner.show(this.REVISION_SPINNER);
    this.dfrCpqOperations.getQuoteLineProducts(quote.Id).subscribe({
      next: (quoteWithProducts: any) => {
        console.log('quoteWithProducts', quoteWithProducts);
        if (quoteWithProducts.QuoteLines.length > 0) {
          this.reopenConfiguration(quoteWithProducts);
        } else {
          this.navigateToChoosePage(quote);
        }
      },
      error: err => {
        this.spinner.hide(this.REVISION_SPINNER);
        this.toastr.error('Failed to fetch data. Please contact your CPQ administrator', 'Error!', {
          disableTimeOut: true,
          closeButton: true
        });
      }
    });
  }

  navigateToChoosePage(quote: DfrCpqQuote) {
    const openSubscription = this.cartService
      .openConfigurationDefaultDataset(this.projectID, quote.Id)
      .pipe(
        tap((configData) => this.configID = configData.configId),
        switchMap(configData => this.productService.publicationForConfig(configData.configId)),
        tap((data) => {
          if (data) {
            this.productsData = data.products?.available || [];
          }
        }),
        switchMap(data => this.cartService.deleteExistingConfiguredProductsIfAny(quote.Id)),
        switchMap(x => this.productService.selectProduct(this.configID, this.productsData[2].name)),
      )
      .subscribe(
        data => {
          this.spinner.hide(this.REVISION_SPINNER);
          this.navigateToPreselectPage(quote, this.configID);
        },
        err => {
          this.spinner.hide(this.REVISION_SPINNER);
          // FIXME: We need to better handle this
          this.toastr.error(
            'There was a fatal error while starting a configuration'
          );
        }
      );
    this.subscription$.push(openSubscription);
  }

  reopenConfiguration(quote: DfrCpqQuote) {
    const [quoteLine] = quote.QuoteLines;
    const productId = (quoteLine?.ProductId as any)?.Id;
    let configID: string;
    if (!Boolean(productId)) { return; }
    this.cartService.reopenConfiguration(productId).subscribe({
      next: (configSession) => {
        configID = configSession.configId;
        this.spinner.hide(this.REVISION_SPINNER);
        if (!configSession.configId) {
          this.spinner.hide(this.REVISION_SPINNER);
          this.toastr.error('A valid configuration ID is missing. Please contact CPQ administrator.', 'Error!', {
            disableTimeOut: true,
            closeButton: true
          });
        } else {
          this.navigateToPreselectPage(quote, configSession.configId, productId);
        }
      },
      error: err => {
        this.spinner.hide(this.REVISION_SPINNER);
      },
      complete: () => {
      }
    });
  }

  navigateToPreselectPage(quote: DfrCpqQuote, configId: string, productID?: string): void {
    if (productID) {
      this.router.navigate(['product/selection', quote?.Name, this.projectID, quote?.Id, configId, this.project?.Ambient_Temp__c],
        { queryParams: { update: CpqObjectType.Product, productID } });
    } else {
      this.router.navigate(['product/selection', quote?.Name, this.projectID, quote?.Id, configId, this.project?.Ambient_Temp__c]);
    }
  }

  addDaysToDate(date: Date, days: number): Date {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }
  calculateDaysUntilExpired(revision: DfrCpqQuote): number {
    if (revision?.ExpirationDate) {
      return differenceInCalendarDays(new Date(revision.ExpirationDate), new Date());
    }
    return undefined;
  }
  openEnergyEstimation() {
    const instance = this.modalService.open(EnergyEstimationComponent, {
      size: 'lg',
      windowClass: 'previewModal'
    }).componentInstance;
  }

  conditionallyDisplayExpiryWarning(revisions: DfrCpqQuote[]) {
    revisions
      .filter(quote => quote.IsPrimary)
      .slice(0, 1)
      .forEach(primaryQuote => {
        const days = this.calculateDaysUntilExpired(primaryQuote);
        if (days >= 0) {

        }
      });
  }


  startNewModelSearch() {
    const rex = /^[a-zA-Z0-9_ ]*$ |([\w ]+)/;
    if (!rex.test(this.searchString.trim())) {
      this.toastr.error('Please enter valid search text');
      return;
    }
    this.createProjectAndRevisionWithDefaultValues();
  }



  createProjectAndRevisionWithDefaultValues() {
    this.spinner.show(this.REVISION_SPINNER);
    try {
      this.subscription$.push(this.dfrCpqOperations.createDFRQuote(this.projectID, true).subscribe({
        next: (revisionId: string) => {
          this.revisionIdQS = revisionId;
          this.startNewConfiguration();
        },
        error: (err) => {
          this.spinner.hide(this.REVISION_SPINNER);
          this.showToasterMsg();
        }
      }));
    } catch (err) {
      this.spinner.hide(this.REVISION_SPINNER);
      this.showToasterMsg();
    }
  }

  startNewConfiguration() {
    const openSubscription = this.cartService
      .openConfigurationDefaultDataset(this.projectID, this.revisionIdQS)
      .pipe(
        tap((configData) => this.configIdQS = configData.configId),
        switchMap(configData => this.productService.publicationForConfig(configData.configId)),
        tap((data) => {
          if (data) {
            this.productsData = data.products?.available || [];
          }
        }),
        switchMap(data => this.cartService.deleteExistingConfiguredProductsIfAny(this.revisionIdQS)),
        switchMap(x => this.productService.selectProduct(this.configIdQS, this.productsData[2].name)),
      )
      .subscribe(
        data => {
          this.spinner.hide(this.REVISION_SPINNER);
        },
        err => {
          this.spinner.hide(this.REVISION_SPINNER);
          this.toastr.error(
            'There was an error while starting a configuration'
          );
        }
      );
    this.subscription$.push(openSubscription);
  }

  CreateNewRevision(revisionValue: string) {
    if (revisionValue) {
      if (revisionValue === RevisionTypes.NEWPROJECT) {
        this.showSearchProduct = false;
        this.newRevision();
      }
      else if (revisionValue === RevisionTypes.NEWPROJECT_QUICKSEARCH) {
        this.showSearchProduct = !this.showSearchProduct;
      }
      else {
        console.log('Wrong Selection Revision Type');
      }
    }
  }

  // new function for complete proposal
  completeSfdcProposal() {
    const instance = this.modalService.open(CreateLeadSfdcComponent, {
      size: 'md',
      windowClass: 'previewModal'
    }).componentInstance;
    instance.projectId = this.projectID;
    instance.title = 'Request for Complete Proposal';
    instance.context = 'proposal';
    instance.completeProposal.subscribe((emittedValue: boolean) => {
      if (emittedValue) {
        this.onUpdateProject();
      }
    });
  }

  onUpdateProject() {
    const updateIsSubmit = { IsSubmittedToCRM__c: true }
    this.subscription$.push(this.cartService.updateObjectById(CpqObjectType.Opportunity, this.projectID, updateIsSubmit).subscribe({
      next: (res) => {
        this.getOppurtunity();
      },
      error: err => {
        this.spinner.hide(this.REVISION_SPINNER);
        this.toastr.success('Error in updating project details');
      }
    })
    );
  }

  checkZipCode(isZipCodeEmpty: boolean) {
    return this.isZipCodeEmpty = isZipCodeEmpty;
  }

  onProjectStatusChange(onProjectStatusChange) {
    this.getOppurtunity();
  }

  onUnitChange(onUnitChange) {
    this.getRevisionData();
  }

  getExpirationClass(revision): string {
    return this.dfrCpqOperations.returnExpireClass(revision);
  }

  getExpirationCText(revision): string {
    return this.dfrCpqOperations.returnTitleText(revision);
  }

  // Need to test first
  parseCapacity(capacity: string): number {
    let numericPart = 0;
    if (!capacity) {
      this.totalUnit = '';
      return 0;
    }
    capacity = capacity.toUpperCase();
    if (capacity.includes('KW')) {
      numericPart = parseFloat(capacity.replace('KW', ''));
      this.totalUnit = 'kW';
    } else if (capacity.includes('KBTUH')) {
      numericPart = parseFloat(capacity.replace('KBTUH', ''));
      this.totalUnit = 'KBTUH';
    }
    // const numericPart = parseFloat(capacity.replace('KW', ''));
    return isNaN(numericPart) ? 0 : numericPart;
  }


  ngOnDestroy() {
    this.subscription$.forEach(sub => sub.unsubscribe());
  }
}
