import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class BlobService {
  constructor(private http: HttpClient) {}

  private backendUrl = environment.B2CConfigs.BackendURL;
  private defaultBlobId = environment.B2CConfigs.DefaultBlobId;

  getBlobByUniqueId(uniqueId) {
    return this.http.get(`${this.backendUrl}/blobmgmt/Download/${uniqueId}`);
  }

  uploadBlob(uniqueId, file) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post(
      `${this.backendUrl}/blobmgmt/${this.defaultBlobId}/${uniqueId}`,
      formData
    );
  }

  updateBlob(uniqueId, file) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.put(
      `${this.backendUrl}/blobmgmt/Update/${uniqueId}`,
      formData
    );
  }

  deleteBlob(uniqueId) {
    return this.http.delete(`${this.backendUrl}/blobmgmt/Delete/${uniqueId}`);
  }

  uploadSoftwareForSigning(file) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post(
      `${this.backendUrl}/signsoftware/${this.defaultBlobId}`,
      formData
    );
  }
}
