import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@cpq-app/shared/material-module';
import { RouterModule, Routes } from '@angular/router';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { DataTablesModule } from 'angular-datatables';
import { BreadcrumbsModule } from 'src/app/shared/breadcrumbs/breadcrumbs.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatatableComponent } from '@cpq-app/shared/datatable/datatable.component';
import { SharedModule } from '@cpq-app/shared/shared-module';
import { SortableHeaderDirective } from '@cpq-app/shared/datatable/sortable.directive';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CpqOperations } from './cpqOperations.service'
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProductSelectorComponent } from './product-selector/product-selector.component';
import { ProjectComponent } from './project/project.component';
import { ArchivedProjectsComponent } from './archived-projects/archived-projects.component';
import { BannerComponent } from './banner/banner.component';
import { ProjectDetailsComponentComponent } from './project-details-component/project-details-component.component';
import { ShareComponent } from './share/share.component';
import { GuidedSellingComponent } from './guided-selling/guided-selling.component';
import { CustomIconComponent } from './custom-icon/custom-icon.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { VisualizeComponent } from './visualize/visualize.component';
import { ModelSearchComponent } from './model-search/model-search.component';
import { SaveProjectComponent } from './save-project/save-project.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { EnergyEstimationComponent } from './energy-estimation/energy-estimation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CreateLeadSfdcComponent } from './create-task-sfdc/create-task-sfdc.component';
import { OpenProjectComponent } from './open-project/open-project.component';
import { EditDialogComponent } from './edit-dialog/edit-dialog.component';
import { HidePriceDirective } from '@cpq-app/shared/directives/hide-price.directive';
import { GoogleMapsModule } from '@angular/google-maps';
import { PreventSpaceDirective } from '@cpq-app/shared/directives/prevent-space.directive';
import { temperatureVisibilityDirective } from '@cpq-app/shared/directives/temperatureVisibility.component';
import { textTransformerDirective } from '@cpq-app/shared/directives/text-transformer.directive';
import { CompareProductsComponent } from './compare-products/compare-products.component';
import { CreateAccountsComponent } from './accounts/create-accounts/create-accounts.component';
import { ViewAccountsComponent } from './accounts/view-accounts/view-accounts.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    BreadcrumbsModule,
    AccordionModule.forRoot(),
    DataTablesModule.forRoot(),
    NgbModule,
    SharedModule,
    NgxSpinnerModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxSliderModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    GoogleMapsModule,
  ],
  declarations: [
    DashboardComponent,
    ProjectComponent,
    ArchivedProjectsComponent,
    ProductSelectorComponent,
    BannerComponent,
    ProjectDetailsComponentComponent,
    ShareComponent,
    GuidedSellingComponent,
    CustomIconComponent,
    ProductCardComponent,
    ProductDetailComponent,
    VisualizeComponent,
    ModelSearchComponent,
    SaveProjectComponent,
    EnergyEstimationComponent,
    CreateLeadSfdcComponent,
    OpenProjectComponent,
    EditDialogComponent,
    HidePriceDirective,
    PreventSpaceDirective,
    temperatureVisibilityDirective,
    textTransformerDirective,
    CompareProductsComponent,
    CreateAccountsComponent,
    ViewAccountsComponent
  ],
  providers: [NgbActiveModal, CpqOperations]
})
export class DFRModule { }

