import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class EntityImageService {

  constructor(private http: HttpClient) { }

  private backendUrl = environment.B2CConfigs.BackendURL;

  getImage = (entityId: any, entityName: any) => {
    return this.http.get(`${this.backendUrl}/imageEntity/getImage/${entityId}/${entityName}`);
  }

  saveImage = (formdata: any) => {
    return this.http.post(`${this.backendUrl}/imageEntity/saveimage`, formdata);
  }

  getLatLong = (req: any) => {
    return this.http.post(`${this.backendUrl}/location/getLatLong`, req);
  }
}
