<h3><b class="capitalize">Edit Rack Name</b></h3>
<mat-dialog-content class="mat-typography">
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Edit Rack Name</mat-label>
    <input #rackName matInput placeholder="Placeholder" [formControl]="input" appPreventSpace />
    <!-- <mat-hint>Please enter under 10 characters.</mat-hint> -->
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close mat-stroked-button>Close</button>
  <button mat-button color="primary"
    class="mat-focus-indicator add-rack-btn mat-flat-button mat-button-base mat-primary" [disabled]="isEmptyRackName()"
    (click)="data.callback(input.value)">
    Update
  </button>
</mat-dialog-actions>