import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { CartService, CpqObjectType, CpqQueryObjects } from '@cpq-app/services/cart.service';
import { SalesforceProxyService, sfdcObjectType } from '@cpq-app/services/salesforce.service';
import { CpqAccounts } from '@cpq-app/tenants/Cpq.interfaces.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CpqOperations } from '../cpqOperations.service';
import { DfrCpqOpportunity, DfrJobRevision, DfrJobs, DfrRevisions } from '../DFR.interfaces.service';
const DELAY_BEFORE_ACOOUNTS_LOAD = 5000;
@Component({
  selector: 'app-save-project',
  templateUrl: './save-project.component.html',
  styleUrls: ['./save-project.component.scss']
})
export class SaveProjectComponent implements OnInit, OnDestroy {
  saveProjectForm: FormGroup;
  subscription$: Subscription[] = [];
  projectInfo: DfrJobs;
  availableAccounts = [];
  submitted = false;
  PROJECT_SPINNER = "project spinner";
  @Input() projectId: string;


  constructor(private formBuilder: FormBuilder,
    public modal: NgbActiveModal,
    private router: Router,
    private dfrCpqOperations: CpqOperations,
    private toastr: ToastrService,
    private cartService: CartService,
    private spinner: NgxSpinnerService,
    private salesforce: SalesforceProxyService
  ) { }

  ngOnInit(): void {
    this.initialFormData();
    this.getAccountNames();
    this.updateFormData(); console.log('this.projectId');
    console.log(this.projectId);

  }
  initialFormData() {
    const nonWhiteSpaceRegExp: RegExp = new RegExp('\\S');
    this.saveProjectForm = this.formBuilder.group({
      accountSelection: [null],
      projectName: [null, [Validators.required, Validators.pattern(nonWhiteSpaceRegExp), Validators.maxLength(120)]],
      totalAmount: [null],
    });
  }

  getAccountNames() {
    this.spinner.show(this.PROJECT_SPINNER);
    this.availableAccounts = [];
    this.subscription$.push(this.cartService.getCpqObjects<CpqAccounts>(CpqQueryObjects.Accounts, { resolveNames: 'true' })
      .subscribe(
        (results) => {
          this.availableAccounts = [...this.availableAccounts, ...results];
        },
        err => {
          this.toastr.error(
            'There is fatal error while fetching Account Names', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      ));
  }
  updateFormData() {
    this.spinner.show(this.PROJECT_SPINNER);
    this.subscription$.push(this.cartService.getCpqObjects<DfrCpqOpportunity>(
      CpqQueryObjects.OpportunityQuotes, { Id: this.projectId, resolveNames: 'true' })
      .subscribe({
        next: (data: any) => {
          try {
            this.projectInfo = data;
            this.saveProjectForm.patchValue({
              accountSelection: data?.AccountId?.Id,
              projectName: data?.Name,
            });
            if (data?.AccountId?.Name)
              setTimeout(() => this.spinner.hide(this.PROJECT_SPINNER), DELAY_BEFORE_ACOOUNTS_LOAD);
            else
              this.spinner.hide(this.PROJECT_SPINNER);

          } catch (error) {
            console.log(error);
          }

        },
        error: err => {
          this.spinner.hide(this.PROJECT_SPINNER);
          this.toastr.error(
            'There is an error while fetching projects ', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      }));
  }

  onProjectSave() {
    console.log('this.saveProjectForm.value.accountSelection', this.saveProjectForm.value.accountSelection);
    this.cartService.updateObjectById(CpqObjectType.Opportunity,this.projectId, {
      AccountId: this.saveProjectForm.value.accountSelection,
      Name: this.saveProjectForm.value.projectName
    }).pipe().subscribe(data => {
      this.modal.close();
      //this.updateOppInSFDC(this.saveProjectForm.value.projectName, this.saveProjectForm.value.accountSelection);
      this.goBacktoRevision();
    });
  }

  updateOppInSFDC(projectName, AccountId) {
    const filteredAccounts = this.availableAccounts.filter((account) => account.Id === AccountId);
    console.log(filteredAccounts);
    if (filteredAccounts.length > 0) {
      const sfdcLeadPayload = { Name: projectName, AccountId: filteredAccounts[0].ExternalId };
      this.salesforce.updateSfdcObjectById(this.projectInfo?.ExternalId, sfdcObjectType.OPPORTUNITY, sfdcLeadPayload).subscribe({
        next: (lead) => {
          this.cartService.updateObjectById(CpqObjectType.Opportunity,this.projectId, {
            AccountId: this.saveProjectForm.value.accountSelection?.Id,
            Name: this.saveProjectForm.value.projectName
          }).subscribe();
          this.goBacktoRevision();
        },
        error: err => {
          console.log('Issue in Salesforce opportunity', err);
        }
      })
    } else {
      this.goBacktoRevision();
    }
  }

  goBacktoRevision() {
    try {
      this.router.navigate([
        'productSelector',
        this.projectId
      ]);

    } catch (error) {
      console.log(error);
    }
  }
  ngOnDestroy() {
    this.subscription$.forEach(sub => sub.unsubscribe());
  }

}
