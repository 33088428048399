<div class="modal-header mt-2">
    <h4 class="modal-title main-header pull-left"> Project Details </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="project-details modal-body">
    <ngx-spinner class="spinner-attributes" bdOpacity=3 bdColor="rgba(0, 0, 0, 0.3)" size="medium" color="#4054B2"
        type="line-scale-party" [fullScreen]="false" [name]="PROJECT_SPINNER">
        <p class="login-spinner spinner mt-3">Please wait...</p>
    </ngx-spinner>
    <form class="pt-3" [formGroup]="saveProjectForm" (ngSubmit)="onProjectSave()">
        <div class="same-row-controls pb-3">
            <div class="child-control">
                <label for="projectName" class="form-labels project-top-text">Created Date</label>
                <label for="projectName"
                    class="form-labels f-label">{{projectInfo?.CreatedDate|date:'yyyy-MM-dd'}}</label>
            </div>
            <div class="child-control">
                <label for="projectName" class="form-labels project-top-text">Project Owner</label>
                <label for="projectName" class="form-labels f-label">{{projectInfo?.OwnerId?.Name}}</label>
            </div>
        </div>
        <span class="subheader-text pt-4">Project</span>
        <div class="same-row-controls pt-3 pb-5">
            <div class=" child-control">
                <label for="projectName" class="form-labels f-label">Project Name <i class='fa fa-info-circle ml-2 search-info' ngbTooltip="Update project name"></i></label>
                <input type="text" class="form-control" id="projectName" formControlName="projectName"
                    [ngClass]="{ 'is-invalid':  (saveProjectForm.controls['projectName'].dirty || saveProjectForm.controls['projectName'].touched || submitted) && saveProjectForm.controls['projectName'].errors }">
                <div *ngIf="saveProjectForm.controls['projectName'].invalid && (saveProjectForm.controls['projectName'].dirty || saveProjectForm.controls['projectName'].touched || submitted)"
                    class="invalid-feedback">
                    <div *ngIf="saveProjectForm.controls['projectName'].errors?.required">
                        Project Name is required</div>
                    <div *ngIf="saveProjectForm.controls['projectName'].errors?.pattern">
                        Project Name must be a character</div>
                    <div *ngIf="saveProjectForm.controls['projectName'].errors?.maxlength">Project Name
                        must
                        contain max of
                        120
                        characters</div>
                </div>
            </div>
            <div class="required child-control">
                <label for="accountSelection" class="form-labels f-label">Sold to Customer <i class='fa fa-info-circle ml-2 search-info' ngbTooltip="Select sold to customer."></i></label>
                <select class="form-control" id="accountSelection" formControlName="accountSelection"
                    [ngClass]="{ 'is-invalid':  (saveProjectForm.controls['accountSelection'].dirty || saveProjectForm.controls['accountSelection'].touched || submitted) && saveProjectForm.controls['accountSelection'].errors }">
                    <option [ngValue]="undefined" disabled>Select Sold to Customer</option>
                    <option *ngFor="let account of availableAccounts" [ngValue]="account.Id">
                        {{account?.Name}}
                    </option>
                </select>
                <div *ngIf="saveProjectForm.controls['accountSelection'].invalid && (saveProjectForm.controls['accountSelection'].dirty || saveProjectForm.controls['accountSelection'].touched || submitted)"
                    class="invalid-feedback">
                    <div *ngIf="saveProjectForm.controls['accountSelection'].errors.required">Account Name is required
                    </div>
                    <div *ngIf="saveProjectForm.controls['accountSelection'].errors.pattern">
                        Account Name must be a character</div>
                    <div *ngIf="saveProjectForm.controls['accountSelection'].errors.maxlength">Account Name must contain
                        max
                        of
                        100 characters</div>
                </div>
            </div>
        </div>
        <div class="modal-footer d-flex">
            <button type="button" class="dfr-btn-light" (click)="modal.dismiss()">Cancel</button>
            <button type="submit" class="dfr-btn-dark" [disabled]="saveProjectForm.invalid">Save</button>
        </div>
    </form>

</div>