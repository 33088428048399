import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@cpq-environments/environment';
import { Observable } from 'rxjs';

const Salesforce_API = {
  SALESFORCE: 'salesforce',
  OPPORTUNITY: 'opportunity',
  LEAD: 'lead',
  OBJECT: 'object',
  CURRENT_CONTACT: 'current-contact',
  ACCOUNT: 'account',
};


export enum SfdcLeadSource {
  CPQ = 'CPQ'
}

export enum VwsSfdcOpportunityStage {
  DEFAULT = 'Needs Assessment'
}

export interface SfdcOpportunity {
  Name: string;
  StageName: string;
  CloseDate?: Date;
  CPQaccountNumber?: string;
  SFDCaccountField?: string;
  LeadSource?: SfdcLeadSource;
  Pricebook2Id?: string;
}

export interface SfdcLead {
  LastName: string;
  Email: string;
  Company: string;
  OpportunityId__c: string;
  Comments__c: string;
  CPQ_Convert_Lead__c: boolean;
  CPQ_Project__c: string;
}

export interface VwsSfdcOpportunity extends SfdcOpportunity {
  Projected_Ship_Date__c: Date;
  CPQ_Opportunity_Owner_Email__c?: string;
  Outside__c?: boolean;
}

export interface TwgSfdcOpportunity extends SfdcOpportunity {
  CPQ_Quote_Id__c: string;
  Estimated_Close_Value__c: number;
}

// new interface
export interface SfdcTask {
  Subject: string;
  Priority: string;
  Status: string;
  WhoId: string;
  Description: string;
  ActivityDate: Date;
  CPQ_Project_Link__c: string;
}

export interface SfdcContact {
  FirstName: string;
  LastName: string;
  Email: string;
  AccountId: string;
  OwnerId: string;
}
// end interface

export enum sfdcObjectType {
  LEAD = 'Lead',
  OPPORTUNITY = 'Opportunity',
  TASK = 'Task',
  CONTACT = 'Contact'
}

@Injectable(
  { providedIn: 'root' }
)
export class SalesforceProxyService {
  constructor(
    private http: HttpClient) { }

  private backendUrl = environment.B2CConfigs.BackendURL;

  createOpportunity(payload: SfdcOpportunity): Observable<SalesforceResult> {
    return this.createSfdcObject(sfdcObjectType.OPPORTUNITY, payload);
  }

  createLead(payload: SfdcLead): Observable<SalesforceResult> {
    return this.createSfdcObject(sfdcObjectType.LEAD, payload);
  }

  createSfdcObject(sfdcObjectType: sfdcObjectType, payload: any): Observable<SalesforceResult> {
    const url = this.salesforceUrl(Salesforce_API.OBJECT, sfdcObjectType);
    return this.http.post(url, payload);
  }

  getSfdcCurrentContactByEmailId(emailId: string): Observable<any> {
    const url = this.salesforceUrl(Salesforce_API.CURRENT_CONTACT);
    const params = new HttpParams().set("emailId", emailId);
    return this.http.get(url, { params });
  }

  getSfdcAccountById(sfdcAccountId: string): Observable<any> {
    const url = this.salesforceUrl(Salesforce_API.ACCOUNT, sfdcAccountId);
    return this.http.get(url);
  }

  updateSfdcObjectById(objectId, sfdcObjectType: sfdcObjectType, payload): Observable<SalesforceResult> {
    const url = this.salesforceUrl(Salesforce_API.OBJECT, sfdcObjectType, objectId);
    return this.http.put(url, payload);
  }

  salesforceUrl(...args: string[]): string {
    let url = `${this.backendUrl}/${Salesforce_API.SALESFORCE}`;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < args.length; i++) {
      if (args[i] != null) {
        // Do not append null or undefined; doesn't stop empty strings
        url += '/' + args[i];
      }
    }

    return url;
  }
}
export interface SalesforceResult {
  errors?: Array<any>;
  id?: string;
  externalId?: string;
}

