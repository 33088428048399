import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { HeaderService } from '@cpq-app/header/header.service';
import { AlertService } from '@cpq-app/shared/services/alert.service';
import { LogoutService } from '@cpq-app/shared/services/logoutService';
import { MSALService } from '@cpq-app/shared/services/msal.service';
import { environment } from '@cpq-environments/environment';
import { StatusCodes } from 'http-status-codes';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';


export enum SessionVariables {
  PERSONA = 'persona',
  USER_TYPE = 'userType',
  OPCO_NAME = 'opcoName',
  ORGANIZATION_ID = 'organizationId',
  NAVIGATED_TO_BEFORELOGIN_URL = 'navigatedToBeforeLoginUrl'
}

const DELAY_TIME_BEFORE_AUTO_LOGOUT = 2000; // in mS
const TOASTER_TIME_OUT = 5000;
@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class DFRHeaderComponent implements OnInit {
  headerData: any = {};
  userName: string;
  userType: string;
  cxUrl: string;
  ADMIN_ROLE = 'Global System Admin';

  constructor(
    private router: Router,
    private msalService: MSALService,
    private userService: UsersService,
    private headerService: HeaderService,
    private alertService: AlertService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private logoutService: LogoutService,
  ) { }

  ngOnInit(): void {
    this.cxUrl = environment.cxPortal.baseUrl;
    this.spinner.show();
    const loggedInUserAdInfo = this.msalService.getUser().idToken;
    this.getUserDetails(loggedInUserAdInfo.oid).subscribe(res => {
      if (res['statusCode'] !== StatusCodes.OK) {
        this.alertService.error(
          `${res['message']}`
        );
      } else {
        let userData = res['data'];
        let headerData;
        if (userData === null) {
          if (environment.B2CConfigs.superAdminOid !== loggedInUserAdInfo.oid && loggedInUserAdInfo.OpcoName !== sessionStorage.getItem(SessionVariables.OPCO_NAME)) {
            this.toaster.error('User is not registered within application.');
            setTimeout(() => {
              this.logoutService.logout();
            }, DELAY_TIME_BEFORE_AUTO_LOGOUT);
          }
        }
        this.getHeaderDetails(loggedInUserAdInfo.oid).subscribe(res1 => {
          if (res1['statusCode'] !== StatusCodes.OK) {
            this.alertService.error(`${res1['message']}`);
          } else {
            headerData = res1['data'];
            let userRole = '';
            headerData['user'] = {};
            if (userData === null && environment.B2CConfigs.superAdminOid === loggedInUserAdInfo.oid) {
              userRole = 'Super Admin';
              let userName = loggedInUserAdInfo.given_name + ' ' + loggedInUserAdInfo.family_name;
              this.headerService.setUsername(userName);
              headerData['user']['userType'] = 'superadmin';
            } else {
              sessionStorage.setItem(SessionVariables.ORGANIZATION_ID, userData['organizationId']);
              sessionStorage.setItem("UserFirstName", userData['firstName']);
              sessionStorage.setItem("UserLastName", userData['lastName']);
              sessionStorage.setItem("Email", userData['email']);
              userRole = userData['personaName'];
              headerData['persona'] = userRole;
              headerData['user']['userType'] = userData['userType'];
              let userName = userData['firstName'] + ' ' + userData['lastName'];
              this.headerService.setUsername(userName);
              if (userData.isActive !== true) {
                this.toaster.error('User is inactive. Please contact admin in order to login.', '', {
                  timeOut: TOASTER_TIME_OUT
                });
                setTimeout(() => {
                  this.logoutService.logout();
                }, DELAY_TIME_BEFORE_AUTO_LOGOUT);
              } else {
                if (userData['privacyTerm'] === null || userData['privacyTerm']?.isAccepted === false) {
                  this.router.navigate(['/privacy-terms']);
                }
              }
              this.updateLastLoginData(userData);
            }
            this.headerService.getUserName().subscribe((name) => {
              this.userName = sessionStorage.getItem(SessionVariables.USER_TYPE) ? name + `(${sessionStorage.getItem(SessionVariables.USER_TYPE)})` : name;
            });
            const userType = headerData['user']['userType'];
            this.setSessionStorageData(userRole, userType);
            headerData['user']['role'] = userRole;
            headerData['user']['pic'] =
              userRole === 'Super Admin'
                ? './assets/images/administrator-profile-pic.png'
                : userData?.profilePic?.blobUri;

            if (userRole === 'DistributorAdmin') {
              headerData['menuItems'].forEach(function (menuItem) {
                menuItem.subMenus = menuItem.subMenus.filter(subMenu => subMenu.identifier !== 'ORGANIZATIONS');
              });
            }
            let data = headerData
            const accountMenu = {
              id: "",
              identifier: "ACCOUNT_LEVEL",
              logoHeight: "",
              logoPath: "",
              logoWidth: "",
              menuOrder: 3,
              name: "Accounts",
              parentId: "4b56f7e8bc4240889a43fe21bbf9f50c",
              routerLink: "accounts",
              subMenus: []
            }
            const index = headerData.menuItems.findIndex(x => x.identifier === "SETUP");
            data.menuItems[index]?.subMenus.push(accountMenu);
            this.headerData = data;
          }
        });
      }
      this.spinner.hide();
    });
  }

  updateLastLoginData(user) {
    user.lastLoggedIn = new Date().toISOString();
    this.userService.EditProfile(user).subscribe((res) => { },
      err => {
        this.toaster.error(err.message);
      });
  }

  setSessionStorageData(userRole: string, userType: string) {
    sessionStorage.setItem(SessionVariables.PERSONA, userRole);
    sessionStorage.setItem(SessionVariables.USER_TYPE, userType);
  }

  getUserDetails(loggedInUserOid) {
    return this.userService.getUser(loggedInUserOid);
    ;
  }

  getHeaderDetails(loggedInUserOid) {
    return this.headerService.getHeader(loggedInUserOid);
  }

  redirectToCxportal() {
    window.location.href = environment.cxPortal.baseUrl;
  }

  openUserProfile() {
    if (this.userType !== 'superadmin') {
      this.router.navigate(['/profile']);
    } else {
      this.toaster.info('Profile is not available for super admin.');
    }
  }

  openDistributorProfile() {
    this.router.navigate(['/_admin/organizations/profile']);
  }


  changePassword() {
    this.msalService.callChangePasswordPolicy();
  }

  signOut() {
    this.logoutService.logout();
  }

}
